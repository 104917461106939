import { useContext, useState, useEffect } from "react";
import {
	Paper,
	Grid,
	Typography,
	Link,
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	Collapse,
	Tooltip,
} from "@mui/material";
import { savedDataContext } from "../..";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { SnackBarComponent2 } from "../../../../../components/snackbar/SnackBarComponent2";
export default function Rundown({
	enrollId,
	// rundownVisible,
	// setRundownVisible,
}) {
	const { SnackBarComponent, showSnackbar } = SnackBarComponent2();
	const [rundownVisible, setRundownVisible] = useState(false);
	const { savedEnrollData, setSavedEnrollData } =
		useContext(savedDataContext);
	const [rundown, setRundown] = useState(
		savedEnrollData[enrollId]?.rundown || ""
	);

	function handleCheckboxChange() {
		console.log("hide_personal_details");
	}

	// savedEnrollData[enrollId]?.rundown was passing in as undefined on page load, and setting the state to undefined,
	// when the data loads, the state wasn't being reset to the correct string. This catches the data load and correctly sets the rundown state.
	useEffect(() => {
		if (savedEnrollData[enrollId]?.rundown !== undefined) {
			setRundown(savedEnrollData[enrollId]?.rundown);
		}
	}, [savedEnrollData, enrollId]);

	function handleRundownChange(event) {
		setRundown(event.target.value);
	}

	async function handleRundownSave() {
		try {
			const rundownData = {
				rundown: rundown,
				enroll_id: enrollId,
			};
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() + "api/admin/update_rundown/",
				rundownData
			);

			if (response.status === 200) {
				setSavedEnrollData((prevData) => {
					const newState = { ...prevData };
					newState[enrollId].rundown = rundown;
					return newState;
				});
				showSnackbar("Rundown saved successfully", "success");
			} else {
				console.log(response.data);
				showSnackbar("Error saving rundown", "error");
			}
		} catch (error) {
			console.log("Error:", error);
			showSnackbar("Error saving rundown", "error");
		}
	}

	return (
		<>
			{SnackBarComponent}
			<Paper
				sx={{
					marginTop: "8px",
					marginBottom: "8px",
				}}
			>
				<Grid container item xs={12}>
					<Grid item xs={6} pl={1.25} pr={2} pt={1.25}>
						<Typography>
							<Link
								variant="body1"
								underline="hover"
								color="secondary"
								onClick={() => setRundownVisible(!rundownVisible)}
								style={{
									cursor: "pointer",
								}}
							>
								{rundownVisible ? "Hide Rundown" : "Show Rundown"}
							</Link>
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign="right">
						<Tooltip title={"Coming soon"} arrow placement="top">
							<FormControlLabel
								control={
									<Checkbox
										checked={
											savedEnrollData[enrollId]
												?.hide_personal_Details
										}
										onChange={handleCheckboxChange}
										sx={{
											color: "primary.contrastText",
											"&.Mui-checked": {
												color: "primary.contrastText",
											},
										}}
									/>
								}
								label="Hide Details on Client Portal"
							/>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid item xs={12} pt={0.5}>
					<Collapse in={rundownVisible} timeout="auto">
						<Grid container pl={1} pb={1}>
							<Grid item xs={12}>
								<TextField
									multiline
									minRows={4}
									fullWidth
									name="rundown"
									label="Rundown"
									value={rundown || ""}
									onChange={handleRundownChange}
								/>
							</Grid>
							<Grid item xs={12} pt={1}>
								<Button
									variant="outlined"
									color="secondary"
									onClick={handleRundownSave}
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Collapse>
				</Grid>
			</Paper>
		</>
	);
}
