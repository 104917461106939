import { URLS } from "../../../urls";

export const headerNavigationData = [
  {
    text: "Log In",
    link: URLS.AUTH.LOGIN,
  },
  {
    text: "JOIN THE BENCH",
    link: URLS.AUTH.REGISTER,
  },
];

export const headerData = {
  logo_url: "/images/logo-plb.png",
  // logo_url:
  //   "https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/assets/PL+2+LINE+LOGO+2+COLOUR_REVERSED.png",

  headerNavigationItems: headerNavigationData,
};
