export class BrowserUtils{
    
    public static redirect(path: string): void{
        window.location.href = path;
    }

    public static openNewTab(path: string): void{
        window.open(path, "_blank");
    }

    public static refresh(): void{
        window.location.reload();
    }

    public static awsURL(path: string): string {
        // so stupid, but we'll fix later. Just need to add the first part of URL to defaults
            return (path)
    } 
}