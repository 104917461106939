import React from "react";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { UploaderFileProps } from "./types";

export default function FileUploaderComponent({
	imageUploadHandler,
	acceptFile = "*",
	buttonText = "image",
	loading,
	error,
	disabled = false,
}: UploaderFileProps) {
	return (
		<Button
			variant="outlined"
			color="secondary"
			component="label"
			size="small"
			disabled={disabled}
		>
			{loading ? (
				<>
					<CircularProgress
						size={20}
						style={{ marginRight: "10px" }}
					/>
					Uploading...
				</>
			) : (
				<>
					{buttonText}
					<input
						type="file"
						hidden
						onChange={imageUploadHandler}
						accept={acceptFile}
					/>
				</>
			)}
		</Button>
	);
}
