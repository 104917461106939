import { useContext, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	MenuItem,
	Typography,
	TextField,
	IconButton,
	CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SnackbarComponent from "../../../../../../components/snackbar";
import { benchStatus } from "../data";
import { UserActionsContext } from "../UserActions";
import { RESTUtils } from "../../../../../../utilities/RESTUtils";

export default function NewUsersTable({ users, setUsers, loading, setNewUsersLoading, error }) {
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");

	const { changeBenchStatus, handleNameClick } =
		useContext(UserActionsContext);

	function handleCloseSnackbar() {
		setShowSnackbar(false);
		setSnackbarMessage("");
	}
	function setSnackbar(type) {
		if (type === "error") {
			setSnackbarMessage("Something went wrong");
			setSnackbarSeverity("error");
		} else {
			setSnackbarMessage("Hidden successfully");
			setSnackbarSeverity("success");
		}
		setShowSnackbar(true);
	}

	async function changeUserBenchStatus(user_uuid, status) {
		if (changeBenchStatus(user_uuid, status)) {
			setUsers((prev) => {
				const newUsers = prev.map((user) => {
					if (user.user === user_uuid) {
						return { ...user, bench_status: status };
					}
					return user;
				});
				return newUsers;
			});
		}
	}

	async function toggleNewUser(user_uuid) {
		try {
			setNewUsersLoading(true);
			const newUsers = users.filter((user) => user.user !== user_uuid);
			setUsers(newUsers);
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() +
				`api/admin/toggle_new_user/?user_uuid=${user_uuid}`
			);
			if (response.status === 200) {
				setSnackbar("success");
			} else {
				setSnackbar("error");
				console.error("Error hiding new user:", response.data);
			}
		} catch (error) {
			setSnackbar("error");
			console.error("Error hiding new user:", error);
		} finally {
			setNewUsersLoading(false);
		}
	}

	return (
		<>
			<TableContainer
				style={{
					minHeight: 300,
					maxHeight: 300,
					overflowY: "auto",
				}}
			>
				<SnackbarComponent
					show={showSnackbar}
					message={snackbarMessage}
					severity={snackbarSeverity}
					positionVertical="bottom"
					positionHorizontal="center"
					autoHideDuration={5000}
					handleCloseCallback={handleCloseSnackbar}
				/>

				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									width: "35%",
									// backgroundColor: "background.paper",
									borderBottom: "1px solid #6c6e70",
									py: 0.25,
								}}
							>
								Name
							</TableCell>
							<TableCell
								sx={{
									width: "15%",
									// backgroundColor: "background.paper",
									borderBottom: "1px solid #6c6e70",
									py: 0.25,
								}}
							>
								International
							</TableCell>
							<TableCell
								sx={{
									width: "30%",
									// backgroundColor: "background.paper",
									borderBottom: "1px solid #6c6e70",
									py: 0.25,
								}}
							>
								Bench Status
							</TableCell>
							<TableCell
								sx={{
									width: "20%",
									// backgroundColor: "background.paper",
									borderBottom: "1px solid #6c6e70",
									py: 0.25,
								}}
							>
								Hide
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{error && (
							<TableRow>
								<TableCell
									colSpan={4}
									style={{
										textAlign: "center",
										paddingTop: "15px",
										paddingBottom: "15px",
									}}
								>
									<Typography color="error">
										Error fetching users. Please try again.
									</Typography>
								</TableCell>
							</TableRow>
						)}
						{!error && users.length === 0 && !loading && (
							<TableRow>
								<TableCell
									colSpan={4}
									style={{
										textAlign: "center",
										paddingTop: "15px",
										paddingBottom: "15px",
									}}
								>
									<Typography>No users found.</Typography>
								</TableCell>
							</TableRow>
						)}
						{loading && (
							<TableRow>
								<TableCell
									colSpan={4}
									style={{
										textAlign: "center",
										height: "260px",
									}}
								>
									<CircularProgress />
								</TableCell>
							</TableRow>
						)}
						{!error &&
							users.length > 0 &&
							users.map((row) => (
								<TableRow key={row.user}>
									<TableCell
										style={{
											cursor: "pointer",
											borderBottom: "1px solid #6c6e70",
										}}
										onClick={() =>
											handleNameClick(row.user)
										}
									>
										<Typography
											color="primary"
											sx={{
												color: "primary.contrastText",
												"&:hover": {
													color: "primary.main",
												},
											}}
										>
											{(row.first_name != null && row.last_name != null) ? row.first_name +
												" " +
												row.last_name : row.email}
										</Typography>
									</TableCell>
									<TableCell
										style={{
											borderBottom: "1px solid #6c6e70",
										}}
									>
										{row.international ? "Yes" : "No"}
									</TableCell>
									<TableCell
										style={{
											borderBottom: "1px solid #6c6e70",
										}}
									>
										<TextField
											select
											variant="standard"
											value={row.bench_status || ""}
											size="small"
											InputProps={{ inputProps: { sx: { borderBottom: '1px solid lightgrey' } } }}
											onChange={(e) =>
												changeUserBenchStatus(
													row.user,
													e.target.value
												)
											}
											fullWidth
										>
											{benchStatus.map((status) => (
												<MenuItem
													key={status}
													value={status}
													dense
												>
													{status}
												</MenuItem>
											))}
										</TextField>
									</TableCell>
									<TableCell
										style={{
											borderBottom: "1px solid #6c6e70",
										}}
									>
										<IconButton
											onClick={() =>
												toggleNewUser(row.user)
											}
										>
											<ClearIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
