import { Theme } from "@mui/material";
import defaultTheme from "./defaultTheme";
import peterLucasTheme from "./lightTheme";
import stzuminusTheme from "./stzuminusTheme";
import { AvailableTheme, CustomPaletteExtensions } from "./types";
import darkTheme from "./darkTheme";

declare module '@mui/material/styles' {
    interface PaletteOptions extends CustomPaletteExtensions { }
}

export default function getTheme(theme_name = 'default'): Theme {
    switch (theme_name) {
        case AvailableTheme.Dark:
            return darkTheme;
        case AvailableTheme.Light:
            return peterLucasTheme;
        case AvailableTheme.Stzuminus:
            return stzuminusTheme;
        case AvailableTheme.Default:
            return defaultTheme;
    }
    return defaultTheme;
};