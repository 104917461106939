import { Grid, Card, Typography, Tooltip, IconButton } from "@mui/material";
import { CustomAvatar, typographyStyle } from "../../styles";
import MailIcon from "@mui/icons-material/Mail";
import RedoIcon from "@mui/icons-material/Redo";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";

export default function RejectedCard({ candidate, changeUserStatus }) {
    const { theme } = useThemeContext();

    return (
        <Grid item xs={12}>
            <Card
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    backgroundColor: theme === 'light' ? 'lightgrey' : "#222222",
                }}
            >
                <Grid item xs={2.8}>
                    <CustomAvatar src={candidate.image_url} />
                </Grid>

                <Grid item xs={8.2}>
                    <Typography variant="h8" sx={typographyStyle}>
                        {`${candidate.first_name} ${candidate.last_name}`}
                    </Typography>
                </Grid>


                {candidate.has_notes && (
                    <Tooltip
                        title="Has Notes"
                        arrow
                        placement="top"
                    >
                        <MailIcon fontSize="small" />
                    </Tooltip>
                )}

                <Tooltip
                    title="Reactivate"
                    arrow
                    placement="top"
                >
                    <IconButton
                        color="error"
                        onClick={() =>
                            changeUserStatus(
                                "proposed",
                                candidate.user
                            )
                        }
                    >
                        <RedoIcon />
                    </IconButton>
                </Tooltip>
            </Card>
        </Grid>
    )
}