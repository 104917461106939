import {
	Box,
	TextField,
	InputAdornment,
	IconButton,
	Tooltip,
	FormControlLabel,
	Checkbox,
	Button,
	Grid,
	Link,
	Typography,
	CircularProgress
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReCaptchaComponent from "../../../components/recaptcha";
import { useContext } from "react";
import { LoginContext } from ".";

export default function SignInForm() {
	const { handleSubmit, showPassword, setShowPassword, setCaptchaValue, errorMessage,
		passwordRecoveryHandle, buttonLoading, recaptchaError, setRecaptchaError,
		rememberMe, setRememberMe, email, setEmail } = useContext(LoginContext);
	return (
		<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
			<TextField
				margin="normal"
				required
				fullWidth
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				id="email"
				label="Email"
				name="email"
				autoComplete="email"
				autoFocus
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								color: "secondary.main",
							}}
						>
							<EmailIcon />
						</InputAdornment>
					),
				}}
				inputProps={{ maxLength: 254 }}
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				type={showPassword ? "text" : "password"}
				id="password"
				autoComplete="current-password"
				InputProps={{
					style: {
						fontFamily: "Arial",
					},
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								color: "secondary.main",
							}}
						>
							<LockIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment
							position="end"
							sx={{
								color: "secondary.main",
							}}
						>
							<Tooltip
								title={
									showPassword
										? "Hide Password"
										: "Show Password"
								}
								arrow
								placement="top"
							>
								<IconButton
									onClick={() => {
										setShowPassword(!showPassword);
									}}
								>
									{showPassword ? (
										<VisibilityOffIcon />
									) : (
										<VisibilityIcon />
									)}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
			/>
			<FormControlLabel
				sx={{
					marginBottom: "10px",
					paddingLeft: "0px",
				}}
				control={
					<Checkbox
						checked={rememberMe}
						onChange={(e) => setRememberMe(e.target.checked)}
						value="remember"
						color="primary"
						sx={{
							color: "primary.contrastText",
							"&.Mui-checked": {
								color: "primary.contrastText",
							},
						}}
					/>
				}
				label="Remember me"
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<ReCaptchaComponent setCaptchaValue={setCaptchaValue} recaptchaError={recaptchaError} setRecaptchaError={setRecaptchaError} />
			</div>
			{errorMessage && (
				<Typography
					component="p"
					variant="body1"
					color={"error.main"}
					fontWeight={"bold"}
					paddingTop={2}
				>
					{errorMessage}
				</Typography>
			)}
			<Button
				type="submit"
				fullWidth
				variant="outlined"
				color="secondary"
				sx={{ mt: 2, mb: 2 }}
				disabled={buttonLoading}
				startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
			>
				{buttonLoading ? "Signing in" : "Sign In"}
			</Button>
			<Grid container>
				<Grid item xs>
					<Link
						onClick={passwordRecoveryHandle}
						variant="body1"
						href="#"
						color={"secondary.main"}
						underline="none"
					>
						Forgot Password?
					</Link>
				</Grid>
				<Grid item>
					<Link
						href="/register"
						variant="body1"
						color={"secondary.main"}
						underline="none"
					>
						{"Register"}
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
}
