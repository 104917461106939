import { Grid, Typography, Stack, Link } from "@mui/material";
import EightStageBadges from "../../../../components/projectStrengths/eightStage/EightStageBadges";
import ThreeDSliders from "../../../../components/projectStrengths/3DPersona/ThreeDSliders";

export default function QuizResults({ personality, eight_stage, onEditPage, uuid }) {

    return (
        <Grid container >
            <Grid item xs={6} pt={personality ? '25px' : '80px'} >
                {personality ? (
                    <Stack direction="column" alignItems="center" px={1}>
                        <ThreeDSliders threeDData={personality} />
                    </Stack>
                ) : (
                    onEditPage && (
                        <Typography sx={{ textAlign: "center" }}>
                            Complete your{" "}
                            <Link href="/personality_quiz" variant="body1" color="secondary.main">
                                personality assessment
                            </Link>
                            .
                        </Typography>
                    )
                )}
            </Grid>
            <Grid item xs={6} pt={eight_stage ? '25px' : '80px'} >
                {eight_stage ? (
                    <>
                        <EightStageBadges eightStageData={eight_stage} />
                        {personality && onEditPage && (
                            <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                                <a
                                    href={`/personality/${uuid}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "white" }}
                                >
                                    Learn More
                                </a>
                            </div>
                        )}
                    </>
                ) : (
                    onEditPage && (
                        <Typography sx={{ textAlign: "center" }}>
                            Find your{" "}
                            <Link href="/project_strengths" variant="body1" color="secondary.main">
                                project strengths
                            </Link>
                            .
                        </Typography>
                    )
                )}
            </Grid>
        </Grid>
    );
}