import { Card, Divider, Grid, Typography } from '@mui/material';
import ReferencesSkillsTable from './ReferenceSkillsTable';

export default function ReferencePreview({ curReferenceData }) {
    const skills = curReferenceData.skills;

    return (
        <>
            <Card sx={{ padding: "20px", marginTop: '10px' }}>
                <Grid container>
                    <Grid container>
                        <Grid item xs={4.5}>
                            <Typography variant="h5">
                                Job Details
                            </Typography>
                        </Grid>
                        <Grid item xs={7.5}>
                            <Typography variant="h5">
                                Overview
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item xs={4.5} py={1}>
                        <Typography variant="body1">
                            {curReferenceData.title}
                        </Typography>
                        <Typography variant="body2">{curReferenceData.start_date} - {curReferenceData.end_date || "Current"}</Typography>

                        <Typography variant="body2">
                            {curReferenceData.organization && curReferenceData.location
                                ? `${curReferenceData.organization} | ${curReferenceData.location}`
                                : curReferenceData.organization || curReferenceData.location}
                        </Typography>

                    </Grid>
                    <Grid item xs={7.5} py={1}>
                        <Typography variant="body1">
                            {curReferenceData.overview}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} pt={1}>
                        <Typography variant="h5">
                            Skills
                        </Typography>
                        <Divider />
                        <ReferencesSkillsTable SkillsData={skills} />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}