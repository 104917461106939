import { Avatar, Box, Divider, IconButton, Link, Menu, MenuItem, Typography, Switch, FormControlLabel } from '@mui/material';
import React from 'react'
import { switchStyle, userMenuLinkStyle } from './style';
import { userMenuProps } from './types';
import { SessionUtils } from '../../../utilities/SessionUtils';
import { BrowserUtils } from '../../../utilities/BrowserUtils';
import { URLS } from '../../../urls';
import { useThemeContext } from '../../../themes/ThemeProviderWrapper';

function UserMenu({ user }: userMenuProps) {
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	let name;
	if (user.first_name && user.last_name) {
		name = user.first_name + ' ' + user.last_name;
	} else {
		name = ' ';
	}

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogOut = () => {
		SessionUtils.removeCurrentUserSession();
		BrowserUtils.redirect('/login');
	}

	const { toggleTheme, theme } = useThemeContext();

	return (
		<Box sx={{ flexGrow: 0 }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					padding: "10px"
				}}>
				<Typography variant='body2' component="div"
					sx={{
						p: "10px",
						fontSize: "1rem",
						color: '#e2e3e4'
					}}>
					{name}
				</Typography>

				<IconButton onClick={handleOpenUserMenu}
					sx={{ p: 0 }}>
					{/* zyzz this makes no sense to me. Issue is changing the profile pic (which doesn't change url)
					doesn't cause avatar to update. Fix is to add imageHash (in profile), but if we do it here, even 
					with an empty value , it works. However,removing imageHash breaks it, even tho this value is alwa
						ys empty. user.imageHash is never set, but removing it breaks (even though image_url should 
						update to have a new hash value, and thus cause an update ???? no idea*/}
					<Avatar alt={name} src={(user.image_url) ? `${user.image_url}?${user?.imageHash}` : "/images/profile_image_placeholder.png"}
						sx={{
							borderWidth: "2px",
							borderStyle: "solid",
							borderColor: "#383838"
						}} />

				</IconButton>

			</Box>

			<Menu
				sx={{
					mt: '45px',
				}}
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<Link href={URLS.DASHBOARD.CANDIDATE} underline='none' color={'inherit'}>
					<MenuItem onClick={handleCloseUserMenu} sx={userMenuLinkStyle}>
						Dashboard
					</MenuItem>
				</Link>
				<Link href={URLS.USER.PROFILE} underline='none' color={'inherit'}>
					<MenuItem onClick={handleCloseUserMenu} sx={userMenuLinkStyle}>
						Profile
					</MenuItem>
				</Link>
				<Link href="#" onClick={handleLogOut} underline='none' color={'inherit'}>
					<MenuItem onClick={handleCloseUserMenu} sx={userMenuLinkStyle}>
						Log Out
					</MenuItem>
				</Link>


				{/* {user.is_admin && */}
				<Divider />
				{/* } */}
				{/* {user.is_admin && */}
				<MenuItem>
					<FormControlLabel
						control={<Switch onChange={toggleTheme} sx={switchStyle} />}
						label={theme === 'dark' ? "Dark Mode" : "Light Mode"}
					/>
				</MenuItem>
				{/* } */}
			</Menu>
		</Box >
	)
}

export default UserMenu;