import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";

export default function Notification({ notification, handleRemoveNotification }) {
    const { theme } = useThemeContext();
    function getIcon(importance) {
        switch (importance) {
            case 0:
                return <InfoIcon sx={{ color: '#B59900' }} />;
            case 1:
                return <CheckCircleIcon sx={{ color: 'green' }} />;
            case 2:
                return <WarningIcon sx={{ color: 'primary.main' }} />;
            case 3:
                return <ErrorIcon sx={{ color: '#B59900' }} />;
            default:
                return <MailIcon sx={{ color: 'grey' }} />;
        }
    }

    function getTimeSince(timestamp) {
        const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);

        let interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
            return `${interval}y`;
        }
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return `${interval}mo`;
        }
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return `${interval}d`;
        }
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return `${interval}h`;
        }
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return `${interval}m`;
        }
        return `${Math.floor(seconds)}s`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme === 'light' ? 'white' : 'secondary.contrastText',
                p: 1.5,
                // mt: 1,
                borderBottom: '1px solid #444',
            }}
        >
            {getIcon(notification.importance)}

            <Box sx={{ flexGrow: 1, textAlign: 'left', pl: 1 }}>
                <Typography variant="body2">
                    <strong>{notification.title}</strong>
                </Typography>
                <Typography variant="body2">
                    {notification.details}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontSize: '0.75rem', color: 'rgba(255, 255, 255, 0.7)' }}>
                    {getTimeSince(notification.timestamp)}
                </Typography>
            </Box>

            <Tooltip title="Delete" arrow placement="top">
                <IconButton onClick={() => handleRemoveNotification(notification.id)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}