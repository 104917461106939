import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useRef } from 'react'
import { useThemeContext } from "../../themes/ThemeProviderWrapper";

function ReCaptchaComponent({ setCaptchaValue, recaptchaError, setRecaptchaError }) {
  const { theme } = useThemeContext();

  useEffect(() => {
    if (recaptchaError) {
      window.grecaptcha.reset();
      setRecaptchaError(false);
    }
  }
    , [recaptchaError])
  const captchaChange = (value) => {
    setCaptchaValue(value)
  };
  const useInstanceLoader = (siteKey) => {
    const helperInstance = useRef(null);

    useEffect(() => {
      if (!window.grecaptcha) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          helperInstance.current = window.grecaptcha;
        };
        document.body.appendChild(script);
      } else {
        helperInstance.current = window.grecaptcha;
      }

      return () => {
        if (helperInstance.current) {
          helperInstance.current.reset();
        }
      };
    }, []);

    return helperInstance.current;
  };


  // Localhost

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const instance = useInstanceLoader(siteKey);

  return (
    <>
      {/* <script src="https://www.google.com/recaptcha/api.js" async defer></script> */}
      <ReCAPTCHA
        sitekey={siteKey}
        onChange={captchaChange}
        grecaptcha={instance}
        theme={theme === 'dark' ? "dark" : "light"}


      />

    </>
  );
}

export default ReCaptchaComponent;