import { SessionUtils } from "../../../../../utilities/SessionUtils";

export const steps = [
  {
    title: "Profile Picture and Video",
    desc: "Add a profile picture and video to your profile",
    completed: true, // probably not where this data will come from, but here for now
  },
  {
    title: "Quizzes",
    desc: "Complete the personality assessment and project strengths quizzes",
    completed: true,
  },
  {
    title: "Overview / Qualifications",
    desc: "Update your overview and qualifications",
    completed: true,
  },
  {
    title: "Experience & Skills",
    desc: "Update your experience and skills",
    completed: true,
  },
  {
    title: "Notable Projects",
    desc: "Add your notable projects",
    completed: false,
  },
  {
    title: "Educations",
    desc: "Update your education",
    completed: false,
  },
  {
    title: "Awards",
    desc: "Add any awards you have received",
    completed: false,
  },
  {
    title: "References (?)",
    desc: "Add references",
    completed: false,
  },
];
export const stepperData = [
  {
    title: 'Profile Picture and Video',
    id: 'media',
    desc: 'Add a profile picture and a video introducing yourself to your profile.',
  },
  {
    title: 'Quizzes',
    id: 'quizzes',
    desc: 'Complete the personality assessment and project strengths quizzes.',
  },
  {
    title: 'Overview / Qualifications',
    id: 'details',
    desc: 'Update your overview and qualifications to demonstrate your core strengths.',
  },
  {
    title: 'Experience & Skills',
    id: 'experience',
    desc: 'Update your experience and skills.',
  },
  {
    title: 'Notable Projects',
    id: 'notableProjects',
    desc: 'Add any notable projects you have worked on in the past. Adding up to three is recommended!',
  },
  {
    title: 'Educations',
    id: 'education',
    desc: 'Update your past educations.',
  },
  {
    title: 'Awards / Recognitions',
    id: 'awards',
    desc: 'Add any awards or recognitions you have received.',
  },
  {
    title: 'References',
    id: 'references',
    desc: 'Add references.',
  },
]

export const checkboxes = [
  ["Profile Picture", "Video"],
  ["Personality Assessment", "Project Strengths"],
  ["Overview", "Qualifications"],
  ["Experience", "Skills"],
  ["Notable Projects"],
  ["Educations"],
  ["Awards"],
  ["References"],
];

export const stepData = [
  {
    title:
      "Add Profile Media",
    description:
      "A profile picture, cover image, and video helps your profile stand out and showcases your personality.",
    image: SessionUtils.getCurrentUserSession()?.image_url || 'images/profile_image_placeholder.png',
    leftXs: 8,
    rightXs: 4,
    imgHeight: '20ch',
  },
  {
    title: "Complete the Personality and Project Strengths Quizzes",
    description:
      "These quizzes highlight your key abilities and personality, ensuring employers know you'll be a great fit.",
    image: '/images/characters/Peter_Lucas_Guardian.png',
    leftXs: 9.5,
    rightXs: 2.5,
    imgHeight: '20ch',

  },
  {
    title: "Add Overview and Qualifications",
    description:
      "A well-crafted professional overview summarizes your professional identity and career goals, while your qualifications showcase your relevant skills, certifications, and experience.",
    image: '/images/characters/Peter_Lucas_Thinker.png',
    leftXs: 9.5,
    rightXs: 2.5,
    imgHeight: '20ch',
  },
  {
    title: "Add Work Experiences and Skills",
    description:
      "Detailed descriptions of your past roles and responsibilities provide concrete evidence of your capabilities. Highlighting your acquired skills at these experiences allows employers to match your expertise with job requirements.",
    image: '/images/characters/Peter_Lucas_Entrepreneur.png',
    leftXs: 9.5,
    rightXs: 2.5,
    imgHeight: '20ch',
  },
  {
    title: "Add Notable Projects",
    description:
      " Your project summaries demonstrate real-world experience and are concrete examples of your background and knowledge. This differentiates you from others by highlighting your personal contributions and successful project outcomes.",
    image: '/images/characters/Peter_Lucas_Inventor.png',
    leftXs: 9,
    rightXs: 3,
    imgHeight: '20ch',
  },
  {
    title: "Add Educational Background",
    description:
      "Your educational history provides the foundation of your qualifications. Keeping this information current ensures that all your academic achievements are recognized, including any recent courses or certifications that enhance your skill set.",
    image: '/images/characters/Peter_Lucas_Examiner.png',
    leftXs: 9.5,
    rightXs: 2.5,
    imgHeight: '20ch',
  },
  {
    title: "Add Awards and Recognitions",
    description:
      "Listing your awards and recognitions highlights your achievements and excellence. This builds your professional reputation and distinguishes you as a standout candidate.",
    image: '/images/characters/Peter_Lucas_Champion.png',
    leftXs: 10.5,
    rightXs: 1.5,
    imgHeight: '20ch',
  },
  {
    title: "Add References",
    description:
      "Including references adds credibility to your profile. Positive testimonials reinforce your reputation and provide insights into your character and work ethic, boosting employer confidence in your abilities and experience.",
    image: '/images/characters/Peter_Lucas_Advocate.png',
    leftXs: 9.5,
    rightXs: 2.5,
    imgHeight: '20ch',
  },
];
