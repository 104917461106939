import { useState } from "react";
import {
  Typography,
  CircularProgress,
  IconButton,
  Grid,
  Tooltip,
  Box
} from "@mui/material";
import IntroVideoModal from "./IntroVideoModal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import UploadIcon from "@mui/icons-material/Upload";
import { styled } from "@mui/system";
import UploadMediaModal from "./UploadMediaModal";

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '95%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black overlay
  zIndex: 3, // Ensure the overlay is above the image
  transition: 'opacity 0.5s ease',
  opacity: 0, // Initially hidden
  borderRadius: '50%',
});
export default function ImageVideoHandler({
  image_url,
  video_url,
  isEdit = false,
  uploadImage,
  uploadVideo,
  uploadError = false,
  imageLoading,
  videoLoading,
  imgHeight = "auto",
  imgWidth = "auto",
  objectFit = "fill",
  imageHash,
  showUploadModal,
  setShowUploadModal,
  isPrintMode = false
}) {
  const [imgIsHovered, setImgIsHovered] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    width: imgWidth,
    height: imgHeight,
    objectFit: objectFit,
    cursor: isEdit ? "pointer" : "default",
    borderRadius: "50%",
    border: "2px solid white",
    overflowClipMargin: 'unset' // Chromium browsers artifact when downsizing high resolution image files. This is a temp fix, but should send smaller image files from the backend. See https://stackoverflow.com/questions/74502978/object-fit-cover-gives-pixelated-images-on-chrome
  };

  function handleImageUpload(event) {
    const file = event.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 5MB limit (change as needed)
    const allowedFileTypes = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (file) {
      if (!allowedFileTypes.exec(file.name)) {
        alert('Invalid file type. Please upload an image file (jpg, jpeg, png, gif).');
      } else if (file.size > maxSizeInBytes) {
        alert('File size exceeds the limit of 10MB. Please choose a smaller file.');
      } else {
        uploadImage(file);
      }
    }
  }

  function handleVideoUpload(event) {
    const file = event.target.files[0];
    const maxSizeInBytes = 150 * 1024 * 1024; // 150MB limit
    const allowedFileTypes = /(\.mp4|\.webm|\.ogg)$/i;

    if (file) {
      if (!allowedFileTypes.exec(file.name)) {
        alert('Invalid file type. Please upload a video file (mp4, webm, ogg).');
      } else if (file.size > maxSizeInBytes) {
        alert('File size exceeds the limit of 150MB. Please choose a smaller file.');
      } else {
        uploadVideo(file);
      }
    }
  }

  function handleVideoDelete() {
    console.log("Delete video");
  }

  function openImageFileInput() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.style.display = "none";
    input.addEventListener("change", handleImageUpload);
    input.click();
  }
  function openVideoFileInput() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/*";
    input.style.display = "none";
    input.addEventListener("change", handleVideoUpload);
    input.click();
  }

  const hasMedia = image_url || video_url;

  return (
    <>

      {showUploadModal && (
        <UploadMediaModal
          show={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          openImageFileInput={openImageFileInput}
          openVideoFileInput={openVideoFileInput}
          imageLoading={imageLoading}
          videoLoading={videoLoading}
        />
      )}

      {showVideoModal && (
        <IntroVideoModal
          video_url={video_url}
          show={showVideoModal}
          setShowVideoModal={setShowVideoModal}
          openVideoFileInput={openVideoFileInput}
          handleVideoDelete={handleVideoDelete}
          videoLoading={videoLoading}
          isEdit={isEdit}
        />
      )}

      {isEdit ? (
        <Grid
          container
          item
          xs={12}
          position="relative"
          justifyContent="center"
        >
          <label
            htmlFor="imageInput"
            style={{ position: "relative", zIndex: 4 }}
          >
            <div
              onMouseOver={(e) => {
                setImgIsHovered(true);
              }}
              onMouseOut={(e) => {
                setImgIsHovered(false);
              }}
            >
              <DarkOverlay style={{ opacity: imgIsHovered ? 1 : 0, cursor: isEdit ? 'pointer' : 'default', }} />
              <img
                src={image_url ? image_url + '?' + imageHash : "/images/profile_image_placeholder.png"}
                alt="profile"
                style={imageStyle}
                onClick={() => { document.getElementById("imageInput") }}
              />

              <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: imgIsHovered ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
                color: "white",
                zIndex: 6,
                cursor: "pointer",
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
              }}>
                <Typography>
                  {image_url ? "Edit image" : "Upload image"}
                </Typography>
              </Box>

              <input
                id="imageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>

            <Tooltip
              title={!hasMedia ? "Upload Media" : video_url ? "View Video" : "Upload Video"}
              arrow
              placement="top"
            >
              <IconButton
                onClick={() => {
                  if (!hasMedia) {
                    setShowUploadModal(true);
                  } else if (video_url) {
                    setShowVideoModal(true);
                  } else {
                    openVideoFileInput();
                  }
                }}
                sx={{
                  border: "1px solid white",
                  // backgroundColor: "gray",
                  backgroundColor: "transparent",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "lightgray",
                    color: "black",
                  },
                  position: "absolute",
                  bottom: "10%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 7,
                }}
              >
                {videoLoading || imageLoading ? (
                  <CircularProgress size={24} />
                ) : video_url ? (
                  <PlayArrowIcon />
                ) : (
                  <UploadIcon />
                )}
              </IconButton>
            </Tooltip>
          </label>
        </Grid>
      ) : (
        <Grid container>
          <Grid
            container
            item
            xs={12}
            position="relative"
            justifyContent="center"
          >
            <img
              src={image_url ? image_url + '?' + Date.now() : "/images/profile_image_placeholder.png"}
              alt="profile"
              style={imageStyle}
            />

            {!isPrintMode && (
              video_url && (
                <Tooltip title={"View Video"} arrow placement="top">
                  <IconButton
                    onClick={() => setShowVideoModal(true)}
                    sx={{
                      border: "1px solid white",
                      // backgroundColor: "gray",
                      backgroundColor: "transparent",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "lightgray",
                        color: "black",
                      },
                      position: "absolute",
                      bottom: "10%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 7,
                    }}
                  >
                    {videoLoading ? <CircularProgress /> : <PlayArrowIcon />}
                  </IconButton>
                </Tooltip>
              )
            )}

          </Grid>
        </Grid>
      )}
    </>
  );
}
