import { useState } from "react";
import { ButtonGroup, IconButton, Tooltip, Box, Divider } from "@mui/material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import CancelIcon from "@mui/icons-material/Cancel";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

export default function SpotlightActions({
  handleViewProfile,
  candidateData,
  openModal,
  user,
  setShowInterviewModal,
  changeUserStatus,
  user_uuid,
}) {
  const [showAddedMessage, setShowAddedMessage] = useState(false);

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap={'nowrap'}>
      <ButtonGroup size="small">
        <Tooltip title="View Profile" arrow placement="top">
          <IconButton color="primary" onClick={handleViewProfile}>
            <AccountBoxIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={candidateData.notes.length > 0 ? "View Notes" : "Add Notes"}
          arrow
          placement="top"
        >
          <IconButton
            color="primary"
            onClick={openModal}
            sx={{
              paddingTop: '10px',
              color: candidateData.notes.length > 0 ? "white" : null,
            }}
          >
            {candidateData.notes.length === 0 ? (
              <AddCommentOutlinedIcon />
            ) : (
              <AssignmentOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip
          title={
            user.interview_requested
              ? "Interview Already Requested"
              : "Request Interview"
          }
          arrow
          placement="top"
        >
          {user.interview_requested ? (
            <span>
              <IconButton color="primary">
                <PeopleRoundedIcon color="primary" />
              </IconButton>
            </span>
          ) : (
            <IconButton onClick={() => setShowInterviewModal(true)} color="primary">
              <PeopleOutlineIcon />
            </IconButton>
          )}
        </Tooltip>
      </ButtonGroup>

      <Divider orientation="vertical" flexItem />

      <ButtonGroup size="small">
        <Tooltip title="Not Interested" arrow placement="top">
          <span>
            <IconButton
              color="primary"
              disabled={user.status === "rejected"}
              onClick={() => changeUserStatus("rejected", user_uuid)}
            >
              <CancelIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            user.status === "shortlisted"
              ? showAddedMessage
                ? "Added!"
                : "Remove from Short List"
              : showAddedMessage
                ? "Removed"
                : "Add to Short List"
          }
          arrow
          placement="top"
        >
          <span>
            <IconButton
              color="primary"
              // disabled={user.status === "shortlisted"}
              onClick={() => {
                user.status === "shortlisted" ?
                  changeUserStatus("proposed", user_uuid)
                  : changeUserStatus("shortlisted", user_uuid);
                setShowAddedMessage(true);
                setTimeout(() => {
                  setShowAddedMessage(false);
                }, 2000);
              }}
            >
              {user.status === "shortlisted" ? (
                <StarIcon sx={{ color: "#efa032" }} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
}
