import { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { AvailableTheme } from './types';
import getTheme from '.';
import { SessionUtils } from '../utilities/SessionUtils';

const ThemeContext = createContext();

export const ThemeProviderWrapper = ({ children }) => {
    const savedTheme = SessionUtils.getThemeSession();
    const [theme, setTheme] = useState(savedTheme || AvailableTheme.Dark);

    useEffect(() => {
        const savedTheme = SessionUtils.getThemeSession();
        if (savedTheme && savedTheme !== theme) {
            setTheme(savedTheme === 'light' ? AvailableTheme.Light : AvailableTheme.Dark);
        }
    }, []); // Empty dependency array ensures this only runs once on mount

    function toggleTheme() {
        setTheme(prevTheme =>
            prevTheme === AvailableTheme.Light ? AvailableTheme.Dark : AvailableTheme.Light
        );
        SessionUtils.setThemeSession(theme === AvailableTheme.Light ? 'dark' : 'light');
    }

    const themeObject = createTheme(getTheme(theme));

    const globalStyles = {

        /* For WebKit browsers (Chrome, Safari) */
        '::-webkit-scrollbar': {
            width: '12px',
        },
        '::-webkit-scrollbar-thumb': {
            background: theme === AvailableTheme.Dark ? '#888' : '#bbb',
        },
        '::-webkit-scrollbar-track': {
            background: theme === AvailableTheme.Dark ? '#333' : '#eee',
        },
    };


    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <ThemeProvider theme={themeObject}>
                <GlobalStyles styles={globalStyles} />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
