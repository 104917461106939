import { Chip, Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { darken } from "@mui/material/styles";

export default function XPLevelModal({ open, setOpen, updateProfileAPI }) {
	const options = [
		{ label: "Junior", color: "#37b526" },
		{ label: "Intermediate", color: "#bf640f" },
		{ label: "Senior", color: "#2284e7" },
	];
	const handleChipClick = (value) => {
		updateProfileAPI("experience_level", value);
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Select an Experience Level</DialogTitle>

			<Divider
				variant="middle"
				sx={{
					backgroundColor: "grey",
					marginTop: "-10px",
				}}
			/>

			<Stack spacing={1.5} padding={2}>
				{options.map((option) => (
					<Chip
						key={option.label}
						sx={{
							backgroundColor: option.color,
							width: "100%",
							"&:hover": {
								backgroundColor: darken(option.color, 0.3),
							},
						}}
						label={option.label}
						onClick={() => handleChipClick(option.label)}
					/>
				))}
			</Stack>
		</Dialog>
	);
}
