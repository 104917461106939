import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import GroupsIcon from "@mui/icons-material/Groups";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArchiveIcon from '@mui/icons-material/Archive';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import UndoIcon from '@mui/icons-material/Undo';
dayjs.extend(relativeTime);

export const columns = ({ toggle_job_public, toggle_job_active }) => [
	{
		field: "title",
		headerName: "Job Title",
		flex: 0.65,
		editable: false,
		headerAlign: "center",
		align: "left",
	},
	{
		field: "client",
		headerName: "Client",
		flex: 0.6,
		editable: false,
		headerAlign: "center",
		align: "center",
	},
	{
		field: "location.city",
		headerName: "City",
		flex: 0.4,
		headerAlign: "center",
		align: "center",
		valueGetter: (params) => params.row.city,
	},
	// {
	// 	field: "location.region",
	// 	headerName: "Region",
	// 	flex: 0.3,
	// 	headerAlign: "center",
	// 	align: "center",
	// 	valueGetter: (params) => params.row.region,
	// },
	{
		field: "date_created",
		headerName: "Date Created",
		flex: 0.3,
		headerAlign: "center",
		align: "center",
		valueFormatter: (params) => {
			const date = new Date(params.value);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		},
	},
	// {
	// 	field: "viewJob",
	// 	headerName: "View Job",
	// 	sortable: false,
	// 	filterable: false,
	// 	flex: 0.3,
	// 	headerAlign: "center",
	// 	align: "center",
	// 	renderHeader: (params) => <span>{params.colDef.headerName}</span>,
	// 	renderCell: (params) => (
	// 		<IconButton
	// 			onClick={() =>
	// 				BrowserUtils.openNewTab(`job/${params.row.uuid}`)
	// 			}
	// 			color="primary"
	// 			aria-label="View Profile"
	// 		>
	// 			{/* <VisibilityIcon sx={{color: '#00b599'}}/> */}
	// 			<SearchIcon />
	// 			{/* <VisibilityIcon sx={{color: '#26FFDD'}}/> */}
	// 		</IconButton>
	// 	),
	// },

	{
		field: "viewWorkable",
		headerName: "View Workable",
		sortable: false,
		filterable: false,
		flex: 0.3,
		headerAlign: "center",
		align: "center",
		renderHeader: (params) => <span>{params.colDef.headerName}</span>,
		renderCell: (params) => (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					let jobID = params.row.workable_uuid;
					let newURL = `https://peterlucas.workable.com/backend/jobs/${jobID}/browser/applied`;
					window.open(newURL, "_blank");
				}}
				color="primary"
				aria-label="View Profile"
			>
				{/* <OpenInNewIcon sx={{color:'#B59900'}}/> */}
				{/* <OpenInNewIcon sx={{color: '#00B53F'}}/> */}
				<OpenInNewIcon />
				{/* <OpenInNewIcon sx={{color: '#26FF71'}}/> */}
			</IconButton>
		),
	},
	{
		field: "public",
		headerName: "Public",
		sortable: false,
		filterable: false,
		flex: 0.2,
		headerAlign: "center",
		align: "center",
		renderHeader: (params) => <span>{params.colDef.headerName}</span>,
		renderCell: (params) => (
			<Tooltip title={params.row.public ? "Hide" : "Make Public"} arrow placement="top">
				<IconButton
					onClick={(event) => {
						event.stopPropagation();
						toggle_job_public(params.row);
					}}
					color="primary"
					aria-label="Public"
				>
					{params.row.public ? <VisibilityIcon /> : <VisibilityOffIcon />}
				</IconButton>
			</Tooltip>
		),
	},
	{
		field: "archive",
		headerName: "Archive",
		sortable: false,
		filterable: false,
		flex: 0.2,
		headerAlign: "center",
		align: "center",
		renderHeader: (params) => <span>{params.colDef.headerName}</span>,
		renderCell: (params) => (
			<Tooltip title={params.row.active ? "Archive" : "Undo"} arrow placement="top">
				<IconButton
					onClick={(event) => {
						event.stopPropagation();
						toggle_job_active(params.row);
					}}
					color="primary"
					aria-label="Archive"
				>
					{params.row.active ? <ArchiveIcon /> : <UndoIcon />}
				</IconButton>
			</Tooltip>
		),
	},
	{
		field: "viewClientPortal",
		headerName: "View Client Portal",
		sortable: false,
		filterable: false,
		flex: 0.3,
		headerAlign: "center",
		align: "center",
		renderHeader: (params) => <span>{params.colDef.headerName}</span>,
		renderCell: (params) => (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					BrowserUtils.openNewTab(`client_portal/${params.row.uuid}`);
				}}
				color="primary"
				aria-label="View Profile"
			>
				{/* <GroupsIcon sx={{color: '#9900B5'}}/> */}
				{/* <GroupsIcon sx={{color: '#0077B5'}}/> */}
				<GroupsIcon />
				{/* <GroupsIcon sx={{color: '#26B4FF'}}/> */}
			</IconButton>
		),
	},
];
