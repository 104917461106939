import { useState } from "react";
import { Typography, CircularProgress, Button, Grid } from "@mui/material";
import GraphEmail from "./message/GraphEmail";
import { typographyStyle } from "./styles";
import SendEmailForm from "./sendMessage/SendEmailForm";

export default function MessageTab2({
    emailData,
    setEmailData,
    user,
    emailLoading,
    showSnackbar,
    showSendEmail = false,
    isSendingNewEmail,
    setIsSendingNewEmail,
    job = false,
    fromCS = false,
    fromAD = false
}) {

    const [isReplying, setIsReplying] = useState(false);

    function toggleSendingNewEmail() {
        if (!isReplying) {
            setIsSendingNewEmail((prev) => !prev);
        }
    }

    if (emailLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            {!isSendingNewEmail && !isReplying && showSendEmail && (
                <Grid container justifyContent="center">
                    <Button onClick={() => toggleSendingNewEmail()} size="small" variant="outlined" color="secondary">
                        Send New Email
                    </Button>
                </Grid>
            )}

            {isSendingNewEmail && (
                <SendEmailForm
                    emailData={emailData}
                    setEmailData={setEmailData}
                    users={[user]}
                    setShow={setIsSendingNewEmail}
                    job={job}
                    showSnackbar={showSnackbar}
                    fromAD={fromAD}
                />
            )}

            {emailData.length === 0 ? (
                <Typography sx={typographyStyle}>
                    No messages found.
                </Typography>
            ) : (
                <div style={{ overflowY: 'auto', maxHeight: fromCS && '405px' }}>
                    {emailData.map((email, index) => (
                        <GraphEmail
                            key={index}
                            message={email}
                            user={user}
                            isReplying={isReplying}
                            setIsReplying={setIsReplying}
                            setEmailData={setEmailData}
                            emailData={emailData}
                            isSendingNewEmail={isSendingNewEmail}
                            showSnackbar={showSnackbar}
                        />
                    ))
                    }
                </div>
            )}
        </>
    )
};
