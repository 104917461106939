export const mainStyle = {
    backgroundColor: "#f6f9fc",
    padding: "10px 0",
};

export const containerStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #f0f0f0",
    padding: "45px",
};

export const textStyle = {
    fontSize: "16px",
    fontFamily: "Futura, sans-serif",
    fontWeight: "300",
    color: "#404040",
    lineHeight: "26px",
};

export const buttonStyle = {
    backgroundColor: "#007ee6",
    borderRadius: "5px",
    color: "#fff",
    fontFamily: "'Futura', 'Helvetica Neue', Arial",
    fontSize: "15px",
    textDecoration: "none",
    textAlign: "center",
    display: "block",
    width: "210px",
    padding: "14px 7px",
    justifyContent: "center",
};