import { useState, useEffect, createContext } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { User } from "../../../models/User";
import { SessionUtils } from "../../../utilities/SessionUtils";
import { BrowserUtils } from "../../../utilities/BrowserUtils";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { URLS } from "../../../urls";
import { useParams } from "react-router-dom";
import LoginWeb from "./LoginWeb";
import LoginMobile from "./LoginMobile";
import { LoginContextType } from "./interfaces";
import TwoButtonModal from "../../../components/modals/TwoButtonModal";

export const LoginContext = createContext<LoginContextType | undefined>(undefined)

export default function LoginPage() {
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showModal, setShowModal] = useState<boolean>(false);
	const [recoveryEmail, setRecoveryEmail] = useState<string>("");
	const [recoveryEmailLoading, setRecoveryEmailLoading] = useState<boolean>(false);
	const [recoveryEmailError, setRecoveryEmailError] = useState<boolean>(false);
	const [recoveryEmailErrorMessage, setRecoveryEmailErrorMessage] = useState<string>("");
	const [recoveryEmailSuccess, setRecoveryEmailSuccess] = useState<boolean>(false);
	const [captchaValue, setCaptchaValue] = useState<string>("");
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");
	const [buttonLoading, setButtonLoading] = useState(false);
	const { urlValue } = useParams();
	const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [rememberMe, setRememberMe] = useState<boolean>(false);
	const [showNewBenchModal, setShowNewBenchModal] = useState<boolean>(false);

	useEffect(() => {
		const savedEmail = localStorage.getItem('rememberMeEmail');
		const rememberMeChecked = localStorage.getItem('rememberMe') === 'true';

		if (savedEmail && rememberMeChecked) {
			setEmail(savedEmail);
			setRememberMe(true);
		}
	}, []);

	const emailRegex = new RegExp(
		/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
		"gm"
	);

	const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

	function isInvalidEmail(email: string): boolean {
		return !emailRegex.test(email);
	}
	async function handleResetPassword(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		if (recoveryEmail === '') {
			setRecoveryEmailError(true);
			setRecoveryEmailErrorMessage("Please enter an email address");
		}
		else if (isInvalidEmail(recoveryEmail)) {
			setRecoveryEmailError(true);
			setRecoveryEmailErrorMessage("Invalid email address");
		} else {
			setRecoveryEmailLoading(true);
			try {
				const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/user/reset_password/', { 'email': recoveryEmail });
				if (response.status === 200) {
					// zyzz I'm thinking we just update the modal vars
					setRecoveryEmailError(false);
					setRecoveryEmailSuccess(true);
				} else {
					setRecoveryEmailError(true);
					setRecoveryEmailErrorMessage('Failed to send password reset email. Please try again');
				}
			} catch (error) {
				setRecoveryEmailError(true);
				setRecoveryEmailErrorMessage('Failed to send password reset email. Please try again');
			} finally {
				setRecoveryEmailLoading(false);
			}
		}
	};

	function handleResendEmail(email: string) {
		RESTUtils.POST(
			RESTUtils.getAPIUrl() + 'api/user/resend-email-verification/',
			{ email: email }
		);
	}

	useEffect(() => {
		if (urlValue === "redirect") {
			setShowSnackbar(true);
			setSnackbarMessage("You have been logged out due to inactivity");
			setSnackbarSeverity("info");
		} else if (urlValue === "success") {
			setShowSnackbar(true);
			setSnackbarMessage("Registration Successful");
			setSnackbarSeverity("success");
		} else if (urlValue === "password_reset") {
			setShowSnackbar(true);
			setSnackbarMessage("Password successfully reset");
			setSnackbarSeverity("success");
		} else if (urlValue === "deleted") {
			setShowSnackbar(true);
			setSnackbarMessage("Account successfully deleted");
			setSnackbarSeverity("success");
		}
	}, []);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		if (rememberMe) {
			localStorage.setItem('rememberMeEmail', email);
			localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
		}
		else {
			localStorage.removeItem('rememberMeEmail');
			localStorage.removeItem('rememberMe');
		}
		if (!formValidation(data)) {
			return;
		}
		setButtonLoading(true);
		const msg = {
			username: data.get("email"),
			email: data.get("email"),
			password: data.get("password"),
			captcha: captchaValue,
		};

		const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + "api/users/login/", msg);

		if (response.status === 200) {
			const item = response.data;
			const user: User = {
				first_name: item.first_name,
				last_name: item.last_name,
				email: item.email,
				uuid: item.uuid,
				access_token: item.token,
				refresh_token: item.refresh,
				image_url: item.image_url,
				is_admin: item.is_admin,
				intake_complete: item.intake_complete,
				updated: item.updated,
				isPseudo: item.isPseudo,
			};
			SessionUtils.setCurrentUserSession(user);
			if (user.is_admin) {
				BrowserUtils.redirect(URLS.ADMIN.DASHBOARD);
			} else if (user.intake_complete) {
				BrowserUtils.redirect(URLS.USER.DASHBOARD);
			} else if (user.isPseudo) {
				BrowserUtils.redirect(URLS.AUTH.PSEUDO_MERGE);
				// BrowserUtils.redirect(URLS.ADMIN.DASHBOARD);
			} else {
				BrowserUtils.redirect(URLS.AUTH.INTAKE);
			}
		} else if (response.status === 202) {
			setShowNewBenchModal(true);
			setButtonLoading(false);
		} else {
			setRecaptchaError(true);
			const errors = response.data.non_field_errors;
			if (errors && errors.includes('Unable to log in with provided credentials.')) {
				setErrorMessage("Unable to log in with provided credentials.");
			} else if (errors && errors.includes('E-mail is not verified.')) {
				setErrorMessage("E-mail is not verified. Please check your inbox for a new verification link.");
				handleResendEmail(data.get('email') as string);
			} else if (response.data.error && response.data.error.includes('captcha failed')) {
				setErrorMessage("ReCAPTCHA failed");
			} else {
				setErrorMessage("An error occurred. Please try again.");
				console.log(response.data);
			}
			setButtonLoading(false);
		}
	};

	function formValidation(data: FormData): boolean {
		const email = data.get("email") ? (data.get("email") as string) : "";
		const password = data.get("password")
			? (data.get("password") as string)
			: "";

		if (!email) {
			setErrorMessage("Email is Required!");
			return false;
		} else if (!emailRegex.test(email)) {
			setErrorMessage("Invalid Email!");
			return false;
		} else if (!password) {
			setErrorMessage("Password is Required!");
			return false;
		}

		setErrorMessage("");
		return true;
	}

	const closeModal = () => {
		setRecoveryEmail('');
		setRecoveryEmailError(false);
		setRecoveryEmailErrorMessage("");
		setShowModal(false);
	};

	const passwordRecoveryHandle = () => {
		setRecoveryEmailSuccess(false);
		setShowModal(true);
	};
	const handleCloseSnackbar = () => {
		setShowSnackbar(false);
		setSnackbarMessage("");
	};
	return (
		<LoginContext.Provider value={{
			showSnackbar,
			snackbarMessage,
			snackbarSeverity,
			handleCloseSnackbar,
			showModal,
			recoveryEmail,
			setRecoveryEmail,
			recoveryEmailLoading,
			recoveryEmailError,
			recoveryEmailErrorMessage,
			recoveryEmailSuccess,
			closeModal,
			handleResetPassword,
			showPassword,
			setShowPassword,
			setCaptchaValue,
			errorMessage,
			handleSubmit,
			buttonLoading,
			recaptchaError,
			setRecaptchaError,
			passwordRecoveryHandle,
			rememberMe,
			setRememberMe,
			email,
			setEmail,
		}}>

			{showNewBenchModal && (
				<TwoButtonModal
					show={showNewBenchModal}
					modalTitle="The Bench has been updated!"
					modalMessage={
						<Typography>
							The Peter Lucas Bench has been updated!
							To access your account, please reset your password
							using the link we've sent to your email.
							<br />
							<br />
							Thank you,<br />
							The Peter Lucas Team
						</Typography>
					}
					closeButtonText="Close"
					closeButtonCallback={() => { setShowNewBenchModal(false) }}
				/>
			)}

			{isMobile ?
				<LoginMobile />
				:
				<LoginWeb />
			}
		</LoginContext.Provider>
	);
}
