import { Box, Link } from '@mui/material'
import React from 'react'
import { HeaderNavigationProps } from './types'
import { useThemeContext } from '../../../themes/ThemeProviderWrapper'

export default function HeaderNavigationWeb({ items }: HeaderNavigationProps) {
    const { theme } = useThemeContext();
    return (
        <Box sx={{ flexGrow: 1, flexDirection: 'row-reverse', display: { xs: 'none', md: 'flex' } }}>
            {items.map((item, index) => (
                <Link
                    key={index}
                    href={item.link}
                    underline='none'
                    sx={{
                        m: 2,
                        color: theme === 'dark' ? 'secondary.main' : 'white'
                        ,
                        display: 'block',
                        ":hover": { color: theme === 'dark' ? 'red' : 'header_footer_hover_button_color.main' },
                    }}
                >
                    {item.text}
                </Link>
            ))
            }
        </Box >
    )
}