import UnstyledTextField from "./UnstyledTextField";

export default function Entry({ entryData, section, index }) {
    const textFields = ['title', 'details', 'description']
    return (
        <>
            {textFields.map((field, idx) => (
                (entryData[field]?.visible) && (
                    <UnstyledTextField
                        key={idx}
                        field={field}
                        value={entryData[field].text}
                        bold={field === 'title'}
                        marginBottom={field === 'description' ? 1.9 : 0}
                        index={index}
                        section={section}
                    />
                )
            ))}
        </>
    );
}