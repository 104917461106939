export const stepsData = [
    {
        title: 'Profile Picture, Banner, and Video',
        id: 'media',
        desc: 'Add a profile picture, a banner image, and a video introducing yourself to your profile.',
    },
    {
        title: 'Quizzes',
        id: 'quizzes',
        desc: 'Complete the personality assessment and project strengths quizzes.',
    },
    {
        title: 'Overview / Qualifications',
        id: 'details',
        desc: 'Update your overview and qualifications to demonstrate your core strengths.',
    },
    {
        title: 'Experience & Skills',
        id: 'experience',
        desc: 'Update your experience and skills. Skills related to each experience can be added within the experience entry.',
    },
    {
        title: 'Notable Projects',
        id: 'notableProjects',
        desc: 'Add any notable projects you have worked on in the past. The more details, the better!',
    },
    {
        title: 'Education',
        id: 'education',
        desc: 'Update your past education.',
    },
    {
        title: 'Awards / Recognitions',
        id: 'awards',
        desc: 'Add any awards or recognitions you have received.',
    },
    {
        title: 'References',
        id: 'references',
        desc: 'Add references for your job experiences.',
    },
]

export function useTasks(tasks) {
    return [
        [
            { label: 'Profile Picture', id: 'image_url', type: 'circle', completed: tasks.image_url },
            { label: 'Banner Image', id: 'image_url', type: 'circle', completed: tasks.banner_url },
            { label: 'Video', id: 'video_url', type: 'circle', completed: tasks.video_url }
        ],
        [
            { label: 'Personality Assessment', id: 'personality', type: 'circle', completed: tasks.personality },
            { label: 'Project Strengths', id: 'eight_stage', type: 'circle', completed: tasks.eight_stage }
        ],
        [
            { label: 'Overview', id: 'overview', type: 'circle', completed: tasks.overview },
            { label: '2 Qualifications', id: 'qualifications', type: 'circle', completed: tasks.qualifications }
        ],
        [
            { label: '2 Experiences', id: 'experiences', type: 'action', completed: tasks.experience },
            { label: '4 Skills', id: 'skills', type: 'action', completed: tasks.skills },
            { label: 'Not Applicable', id: 'experienceNA', type: 'checkbox', completed: tasks.skills }
        ],
        [
            { label: '2 Notable Projects', id: 'notableProjects', type: 'action', completed: tasks.notableProjects },
            { label: 'Not Applicable', id: 'notableProjectsNA', type: 'checkbox', completed: tasks.notableProjects },
        ],
        [
            { label: '1 Education', id: 'educations', type: 'action', completed: tasks.education },
            { label: '1 Certification', id: 'certifications', type: 'action', completed: tasks.certifications },
            { label: 'Not Applicable', id: 'educationNA', type: 'checkbox', completed: tasks.education }
        ],
        [
            { label: '2 Awards', id: 'awards', type: 'action', completed: tasks.awards },
            { label: 'Not Applicable', id: 'awardsNA', type: 'checkbox', completed: tasks.awards },
        ],
        [
            { label: '1 Experience Verification Sent', id: 'references', type: 'action', completed: tasks.references },
            { label: '2 References Added', id: 'userReferences', type: 'action', completed: tasks.userReferences },
            { label: 'Not Applicable', id: 'referencesNA', type: 'checkbox', completed: tasks.userReferences },
        ],
    ];
};
