

// Have to return a bunch of trues for optional forms to fit the required forms into Rakib's old validation code
export default function validateIntakeFom(activeStepIndex, formData) {
    switch (activeStepIndex) {
        case 0:
            return validateResumeForm(formData);
        case 1:
            return validateLocationForm(formData);
        case 2:
            return validateDesignationsForm(formData);
        case 3:
            return true;
        case 4:
            return true;
        default:
            return false;
    }
}
function validateResumeForm(formData) {
    return (
        formData.first_name &&
        formData.last_name &&
        (formData.resume_url || formData.no_resume)
    );
}
function validateLocationForm(formData) {
    return (
        formData.phone &&
        formData.country &&
        formData.city
    );
}
function validateDesignationsForm(formData) {
    return (formData.main_title);
}