export const data = {
	"Assertive Commander": [
		"Bold",
		"Self-assured",
		"Charismatic",
		"Direct",
		"Logical",
		"Social",
		"Rational",
		"Objective",
		"Critical Thinking",
	],
	"Assertive Altruist": [
		"Bold",
		"Self-assured",
		"Charismatic",
		"Direct",
		"People-focused",
		"Social",
		"Emotionally Expressive",
		"Empathetic",
		"Compassionate",
	],
	"Dynamic Commander": [
		"Enthusiastic",
		"Goal-oriented",
		"Meticulous",
		"Logical",
		"Social",
		"Rational",
		"Objective",
		"Critical Thinking",
	],
	"Dynamic Altruist": [
		"Enthusiastic",
		"Goal-oriented",
		"Meticulous",
		"People-focused",
		"Social",
		"Emotionally Expressive",
		"Empathetic",
		"Caring",
		"Compassionate",
	],
	"Assertive Thinker": [
		"Independent",
		"Self-assured",
		"Logical",
		"Rational",
		"Objective",
		"Critical Thinking",
	],
	"Assertive Mediator": [
		"Independent",
		"Self-assured",
		"People-focused",
		"Emotionally Expressive",
		"Empathetic",
		"Caring",
		"Compassionate",
	],
	"Dynamic Thinker": [
		"Quiet",
		"Sensitive",
		"Meticulous",
		"High-achieving",
		"Logical",
		"Rational",
		"Objective",
		"Critical thinking",
	],
	"Dynamic Mediator": [
		"Quiet",
		"Sensitive",
		"Meticulous",
		"High-achieving",
		"People-focused",
		"Emotionally Expressive",
		"Empathetic",
		"Caring",
		"Compassionate",
	],
};
