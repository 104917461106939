import { createContext, useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import ReviewUsersTable from "./AwaitingReview/AwaitingReviewTable";
import NewUsersTable from "./NewUsers/NewUsersTable";
import NewApplications from "./newApplications/NewApplications";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import { SessionUtils } from "../../../../../utilities/SessionUtils";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import SnackbarComponent from "../../../../../components/snackbar";
export const UserActionsContext = createContext();

export default function UserActions() {
	const [curTab, setCurTab] = useState(0);
	const [awaitingReviewUsers, setAwaitingReviewUsers] = useState([]);
	const [awaitingReviewError, setAwaitingReviewError] = useState(false);
	const [awaitingReviewLoading, setAwaitingReviewLoading] = useState(true);
	const [applications, setApplications] = useState([]);
	const [applicationsError, setApplicationsError] = useState(false);
	const [applicationsLoading, setApplicationsLoading] = useState(true);
	const [newUsers, setNewUsers] = useState([]);
	const [newUsersError, setNewUsersError] = useState(false);
	const [newUsersLoading, setNewUsersLoading] = useState(true);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");

	function handleCloseSnackbar() {
		setShowSnackbar(false);
		setSnackbarMessage("");
	}
	function setSnackbar(type) {
		if (type === "error") {
			setSnackbarMessage("Something went wrong");
			setSnackbarSeverity("error");
		} else {
			setSnackbarMessage("Saved");
			setSnackbarSeverity("success");
		}
		setShowSnackbar(true);
	}

	const currentUser = SessionUtils.getCurrentUserSession();

	async function fetchAwaitingReviewUsers() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() + "api/admin/get_awaiting_review_users/"
			);
			if (response.status === 200) {
				setAwaitingReviewUsers(response.data.data);
			} else {
				setAwaitingReviewError(true);
			}
		} catch (error) {
			console.error("Error retrieving new users:", error);
			setAwaitingReviewError(true);
		} finally {
			setAwaitingReviewLoading(false);
		}
	}

	async function getApplications() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() + "api/admin/get_new_applications/"
			);
			if (response.status === 200) {
				setApplications(response.data.data);
			} else {
				setApplicationsError(true);
				console.log("Error fetching applications:", response.data);
			}
		} catch (error) {
			console.error("Error fetching applications:", error);
			setApplicationsError(true);
		} finally {
			setApplicationsLoading(false);
		}
	}
	async function fetchNewUsers() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() + "api/admin/get_new_users/"
			);
			if (response.status === 200) {
				setNewUsers(response.data);
			} else {
				setNewUsersError(true);
			}
		} catch (error) {
			console.error("Error retrieving new users:", error);
			setNewUsersError(true);
		} finally {
			setNewUsersLoading(false);
		}
	}

	async function changeJobBucket(user_uuid, jobID, newBucket) {
		const job_enroll_data = {
			user_uuids: [user_uuid],
			job: jobID,
			admin: currentUser.uuid,
			bucket: newBucket.replace(/\s+/g, "_").toLowerCase(),
		};
		try {
			const response = await RESTUtils.POST(
				RESTUtils.getAPIUrl() + `api/admin/move_to_bucket/`,
				job_enroll_data
			);
			if (response.status === 200) {
				setSnackbar("success");
			}
		} catch (error) {
			console.error("Error moving users to new bucket:", error);
			setSnackbar("error");
		}
	}
	async function changeBenchStatus(user_uuid, status) {
		try {
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() +
				`api/admin/change_bench_status/?user_uuid=${user_uuid}&status=${status}`
			);
			if (response.status === 200) {
				setSnackbar("success");
				return true;
			} else {
				console.error("Error changing user status:", response.data);
				setSnackbar("error");
				return false;
			}
		} catch (error) {
			console.error("Error changing user status:", error);
			setSnackbar("error");
			return false;
		}
	}
	async function saveRundown(enrollId, rundown) {
		try {
			const rundownData = {
				rundown: rundown,
				enroll_id: enrollId,
			};
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() + "api/admin/update_rundown/",
				rundownData
			);

			if (response.status === 200) {

				setSnackbar("success");
			} else {
				console.log(response.data);
				setSnackbar("error");
			}
		} catch (error) {
			console.log("Error:", error);
			setSnackbar("error");
		}
	}

	useEffect(() => {
		Promise.all([
			fetchAwaitingReviewUsers(),
			// getApplications(),
			fetchNewUsers(),
		]);
	}, []);
	function handleNameClick(uuid) {
		BrowserUtils.openNewTab(`profile/edit/${uuid}`);
	}

	return (
		<UserActionsContext.Provider
			value={{
				changeJobBucket,
				handleNameClick,
				changeBenchStatus,
				saveRundown
			}}
		>
			<SnackbarComponent
				show={showSnackbar}
				message={snackbarMessage}
				severity={snackbarSeverity}
				positionVertical="bottom"
				positionHorizontal="center"
				autoHideDuration={5000}
				handleCloseCallback={handleCloseSnackbar}
			/>

			<div style={{ maxHeight: '360px', overflowY: "auto", }}>
				<Tabs
					value={curTab}
					onChange={(event, newValue) => setCurTab(newValue)}
					aria-label="basic tabs example"
					textColor="inherit"
					indicatorColor="primary"
					variant="fullWidth"
					sx={{ paddingBottom: "12px", whiteSpace: "nowrap" }}
				>
					<Tab label="Awaiting Review" />
					{/* <Tab label="New Applications" /> */}
					<Tab label="New Users" />
				</Tabs>

				{curTab === 0 && (
					<ReviewUsersTable
						users={awaitingReviewUsers}
						setUsers={setAwaitingReviewUsers}
						loading={awaitingReviewLoading}
						error={awaitingReviewError}
					/>
				)}
				{/* {curTab === 1 && (
					<NewApplications
						newApplications={applications}
						setNewApplications={setApplications}
						loading={applicationsLoading}
						error={applicationsError}
						jobs={jobs}
					/>
				)} */}
				{curTab === 1 && (
					<NewUsersTable
						users={newUsers}
						setUsers={setNewUsers}
						loading={newUsersLoading}
						setNewUsersLoading={setNewUsersLoading}
						error={newUsersError}
					/>
				)}
			</div>
		</UserActionsContext.Provider>
	);
}
