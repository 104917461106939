import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddSkillComponent from "./AddEditSkillComponent";
export default function NewSkillModal({
  open,
  setOpen,
  experienceID,
  setChangedSkills,
  curSkills,
  setSkillData,
}) {
  const [error, setError] = useState(false);
  const [newSkill, setNewSkill] = useState({
    skill: null,
    frequency: null,
    description: "",
    experience: experienceID,
  });

  function handleSkillSave() {
    if (!newSkill.skill || !newSkill.frequency) {
      setError(true);
    } else {
      //kinda weird, but we need to add a temporary ID on each new skill
      //because we don't actually create save it until we save the experience.
      //hope we don't have a duplicate, n in a 100 million chance 

      const trimmedSkill = newSkill.skill.trim();
      const updatedNewSkill = {
        ...newSkill,
        skill: trimmedSkill
      };

      const tempId = Math.floor(Math.random() * -100000000);
      updatedNewSkill.id = tempId;
      setChangedSkills((prevSkills) => ({
        ...prevSkills,
        added: [...prevSkills.added, updatedNewSkill],
      }));
      setSkillData((prevSkills) => [...prevSkills, { ...updatedNewSkill }]);
      setOpen(false);
    }
  }

  return (
    <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
      <DialogTitle>Add New Skill</DialogTitle>

      <DialogContent>
        <AddSkillComponent
          curSkill={newSkill}
          setCurSkill={setNewSkill}
          error={error}
          curSkills={curSkills}
        />
      </DialogContent>

      <DialogActions>
        <Grid item xs={12} pb={1} pr={1} textAlign={'right'}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(false)}
            sx={{
              width: "80px",
              marginLeft: "18px",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleSkillSave}>
            Add Skill
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
