import { useState } from "react";
import { Tabs, Tab, Grid } from "@mui/material";
import InfoForm from "./InfoTab/InfoForm";
import ResumeHandler from "./ResumeTab/ResumeHandler";
import ReferencesTab from "./referencesTab/ReferencesTab";
import EnrollmentsTab from "./EnrollmentTab/EnrollmentsTab";

export default function DetailsParent({ is_admin, allData, isEdit, activeJobs }) {
  const [curIndex, setCurIndex] = useState(0);
  function handleTabChange(event, newIndex) {
    setCurIndex(newIndex);
  }
  const contentMap = [
    <InfoForm allData={allData} />,
    <ResumeHandler allData={allData} />,
  ];
  if (isEdit) contentMap.push(<ReferencesTab allData={allData} isEdit={isEdit} is_admin={is_admin} />);
  if (is_admin) {
    contentMap.push(
      <EnrollmentsTab allData={allData} activeJobs={activeJobs} />,
      // <ReferencesTab allData = {allData}/>,

    );
  }


  return (
    <>
      <Tabs
        value={curIndex}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
      >
        <Tab label="Details" />
        <Tab label="Resume" />
        {isEdit && <Tab label="References" />}
        {is_admin && [
          <Tab key="enrollments" label="Enrollments" />,
          // <Tab key="references" label="References" />,

        ]}
      </Tabs>

      <Grid container padding={2}>
        <Grid item xs={12}>
          {contentMap[curIndex]}
        </Grid>
      </Grid>
    </>
  );
}
