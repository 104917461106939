import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { HeaderNavigationProps } from './types';
import HeaderNavigationWeb from './web';
import HeaderNavigationMobile from './mobile';

export default function HeaderNavigation( { items }: HeaderNavigationProps) {
    
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <>
            {isMobile ?
                <HeaderNavigationMobile items={items} />
                :
                <HeaderNavigationWeb items={items} />
            }
        </>
    )
}