import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import CandidatePreviewTab from "./CandidatePreviewTab";
import CandidateSearch from "./CandidateSearch";
import Loading from "../../../../../components/loading/Loading";

const LeftTabs = ({
  clearAllFilter,
  standardSearchCriteria,
  keyWords,
  setKeyWords,
  users,
  curBucket,
  selectedUser,
  setSelectedUser,
  setLoading,
  setLeftLoading,
  changeBucket,
  handleUserCardClick,
  dqUsers,
  leftLoading,
  bucketCount,
  searchText,
  setSearchText,
  jobDetails
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setTabValue(0);
  }, [curBucket]);

  return (
    <>
      <Typography>
        {curBucket === "Search"
          ? curBucket
          : `${curBucket} (${bucketCount[curBucket.replace(/\s+/g, "_").toLowerCase()]
          })`}
      </Typography>

      <Grid
        paddingTop={1}
        sx={{ borderColor: tabValue === 1 ? "red" : "white" }}
      >
        {curBucket === "Search" && tabValue === 0 ? (
          <CandidateSearch
            clearAllFilter={clearAllFilter}
            standardSearchCriteria={standardSearchCriteria}
            keyWords={keyWords}
            setKeyWords={setKeyWords}
          />
        ) : leftLoading ? (
          <Loading />
        ) : (
          <CandidatePreviewTab
            users={tabValue === 0 ? users : dqUsers}
            curBucket={curBucket}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setLoading={setLoading}
            setLeftLoading={setLeftLoading}
            changeBucket={changeBucket}
            handleUserCardClick={handleUserCardClick}
            disqualified={tabValue === 0 ? false : true}
            searchText={searchText}
            setSearchText={setSearchText}
            jobDetails={jobDetails}
          />
        )}
      </Grid>
    </>
  );
};

export default LeftTabs;
