import { Grid, Typography, Divider } from '@mui/material';

export default function TaskTab ({data}) {
	const renderRow = (label, value) => (
		<Grid item xs={12} paddingBottom={2}>
		  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<Typography variant="h5">{label}</Typography>
			<Typography variant="h5" style={{ textAlign: "right" }}>
			  {value}
			</Typography>
		  </div>
		  <Divider style={{ margin: "5px 0" }} />
		</Grid>
	  );
	  return (
		<Grid container>
		  {renderRow("Tasks Completed", data.all_tasks_completed)}
		  {renderRow("Client Requests Completed", data.client_tasks_completed)}
		</Grid>
	  );
	}
