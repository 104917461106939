export const footerData = {
    copyright_text : "BY PETER LUCAS PROJECT MANAGEMENT INC.",
    logo_url: "/images/logo-plb.png",
    //logo_url: "images/stzuminus-logo-white.svg",
    address: "904 - 201 21st St E, Saskatoon, SK,  S7K 0B8",
    email: "info@peterlucas.ca",
    phone: "+1.306.954.8550",   
    fb_link: "https://www.facebook.com/PeterLucasProjects",
    linkedin_link: "https://www.linkedin.com/company/peter-lucas-project-management/",
    twitter_link: "https://twitter.com/project_lucas",
    instagram_link: 'https://www.instagram.com/peterlucasprojects/'
};