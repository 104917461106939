import { useState } from "react";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import emptyAnnouncement from "./emptyAnnouncement";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

export default function AddAnnouncement({ closeFn, showSnackbar }) {
    const [announcement, setAnnouncement] = useState(emptyAnnouncement);
    const [error, setError] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);


    function handleChange(event) {
        const { id, value } = event.target;
        setAnnouncement(prevAnnouncement => ({
            ...prevAnnouncement,
            [id]: value
        }));
    }

    function isValidAnnouncement(announcement) {
        return announcement.title.length > 0 && announcement.body.length > 0;
    }

    async function handleAddNotification() {
        if (!isValidAnnouncement(announcement)) {
            setError(true);
            return;
        } else {
            setError(false);
            setButtonLoading(true);
            try {
                const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/admin/create_announcement/', announcement);
                if (response.status === 201) {
                    showSnackbar("Announcement created", "success");
                    setAnnouncement(emptyAnnouncement);
                    closeFn();

                } else {
                    showSnackbar("Error creating announcement", "error");
                    console.error('An error occurred while adding announcement:', error);
                }
            } catch (error) {
                console.error('An error occurred while adding announcement:', error);
                showSnackbar("Error creating announcement", "error");
            } finally {
                setButtonLoading(false);
            }
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Title"
                    id="title"
                    autoFocus
                    fullWidth
                    value={announcement.title}
                    onChange={handleChange}
                    required
                    inputProps={{ maxLength: 255 }}
                    error={error && !announcement.title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Details"
                    id="body"
                    multiline
                    required
                    minRows={4}
                    fullWidth
                    value={announcement.body}
                    onChange={handleChange}
                    error={error && !announcement.body}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Link"
                    id="link"
                    fullWidth
                    value={announcement.link}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} textAlign={'right'}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddNotification}
                    disabled={buttonLoading}
                    startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
                >
                    Add
                </Button>
            </Grid>
        </Grid>
    )
}
