import { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import SnackbarComponent from "../../../../../components/snackbar";
export default function JobNotes({ jobDetails, setJobDetails }) {
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");
	function handleNotesChange(event) {
		setJobDetails({
			...jobDetails,
			notes: event.target.value,
		});
	}
	async function handleNotesSave() {
		setIsSaving(true);
		const noteDetails = {
			job_uuid: jobDetails.uuid,
			notes: jobDetails.notes
		}
		const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/admin/saveJobNotes/', noteDetails)
		if (response.status === 200) {
			setError(false)
			setSnackbarMessage("Saved");
			setSnackbarSeverity("success");
			setShowSnackbar(true);
			setIsSaving(false);
		}
		else {
			setError(true)
			setSnackbarMessage("Something went wrong");
			setSnackbarSeverity("error");
			setShowSnackbar(true);
			setIsSaving(false);
		}
	}
	const handleCloseSnackbar = () => {
		setShowSnackbar(false);
		setSnackbarMessage("");
	};

	return (
		<>
			<SnackbarComponent
				show={showSnackbar}
				message={snackbarMessage}
				severity={snackbarSeverity}
				positionVertical="bottom"
				positionHorizontal="right"
				autoHideDuration={5000}
				handleCloseCallback={handleCloseSnackbar}
			/>
			<TextField
				fullWidth
				multiline
				rows={15}
				sx={{ paddingTop: "25px" }}
				value={jobDetails.notes || ""}
				onChange={handleNotesChange}
				error={error}
			/>
			{/* {error && (
			<Typography> Something went wrong </Typography>
			) 
			} */}
			<Button
				variant={"outlined"}
				color='secondary'
				sx={{ marginTop: "15px" }}
				onClick={handleNotesSave}
				disabled={isSaving}
				startIcon={isSaving && <CircularProgress size={20} />}
			>
				{isSaving ? 'Saving' : 'Save'}
			</Button>
		</>
	);
}
