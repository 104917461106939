import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import SendEmailForm from "../../../../../components/communication/messages/sendMessage/SendEmailForm";
import CloseIcon from "@mui/icons-material/Close";

export default function MassEmailModal({ showSendMassEmailModal, closeButtonCallback, checkedUsers, job }) {

    return (
        <>
            <Dialog open={showSendMassEmailModal} onClose={closeButtonCallback} fullWidth maxWidth={'lg'}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    Send Mass Email
                    <IconButton
                        onClick={closeButtonCallback}
                        color="inherit"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Divider
                    variant="middle"
                    sx={{
                        backgroundColor: "grey",
                        marginTop: "-10px",
                    }}
                />

                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Checked Users:{" "}
                        {checkedUsers.map((user, index) => (
                            `${user.first_name} ${user.last_name}`
                        )).join(', ')}
                    </Typography>

                    <SendEmailForm
                        job={job.title}
                        emailData={[]}
                        setEmailData={() => { }}
                        users={checkedUsers}
                        fromMassEmail={true}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}