import { useState } from "react";
import {
	Grid,
	Typography,
	Tabs,
	Tab,
	Link,
	Button,
	Stack,
	CircularProgress,
} from "@mui/material";
import JobNotes from "./JobNotes";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import SnackbarComponent from "../../../../../components/snackbar";
import JobAdminsComponent from "./JobAdminsComponent";
import DOMPurify from "dompurify";

export default function JobDetails({ jobDetails, setJobDetails, initialLoading }) {
	const [selectedTab, setSelectedTab] = useState(0);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");

	if (initialLoading) {
		return (
			<Grid container justifyContent="center" alignItems="center" padding={2}>
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}
	const workableURL = `https://peterlucas.workable.com/backend/jobs/${jobDetails.workable_uuid}/browser/applied`;

	async function handleChangeStatus() {
		const response = await RESTUtils.PUT(
			RESTUtils.getAPIUrl() +
			`api/admin/toggle_job_active/`,
			{
				job_uuid: jobDetails.uuid,
				active: !jobDetails.active
			}
		);
		if (response.status === 200) {
			setJobDetails((prevJobDetails) => ({
				...prevJobDetails,
				active: !prevJobDetails.active,
			}));
			setSnackbarMessage(jobDetails.active ? "Deactivated" : "Activated");
			setSnackbarSeverity("success");
			setShowSnackbar(true);
		} else {
			setSnackbarMessage("Error");
			setSnackbarSeverity("error");
			setShowSnackbar(true);
		}
	}

	const detailsArray = [
		{ label: "Job Title", value: jobDetails.title ?? "" },
		{ label: "Client", value: jobDetails.client ?? "" },
		{ label: "Country", value: jobDetails.country ?? "" },
		{ label: "Region", value: jobDetails.region ?? "" },
		{ label: "City", value: jobDetails.city ?? "" },
		{ label: "Industry", value: jobDetails.industry ?? "" },
		{ label: "Employment Type", value: jobDetails.employment_type ?? "" },
		{
			label: "Salary",
			value: `${(jobDetails.salary_from || jobDetails.salary_to
				? jobDetails.salary_currency
				: ""
			).toUpperCase()}${jobDetails.salary_from ? ` $${jobDetails.salary_from}` : ""
				}${jobDetails.salary_to ? ` - $${jobDetails.salary_to}` : ""}`,
		},
		{
			label: "State",
			value:
				(jobDetails.state ?? "").charAt(0).toUpperCase() +
				(jobDetails.state ?? "").slice(1),
		},
		{
			label: "Internal",
			value: jobDetails.internal ? "Yes" : "No",
		},
		{
			label: "Public",
			value: jobDetails.public ? "Yes" : "No",
		},
		{
			label: "Active",
			value: jobDetails.active ? "Yes" : "No",
		},
		{
			label: "Date Created",
			value: new Date(jobDetails.date_created).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit'
			}),
		},
	];
	const moreArray = [
		{ label: "Last ID's", value: jobDetails.last_ids?.filter(id => id !== "0").join(", ") || "" },
		{ label: "Shortlist Order", value: jobDetails.CP_SL_order ?? "" },
		{ label: "Shortcodes", value: jobDetails.short_codes?.filter(id => id !== "0").join(", ") || "" },
		{ label: 'UUID', value: jobDetails.uuid ?? "" },
		{ label: "Workable UUID", value: jobDetails.workable_uuid ?? "" },
		{ label: "Workable Type", value: jobDetails.workable_type ?? "" },
	];


	const handleCloseSnackbar = () => {
		setShowSnackbar(false);
		setSnackbarMessage("");
	};

	const contentMap = {
		0: (
			<Grid container spacing={0}>
				<Grid item xs={12} mt={1}>
					<JobAdminsComponent jobDetails={jobDetails} setJobDetails={setJobDetails} />
				</Grid>
				{detailsArray.map((detail, index) => (
					<Grid item xs={12} key={index}>
						<Typography variant="body1" >
							<strong>{`${detail.label}:`}</strong> {detail.value}
						</Typography>
					</Grid>
				))}
			</Grid>
		),
		1: (
			<Typography
				sx={{ paddingTop: "5px" }}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(jobDetails.description),
				}}
			/>
		),
		2: (
			<Typography
				sx={{ paddingTop: "5px" }}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(jobDetails.requirements),
				}}
			/>
		),
		3: <JobNotes jobDetails={jobDetails} setJobDetails={setJobDetails} />,
		4: (
			<Grid container spacing={0}>
				{moreArray.map((detail, index) => (
					<Grid item xs={12} key={index}>
						<Typography variant="body1" >
							<strong>{`${detail.label}:`}</strong> {detail.value}
						</Typography>
					</Grid>
				))}
			</Grid>
		),
	};

	return (
		<>

			<SnackbarComponent
				show={showSnackbar}
				message={snackbarMessage}
				severity={snackbarSeverity}
				positionVertical="bottom"
				positionHorizontal="right"
				autoHideDuration={5000}
				handleCloseCallback={handleCloseSnackbar}
			/>

			<Stack direction={'row'}>
				<Button
					variant="outlined"
					size="small"
					color="secondary"
					component={Link}
					href={workableURL}
					underline="hover"
					target="_blank"
				>
					Workable
				</Button>
				<Button
					variant="outlined"
					size="small"
					color="secondary"
					href={"/client_portal/" + jobDetails.uuid}
					underline="hover"
					target="_blank"
					sx={{ marginLeft: "10px", whiteSpace: "nowrap" }}
				>
					Client Portal
				</Button>
				<Grid item sx={{ marginLeft: "auto" }}>
					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={handleChangeStatus}
					>
						{jobDetails.active ? "Deactivate" : "Reactivate"}
					</Button>
				</Grid>
			</Stack>
			<Tabs
				variant="fullWidth"
				value={selectedTab}
				textColor="inherit"
				indicatorColor="primary"
				onChange={(event, newValue) => setSelectedTab(newValue)}
			>
				<Tab label={"Details"} />
				<Tab label={"Description"} />
				<Tab label={"Requirements"} />
				<Tab label={"Notes"} />
				<Tab label={"More"} />
			</Tabs>

			<div style={{ maxHeight: "625px", overflowY: "auto" }}>
				{contentMap[selectedTab]}
			</div>

		</>
	);
}
