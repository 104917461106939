import { useState, useEffect } from "react";
import { Paper, TextField, Button, Checkbox, FormControlLabel, Grid, } from "@mui/material";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import NewJob from "./newJob";
import JobsDataGrid from "./JobsDataGrid";

export default function AdminDashJobs() {
	const [searchQuery, setSearchQuery] = useState("");
	const [archived, setArchived] = useState(false);
	const [jobData, setJobData] = useState([]);
	const [jobsLoading, setJobsLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 5,
		page: 0,
	});

	function handleInputChange(text) {
		setSearchQuery(text);
		setPaginationModel({ ...paginationModel, page: 0 });
	}
	function clearSearch() {
		setSearchQuery("");
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	const searchJobs = async () => {
		setJobsLoading(true);
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
				`api/admin/JobQuickSearch/?q=${searchQuery}&archived=${archived}&page=${paginationModel.page + 1}`
			);
			setJobData(response.data);
		} catch (error) {
			console.error("Error fetching search results:", error);
		} finally {
			setJobsLoading(false);
		}
	};

	useEffect(() => {
		searchJobs();
	}, [searchQuery, archived, paginationModel.page]);

	return (
		<Paper>
			<Grid container padding={1}>
				<Grid item xs={7}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: "80%",
							}}
						>
							<TextField
								placeholder="Search Jobs"
								aria-label="search Jobs"
								fullWidth
								size="small"
								value={searchQuery}
								onChange={(e) =>
									handleInputChange(e.target.value)
								}
								sx={{ pr: 1 }}
							/>
							<Button
								variant="outlined"
								color="secondary"
								onClick={clearSearch}
								sx={{ mr: 1, height: "40px" }}
							>
								Clear
							</Button>
						</div>
						<FormControlLabel
							label="Search Archived Jobs"
							control={
								<Checkbox
									checked={archived}
									onChange={() => setArchived(!archived)}
									inputProps={{ "aria-label": "controlled" }}
									sx={{
										color: "primary.contrastText",
										"&.Mui-checked": {
											color: "primary.contrastText",
										},
									}}
								/>
							}
						/>
					</div>
				</Grid>

				<Grid item xs={5}>
					<NewJob />
				</Grid>
			</Grid>

			<JobsDataGrid
				jobData={jobData}
				setJobData={setJobData}
				loading={jobsLoading}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
			/>
		</Paper>
	);
}