import React from "react";
import { HeaderComponentProps } from "./types";
import { AppBar, Container, Toolbar } from "@mui/material";
import HeaderNavigation from "./headerNavigation";
import Logo from "./logo";
import UserMenu from "./userMenu";
import { SessionUtils } from "../../utilities/SessionUtils";
import { useLocation } from 'react-router-dom';

export default function HeaderMobile({ headerParams }: HeaderComponentProps) {
  const { logo_url, headerNavigationItems } =
    headerParams;
  const user = SessionUtils.getCurrentUserSession();
  const location = useLocation();
  const isPrintMode = location.pathname.includes('/profile/print/');
  return (
    <AppBar position="static" >
      <Container>
        <Toolbar
          disableGutters
          sx={{
            paddingBottom: '5px',
            display: 'flex', // Ensure Toolbar uses flex
            justifyContent: isPrintMode ? 'flex-end' : 'space-between', // Adjust spacing logic
          }}
        >
          <Logo logo_url={logo_url} isPrintMode={isPrintMode} alt="Home" />
          {user && !isPrintMode && <UserMenu user={user} />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
