import { Typography } from "@mui/material";


export default function DeacivateModalContent() {
    return (
        <Typography>
            Are you sure you want to deactivate your account?
            Deactivating your account will set your account to an inactive state, but can be recovered anytime.
        </Typography>
    )
}