import { Typography } from "@mui/material";
import { StyledSlider, sliderStyleMobile } from "./styles";

export default function ThreeDSliderMobile({ label, value }) {
  const highestLabel =
    value >= 50 ? `${value}% ${label}`
      : `${100 - value}% ${label === "Extrovert" ? "Introvert"
        : label === "Thinking" ? "Feeling"
          : "Dynamic"}`;

  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Typography style={{ ...sliderStyleMobile, whiteSpace: "nowrap" }}>
        {highestLabel}
      </Typography>
      <StyledSlider
        value={value || 0}
        disabled
        sx={{
          "& .MuiSlider-track": {
            backgroundColor: value >= 50 ? "#71071b" : "#4D4D4D",
          },
          "& .MuiSlider-rail": {
            backgroundColor: value < 50 ? "#71071b" : "#4D4D4D",
          },
        }}
      />
    </div>
  );
}
