import { Card, Typography } from "@mui/material";

export default function PastCertificates({
    onClickFn,
    isEdit,
    certificateData,
    addMargin,
    isPrintMode
}) {
    return (
        <Card
            onClick={isEdit ? () => onClickFn(certificateData) : undefined}
            sx={{
                padding: isPrintMode ? '10px' : "20px",
                transition: "background-color 0.2s ease",
                cursor: isEdit ? "pointer" : "default",
                marginBottom: addMargin ? "10px" : "0",
                '&:hover': {
                    backgroundColor: isEdit ? 'secondary.cardHover' : null,
                }
            }}
        >
            <Typography variant="body1">
                {certificateData.title}
                {certificateData.institution && ` - ${certificateData.institution}`}
                {certificateData.year_acquired && ` - ${certificateData.year_acquired}`}
            </Typography>
            <Typography variant="body2">{certificateData.expiry_date || ""}</Typography>
        </Card>
    );
}
