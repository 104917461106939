import { useMediaQuery, useTheme } from '@mui/material';
import HeaderWeb from './web';
import HeaderMobile from './mobile';
import { HeaderComponentProps } from './types';
import { useLocation } from 'react-router-dom';

function HeaderComponent({ headerParams, user }: HeaderComponentProps) {
    const location = useLocation();
    let isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    if (location.pathname.includes('/pdf')) return null

    return (
        <div>
            {
                isMobile ?
                    <HeaderMobile headerParams={headerParams} user={user} /> :
                    <HeaderWeb headerParams={headerParams} user={user} />
            }
        </div>
    )
}

export default HeaderComponent;