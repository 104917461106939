import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
    IconButton,
    CircularProgress
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export default function UploadMediaModal({
    show = false,
    setShowUploadModal,
    openImageFileInput,
    openVideoFileInput,
    imageLoading,
    videoLoading,
}) {

    return (
        <Dialog
            open={show}
            onClose={() => {
                setShowUploadModal(false);
            }}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                Upload Media
                <IconButton
                    onClick={() => {
                        setShowUploadModal(false);
                    }}
                >
                    <ClearIcon />
                </IconButton>
            </DialogTitle>

            <Divider
                variant="middle"
                sx={{
                    backgroundColor: "grey",
                    marginTop: "-10px",
                }}
            />

            <DialogContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        openImageFileInput();
                    }}
                    disabled={imageLoading}
                    startIcon={imageLoading && <CircularProgress color="inherit" size={20} />}
                >
                    Upload Profile Picture
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        openVideoFileInput();
                    }}
                    disabled={videoLoading}
                    startIcon={videoLoading && <CircularProgress color="inherit" size={20} />}
                >
                    Upload Intro Video
                </Button>
            </DialogContent>


            <DialogActions sx={{ display: "flex", justifyContent: "flex-end", marginTop: '-25px' }}>
                <Button
                    onClick={() => {
                        setShowUploadModal(false);
                    }}
                    sx={{ m: 2 }}
                    variant="outlined"
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
