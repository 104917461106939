export const iconNames = [
    "exploreIcon",
    "inventIcon",
    "evaluateIcon",
    "planIcon",
    "startIcon",
    "helpIcon",
    "controlIcon",
    "finishIcon",
];

export const strengths = [
    "Identifying",
    "Inventing",
    "Evaluating",
    "Planning",
    "Leading",
    "Coordinating",
    "Controlling",
    "Finishing"
]