import { createContext, useState } from "react";
import { CircularProgress, Grid, Paper, Container, IconButton, Button } from "@mui/material";
import CommunicationBox from "../../components/communication/CommunicationBox";
import { SnackBarComponent2 } from "../../components/snackbar/SnackBarComponent2";
import { RESTUtils } from "../../utilities/RESTUtils";
import { useThemeContext } from "../../themes/ThemeProviderWrapper";
import ProfileStatusProfile from "./components/completionStatusProfile";
import TopBox from "./components/topBox/TopBox";
import TopBoxPrint from "./components/topBox/print/TopBoxPrint";
import OverviewQualifications from "./components/OverviewQualifications";
import BottomBoxTabs from "./components/bottomBox/BottomBoxTabs";
import BottomBoxPrint from './components/bottomBox/BottomBoxPrint';
import EmailIcon from "@mui/icons-material/Email";

export const ProfileDataContext = createContext();

export default function ProfileWeb2({
    allData,
    buttons,
    loading,
    isEditMode,
    isPrintMode = false,
    userInfo,
    setUserInfo,
    is_admin,
    personalityData,
    showSnackbar,
    emailData,
    setEmailData,
    communicationVisible,
    setCommunicationVisible,
    uuid,
    cur_user_uuid,
    activeJobs,
    events,
    emailLoading
}) {
    const [pageWidth, setPageWidth] = useState('lg');
    const { SnackBarComponent } = SnackBarComponent2();
    const [saving, setSaving] = useState(false);
    const { theme } = useThemeContext();

    const [sectionsOpen, setSectionsOpen] = useState({
        projects: true,
        educations: true,
        awards: true,
    });


    const handleChange = (fieldName, value) => {
        const updatedUserProfile = {
            ...allData.userInfo,
            [fieldName]: value,
        };
        allData.setUserInfo(updatedUserProfile);
    };

    async function handleSaveUserProfile() {
        setSaving(true);
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${uuid}`,
                {
                    overview: allData.userInfo.overview,
                    qualifications: allData.userInfo.qualifications,
                }
            );
            if (response.status === 200) {
                allData.showSnackbar("Profile Saved", "success");
            } else {
                console.log(response.data);
                allData.showSnackbar("Something went wrong", "error");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setSaving(false);
        }
    }
    function handleIconClick() {
        setCommunicationVisible(!communicationVisible);
    }

    if (loading) {
        return (
            <Grid
                container
                style={{
                    margin: "auto",
                    padding: "30px",
                    justifyContent: "center",

                }}
            >
                <Grid item xs={5} px={2}>
                    <Paper
                        elevation={3}
                        style={{
                            padding: "20px",
                            textAlign: "center",
                        }}
                    >
                        <CircularProgress />
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    return (
        <Container component="main" sx={{ p: { xs: 0, md: 2 }, }} maxWidth={pageWidth}>
            <Grid container spacing={2} padding={isPrintMode ? 1 : 0}>
                {SnackBarComponent}

                {isEditMode && !loading && (
                    <Grid item xs={3} style={{ transition: "0.4s ease-out" }} display={(is_admin || allData.profileCompletion) && 'none'}>
                        <div style={{ position: 'sticky', top: '1rem' }}>
                            <Paper sx={{ mb: 1 }}>
                                <ProfileStatusProfile allData={allData} />
                            </Paper>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    onClick={() => { window.location.href = "/dashboard"; }}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ backgroundColor: theme === 'light' && '#fefefa' }}
                                >
                                    Return To Dashboard
                                </Button>
                            </div>
                        </div>
                    </Grid>
                )}

                <Grid
                    container
                    item
                    xs={
                        isEditMode && !allData.profileCompletion
                            ? (
                                is_admin
                                    ? (communicationVisible ? 7 : 12)
                                    : 9
                            )
                            : (communicationVisible ? 7 : 12)
                    }
                    style={{
                        position: 'relative',
                        transition: "0.4s ease-out",
                    }}
                    spacing={1}
                >
                    <Grid item xs={12}>
                        {isEditMode && allData.profileCompletion && !is_admin && (
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button
                                    onClick={() => { window.location.href = "/dashboard"; }}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mb: 1 }}
                                >
                                    Return To Dashboard
                                </Button>
                            </div>
                        )}
                        <Paper sx={{ position: 'relative' }}>
                            {isEditMode && is_admin && cur_user_uuid !== uuid &&
                                (<div style={{
                                    position: 'absolute', top: 6, right: '10px', zIndex: 3,
                                }}>
                                    <IconButton
                                        onClick={handleIconClick}
                                        sx={{
                                            border: "1px solid white",
                                            // backgroundColor: "gray",
                                            backgroundColor: "transparent",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "lightgrey",
                                                color: "white",
                                            },
                                        }}>
                                        <EmailIcon />
                                    </IconButton>
                                </div>
                                )}

                            {isPrintMode ? (
                                <TopBoxPrint
                                    allData={allData}
                                    userData={userInfo}
                                    setUserData={setUserInfo}
                                    onEditPage={isEditMode}
                                    isAdmin={is_admin}
                                    personalityData={personalityData}
                                    showSnackbar={showSnackbar}
                                    isPrintMode={isPrintMode}
                                    sectionsOpen={sectionsOpen}
                                    setSectionsOpen={setSectionsOpen}
                                />
                            ) :
                                <TopBox
                                    allData={allData}
                                    userData={userInfo}
                                    setUserData={setUserInfo}
                                    onEditPage={isEditMode}
                                    isAdmin={is_admin}
                                    personalityData={personalityData}
                                    showSnackbar={showSnackbar}
                                    isPrintMode={isPrintMode}
                                />
                            }
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper>
                            <OverviewQualifications
                                profileData={allData.userInfo}
                                setProfileData={allData.setUserInfo}
                                skillData={allData.skillData}
                                experienceData={allData.experienceData}
                                isEdit={isEditMode}
                                handleChange={handleChange}
                                handleSaveUserProfile={handleSaveUserProfile}
                                fromCandidateSearch={false}
                                saving={saving}
                                stackDirection={"row"}
                                isPrintMode={isPrintMode}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {isPrintMode ? (
                            <BottomBoxPrint allData={allData} sectionsOpen={sectionsOpen} />
                        ) :
                            <BottomBoxTabs
                                allData={allData}
                                activeJobs={activeJobs}
                                isEdit={isEditMode}
                                loading={loading}
                                is_admin={is_admin}
                                uuid={uuid}
                                buttons={buttons}
                            />
                        }
                    </Grid>
                </Grid>

                {!loading && cur_user_uuid !== uuid && is_admin && communicationVisible && (
                    <Grid item xs={5}>
                        <div style={{ position: 'sticky', top: '1rem' }}>
                            <CommunicationBox
                                emailData={emailData}
                                setEmailData={setEmailData}
                                activeJobs={activeJobs}
                                communicationVisible={communicationVisible}
                                user={{ 'uuid': uuid, 'image_url': userInfo.image_url, 'email': userInfo.email, 'name': userInfo.first_name }}
                                events={events}
                                emailLoading={emailLoading}
                                allData={allData}
                                pageWidth={pageWidth}
                                setPageWidth={setPageWidth}
                                showSnackbar={showSnackbar}
                            />
                        </div>
                    </Grid>
                )}
            </Grid >
        </Container >
    );
}
