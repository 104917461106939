import { Divider } from "@mui/material";
import Overview from "./Overview";
import ProfessionalHistories from './professionalHistory';

export default function RightColumn({ overview, professionalHistory }) {
    return (
        <div style={{ padding: '7px' }}>
            {overview && (
                <Overview overview={overview} />
            )}

            {overview && professionalHistory && (
                <Divider />
            )}

            {professionalHistory && (
                <ProfessionalHistories professionalHistoryData={professionalHistory} />
            )}
        </div>
    )
}