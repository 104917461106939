import { useState, useContext } from "react";
import { TextField } from "@mui/material";
import { TemplateContext } from "..";

export default function UnstyledTextField({
    value,
    fullWidth = true,
    bold = false,
    marginBottom = 0,
    lineHeight = 1.2,
    index,
    section,
    field
}) {
    const { setTemplateData } = useContext(TemplateContext);

    function handleChange(e) {
        const { value } = e.target;
        // setTemplateData((prevData) => {
        //     const updatedData = { ...prevData };
        //     updatedData[section].entries[index][field].text = value;
        //     return updatedData;
        // });
        setTemplateData((prevData) => {
            return {
                ...prevData,
                [section]: {
                    ...prevData[section],
                    entries: prevData[section].entries.map((entry, idx) =>
                        idx === index
                            ? { ...entry, [field]: { ...entry[field], text: value } }
                            : entry
                    ),
                },
            };
        });
    }

    return (
        <TextField
            multiline
            value={value}
            fullWidth={fullWidth}
            variant="standard"
            onChange={handleChange}
            InputProps={{
                disableUnderline: true,
                spellCheck: true,
                style: {
                    whiteSpace: 'pre-line',
                    border: 'none',
                    padding: 0,
                    fontSize: '0.875rem',
                },
            }}
            sx={{
                '& .MuiInputBase-input': {
                    fontWeight: bold && 'bold',
                    lineHeight: lineHeight,
                },
                marginBottom: marginBottom
            }}
        />
    )
}