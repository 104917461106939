import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from "@mui/material";

export default function UnsavedChangesDialog({
	open,
	setOpen,
	closeModalCallback,
}) {
	return (
		<Dialog open={open}>
			<DialogContent>
				<Typography>
					Warning: Unsaved changes will be lost. Continue?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => setOpen()}
					variant="outlined"
					color="secondary"
				>
					Cancel
				</Button>
				<Button
					onClick={() => closeModalCallback()}
					variant="outlined"
					color="primary"
				>
					Exit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
