import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
const headerStyle = {
    textAlign: "center",
    fontWeight: "bold",
}

export default function ReferencesHeader({ is_admin }) {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography
                        variant="subtitle1"
                        sx={headerStyle}
                    >
                        Name
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="subtitle1"
                        sx={headerStyle}
                    >
                        Company
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="subtitle1"
                        sx={headerStyle}
                    >
                        Title
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="subtitle1"
                        sx={headerStyle}
                    >
                        Email
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="subtitle1"
                        sx={headerStyle}
                    >
                        Phone
                    </Typography>
                </TableCell>

                {is_admin && (
                    <TableCell>
                        <Typography
                            variant="subtitle1"
                            sx={headerStyle}
                        >
                            
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}
