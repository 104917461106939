import { useState, useMemo } from "react";
import TwoButtonModal from "../TwoButtonModal";
import { Autocomplete, TextField } from "@mui/material";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { SessionUtils } from "../../../utilities/SessionUtils";

export default function AddToJobModal({
    showAddJobModal,
    setShowAddJobModal,
    uuid,
    activeJobs,
    showSnackbar,
    setEnrollmentData = false
}) {
    const [loading, setLoading] = useState(false);
    const [curJob, setCurJob] = useState(null);
    const currentUser = SessionUtils.getCurrentUserSession();

    async function handleAddToJob() {
        setLoading(true);
        if (curJob) {
            try {
                const job_enroll_data = {
                    user_uuids: [uuid],
                    job: curJob.id,
                    admin: currentUser.uuid,
                    bucket: 'applied',
                };
                const response = await RESTUtils.POST(
                    RESTUtils.getAPIUrl() + `api/admin/move_to_bucket/`,
                    job_enroll_data
                );
                if (response.status === 200) {
                    const enroll_id = response.data.job_enroll_ids[0];
                    if (setEnrollmentData) {
                        setEnrollmentData((prev) => {
                            const index = prev.findIndex((enrollment) => enrollment.job.uuid == curJob.uuid);
                            if (index !== -1) {
                                const newEnrollment = [...prev];
                                newEnrollment[index].bucket = 'applied';
                                return newEnrollment;
                            } else {
                                const newEnrollment = [...prev];
                                newEnrollment.push({
                                    job: curJob,
                                    bucket: 'applied',
                                    user_data: uuid,
                                    id: enroll_id,

                                });
                                return newEnrollment;
                            }
                        });
                    }
                    showSnackbar("Added to job", "success");
                } else {
                    console.log(response.data);
                    showSnackbar("Error adding to job", "error");
                }
            } catch (error) {
                console.error("An error occurred:", error);
                showSnackbar("Error adding to job", "error");
            } finally {
                setLoading(false);
                setShowAddJobModal(false);
            }
        }
    }

    function handleClose() {
        setShowAddJobModal(false);
        setCurJob(null);
    }

    return (
        <TwoButtonModal
            show={showAddJobModal}
            modalTitle="Add to Job"
            modalMessage={
                <Autocomplete
                    options={activeJobs || []}
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option) => (
                        <li {...props} key={option.uuid}>
                            {option.title}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Move to Job" />}
                    onChange={(event, value) => setCurJob(value)}
                    style={{ width: "100%" }}
                />
            }
            closeButtonText="Cancel"
            closeButtonCallback={handleClose}
            okButtonText="Add"
            okButtonCallback={handleAddToJob}
            okButtonLoading={loading}
        />
    );
}
