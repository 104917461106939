import { useState } from "react";
import { Grid, Typography, Avatar, Card } from "@mui/material";
import dateOptions from "./dateOptions";

export default function Note({
	noteData,
	onClickFn,
}) {
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = () => {
		setIsHovered(true);
	};
	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const parsedDate = new Date(noteData.date_created);
	const formattedTimestamp = parsedDate.toLocaleString("en-US", dateOptions);

	return (
		<Card
			onClick={() => onClickFn(noteData)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				padding: "10px",
				transition: "background-color 0.2s ease",
				cursor: "pointer",
				"&:hover": {
					backgroundColor: "secondary.cardHover",
				},
			}}
		>
			<Grid container alignItems="center">
				<Grid item>
					<Avatar
						src={noteData.author.image_url}
						alt="Profile"
					/>
				</Grid>
				<Grid item pl={1}>
					<Typography>{`${noteData.author.first_name} ${noteData.author.last_name}`}</Typography>
				</Grid>
			</Grid>

			<Grid item xs={12} pt={0.5}>
				<Typography variant="body2" color="secondary.main">
					{formattedTimestamp}
				</Typography>
			</Grid>
			{noteData.message && (
				<Grid item xs={12}>
					<Typography>{noteData.message}</Typography>
				</Grid>
			)}
		</Card>
	);
}
