import {
	Box,
	Autocomplete,
	Typography,
	Grid,
	FormControl,
	TextField,
	MenuItem,
} from "@mui/material";
import { countryOptions, provinceOptions } from "../data";
import PhoneMaster from "../../../../components/phoneInput/PhoneMaster";

export default function ContactForm({ formData, setFormData }) {
	function handlePhoneChange(value) {
		setFormData((prev) => ({
			...prev,
			phone: value,
		}));
	}

	return (
		<Box display="inherit">
			<Typography
				variant="h6"
				component="div"
				paddingBottom={2}
				textAlign="center"
			>
				Enter your contact details.
			</Typography>

			<Grid container justifyContent="center">

				<Grid item xs={12} pt={2} pb={2}>
					<FormControl fullWidth>
						<PhoneMaster value={formData.phone || ""} handlePhoneChange={handlePhoneChange} />
					</FormControl>
				</Grid>

				<Grid item xs={12} paddingBottom={2}>
					<FormControl fullWidth>
						<Autocomplete // See https://mui.com/material-ui/react-autocomplete/#system-CountrySelect.js
							id="country-select"
							options={countryOptions}
							autoHighlight
							getOptionLabel={(option) => option.label}
							onChange={(event, value) => {
								setFormData({
									...formData,
									country: value === null ? null : value.label,
									province: (value === null || value.label !== "Canada") ? "" : formData.province, // Reset the province field if the country is not Canada. Not sure if this would ever get used, but it is here nonetheless
								});
							}}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									<img
										loading="lazy"
										width="20"
										srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
										src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
										alt=""
									/>
									{option.label} ({option.code}) +{option.phone}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Choose a country *"
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>

				{formData.country === "Canada" && (
					<Grid item xs={12} paddingBottom={2}>
						<FormControl fullWidth>
							<TextField
								select
								label="Province"
								id="region"
								value={formData.country === "Canada" ? (formData.region || "") : ""}
								onChange={(e) => setFormData({ ...formData, region: e.target.value })}
							>
								{provinceOptions.map((name) => (
									<MenuItem key={name} value={name}>
										{name >= 10 ? `${name}+` : name}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12} paddingBottom={2}>
					<TextField
						fullWidth
						required
						label="City"
						id="city"
						value={formData.city || ""}
						onChange={(e) => setFormData({ ...formData, city: e.target.value })}
						inputProps={{ maxLength: 254 }}
					/>
				</Grid>

				{/* <Grid item xs={12} justifyContent="left" textAlign="left">
					<FormControlLabel
						control={<Checkbox sx={{
							color: "primary.contrastText",
							"&.Mui-checked": {
								color: "primary.contrastText",
							},
						}} />}
						label="Able to work in Canada"
					/>
				</Grid> */}
			</Grid>
		</Box>
	);
}