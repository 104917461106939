import { Typography, Grid } from '@mui/material';
import Notification from './Notification';
import { RESTUtils } from '../../../../../utilities/RESTUtils';

export default function CanDashNotifications({ notifications, setNotifications }) {

    async function handleRemoveNotification(id) {
        setNotifications(notifications.filter(notification => notification.id !== id));
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() +
                `api/user/read_notification/?id=${id}`
            );
            if (response.status === 200) {
                console.log("Notification closed successfully");
            } else {
                console.log("Error closing notification");
            }
        } catch (error) {
            console.error("Error closing notification:", error);
        }
    }
    return (
        <>
            {/* <Typography variant="h6" sx={{ p: 1, }}>
                Notifications
            </Typography>
            <Grid item xs={12}>
                <Divider />
            </Grid> */}

            <Grid container>
                {notifications && notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <Grid item xs={12} key={notification.id}>
                            <Notification
                                notification={notification}
                                setNotifications={setNotifications}
                                handleRemoveNotification={handleRemoveNotification}
                            />
                        </Grid>
                    ))
                ) : (
                    <Typography variant="h7" sx={{ p: 1 }}>
                        No Notifications found
                    </Typography>
                )}
            </Grid>
        </>
    );
};


