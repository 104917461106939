import { Tooltip, Checkbox, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from "@mui/icons-material/Email";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CancelIcon from "@mui/icons-material/Cancel";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";

export default function QuickSearchIcons({
  selectedOption,
  showSendEmail,
  setShowSendEmail,
  handleClearClick,
  searchInternational,
  setSearchInternational,
}) {
  const isBenchAdmin = selectedOption?.first_name === "Bench" && selectedOption?.last_name === "Admin";

  function handleProfileClick() {
    BrowserUtils.openNewTab(`profile/edit/${selectedOption.uuid}`);
  }

  return (
    <Stack
      style={{ flexDirection: "row", marginLeft: "15px", paddingTop: "10px" }}
    >
      <Tooltip title={"Search International"} arrow placement="top">

        <Checkbox
          checked={searchInternational}
          onChange={() => setSearchInternational((prevValue) => !prevValue)}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: "primary.contrastText",
            "&.Mui-checked": {
              color: "primary.contrastText",
            },
          }}
        />

      </Tooltip>
      <Tooltip title={!selectedOption?.uuid ? "Not a Bench member" : "Go To Profile"} arrow placement="top">
        <span>
          <IconButton
            onClick={handleProfileClick}
            disabled={!selectedOption || !selectedOption?.uuid}
            color="primary"
          >
            <PersonOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={showSendEmail ? "Close Message" : "Send Message"} arrow placement="top">
        <span>
          <IconButton
            // sx={{ padding: "4px" }}
            onClick={() => {
              setShowSendEmail(!showSendEmail);
            }}
            disabled={!selectedOption || isBenchAdmin}
            color="primary"
          >
            {showSendEmail ? <CancelScheduleSendIcon /> : <EmailIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Clear" arrow placement="top">
        <span>
          <IconButton
            // sx={{ padding: "4px" }}
            onClick={handleClearClick}
            disabled={!selectedOption}
            color="primary"
          >
            <CancelIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}