const EmptyAwardComponent = (uuid) => {
	let emptyAward = {
		title: "",
		year_awarded: null,
		organization: "",
		user_data: uuid,
		id: null,
	};
	return emptyAward;
}

export default EmptyAwardComponent;
