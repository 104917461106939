import { useState } from "react";
import {
	Grid,
	Table,
	TableBody,
	Typography,
	Button,
	TableRow,
	TableCell
} from "@mui/material";
import TwoButtonModal from "../../../../components/modals/TwoButtonModal";
import { ModalHook } from "../../../../components/modals/Hooks/hooks";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import AwardModalContent from "../../../../components/modals/AwardModal";
import AwardRow from "./AwardRow";
import AwardHeader from "./AwardHeader";
import emptyAward from "./emptyAward";

export default function AwardTab({ isEdit, allData }) {
	const [curAwardData, setCurAwardData] = useState();
	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);
	const [isNewAward, setIsNewAward] = useState(false);
	const [modalVars, setModalVars] = ModalHook();
	const [buttonLoading, setButtonLoading] = useState({
		okButton: false,
		deleteButton: false,
	});
	let awardData, setAwardData, userInfo, showSnackbar, uuid;
	if (!isEdit) {
		({ awardData } = allData);
	} else {
		({ awardData, setAwardData, userInfo, showSnackbar } = allData);
		uuid = userInfo.uuid;
	}

	function openNewAwardModal() {
		setIsNewAward(true);
		setCurAwardData(emptyAward(uuid));
		setModalVars({
			modalTitle: "Add Award",
			okButtonText: "Add",
		});
		setShowModal(true);
	}
	function editAwardModal(item) {
		setIsNewAward(false);
		setCurAwardData(item);
		setModalVars({
			modalTitle: "Edit Award",
			okButtonText: "Save",
		});
		setShowModal(true);
	}
	const closeModal = () => {
		setShowModal(false);
		setError(false);
	};

	function validateAward() {
		setError(!curAwardData.title || !curAwardData.organization);
		return curAwardData.title && curAwardData.organization;
	}
	async function createAward() {
		if (validateAward()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			try {
				const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + "api/user/createAward/", curAwardData);
				if (response.status === 201) {
					curAwardData.id = response.data;
					setAwardData(prevAwardData => [
						...prevAwardData,
						curAwardData
					])
					closeModal(true);
					showSnackbar('Award created', 'success');
				} else {
					showSnackbar('Error creating award', "error");
				}
			} catch (error) {
				console.log(error);
				showSnackbar('Error creating award', "error");
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		}
	}
	async function editAward() {
		if (validateAward()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			try {
				const response = await RESTUtils.PUT(RESTUtils.getAPIUrl() + "api/user/editAward/", curAwardData);
				if (response.status === 200) {
					setAwardData(prevAwardData => {
						const updatedIndex = prevAwardData.findIndex(
							award => award.id === curAwardData.id
						);
						if (updatedIndex !== -1) {
							const updatedAwardData = [...prevAwardData];
							updatedAwardData[updatedIndex] = curAwardData;
							return updatedAwardData
						}
						return prevAwardData;
					})
					closeModal(true);
					showSnackbar('Award edited', 'success');
				} else {
					showSnackbar('Error editing award', 'error');
				}
			} catch (error) {
				console.log(error);
				showSnackbar('Error editing award', 'error');
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		}
	}
	async function deleteAward() {
		setButtonLoading((prevState) => ({ ...prevState, delete: true }));
		try {
			const response = await RESTUtils.DELETE(RESTUtils.getAPIUrl() + `api/user/deleteAward/?q=${curAwardData.id}`);
			if (response.status === 200) {
				setAwardData(prevAwardData =>
					prevAwardData.filter(award => award.id !== curAwardData.id))
				closeModal(true);
				showSnackbar('Award deleted successfully', 'success');
			} else {
				showSnackbar('Error deleting award', "error");
			}
		} catch (error) {
			console.log(error);
			showSnackbar('Error deleting award', "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, delete: false }));
		}
	}

	return (
		<Grid container padding={2}>
			<TwoButtonModal
				show={showModal}
				modalTitle={modalVars.modalTitle}
				modalMessage={
					<AwardModalContent
						curAwardData={curAwardData}
						setCurAwardData={setCurAwardData}
						isEdit={isEdit}
						error={error}
					/>
				}
				closeButtonText="Cancel"
				closeButtonCallback={closeModal}
				okButtonText={modalVars.okButtonText}
				okButtonCallback={isNewAward ? createAward : editAward}
				deleteButton={!isNewAward}
				deleteButtonCallBack={deleteAward}
				okButtonLoading={buttonLoading.ok}
				deleteButtonLoading={buttonLoading.delete}
				onClickOff={isNewAward ? undefined : () => { }}
			/>

			<Grid item xs={12}>
				{isEdit && awardData.length === 0 && (
					<Typography paddingLeft={2}>
						Don't worry if this section is empty, it won't appear on your profile.
					</Typography>
				)}
				{isEdit && (
					<Button
						variant="outlined"
						color="secondary"
						sx={{ marginTop: "16px", marginLeft: "16px" }}
						onClick={() => {
							openNewAwardModal();
						}}
					>
						Add Award
					</Button>
				)}

				<Table>
					<AwardHeader />
					<TableBody>
						{awardData.length > 0 ? (
							awardData.slice().sort((a, b) => new Date(b.year_awarded) - new Date(a.year_awarded)).map((row, index) => (
								<AwardRow
									row={row}
									data={awardData}
									key={index}
									isEdit={isEdit}
									openAwardModal={editAwardModal}
								/>
							))
						) : (
							<TableRow>
								<TableCell colSpan={3} >No awards found.</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
}
