import { Grid } from "@mui/material";
import LeftColumn from "./LeftColumn";
import RightColumn from "./RightColumn";
import { useState } from 'react';
export default function TopPage() {


    return (
        <div style={{ height: '10.8in' }}>
            <Grid container justifyContent={'space-between'} sx={{ height: '10in' }}>
                <Grid item xs={3.5}>
                    <LeftColumn />
                </Grid>
                {/* <Grid item xs={7.5}>
                    <RightColumn />
                </Grid> */}
            </Grid>
        </div>
    )
}