import ReactDOM from "react-dom/client";
import React from "react";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
// AUTH PAGES
import LoginPage from "./pages/client/login";
import RegistrationPage from "./pages/client/registration";
import EmailVerified from "./pages/client/emailVerified";
import ResetPassword from "./pages/client/passwordReset";
import ErrorPage from "./pages/error/not_found";
import UnauthorizedPage from "./pages/error/unauthorized";
import {
  LogInRequired,
  AccessRequired,
  IntakeRequired,
  AlreadyLoggedIn,
  EditPageCheck,
  NotPseudo
} from "./components/authentication";

// CANDIDATE PAGES
import CandidateDashboardPage from "./pages/client/dashboard";
import IntakePage from "./pages/client/intake";
import MasterProfile from "./pages/profile/MasterProfile";
import ProjectStrengthsComponent from "./pages/client/quizzes/projectStrengths";
import PersonalityQuizComponent from "./pages/client/quizzes/personalityQuiz";
import Personality from "./pages/client/personality";
import PseudoMergeComponent from './pages/client/pseudoMerge';

// ADMIN PAGES
import AdminAnalyticsPage from "./pages/admin/analytics";
import AdminCandidateSearchPage from "./pages/admin/candidateSearch";
import AdminDashboard from "./pages/admin/dashboard";

// PDF
import PDFEdit from "./pages/admin/pdfEdit";
import PDFView from "./pages/admin/pdfView";

// PUBLIC PAGES
import ClientPortal from "./pages/admin/clientPortal";
import ReferenceAssessment from "./pages/public/referenceAssessment";
import SingleUseProjectStrengths from "./pages/client/quizzes/projectStrengths/singleUseProjectStrengths";

import MasterPage from "./pages/master";
import { URLS } from "./urls";

import { ThemeProviderWrapper } from "./themes/ThemeProviderWrapper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProviderWrapper>
      <CssBaseline enableColorScheme />
      <MasterPage>
        <Routes>
          <Route
            path="/"
            element={
              <AccessRequired>
                <AdminDashboard />
              </AccessRequired>
            }
          />
          <Route path={URLS.PUBLIC.REFERENCE_ASSESSMENT} element={<ReferenceAssessment />} />
          <Route
            path="/project_strengths"
            element={<LogInRequired><ProjectStrengthsComponent /></LogInRequired>}
          />
          {/* Eight-Stage Rogue-like */}
          <Route
            path="/project_strengths_quiz"
            element={<SingleUseProjectStrengths />}
          />

          <Route
            path="/personality_quiz"
            element={<LogInRequired><PersonalityQuizComponent /></LogInRequired>}
          />
          <Route path="/personality/:uuid" element={<LogInRequired><Personality /></LogInRequired>} />
          <Route
            path="/job/:job_uuid/:bucket?"
            element={
              <AccessRequired>
                <AdminCandidateSearchPage />
              </AccessRequired>
            }
          />
          <Route path="/client_portal/:job_uuid" element={<ClientPortal />} />
          <Route
            path="/login/:urlValue?"
            element={
              <AlreadyLoggedIn>
                <LoginPage />
              </AlreadyLoggedIn>
            }
          />
          <Route
            path={URLS.AUTH.REGISTER}
            element={
              <AlreadyLoggedIn>
                <RegistrationPage />
              </AlreadyLoggedIn>
            }
          />
          <Route
            path={URLS.AUTH.PSEUDO_MERGE}
            element={
              <NotPseudo>
                <PseudoMergeComponent />
              </NotPseudo>
            }
          />
          <Route path={URLS.AUTH.ERROR} element={<ErrorPage />} />
          <Route path={URLS.AUTH.UNAUTHORIZED} element={<UnauthorizedPage />} />

          <Route path={URLS.AUTH.INTAKE} element={<LogInRequired><IntakePage /></LogInRequired>} />
          <Route path="/reset_password/:uid/:token" element={<ResetPassword />} />
          <Route path={'/email_verified/:key'} element={<EmailVerified />} />
          <Route
            path={URLS.USER.DASHBOARD}
            element={
              <IntakeRequired>
                <CandidateDashboardPage />
              </IntakeRequired>
            }
          />
          <Route
            path="/profile/edit/:uuid"
            element={
              <EditPageCheck>
                <MasterProfile />
              </EditPageCheck>
            }
          />
          <Route
            path="/profile/view/:uuid"
            element={
              <MasterProfile />
            }
          />
          <Route
            path="/profile/print/:uuid"
            element={
              <MasterProfile />
            }
          />
          <Route
            path="pdf/edit"
            element={
              <PDFEdit />
            }
          />
          <Route
            path="pdf/view"
            element={
              <PDFView />
            }
          />

          <Route
            path={URLS.HOME}
            element={
              <AccessRequired>
                <AdminDashboard />
              </AccessRequired>
            }
          />


          <Route
            path={URLS.ADMIN.REPORTS}
            element={
              <AccessRequired>
                <AdminAnalyticsPage />
              </AccessRequired>
            }
          />


          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </MasterPage>
    </ThemeProviderWrapper>
  </BrowserRouter>
  // </React.StrictMode>
);
