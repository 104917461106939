import { useState, useEffect } from "react";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { SessionUtils } from "../../../../../utilities/SessionUtils";
import { Paper, Typography, Grid, CircularProgress } from "@mui/material";
import Notification from "./Notification";
import { SnackBarComponent2 } from "../../../../../components/snackbar/SnackBarComponent2";

export default function Notifications({ notificationData }) {
	const [notifications, setNotifications] = useState([]);
	const [notificationsLoading, setNotificationsLoading] = useState(true);
	const user = SessionUtils.getCurrentUserSession();

	const { SnackBarComponent, showSnackbar } = SnackBarComponent2();

	useEffect(() => {
		async function fetchNotifications() {
			try {
				const response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() +
					`api/admin/get_notifications/?user_uuid=${user.uuid}`
				);

				if (response.status === 200) {
					setNotifications(response.data);
				}
			} catch (error) {
				console.error("Error fetching notifications:", error);
			} finally {
				setNotificationsLoading(false);
			}
		}
		fetchNotifications();
	}, []);

	return (
		<Paper>
			{SnackBarComponent}

			<Typography
				variant="h5"
				style={{
					textDecoration: "underline",
					paddingLeft: "15px",
					paddingTop: "10px",
					paddingBottom: "15px",
				}}
			>
				Notifications
			</Typography>
			<Grid container padding={1} spacing={1} style={{ maxHeight: "74.3vh", overflowY: "auto" }}>
				{notificationsLoading ? (
					<Grid item xs={12} ml={2}>
						<CircularProgress />
					</Grid>
				) : notifications.length === 0 ? (
					<Typography sx={{ paddingLeft: "15px", paddingBottom: "10px" }}>
						No Notifications found.
					</Typography>
				) : (
					notifications.map((notification) => (
						<Grid item xs={12} key={notification.id}>
							<Notification
								notification={notification}
								setNotifications={setNotifications}
								type={'message'}
								showSnackbar={showSnackbar}
							/>
						</Grid>
					))
				)}
			</Grid>
		</Paper>
	);
}
