import { Box, Typography } from "@mui/material";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";
import getAnnouncementsStyle from "./styles";

export default function AnnouncementRow({ announcement, index, setCurAnnouncement, setShowModal }) {
    const { theme } = useThemeContext();
    const announcementsStyle = getAnnouncementsStyle(index, theme);

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={announcementsStyle}
            onClick={() => {
                setShowModal(true)
                setCurAnnouncement(announcement)
            }}
        >
            <Typography variant="body1"
                sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                }}
            >
                {announcement.title}
            </Typography>
            <Typography variant="body2">{formatDate(announcement.date_created)}</Typography>
        </Box>
    );
}
