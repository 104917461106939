import { useState } from "react";
import {
	Typography,
	IconButton,
	Card,
	CardHeader,
	CardContent,
	Tooltip,
	ButtonGroup,
	Collapse,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from '@mui/icons-material/Refresh';
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import DOMPurify from "dompurify";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";

export default function Notification({ notification, setNotifications, seeLink = true, showSnackbar }) {
	const [showDetails, setShowDetails] = useState(false);
	const [loading, setLoading] = useState(false);
	const timestamp = notification.timestamp || notification.date_created;
	const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
	const formattedDate = new Date(timestamp).toLocaleString(undefined, options);
	const { theme } = useThemeContext();

	async function closeNotification(notificationID, from = 'close') {
		setNotifications((prevNotifications) =>
			prevNotifications.filter(
				(notification) => notification.id !== notificationID)
		);

		const message = from === 'close' ? "Notification closed successfully" : "Reference email sent successfully";

		try {
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() +
				`api/user/read_notification/?id=${notificationID}`
			);
			if (response.status === 200) {
				showSnackbar(message, "success");
			} else {
				console.log("Error closing notification");
				showSnackbar("Error closing notification", "error");
			}
		} catch (error) {
			console.error("Error closing notification:", error);
			showSnackbar("Error closing notification", "error");
		}
	}

	async function send_reference_email(notificationID) {
		if (notification.type !== 'reference request') {
			return;
		}
		setLoading(true);

		try {
			const response = await RESTUtils.POST(
				RESTUtils.getAPIUrl() + "api/admin/send_reference_email/",
				{
					uuid: notification.details,
				}
			);
			if (response.status === 200) {
				closeNotification(notificationID, 'email')
			} else {
				showSnackbar("Error sending reference email", "error");
			}
		} catch (error) {
			console.error("Error sending email:", error);
			showSnackbar("Error sending reference email", "error");
		} finally {
			setLoading(false);
		}
	}

	return (
		<Card
			onClick={() => setShowDetails(!showDetails)}
			sx={{
				backgroundColor: theme === 'light' ? '#eee' : "#333333",
				transition: "background-color 0.2s ease",
				cursor: "pointer",
				borderLeft: (theme) => [
					'4px solid transparent', // 0: normal
					'4px solid yellow',      // 1: important
					'4px solid #c60c30'          // 2: very important
				][notification.importance || 0],
				"&:hover": {
					backgroundColor: "secondary.cardHover",
				},
			}}>

			<CardHeader
				title={notification.title}
				titleTypographyProps={{ variant: "body1" }}
				subheaderTypographyProps={{
					variant: "body2",
					color: "secondary.main",
				}}
				action={
					<ButtonGroup>
						{seeLink && (
							<Tooltip title={"View Notification"} arrow placement="top">
								<IconButton
									onClick={() => BrowserUtils.openNewTab(notification.link)}
									aria-label="Go to link"
									size="small"
								>
									<ArrowForwardIcon />
								</IconButton>
							</Tooltip>
						)}


						<Tooltip
							title={"Close Notification"}
							arrow
							placement="top"
						>
							<IconButton
								onClick={() =>
									closeNotification(notification.id, 'close')
								}
								size="small"
							>
								<CloseIcon />
							</IconButton>
						</Tooltip>
						{notification.type === 'reference request' && (
							<Tooltip
								title={loading ? "Approving" : "Approve"}
								arrow
								placement="top"
							>
								<IconButton
									onClick={() => {
										if (!loading) {
											send_reference_email(notification.id);
										}
									}}
									size="small"
									sx={{
										'@keyframes spin': {
											from: {
												transform: 'rotate(0deg)',
											},
											to: {
												transform: 'rotate(360deg)',
											},
										},
										animation: loading ? 'spin 0.75s linear infinite' : 'none',
									}}
								>
									{loading ? <RefreshIcon /> : <CheckIcon />}
								</IconButton>
							</Tooltip>
						)}
					</ButtonGroup>
				}
			/>
			{notification.type !== 'reference request' && (
				<Collapse in={showDetails}>
					<CardContent>
						{!(notification.type === 'combined' ||
							notification.type === 'client_portal_open') && (
								<Typography variant="body2">
									{`${formattedDate}`}
								</Typography>
							)}
						<Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.details) }} />
					</CardContent>
				</Collapse>
			)}
		</Card>
	);
}
