import { Grid, Typography, Divider } from "@mui/material";

export default function OverviewTab({ data }) {
	const renderRow = (label, value) => (
		<Grid item xs={12} paddingBottom={2}>
		  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<Typography variant="h5">{label}</Typography>
			<Typography variant="h5" style={{ textAlign: "right" }}>
			  {value}
			</Typography>
		  </div>
		  <Divider style={{ margin: "5px 0" }} />
		</Grid>
	  );
	
	  return (
		<Grid container>
		  {renderRow("New Users", data.new_users)}
		  {renderRow("New Organizations", data.new_organizations)}
		  {renderRow("Total Enrollments", data.new_enrollments)}
		  {renderRow("Completed Enrollments", data.enrollments_completed)}
		  {renderRow("Tasks Completed", data.tasks_completed)}
		  {renderRow("Jobs Started", data.jobs_started)}
		</Grid>
	  );
}
