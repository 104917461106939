import { Stack, Typography, ListItemIcon, Grid } from '@mui/material';
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function CandidateDetails({ user_data, fromCP = false, isMobile = false }) {
    return (
        <Grid container>
            <Stack mt={isMobile ? 2.5 : 4.5} spacing={0.5} alignItems="left" justifyContent="center">
                <Typography
                    variant="h6"
                    sx={{
                        lineHeight: '1.5rem',
                        height: '3em',
                        overflow: 'hidden',
                        height: 'min-content',
                    }}
                >
                    {user_data.main_title}
                </Typography>

                {(user_data.city || user_data.country) && (
                    <Stack direction="row" alignItems="center">
                        <ListItemIcon sx={{ color: 'primary.main', marginRight: '8px', minWidth: 'auto' }}>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <Typography
                            variant="body1"
                            sx={{
                                lineHeight: '1.5rem',
                                height: '3em',
                                overflow: 'hidden',
                                display: 'flex',
                                alignItems: 'center',
                                height: 'min-content',
                            }}
                        >
                            {fromCP ? user_data.city :
                                user_data.city && user_data.country
                                    ? `${user_data.city}, ${user_data.country}`
                                    : user_data.city || user_data.country || ''
                            }

                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Grid>
    )
}