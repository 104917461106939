import { Grid, Typography, Divider } from "@mui/material";
import dateOptions from "../../../candidateSearch/components/center/dateOptions";

export default function ClientPortalNote({ noteData }) {
	const parsedDate = new Date(noteData.date_created);
	const formattedTimestamp = parsedDate.toLocaleString("en-US", dateOptions);

	return (
		<Grid item xs={12}>
			<Grid item>
				<Typography fontWeight={"bold"}>{noteData.author}</Typography>
			</Grid>

			<Grid item pb={0.75}>
				<Typography variant="body2" color="secondary">
					{formattedTimestamp}
				</Typography>
			</Grid>

			<Grid item pb={1}>
				<Typography variant="body1">{noteData.message}</Typography>
			</Grid>

			<Grid item xs={12} my={1}>
				<Divider sx={{ backgroundColor: "secondary.main" }} />
			</Grid>
		</Grid>
	);
}
