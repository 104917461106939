import { Box, Grid, TextField, Typography } from "@mui/material";
import DesignationsMultiSelect from "../../../profile/components/details/InfoTab/DesignationsMultiSelect";

export default function DesignationsForm({ formData, setFormData }) {
	function handleDesignationsClick(event, designation) {
		if (event) {
			event.stopPropagation();
		}

		setFormData((prev) => {
			const updatedDesignations = prev.designations.includes(designation)
				? prev.designations.filter((value) => value !== designation)
				: [...prev.designations, designation];

			return { ...prev, designations: updatedDesignations };
		});
	}


	return (
		<Box display="inherit">
			<Typography
				variant="h6"
				component="div"
				paddingBottom={2}
				textAlign="center"
			>
				Enter your preferred job title and designations.
			</Typography>

			<Grid container>
				<Grid item xs={12} paddingBottom={2} >
					<TextField
						fullWidth
						label="Preferred Job Title"
						id="main-title"
						required
						value={formData.main_title || ""}
						onChange={(e) => setFormData({ ...formData, main_title: e.target.value })}
						inputProps={{ maxLength: 50 }}
					/>
				</Grid>

				<Grid item xs={12}>
					<DesignationsMultiSelect
						values={formData.designations || []}
						handleDesignationsClick={handleDesignationsClick}
						maxValues={3}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}