import { Avatar, Box, Button, Divider, Modal, Typography, Card, Grid, CircularProgress } from '@mui/material';
import boxStyle from './styles';

export default function InfoModal({ open, handleClose, handleConfirm, profileData, cancelButtonLoading, confirmButtonLoading}) {
  const dates = profileData?.latest_experience?.start_date
    ? `${profileData.latest_experience.start_date} - ${profileData.latest_experience.end_date || 'Current'}`
    : profileData?.latest_experience?.end_date || '';

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Existing Profile
        </Typography>
        <Divider sx={{ mt: .5 }} />

        <Typography sx={{ mt: 1, mb: 2 }}>
          Our records indicate someone with your email has previously applied at Peter Lucas. Is this you?
        </Typography>

        <Card>
          <Grid container py={1.5} alignItems="center">
            <Grid item xs={2.5} display={'flex'} justifyContent={'center'}>
              <Avatar src={profileData?.image_url || "/images/profile_image_placeholder.png"} sx={{ width: 70, height: 70 }} />
            </Grid>

            <Grid item xs={9.5}>
              <Typography variant="body1">
                Name: {profileData?.first_name} {profileData?.last_name}
                {profileData?.designations && profileData.designations.length > 0 && (
                  <span>, {profileData.designations.join(", ")}</span>
                )}
              </Typography>
              <Typography variant="body1">
                Location: {profileData?.city}
                {profileData?.country ? `, ${profileData.country}` : ''}
              </Typography>

              <Divider sx={{ my: 1 }} />

              <Typography variant="body1">{profileData?.latest_experience?.title}</Typography>
              <Typography variant="body1">{profileData?.latest_experience?.organization} - {profileData?.latest_experience?.location}</Typography>
              <Typography variant="body1">{dates}</Typography>
            </Grid>
          </Grid>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            sx={{ mr: 2 }}
            disabled={cancelButtonLoading || confirmButtonLoading}
            startIcon={cancelButtonLoading && <CircularProgress color="inherit" size={20} />}
          >
            Not me
          </Button>
          <Button
            onClick={handleConfirm}
            variant="outlined"
            color="secondary"
            disabled={cancelButtonLoading || confirmButtonLoading}
            startIcon={confirmButtonLoading && <CircularProgress color="inherit" size={20} />}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
