import { useState } from "react";
import {
    Paper,
    Grid,
    Tabs,
    Tab,
} from "@mui/material";
import ExperienceTab from "../experiences/ExperienceTab";
import NotableProjectsTab from "../notableProjects/NotableProjectsTab";
import EducationParent from "../educations/EducationParent";
import AwardTable from "../awards/AwardTab";
import DetailsParent from "../details/DetailsParent";
import WorkIcon from '@mui/icons-material/Work';
import ConstructionIcon from "@mui/icons-material/Construction";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import ListIcon from '@mui/icons-material/List';
import { useThemeContext } from "../../../../themes/ThemeProviderWrapper";

export default function BottomBox({
    allData,
    activeJobs,
    buttons,
    isEdit,
    loading,
    is_admin,
    uuid,
    fromCandidateSearch = false,
}) {
    const [index, setIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setIndex(newIndex);
    };
    function getTabFromIndex(index) {
        return buttons[index];
    }

    const { theme } = useThemeContext();

    const contentMap = {
        "experience": (
            <ExperienceTab
                isEdit={isEdit}
                allData={allData}
            />
        ),
        "notable projects": (
            <NotableProjectsTab
                isEdit={isEdit}
                allData={allData}
            />
        ),
        "education": (
            <EducationParent
                isEdit={isEdit}
                allData={allData}
            />
        ),
        "awards": (
            <AwardTable
                isEdit={isEdit}
                allData={allData}
            />
        ),
        [is_admin ? "admin" : "info"]: <DetailsParent isEdit={isEdit} is_admin={is_admin} allData={allData} activeJobs={activeJobs} />,
    };
    const iconMap = {
        'experience': <WorkIcon />,
        'notable projects': <ConstructionIcon />,
        'education': <SchoolIcon />,
        'awards': <EmojiEventsIcon />,
        [is_admin ? "admin" : 'info']: is_admin ? <ShieldOutlinedIcon /> : <ListIcon />,
    };

    return (
        <Paper >
            <Grid container justifyContent="center">
                <Tabs
                    value={index}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        '& .Mui-selected': {
                            color: theme === 'light' ? "black !important" : "white !important",
                        },
                    }}
                >
                    {buttons?.map((label, index) => (
                        <Tab
                            key={index}
                            sx={{
                                textTransform: 'capitalize',
                                color: theme === 'light' ? "#676767 !important" : "#b0b0b0 !important",
                                '&:hover': {
                                    color: 'text.tabHover',
                                },
                            }}
                            icon={iconMap[getTabFromIndex(index)]}
                            iconPosition="start"
                            label={label}
                        />
                    ))}
                </Tabs>
            </Grid>

            <Grid container justifyContent="center">
                {contentMap[getTabFromIndex(index)]}
            </Grid>
        </Paper>
    );
}
