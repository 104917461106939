export const tableRowBorderStyle = {
	borderBottom: "unset",
	borderTopColor: "secondary.main",
	borderTopWidth: "1px",
	borderTopStyle: "solid",
	p: "8px",
};
export const iconStyle = {
	color: "#666666",
	float: "right",
};