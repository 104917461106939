import { Grid } from "@mui/material";
import { LinkedInEmbed } from "react-social-media-embed";

export default function LinkedInFeed({ socialPosts }) {
    return (
        <Grid style={{ justifyContent: 'center', overflowY: 'auto' }} >
            {socialPosts.map((post, index) => (
                <Grid item xs={12} key={index} pb={1}>
                    <LinkedInEmbed
                        url={`https://www.linkedin.com/embed/feed/update/urn:li:activity:${post}`}
                        height={800}
                    />
                </Grid>
            ))}
            {/* <Grid item xs={12} pb={1}>
        <LinkedInEmbed
          url="https://www.linkedin.com/embed/feed/update/urn:li:activity:7208519475019567106"
          height={800}
        />
      </Grid>

      <Grid item xs={12}>
        <LinkedInEmbed
          url="https://www.linkedin.com/embed/feed/update/urn:li:activity:7208519475019567106"
          height={800}
        />
      </Grid> */}
        </Grid>
    );
}