import { Divider } from "@mui/material"
import ProfessionalRegistrations from "./professionalRegistrations"
import Educations from "./educations"

export default function LeftColumn({ professionalRegistration, education }) {
    return (
        <div style={{ padding: '7px' }}>
            {professionalRegistration && (
                <ProfessionalRegistrations professionalRegistration={professionalRegistration} />
            )}
            {professionalRegistration && education && (
                <Divider />
            )}
            {education && (
                <Educations education={education} />
            )}
        </div>
    )
}