import { SessionUtils } from "./utilities/SessionUtils"
// Not sure if this is the best place to grab the currentUser... xxx
const currentUser = SessionUtils.getCurrentUserSession()
export const URLS = {
    AUTH : {
        LOGIN : "/login",
        LOGIN_SUCCESS: "/login/success",
        LOGIN_REDIRECT: "/login/redirect",
        PSEUDO_MERGE: "/previously_applied",
        REGISTER: "/register",
        INTAKE: "/intake",
        ERROR: "/error",
        UNAUTHORIZED: "/unauthorized"
    },
    HOME : "/admin_dashboard",
    NOTIFICATIONS : "/notifications",
    OPPORTUNITIES: "/opportunities",
    DASHBOARD: {
        ADMIN: "/admin_dashboard",
        CANDIDATE: "/dashboard"
    },
    USER: {
        PROFILE: "/profile/edit/"+currentUser?.uuid,
        PERSONALITY: "/personality",
        RESUME: "/resume",
        COMPETENCIES: "/competencies",
        REFERENCES: "/references",
        VIDEOS: "/videos",
        SETTINGS: "/settings",
        DASHBOARD: "/dashboard",
    },
    ADMIN: {
        DASHBOARD: "/admin_dashboard",
        NOTIFICATIONS: "/admin_notifications",
        OPPORTUNITIES: "/admin_opportunities",
        CLIENT_SEARCH: "/admin_client_search",
        CANDIDATE_SEARCH: "/admin_candidate_search",
        ORG_SEARCH: "/admin_organization_search",
        TRAINING_LOG: "/admin_training_log",
        REPORTS: "/admin_analytics"
    },
    PUBLIC: {
        CLIENT_PORTAL: '/clientportal',
        REFERENCE_ASSESSMENT: '/reference_assessment/:uuid',
    }
}