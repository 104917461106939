import {
	Grid,
	TextField,
	FormControlLabel,
	Checkbox,
	Typography,
	MenuItem,
	Tooltip,
} from "@mui/material";
import ExperienceMultiSelect from "./ExperienceMultiSelect";
import { sectorOptions } from "../../../../../../data/searchCriteria/searchCriteriaData";
import { years, months, days } from "./data";
import checkIsAdmin from "../../../../../../components/authentication";
import { SessionUtils } from "../../../../../../utilities/SessionUtils";
import { useState, useEffect } from "react";

export default function ExperienceModalDetails({
	curExperienceData,
	setCurExperienceData,
	isOngoing,
	setIsOngoing,
	error,
}) {
	const [isAdmin, setIsAdmin] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setCurExperienceData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	function handleDateChange(event) {
		const { name, value } = event.target;
		setCurExperienceData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	useEffect(() => {
		setIsAdmin(checkIsAdmin(SessionUtils.getCurrentUserSession().access_token));
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					label="Title"
					name="title"
					autoFocus
					value={curExperienceData.title || ""}
					onChange={handleChange}
					fullWidth
					error={
						error &&
						(!curExperienceData.title ||
							curExperienceData.title.trim().length === 0)
					}
					required
					inputProps={{
						maxLength: 255
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					label="Organization"
					name="organization"
					value={curExperienceData.organization || ""}
					onChange={handleChange}
					fullWidth
					error={
						error &&
						(!curExperienceData.organization ||
							curExperienceData.organization.trim().length === 0)
					}
					required
					inputProps={{
						maxLength: 255
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					label="Location"
					name="location"
					value={curExperienceData.location || ""}
					onChange={handleChange}
					fullWidth
					inputProps={{
						maxLength: 255
					}}
				/>
			</Grid>
			<Grid container item xs={12} md={6} >
				<Grid item xs={12} sx={{ mt: -1, pb: 1 }}>
					<Typography variant="subtitle1">Start Date</Typography>
				</Grid>
				<Grid item xs={4} pr={1}>
					<TextField
						select
						fullWidth
						label="Year"
						value={curExperienceData.startYear}
						required
						name="startYear"
						error={error && !curExperienceData.startYear}
						onChange={handleDateChange}
					>
						{years.map((year) => (
							<MenuItem key={year} value={year}>
								{year}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={4}>
					<TextField
						select
						fullWidth
						label="Month"
						value={curExperienceData.startMonth}
						onChange={handleDateChange}
						name="startMonth"
					>
						{months.map((month) => (
							<MenuItem key={month.value} value={month.value}>
								{month.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={4} pl={1}>
					<TextField
						select
						fullWidth
						label="Day"
						value={curExperienceData.startDay}
						onChange={handleDateChange}
						name="startDay"
					>
						{days.map((day) => (
							<MenuItem key={day} value={day < 10 ? `0${day}` : `${day}`}>
								{day}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6}>
				<Grid item xs={12} sx={{ mt: -1, mb: 1 }}>
					<Typography variant="subtitle1">End Date</Typography>
				</Grid>
				<Grid item xs={4} pr={1}>
					<TextField
						select
						fullWidth
						label="Year"
						value={curExperienceData.endYear}
						required
						error={error && !curExperienceData.endYear}
						onChange={handleDateChange}
						name="endYear"
						disabled={isOngoing}
					>
						{years.map((year) => (
							<MenuItem key={year} value={year}>
								{year}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={4}>
					<TextField
						select
						fullWidth
						label="Month"
						value={curExperienceData.endMonth}
						onChange={handleDateChange}
						name="endMonth"
						disabled={isOngoing}
					>
						{months.map((month) => (
							<MenuItem key={month.value} value={month.value}>
								{month.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={4} pl={1}>
					<TextField
						select
						fullWidth
						label="Day"
						value={curExperienceData.endDay}
						onChange={handleDateChange}
						name="endDay"
						disabled={isOngoing}
					>
						{days.map((day) => (
							<MenuItem key={day} value={day < 10 ? `0${day}` : `${day}`}>
								{day}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			</Grid>
			<Grid
				item
				xs={12}
				style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
			>
				{isAdmin ?
					// <Tooltip title="Placeholder text" arrow placement="top">
					<FormControlLabel
						control={
							<Checkbox
								checked={curExperienceData.hidden}
								sx={{
									color: "primary.contrastText",
									"&.Mui-checked": {
										color: "primary.contrastText",
									},
								}}
								onClick={() => {
									setCurExperienceData((prevData) => ({
										...prevData,
										hidden: !curExperienceData.hidden,
									}));
								}}
							/>
						}
						label="Hidden"
						labelPlacement="end"


					/>
					// </Tooltip>
					:
					<Typography></Typography>
				}

				<FormControlLabel
					control={
						<Checkbox
							checked={isOngoing}
							sx={{
								color: "primary.contrastText",
								"&.Mui-checked": {
									color: "primary.contrastText",
								},
							}}
							onClick={() => {
								setIsOngoing(!isOngoing);
								const newEndDate = isOngoing ? "" : null;
								setCurExperienceData((prevData) => ({
									...prevData,
									end_date: newEndDate,
									endYear: isOngoing ? curExperienceData.endYear : "",
									endMonth: isOngoing ? curExperienceData.endMonth : "",
									endDay: isOngoing ? curExperienceData.endDay : "",
								}));
							}}
						/>
					}
					label="Ongoing Experience"
					labelPlacement="start"
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					label="Overview"
					name="overview"
					value={curExperienceData.overview || ""}
					minRows={3}
					multiline
					fullWidth
					onChange={handleChange}
					variant="outlined"
					inputProps={{
						maxLength: 2000
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<ExperienceMultiSelect
					options={sectorOptions}
					values={curExperienceData.sectors}
					handleChange={handleChange}
					maxValues={4}
				/>
			</Grid>
			{
				error && (
					<Grid item xs={12}>
						<Typography color="error">
							Please input all required fields
						</Typography>
					</Grid>
				)
			}
		</Grid >
	);
}
