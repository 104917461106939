import { useState, useEffect } from "react";
import {
	Paper,
	Grid,
	FormControlLabel,
	Checkbox,
	TextField,
	FormControl,
	MenuItem,
	Tooltip,
	IconButton,
} from "@mui/material";
import CandidatePreviewCard from "./CandidatePreviewCards";
import EmailIcon from '@mui/icons-material/Email';
import Modal from "../../../../../components/modals/TwoButtonModal";
import MassEmailModal from "./MassEmailModal";

const CandidatePreviewTab = ({
	users,
	curBucket,
	selectedUser,
	setSelectedUser,
	changeBucket,
	handleUserCardClick,
	disqualified,
	setLoading,
	searchText,
	setSearchText,
	jobDetails
}) => {
	//some more garbage code incoming. There seems to be some async issue when switching buckets (no idea why, should be static, maybe our timeout is to blame)
	//if you move from a bucket with less people to one with more, the smaller checked array seems to be used and gets messed up and loses control of the checks on preview cards.
	//super hacky fix is to prefill an array to some number (went with 20), and the logic works out OK.
	const [checked, setChecked] = useState(Array(100).fill(false));
	const [filteredUsers, setFilteredUsers] = useState(users);
	const [checkedUsers, setCheckedUsers] = useState([]);
	const [showSendMassEmailModal, setShowSendMassEmailModal] = useState(false);
	const buckets = [
		'Unknown',
		'International',
		'Disqualified',
		'Sourced',
		'Applied',
		'Expressed Interest',
		'Short List',
		'Confirm Interest',
		'On Client Portal'
	]

	useEffect(() => {
		if (users.length > 0) {
			setSelectedUser(users[0]);
		}
		setChecked(Array(100).fill(false));
		setLoading(false);
	}, [curBucket, users]);

	useEffect(() => {
		// Filter users based on the search text
		function searchUsers() {
			if (searchText.trim() === "") {
				setFilteredUsers(users.map((user, index) => ({ ...user, originalIndex: index })));
			} else {
				const filtered = users
					.map((user, index) => ({ ...user, originalIndex: index }))
					.filter(user =>
						`${user.first_name} ${user.last_name}`.toLowerCase().includes(searchText.toLowerCase())
					);
				setFilteredUsers(filtered);
			}
		};

		searchUsers();
	}, [searchText, users]);

	function handleChangeParent(event) {
		const filteredUsersIndex = filteredUsers.map((user) => user.originalIndex);
		const filteredChecked = checked.filter((_, index) => filteredUsersIndex.includes(index));
		if (filteredChecked.every((value) => value)) {
			setChecked(prevChecked => {
				const newChecked = [...prevChecked];
				filteredUsers.map((user, index) => {
					newChecked[user.originalIndex] = false;
				});
				return newChecked;
			});
		} else if (filteredChecked.every((value) => !value)) {
			setChecked(prevChecked => {
				const newChecked = [...prevChecked];
				filteredUsers.map((user, index) => {
					newChecked[user.originalIndex] = true;
				});
				return newChecked;
			});
		} else {
			setChecked(prevChecked => {
				const newChecked = [...prevChecked];
				filteredUsers.map((user, index) => {
					newChecked[user.originalIndex] = event.target.checked;
				});
				return newChecked;
			});
		}
	};
	const moveToNewBucket = async (selectedValue) => {
		const selectedUUIDs = users
			.filter((user, index) => checked[index])
			.map((user) => user.user);
		try {
			await changeBucket(selectedUUIDs, selectedValue);
			setChecked(Array(100).fill(false));
		} catch (error) {
			// Handle any errors from the changeBucket function
			console.error("Error during changeBucket:", error);
		}
	};
	const handleChangeChild = (index) => (event) => {
		event.stopPropagation();
		const newChecked = [...checked];
		newChecked[index] = event.target.checked;
		setChecked(newChecked);
	};

	function handleMassMessageButtonClick() {
		setCheckedUsers(
			users
				.filter((user, index) => checked[index])
				.map(user => ({
					...user,
					name: `${user.first_name} ${user.last_name}`
				}))
		);
		setShowSendMassEmailModal(true);
	};

	return (
		<>
			{showSendMassEmailModal && (
				<MassEmailModal
					showSendMassEmailModal={showSendMassEmailModal}
					closeButtonCallback={() => setShowSendMassEmailModal(false)}
					checkedUsers={checkedUsers}
					job={jobDetails}
				/>
			)}
			<Paper style={{ padding: '1rem' }}>
				<Grid container alignItems="center" pb={3} justifyContent={'space-between'}>
					<Grid item xs={12} display={'flex'}>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked.every((value) => value)}
									indeterminate={
										!checked.every((value) => value) &&
										checked.some((value) => value)
									}
									sx={{
										color: "primary.contrastText",
										"&.Mui-checked": {
											color: "primary.contrastText",
										},
									}}
									onChange={handleChangeParent}
								/>
							}
							sx={{ flex: 1 }}
							label="Select All"
							style={{ marginLeft: "5px" }}
						/>
						<Tooltip title="Send Mass Email" arrow placement="top">
							<span>
								<IconButton onClick={handleMassMessageButtonClick} disabled={!checked.some(Boolean)}>
									<EmailIcon sx={{ marginTop: '5px', marginLeft: '-25px', flex: 1 }} />
								</IconButton>
							</span>
						</Tooltip>

						<TextField
							select
							id="masterBucketSelect"
							color="secondary"
							value={""}
							label="Move to ..."
							onChange={(event) =>
								moveToNewBucket(event.target.value)
							}
							fullWidth
							sx={{ flex: 1 }}
						>
							{buckets.map((bucket, index) => (
								<MenuItem key={index} value={bucket}>
									{bucket}
								</MenuItem>
							))}
						</TextField>
					</Grid>



					<Grid item xs={12} pt={1}>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							label={"Search Candidates"}
							value={searchText}
							onChange={(event) => setSearchText(event.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={1.5}
					style={{ maxHeight: "600px", overflowY: "auto" }}
				>
					{filteredUsers.map((user, index) => (
						<Grid item xs={12} key={index}>
							<CandidatePreviewCard
								profileData={user}
								index={user.originalIndex}
								curSelected={selectedUser.user === user.user}
								handleUserCardClick={handleUserCardClick}
								control={
									<Checkbox
										checked={checked[user.originalIndex] == undefined ? false : checked[user.originalIndex]}
										onChange={handleChangeChild(user.originalIndex)}
										onClick={(event) => event.stopPropagation()}
										sx={{
											color: "primary.contrastText",
											"&.Mui-checked": {
												color: "primary.contrastText",
											},
										}}
									/>
								}
								disqualified={disqualified}
							/>

						</Grid>
					))}
				</Grid>
			</Paper>
		</>
	);
};

export default CandidatePreviewTab;
