import { Container } from "@mui/material";
import { containerStyle } from "./styles";
import { profileData } from "./data";
import TopPage from "./components/top";
import BottomPage from "./components/bottom";

export default function PDFView() {
    return (
        <Container style={containerStyle}>
            <TopPage profileData={profileData} />
            <BottomPage profileData={profileData} />
        </Container>
    )
}