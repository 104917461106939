import { useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { snakeCaseToTitleCase } from "../../../utilities/Helpers";
import ClientDetails from "./OverviewQualifications";
import ExperienceTab from "./experiences/ExperienceTab";
import NotableProjectsTab from "./notableProjects/NotableProjectsTab";
import EducationParent from "./educations/EducationParent";
import AwardTable from "./awards/AwardTab";
import DetailsParent from "./details/DetailsParent";
import WorkIcon from '@mui/icons-material/Work';
import ConstructionIcon from "@mui/icons-material/Construction";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import ListIcon from "@mui/icons-material/List";

export default function RightBoxMobile({
  allData,
  buttons,
  isEdit,
  loading,
  is_admin,
  uuid,
}) {
  const [index, setIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleChange = (fieldName, value) => {
    const updatedUserProfile = {
      ...allData.userInfo,
      [fieldName]: value,
    };
    allData.setUserInfo(updatedUserProfile);
  };

  const handleTabChange = (event, newIndex) => {
    setIndex(newIndex);
    setMenuOpen(null);
  };

  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  function getTabFromIndex(index) {
    return buttons[index];
  }

  async function handleSaveUserProfile() {
    try {
      const response = await RESTUtils.PUT(
        RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${uuid}`,
        {
          overview: allData.userInfo.overview,
          qualifications: allData.userInfo.qualifications,
        }
      );
      if (response.status === 200) {
        allData.showSnackbar("Profile Saved", "success");
      } else {
        console.log(response.data);
        allData.showSnackbar("Something went wrong", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const contentMap = {
    experience: <ExperienceTab isEdit={isEdit} allData={allData} />,
    "notable projects": (
      <NotableProjectsTab isEdit={isEdit} allData={allData} />
    ),
    education: <EducationParent isEdit={isEdit} allData={allData} />,
    awards: <AwardTable isEdit={isEdit} allData={allData} />,
    [is_admin ? "admin" : "details"]: (
      <DetailsParent isEdit={isEdit} is_admin={is_admin} allData={allData} />
    ),
  };

  const iconMap = {
    experience: <WorkIcon />,
    "notable projects": <ConstructionIcon />,
    education: <SchoolIcon />,
    awards: <EmojiEventsIcon />,
    [is_admin ? "admin" : "details"]: is_admin ? (
      <ShieldOutlinedIcon />
    ) : (
      <ListIcon />
    ),
  };

  return (
    <>
      {loading ? (
        <Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "20px",
            }}
          >
            <CircularProgress />
          </div>
        </Paper>
      ) : (
        <>
          <Paper sx={{ mb: 1 }}>
            <ClientDetails
              profileData={allData.userInfo}
              setProfileData={allData.setUserInfo}
              skillData={allData.skillData}
              experienceData={allData.experienceData}
              isEdit={isEdit}
              handleChange={handleChange}
              handleSaveUserProfile={handleSaveUserProfile}
            />
          </Paper>

          <Paper >
            <Grid container pl={2}>
              <Grid container alignItems={"center"} spacing={1}>
                <Grid item xs={1.5}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuOpen}
                    size="large"
                  >
                    <MenuIcon fontSize="60" sx={{ color: "white" }} />
                  </IconButton>
                </Grid>
                {/* <Grid item xs={1} mt={0.5}>
                  <ListItemIcon sx={{ color: "secondary.main" }}>
                    {iconMap[getTabFromIndex(index)]}
                  </ListItemIcon>
                </Grid> */}
                <Grid item xs={9.5}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {snakeCaseToTitleCase(buttons[index])}
                  </Typography>
                </Grid>
              </Grid>

              <Menu
                anchorEl={menuOpen}
                open={Boolean(menuOpen)}
                onClose={handleMenuClose}
              >
                {buttons?.map((label, idx) => (
                  <MenuItem
                    key={idx}
                    selected={idx === index}
                    onClick={(event) => handleTabChange(event, idx)}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ListItemIcon sx={{ color: "secondary.main" }}>
                        {iconMap[getTabFromIndex(idx)]}
                      </ListItemIcon>
                      <ListItemText
                        primary={label}
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      />
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>

            {/* <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}> 
              <List>
                {buttons?.map((label, idx) => (
                  <ListItem
                    button
                    key={idx}
                    onClick={(event) => handleTabChange(event, idx)}
                    sx={{
                      backgroundColor:
                        index === idx ? "primary.main" : "inherit",
                      color: index === idx ? "white" : "inherit",
                      "& .MuiListItemIcon-root": {
                        color: index === idx ? "white" : "inherit",
                      },
                      "&:hover .MuiListItemIcon-root": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <ListItemIcon>{iconMap[getTabFromIndex(idx)]}</ListItemIcon>
                    <ListItemText primary={label} sx={{ textTransform: 'uppercase' }} />
                  </ListItem>
                ))}
              </List>
            </Drawer> */}

            <Grid container justifyContent="center">
              {contentMap[getTabFromIndex(index)]}
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
