import { useState } from "react";
import { Box, Typography, Grid, FormControl, FormControlLabel, TextField, Checkbox } from "@mui/material";
import FileUploaderComponent from "../../../../components/uploader";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import { SessionUtils } from "../../../../utilities/SessionUtils";

export default function ResumeForm({ formData, setFormData }) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [resumeUploaded, setResumeUploaded] = useState(false);
	const currentUser = SessionUtils.getCurrentUserSession();
	function handleCheckboxChange(event) {
		setFormData({
			...formData,
			no_resume: event.target.checked,
		});
	}
	async function handleUpload(event) {
		if (event.target.files) {
			const selectedFile = event.target.files[0];

			const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit (change as needed)
			if (selectedFile.size > maxSizeInBytes) {
				alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
			}
			else {
				setError(false);
				const data = new FormData();
				data.append("file", selectedFile);
				const user_data = {
					uuid: currentUser.uuid,
					first_name: formData.first_name,
					last_name: formData.last_name,
					email: currentUser.email,
				}
				data.append("user_data", JSON.stringify(user_data));
				try {
					setLoading(true);
					const response = await RESTUtils.POSTIMAGE(
						RESTUtils.getAPIUrl() + `api/user/resumeUpload/`,
						data);
					if (response.status === 201) {
						setFormData({
							...formData,
							resume_url: response.data,
						});
						setResumeUploaded(true);
						setLoading(false);
						setError(false);
					} else {
						console.log(response.data);
						setLoading(false);
						setError(true);
					}
				} catch (error) {
					console.error("Error uploading image:", error);
					setError(true);
				}
			}
		} else {
			console.log("Error: No image selected");
			setError(true);
		}
	}

	return (
		<Box display={"inherit"} textAlign="center">
			<Typography
				variant="h6"
				component="div"
				paddingBottom={2}
				textAlign="center"
			>
				Enter your first and last name.
				Once you have entered your name, please upload a copy of your resume.
				Your education and work experiences will be auto-filled to your bench profile.
			</Typography>
			<Grid container justifyContent="center" >
				<Grid item xs={6} pr={1}>
					<FormControl fullWidth>
						<TextField
							label="First Name"
							required
							id="first_name"
							value={formData.first_name || ""}
							onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
							inputProps={{ maxLength: 254 }}
							autoFocus={true}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6} pl={1}>
					<FormControl fullWidth>
						<TextField
							label="Last Name"
							required
							id="last_name"
							value={formData.last_name || ""}
							onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
							inputProps={{ maxLength: 254 }}
						/>
					</FormControl>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Typography color={"error"} sx={{ paddingTop: "10px" }}>
							Error uploading resume. Please try again.
						</Typography>
					</Grid>
				)}

				<Grid container alignItems={'center'} alignContent={'center'} mt={2}>
					<Grid item xs={6}>
						{resumeUploaded ? <Typography> Resume Uploaded Successfully! </Typography> :

							<FileUploaderComponent
								imageUploadHandler={handleUpload}
								acceptFile=".pdf, .doc, .docx, .rtf" // Accepted file types for Workable: https://workable.readme.io/reference/job-candidates-create
								buttonText="Upload Resume"
								loading={loading}
								error={error}
								disabled={formData.first_name === "" || formData.last_name === "" || loading}
							/>
						}
					</Grid>
					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={resumeUploaded}
									checked={
										formData.no_resume
									}
									onChange={handleCheckboxChange}
									sx={{
										color: "primary.contrastText",
										"&.Mui-checked": {
											color: "primary.contrastText",
										},
									}}
								/>
							}
							label="I don't have a resume"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
