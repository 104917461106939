import React from "react";
import { Divider, Button } from "@mui/material";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";

export default function Buckets({ bucketData, job_uuid }) {
    const { theme } = useThemeContext();
    const buckets = [
        'Unknown',
        'International',
        'Disqualified',
        'Sourced',
        'Applied',
        'Expressed Interest',
        'Short List',
        'Confirm Interest',
        'On Client Portal'
    ]

    function handleBucketClick(bucket, job_uuid) {
        BrowserUtils.openNewTab(`/job/${job_uuid}/${bucket}`);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'default' }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {buckets.map((bucket, index) => {
                const bucketKey = bucket.toLowerCase().replace(/\s+/g, '_');
                const bucketValue = bucketData[bucketKey] || '';

                const displayText = bucket.length > (bucketValue ? 5 : 9)
                    ? `${bucket.substring(0, bucketValue ? 5 : 9)}...`
                    : bucket;

                return (
                    <React.Fragment key={index}>
                        <Button
                            key={index}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            disabled={!bucketValue}
                            onClick={(e) => bucketValue && handleBucketClick(bucket, job_uuid)}
                            sx={{
                                whiteSpace: "nowrap",
                                backgroundColor: theme === 'dark' ? '#444' : '#ededed',
                                width: '6rem',
                                border: theme === 'dark' ? '1px solid #5e6062' : '1px solid #666',
                                borderRadius: '0px',
                                cursor: bucketValue && 'pointer',
                                '&:hover': {
                                    backgroundColor: theme === 'dark' ? '#666' : '#fff',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: theme === 'dark' ? '#333' : '#d0d0d0',
                                    color: 'grey',
                                    fontWeight: 'normal',
                                }
                            }}
                        >
                            {displayText}
                            {bucketValue ? ` (${bucketValue})` : ''}
                        </Button>
                        {index < buckets.length - 1 &&
                            <Divider orientation="vertical" flexItem />
                        }
                    </React.Fragment>
                );
            })}
        </div >
    );
}