import { Stack, Typography, TextField, MenuItem } from "@mui/material";
import benchStatusOptions from "../../../../data/misc/miscData";
export default function BenchStatusSelect({ bench_status, changeBenchStatus }) {
    return (
        <Stack direction={"row"} alignItems={'center'} spacing={2}>
            <Typography variant="h8">Bench Status:</Typography>

            {/* Select input for bench status */}
            <TextField
                select
                variant="standard"
                value={bench_status || ""}

                onChange={(e) =>
                    changeBenchStatus(e.target.value)
                }
                InputProps={{
                    inputProps: {
                        readOnly: bench_status === 'Pseudo',
                        sx: { borderBottom: "1px solid lightgrey" },
                    },
                }}
            >
                {benchStatusOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    )
}