import React, { useState } from 'react';

import { Card, Divider, Grid, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import Assessment from '../../../../../public/referenceAssessment/Assessment';

export default function ReferenceAdminPreview({ data }) {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    function setAssessmentValues() { }
    function setComments() { }
    const [validationError, setValidationError] = useState(false);
    return (
        <Card sx={{ margin: '10px' }}>
            <Grid container padding={2}>

                <Grid container item xs={12} sx={{ paddingBottom: '10px' }}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" align="center">Assessment</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid item xs={7} md={8} pb={3}>
                    <Typography variant="h5">
                        Job Details
                    </Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography variant="body1">
                        {data.experience_snapshot.title}
                    </Typography>
                    <Typography variant="body2">{data.experience_snapshot.start_date} - {data.experience_snapshot.end_date || "Current"}</Typography>

                    <Typography variant="body2">
                        {data.experience_snapshot.organization && data.experience_snapshot.location
                            ? `${data.experience_snapshot.organization} | ${data.experience_snapshot.location}`
                            : data.experience_snapshot.organization || data.experience_snapshot.location}
                    </Typography>
                </Grid>
                <Grid item xs={5} md={4}
                    paddingLeft={isMobile ? '10px' : '20px'}
                    paddingTop={'10px'}
                    display="flex"
                    alignItems="center"
                >
                    {data.assessments && <Assessment
                        value={data.assessments[0]}
                        index={0}
                        setValues={setAssessmentValues}
                        comments={data.comments}
                        setComments={setComments}
                        setValidationError={setValidationError}
                    />
                    }
                </Grid>

                <Grid item xs={7} md={8} pb={2}>
                    <Typography variant="h5">
                        Overview
                    </Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography variant="body1">
                        {data.experience_snapshot.overview}
                    </Typography>
                </Grid>
                <Grid item xs={5} md={4}
                    paddingLeft={isMobile ? '10px' : '20px'}
                    paddingTop={'10px'}
                    display="flex"
                    alignItems="center"
                >
                    {data.assessments && <Assessment
                        value={data.assessments[1]}
                        index={1}
                        setValues={setAssessmentValues}
                        comments={data.comments}
                        setComments={setComments}
                        setValidationError={setValidationError}
                    />
                    }

                </Grid>

                {data.experience_snapshot.skills.length > 0 && (
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Skills
                        </Typography>
                        <Divider sx={{ mb: 1 }} />

                        <Grid container>
                            {['Skill', 'Frequency', 'Description'].map((header, index) => {
                                if (isMobile && index === 2) return null;
                                return (
                                    <Grid
                                        item
                                        xs={3.5}
                                        md={index < 2 ? 2 : 8}
                                        key={header}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'bold', borderBottom: '1px solid #6c6e70' }}
                                        >
                                            {header}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>


                        {data.experience_snapshot.skills.map((row, index) => (
                            <Grid container alignItems="center">
                                <Grid container item xs={7} md={8} key={index}>
                                    <Grid item xs={6} md={3} sx={{ wordWrap: 'break-word' }}>
                                        <Typography>{row.skill}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3} sx={{ wordWrap: 'break-word' }}>
                                        <Typography>{row.frequency}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} pt={isMobile ? '10px' : null}>
                                        <Typography>{row.description}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5} md={4} key={`assessment-${index}`} paddingLeft={isMobile ? '10px' : '20px'} py={2}>
                                    {data.assessments && <Assessment
                                        value={data.assessments[index + 2]}
                                        index={index + 2}
                                        setValues={setAssessmentValues}
                                        comments={data.comments}
                                        setComments={setComments}
                                        setValidationError={setValidationError}
                                    />}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>

        </Card>
    )
}