import React, { useState } from "react";
import { Typography, Chip, Divider, Box, Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const KeywordBox = ({ clickableTextStyle, criteriaName, filterArray, setterFn }) => {
	const [value, setValue] = useState("");

	const handleDelete = (indexToRemove) => {
		const updatedTitles = filterArray.filter(
			(_, index) => index !== indexToRemove
		);
		setterFn(updatedTitles);
	};

	const handleAdd = () => {
		if (!filterArray.includes(value) && value !== "") {
			setterFn([...filterArray, value]);
			setValue("");
		}
	};

	const clearFilter = () => {
		setterFn([]);
	};

	const handleValueChange = (newValue) => {
		setValue(newValue);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleAdd();
	};

	const boxStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "2px",
		margin: "2px",
	};

	return (
		<>
			<Divider />
			<Box style={boxStyle}>
				<Grid container sx={{ padding: "5px", alignItems: "center" }}>
					<Grid item xs={6}>
						<Typography variant="h8">{criteriaName}</Typography>
					</Grid>
					<Grid item xs={6} align="right">
						<Typography
							variant="h10"
							align="right"
							onClick={clearFilter}
							sx={clickableTextStyle}
						>
							Clear
						</Typography>
					</Grid>
					<Grid item container xs={12} alignItems="center">
						<Grid item xs={11}>
							<form
								onSubmit={handleSubmit}
								style={{ width: "100%" }}
							>
								<TextField
									id="standard-basic"
									variant="standard"
									value={value}
									onChange={(e) => handleValueChange(e.target.value)}
									style={{ width: "100%" }}
									sx={{
										"& .MuiInput-underline:before": {
											borderBottomColor: "secondary.main",
										},
										"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
											borderBottomColor: "white",
										},
									}}
								/>
							</form>
						</Grid>
						<Grid item xs={1}>
							<AddIcon
								sx={clickableTextStyle}
								onClick={handleAdd}
							/>
						</Grid>
					</Grid>
					{filterArray.map((item, index) => (
						<Grid item key={index}>
							<Chip
								label={item}
								style={{ margin: "3px" }}
								variant="outlined"
								onClick={() => handleDelete(index)}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
			<Divider />
		</>
	);
};

export default KeywordBox;
