const admins = [
    {
        first_name: "Bench",
        last_name: "Admin",
        email: "asd@asd.com",
        uuid: "96700be9-9ef3-4a98-a712-14b22f9db63f",
        id: 1577,
        image_url: 'https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/uploads/image/d9e0f025-64d7-4acc-95cc-417461dd0c42.png'
    },
    {
        first_name: "Tammy",
        last_name: "Ceaser",
        email: "tammy.ceaser@peterlucas.ca",
        uuid: "a926385a-b7d9-49e0-951c-44ba77e3e9f5",
        id: 1854,
        image_url: "https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/uploads/image/f3e3bc0d-33b5-4596-a2af-697436a9540c.jpg"
    },
    // {
    //     first_name: "Orrin",
    //     last_name: "Conly",
    //     email: "orrin.conly@peterlucas.ca",
    //     uuid: "9c65f833-7db1-4f77-beef-32947a8916f7",
    //     id: 1585,
    //     image_url: ""
    // },
    {
        first_name: "Megan",
        last_name: "Cordick",
        email: "megan.cordick@peterlucas.ca",
        uuid: "a1a26bf5-38d4-4ff4-a0a8-d117335e7535",
        id: 3189,
        image_url: "",
    },
    {
        first_name: "Rachel",
        last_name: "Derry",
        email: "rachel.derry@peterlucas.ca",
        uuid: "2d0d4f4f-b600-4842-8be6-6c82414b0922",
        id: 1587,
        image_url: ""
    },
    // {
    //     first_name: "Ryan",
    //     last_name: "Dingwall",
    //     email: "ryan.dingwall@peterlucas.ca",
    //     uuid: "ebc35c01-9734-4e6e-8f9d-e21c31e81100",
    //     id: 2256,
    //     image_url: ""
    // },
    {
        first_name: "Liza",
        last_name: "Edeen",
        email: "liza.edeen@peterlucas.ca",
        uuid: "35ad83c2-a81d-44d2-b8ff-a8db1b482698",
        id: 1607,
        image_url: "https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/uploads/image/03023ca1-3cc3-4897-945c-0bfd0b8d1923.webp"
    },
    // {
    //     first_name: "Brandon",
    //     last_name: "Harris",
    //     email: "brandon.harris@peterlucas.ca",
    //     uuid: "e9015566-04fb-434e-8bfd-38b48a6632d4",
    //     id: 1843,
    //     image_url: ""
    // },
    // {
    //     first_name: "Jared",
    //     last_name: "Peterson",
    //     email: "jared.peterson@peterlucas.ca",
    //     uuid: "05fb6a11-9046-4b1a-a0cd-e9618a9816f4",
    //     id: 1609,
    //     image_url: ""
    // },
    // {
    //     first_name: "Brooke",
    //     last_name: "Petreny",
    //     email: "brooke.petreny@peterlucas.ca",
    //     uuid: "0ae9d55b-fb9b-4309-b6ff-e1fa87419e5f",
    //     id: 1608,
    //     image_url: "https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/uploads/image/23ae05bc-3af4-46fb-97b6-c0481f133832.png"
    // },
    // {
    //     first_name: "Alex",
    //     last_name: "Sacher",
    //     email: "alex.sacher@peterlucas.ca",
    //     uuid: "93a48487-8910-4e50-b145-38454c8e369b",
    //     id: 1595,
    //     image_url: ""
    // },
    // {
    //     first_name: "Brittany",
    //     last_name: "Scott",
    //     email: "bscott0987@hotmail.com",
    //     uuid: "d60bef6c-5e93-423a-9b53-f9c10d3217de",
    //     id: 1590,
    //     image_url: "https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/uploads/image/0e455c29-d132-4269-a94d-bcd81cb6650e.jpg"
    // },
    // {
    //     first_name: "Stacey",
    //     last_name: "Sirois",
    //     email: "stacey.sirois@peterlucas.ca",
    //     uuid: "860e7282-785b-4fb5-96f1-501c7b46ee52",
    //     id: 2115,
    //     image_url: ""
    // },
    // {
    //     first_name: "Michael",
    //     last_name: "Whitbread",
    //     email: "michael.whitbread@peterlucas.ca",
    //     uuid: "a4f4b897-c877-4dde-bc96-101cbcd1669b",
    //     id: 1594,
    //     image_url: ""
    // },
];

export default admins;
