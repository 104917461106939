import { useState, useEffect } from "react";
import { useMediaQuery, useTheme, Grid, Typography, Divider } from "@mui/material";
import StatusStepper from "./StatusStepper";
import StepInfoBox from "./StepInfoBox";
import { steps } from "./data";

export default function ProfileStatusDashboard({ uuid, profileProgress }) {
  const [curStep, setCurStep] = useState(null);
  const completedSteps = profileProgress.filter(step => step).length;

  useEffect(() => {
    const index = profileProgress.findIndex(value => value === false);
    setCurStep(index === -1 ? 0 : index);
  }, []);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  if (curStep === null) {
    return null; // Prevents flicker of stepper before useEffect sets curStep
  }
  return (
    <Grid container padding={1}>
      <Grid container item xs={12} justifyContent="space-between" alignItems="center" p={0.5} wrap="nowrap">
        <Grid item xs={8} md={6}>
          <StatusStepper
            profileProgress={profileProgress}
            steps={steps}
            curStep={curStep}
            setCurStep={setCurStep}
          />
        </Grid>

        {!isMobile && (
          <Grid item xs="auto" ml={2} style={{ flexShrink: 0 }}>
            <Typography variant="h6">{`${completedSteps}/8 Steps Complete`}</Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <StepInfoBox
          curStep={curStep}
          uuid={uuid}
        />
      </Grid>
    </Grid>
  );
}