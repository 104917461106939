import { Grid, Card, Typography, Tooltip, Avatar } from "@mui/material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

export default function CandidateListCard({ handleCardClick, candidate }) {
    return (
        <Grid item xs={12}>
            <Card
                onClick={() => handleCardClick(candidate.user)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1.5,
                    cursor: 'pointer',
                    transition: "transform 0.3s",
                    "&:hover": {
                        backgroundColor: "secondary.cardHover",
                        cursor: "pointer",
                    },
                }}
            >
                <Avatar
                    src={candidate.image_url}
                    sx={{ marginRight: 1.5 }}
                />

                <Grid container alignItems="center" justifyContent="space-between" flexGrow={1}>
                    <Grid item xs>
                        {candidate.status === "shortlisted" && (
                            <Typography sx={{ fontSize: "14px", color: "#C60C30" }}>
                                SHORTLISTED
                            </Typography>
                        )}

                        <Typography variant="body1">
                            {`${candidate.first_name} ${candidate.last_name}`}
                        </Typography>
                    </Grid>

                    {candidate.has_notes && (
                        <Grid item>
                            <Tooltip title="Has Notes" arrow placement="top">
                                <AssignmentOutlinedIcon fontSize="small" sx={{ verticalAlign: 'middle', ml: 1 }} />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Grid>
    );
}
