import { useState } from "react";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import NoCandidatesBox from "./NoCandidatesBox";
import CandidateCard from "./CandidateCard";
import InterviewModal from "../interviewModal/interviewModal";
import StarIcon from "@mui/icons-material/Star";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function ShortListWeb({
  unorderedShortList,
  changeUserStatus,
  changeIndex,
  SL_order,
  updateShortList,
  handleCardClick,
  isMobile,
}) {
  const [showModal, setShowModal] = useState(false);
  const shortList = SL_order.map(id => unorderedShortList.find(item => item.id == id))
    .filter(item => item !== undefined);
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    updateShortList([result.source.index, result.destination.index]);
  };

  return (
    <>
      <InterviewModal
        users={shortList}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Grid container alignItems="center" padding={1.5}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <StarIcon />
            {/* <StarIcon sx={{color:"#efa032"}}/> */}
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "0.25em",
                }}
              >
                SHORT
              </span>
              <span style={{ color: "#C60C30" }}>LIST</span>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {shortList.filter((candidate) => !candidate?.interview_requested)
            .length > 0 ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setShowModal(true)}
            >
              Request Interviews
            </Button>
          ) : (
            !isMobile && shortList.length > 0 && (
              <Typography>
                Interviews have been requested for all shortlisted
                candidates
              </Typography>
            )
          )}
        </Grid>
        <Grid item xs={12} pt={1}>
          <Divider />
        </Grid>
        <Grid item xs={12} py={1}>
          <Typography>{`${shortList.length} Candidate${shortList.length !== 1 ? "s" : ""
            }`}</Typography>
        </Grid>

        {shortList.length === 0 && (
          <Grid item xs={12} mt={1.5}>
            <NoCandidatesBox />
          </Grid>
        )}

        <Grid container spacing={1.5}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div  {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  {shortList.length > 0 &&
                    shortList.map((candidate, index) => (
                      <Draggable key={candidate.id} draggableId={candidate.id.toString()} index={index}>
                        {(provided) => (
                          <Grid item key={index} mt={1} style={{ margin: '8px', width: isMobile && '100%' }}>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CandidateCard
                                candidate={candidate}
                                handleCardClick={handleCardClick}
                                changeUserStatus={changeUserStatus}
                                index={index + 1}
                              />
                            </div>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </Grid>
      </Grid>
    </>
  );
}
