import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ThreeDSliderWeb from "./ThreeDSliderWeb";
import ThreeDSliderMobile from "./ThreeDSliderMobile";

export default function ThreeDSliders({ threeDData, useMobile = false }) {
  // The personality quiz scores are measured on a scale of 1 to 6 instead of 0 to 4
  // which corresponds to a range of 12 to 60. Therefore, each total value
  // in the totals array must be normalized into a range of 0 to 48 to represent
  // its percentage contribution.
  const percentages = threeDData.totals.map((value) => {
    // Normalize each value.
    const proportion = (value - 12) / 48;
    // Convert the normalized value to a percentage.
    const percentage = proportion * 100;
    // Round the percentage to the nearest integer.
    return Math.round(percentage);
  });
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const isMobile = useMobile || isSmallScreen;


  return (
    <Grid container spacing={isMobile ? 1 : 2}>
      <Grid item xs={12}>
        {isMobile ? <ThreeDSliderMobile label="Extrovert" value={percentages[0]} /> : <ThreeDSliderWeb label="Extrovert" value={percentages[0]} />}
      </Grid>
      <Grid item xs={12} >
        {isMobile ? <ThreeDSliderMobile label="Thinking" value={percentages[1]} /> : <ThreeDSliderWeb label="Thinking" value={percentages[1]} />}
      </Grid>
      <Grid item xs={12}>
        {isMobile ? <ThreeDSliderMobile label="Assertive" value={percentages[2]} /> : <ThreeDSliderWeb label="Assertive" value={percentages[2]} />}
      </Grid>
    </Grid>
  );
}
