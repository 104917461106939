import { useState } from "react";
import { Button, Grid, CircularProgress, Tooltip, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisuallyHiddenInput from "./styles";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

export default function ResumeHandler({ allData }) {
  const { userInfo, setUserInfo, showSnackbar } = allData;
  const [imageLoading, setImageLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB limit (change as needed)
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
    }
    else {
      const formData = new FormData();
      formData.append("file", file);
      setImageLoading(true);

      try {
        const response = await RESTUtils.POSTIMAGE(
          RESTUtils.getAPIUrl() +
          `api/user/fileUpload/?uuid=${userInfo.uuid}&file_type=resume`,
          formData
        );
        if (response.status === 201) {
          setUserInfo((prevData) => ({
            ...prevData,
            resume_url: response.data,
          }));
          showSnackbar("Resume uploaded successfully", "success");
        } else {
          console.log(response.data);
          showSnackbar("Error uploading resume", "error");
        }
      } catch (error) {
        console.error("Error uploading resume:", error);

      } finally {
        setImageLoading(false);
      }
    }
  };

  function handleRefresh() {
    setRefreshing(true);
    const iframe = document.getElementById('resume-iframe');
    if (iframe) {
      iframe.src = iframe.src; // Assigning the same URL triggers a reload
    }
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "5px" }}>
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={imageLoading ? <CircularProgress color="inherit" size={20} /> : <CloudUploadIcon />}
          color="secondary"
          disabled={imageLoading}
        >
          {userInfo.resume_url ? "Edit Resume" : "Upload Resume"}
          <VisuallyHiddenInput type="file" accept={".pdf, .doc, .docx, .rtf"} onChange={handleFileUpload} />
        </Button>

        {userInfo.resume_url && (
          <Tooltip title={refreshing ? "Refreshing" : "Refresh"} arrow placement="top" sx={{ ml: 1 }}>
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* <Typography sx={{ marginLeft: '10px' }}>
        If the resume is not visible, you may need to check workable   
      </Typography> */}
      </Grid>

      {userInfo.resume_url && !imageLoading && (
        <Grid item xs={12}>
          {refreshing ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <iframe
              id="resume-iframe"
              title="resume"
              // src={`https://docs.google.com/gview?url=${encodeURIComponent(
              //   userInfo.resume_url
              // )}&embedded=true`}
              src={userInfo.resume_url.split('.').pop() === 'pdf' ? userInfo.resume_url : `https://docs.google.com/gview?url=${encodeURIComponent(userInfo.resume_url)}&embedded=true`}
              width={"100%"}
              height={"1000px"}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
