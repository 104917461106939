import {
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { CustomCardNoHover, CustomAvatar, typographyStyle } from "../../styles";

export default function InterviewModalContent({
  interviewVars,
  setInterviewVars,
  handleChange,
  error,
  users = [], // if from spotlight, will be just 1 user
  fromShortlist = false,
}) {
  const handleCheckboxChange = (user) => {
    const index = interviewVars.users.indexOf(user);
    if (index === -1) {
      setInterviewVars((prevData) => ({
        ...prevData,
        users: [...prevData.users, user],
      }));
    } else {
      const newUsers = [...interviewVars.users];
      newUsers.splice(index, 1);
      setInterviewVars((prevData) => ({
        ...prevData,
        users: newUsers,
      }));
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fromShortlist ? (
          <>
            <Typography>
              Select the candidates you would like to interview
            </Typography>
            <Grid container pb={1}>
              {users.map(
                (candidate, index) =>
                  !candidate.interview_requested && (
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      key={index}
                      alignItems="center"
                      marginTop={1}
                    >
                      <Grid item xs={2}>
                        <Checkbox
                          checked={interviewVars.users.includes(candidate.user)}
                          onChange={() => handleCheckboxChange(candidate.user)}
                          sx={{
                            color: "primary.contrastText",
                            "&.Mui-checked": { color: "primary.contrastText" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <CustomCardNoHover>
                          <CustomAvatar src={candidate.image_url} />
                          <Typography variant="h8" sx={typographyStyle}>
                            {`${candidate.first_name} ${candidate.last_name}`}
                          </Typography>
                        </CustomCardNoHover>
                      </Grid>
                    </Grid>
                  )
              )}
              {interviewVars.users.length === 0 && error && (
                <Grid item xs={12}>
                  <Typography color="error">
                    Please select a candidate
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Typography>You are requesting an interview with:</Typography>
            <CustomCardNoHover>
              <CustomAvatar src={users.image_url} />
              <Typography variant="h8" sx={typographyStyle}>
                {`${users.first_name} ${users.last_name}`}
              </Typography>
            </CustomCardNoHover>
          </>
        )}

        <Typography>Your Name</Typography>
        <TextField
          name="name"
          fullWidth
          required
          value={interviewVars.name || ""}
          onChange={handleChange}
          error={error && !interviewVars.name}
          inputProps={{ maxLength: 254 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Please enter your availability (preferred dates and time slots).
        </Typography>
        <TextField
          name="availability"
          multiline
          minRows={3}
          fullWidth
          required
          value={interviewVars.availability || ""}
          onChange={handleChange}
          error={error && !interviewVars.availability}
          inputProps={{ maxLength: 500 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Please select your interview preference.</Typography>
        <FormControl fullWidth>
          <TextField
            select
            name="interview_preference"
            value={interviewVars.interview_preference || ""}
            onChange={handleChange}
            error={error && !interviewVars.interview_preference}
          >
            <MenuItem value="In Person">In Person</MenuItem>
            <MenuItem value="Virtual">Virtual</MenuItem>
            <MenuItem value="Either">Either in person or virtual</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography>Other comments (optional).</Typography>
        <TextField
          name="comments"
          multiline
          minRows={3}
          fullWidth
          value={interviewVars.comments || ""}
          onChange={handleChange}
          inputProps={{ maxLength: 500 }}
        />
      </Grid>
    </Grid>
  );
}
