function capitalize(str) {
  str = str.replace(/_/g, ' ')
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function convertUserData(data) {
    const usersData = data

    let dataArray = [];
  
    for (let i = 0; i < usersData.length; i++) {
      const userData = usersData[i];
     
      dataArray[i * 2] = [capitalize(userData.field)]; // Initialize a new sub-array for each field
      dataArray[(i * 2) + 1] = [null];
      let optionCount = 0;
    
      for (const option in userData.options) {
        const count = userData.options[option];
        dataArray[i * 2][optionCount+1] = capitalize(option); // Assign the option key directly
        dataArray[i * 2 + 1] = dataArray[i * 2 + 1] || []; // Ensure the sub-array exists
        dataArray[i * 2 + 1][optionCount+1] = count;
        optionCount++;
      }
    }
    return dataArray
  }

export function convertData(data) {
    const dataArray = Object.entries(data)
    for (let i = 0; i<dataArray.length; i++) {
      dataArray[i][0] = capitalize(dataArray[i][0])
    }
    return dataArray
  }

export function compileData(overviewData,userData, enrollmentData, taskData) {
    const stackedArray = [];

    function addSpaceRow() {
      stackedArray.push([null, null]); 
    }
    stackedArray.push(['Overview'])
    stackedArray.push(...overviewData);
    addSpaceRow();

    stackedArray.push(['User Data'])
    stackedArray.push(...userData);
    addSpaceRow();

    stackedArray.push(['Enrollment Data'])
    stackedArray.push(...enrollmentData);
    addSpaceRow();

    stackedArray.push(['Task Data'])
    stackedArray.push(...taskData);
    return (stackedArray);
  }