import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Divider,
	CircularProgress
} from "@mui/material";
import { ModalProps } from "./types";
import CloseIcon from "@mui/icons-material/Close";

function TwoButtonModal({
	show = false,
	modalMessage,
	modalTitle,
	closeButtonText,
	closeButtonCallback,
	okButtonText,
	okButtonCallback,
	deleteButton = false,
	deleteButtonCallBack = null,
	deleteButtonText = "Delete",
	onClickOff = closeButtonCallback,
	okButtonLoading = false,
	deleteButtonLoading = false,
	maxWidth = "sm",
}: ModalProps) {
	return (
		<Dialog open={show} onClose={onClickOff} fullWidth maxWidth={maxWidth}>
			{modalTitle && <DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{modalTitle}
				<IconButton
					onClick={closeButtonCallback}
					color="inherit"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>}

			<Divider
				variant="middle"
				sx={{
					backgroundColor: "grey",
					marginTop: "-10px",
				}}
			/>

			<DialogContent>{modalMessage}</DialogContent>
			<DialogActions sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
				<div>
					{deleteButton && (
						<Button
							onClick={deleteButtonCallBack}
							sx={{ ml: 2 }}
							variant="outlined"
							color="primary"
							disabled={deleteButtonLoading}
							startIcon={deleteButtonLoading && <CircularProgress color="inherit" size={20} />}
						>
							{deleteButtonLoading ? "Deleting" : deleteButtonText}
						</Button>
					)}
				</div>
				<div>
					{closeButtonText && (
						<Button
							onClick={closeButtonCallback}
							sx={{ mr: 2 }}
							variant="outlined"
							color="secondary"
						>
							{closeButtonText}
						</Button>
					)}
					{okButtonCallback && (
						<Button
							onClick={okButtonCallback}
							variant="outlined"
							color="secondary"
							sx={{ mr: 2 }}
							disabled={okButtonLoading}
							startIcon={okButtonLoading && <CircularProgress color="inherit" size={20} />}
						>
							{okButtonLoading ? "Saving" : okButtonText}
						</Button>
					)}
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default TwoButtonModal;
