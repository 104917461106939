import { useState, useEffect } from 'react';

export function TaskUseEffects(allData) {
    const { userInfo, personalityData, experienceData, skillData, notableProjectsData, educationData, certificateData, awardData, referenceData, userReferences } = allData;
    const { image_url, banner_url, video_url, overview, qualifications, profile_completion_steps } = userInfo;
    const { personality, eight_stage } = personalityData;

    const [tasks, setTasks] = useState({
        image_url: null,
        banner_url: null,
        video_url: null,
        eight_stage: null,
        personality: null,
        overview: null,
        qualifications: null,
        experience: null,
        skills: null,
        notableProjects: null,
        education: null,
        certifications: null,
        awards: null,
        references: null,
        userReferences: null,
    })

    // -------------------- Individual --------------------

    // Media
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            image_url: Boolean(image_url)
        }));
    }, [image_url]);

    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            banner_url: Boolean(banner_url)
        }));
    }, [banner_url]);

    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            video_url: Boolean(video_url)
        }));
    }, [video_url]);


    // Quizzes
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            eight_stage: Boolean(eight_stage),
        }));
    }, [eight_stage]);

    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            personality: Boolean(personality),
        }));
    }, [personality]);


    // Details
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            overview: Boolean(overview),
        }));
    }, [overview]);

    useEffect(() => {
        const isQualificationsCompleted = qualifications.filter(element => element !== "").length > 1;
        setTasks(prevTask => ({
            ...prevTask,
            qualifications: isQualificationsCompleted,
        }));
    }, [qualifications]);


    // Experience
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            experience: experienceData.length >= 2,
        }));
    }, [experienceData]);

    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            skills: skillData.length >= 4,
        }));
    }, [skillData]);


    // Notable Projects
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            notableProjects: notableProjectsData.length >= 2,
        }));
    }, [notableProjectsData]);


    // Education
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            education: educationData.length >= 1,
        }));
    }, [educationData]);

    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            certifications: certificateData.length >= 1,
        }));
    }, [certificateData]);


    // Awards
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            awards: awardData.length >= 2,
        }));
    }, [awardData]);

    // References/Experience Verifications
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            references: referenceData.length >= 1,
        }));
    }, [referenceData]);

    //User References
    useEffect(() => {
        setTasks(prevTask => ({
            ...prevTask,
            userReferences: userReferences.length >= 2,
        }));
    }, [userReferences]);

    return { tasks };
}
