import { useState } from "react";
import {
    Paper,
    Grid,
    Typography,
    Stack,
} from "@mui/material";
import ExperienceTab from "../experiences/ExperienceTab";
import NotableProjectsTab from "../notableProjects/NotableProjectsTab";
import EducationParent from "../educations/EducationParent";
import AwardTable from "../awards/AwardTab";
import WorkIcon from '@mui/icons-material/Work';
import ConstructionIcon from "@mui/icons-material/Construction";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

export default function BottomBoxPrint({ allData, sectionsOpen }) {
    const [projectsSpacingCount, setProjectsSpacingCount] = useState(0);
    const [educationsSpacingCount, setEducationsSpacingCount] = useState(0);
    const [awardsSpacingCount, setAwardsSpacingCount] = useState(0);

    return (
        <Grid container spacing={1}>
            {allData.experienceData.length > 0 && (
                <Grid item xs={12}>
                    <Paper>
                        <Stack direction={'row'} spacing={1} pt={1} pl={1} alignItems={'center'}>
                            <WorkIcon />
                            <Typography variant="h6" align="left">Experiences</Typography>
                        </Stack>
                        <ExperienceTab
                            isEdit={false}
                            allData={allData}
                            isPrintMode={true}
                        />
                    </Paper>
                </Grid>
            )}
            {allData.notableProjectsData.length > 0 && sectionsOpen.projects && (
                <Grid item xs={12} sx={{ marginTop: `${projectsSpacingCount * 10}px` }}>
                    <Paper>
                        <Stack direction={'row'} spacing={1} pt={1} pl={1} alignItems={'center'}>
                            <ConstructionIcon onClick={() => setProjectsSpacingCount((prevValue) => prevValue - 1)} />
                            <Typography variant="h6" align="left" onClick={() => setProjectsSpacingCount((prevValue) => prevValue + 1)}>Notable Projects</Typography>
                        </Stack>
                        <NotableProjectsTab
                            isEdit={false}
                            allData={allData}
                            isPrintMode={true}
                        />
                    </Paper>
                </Grid>
            )}
            {(allData.educationData?.length > 0 || allData.certifications?.length > 0) && sectionsOpen.educations && (
                <Grid item xs={12} sx={{ marginTop: `${educationsSpacingCount * 10}px` }}>
                    <Paper>
                        <Stack direction={'row'} spacing={1} pt={1} pl={1} alignItems={'center'}>
                            <SchoolIcon onClick={() => setEducationsSpacingCount((prevValue) => prevValue - 1)} />
                            <Typography variant="h6" align="left" onClick={() => setEducationsSpacingCount((prevValue) => prevValue + 1)}>Education</Typography>
                        </Stack>
                        <EducationParent
                            isEdit={false}
                            allData={allData}
                            isPrintMode={true}
                        />
                    </Paper>
                </Grid>
            )}
            {allData.awardData.length > 0 && sectionsOpen.awards && (
                <Grid item xs={12} sx={{ marginTop: `${awardsSpacingCount * 10}px` }}>
                    <Paper>
                        <Stack direction={'row'} spacing={1} pt={1} pl={1} alignItems={'center'}>
                            <EmojiEventsIcon onClick={() => setAwardsSpacingCount((prevValue) => prevValue - 1)} />
                            <Typography variant="h6" align="left" onClick={() => setAwardsSpacingCount((prevValue) => prevValue + 1)}>Awards</Typography>
                        </Stack>
                        <AwardTable
                            isEdit={false}
                            allData={allData}
                            isPrintMode={true}
                        />
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}
