import { useState, useEffect } from "react";
import { MasterPageProps } from "./types";
import Grid from "@mui/material/Grid";
import FooterComponent from "../../components/footer";
import { footerData } from "../../data/master/footer/footerData";
import HeaderComponent from "../../components/header";
import { headerData } from "../../data/master/header/headerData";
import { SessionUtils } from "../../utilities/SessionUtils";
import ReportAnIssueComponent from "../../components/reportAnIssue";

function MasterPage({ children }: MasterPageProps) {
	const [user, setUser] = useState(SessionUtils.getCurrentUserSession());

	const handleStorageChange = () => {
		setUser(SessionUtils.getCurrentUserSession());
	};

	useEffect(() => {
		window.addEventListener('storage', () => { handleStorageChange(); })
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return (
		<div>
			<HeaderComponent headerParams={headerData} user={user} />
			<Grid sx={{ bgcolor: "background.default" }} pb={15}>
				{children}
			</Grid>
			<FooterComponent footerParams={footerData} />
			<ReportAnIssueComponent />
		</div>
	);
}

export default MasterPage;
