import { Chip } from "@mui/material";

export default function WorkableLink({ workable_url }) {
    return (
        <Chip
            label="Workable"
            icon={
                <img
                    src={"/images/workable_logo_round.png"}
                    alt="Workable Logo"
                    height={15}
                />
            }
            component="a"
            href={workable_url}
            target="_blank"
            rel="noopener noreferrer"
            clickable
            size="small"
        />
    );
}
