import { User } from "../models/User";

export class SessionUtils {

    public static setCurrentUserSession(user: User): void {
        localStorage.setItem('CurrentUser', JSON.stringify(user));
        window.dispatchEvent(new Event('storage'));
    }

    public static isUserSessionExist(): boolean {
        if (localStorage.getItem('CurrentUser') === null)
            return false;
        return true;
    }

    public static getCurrentUserSession(): User | null {
        const user = localStorage.getItem('CurrentUser');
        if (user) {
            return JSON.parse(user) as User;
        }
        return null;
    }
    public static setProjectStrengthsSession(projectStrengths: Array<number>): void {
        localStorage.setItem('ProjectStrengths', JSON.stringify(projectStrengths));

    }

    public static getProjectStrengthsSession(): Array<number> | null {
        const projectStrengths = localStorage.getItem('ProjectStrengths');
        if (projectStrengths) {
            return JSON.parse(projectStrengths);
        }
        return null;
    }
    public static clearProjectStrengthsSession(): void {
        const projectStrengths = localStorage.getItem('ProjectStrengths');
        if (projectStrengths) {
            localStorage.removeItem('ProjectStrengths');
        }
    }
    public static getThemeSession(): string {
        const theme = localStorage.getItem('theme');
        if (theme) {
            return JSON.parse(theme);
        }
        return '';
    }
    public static setThemeSession(theme: string): void {
        localStorage.setItem('theme', JSON.stringify(theme));
    }

    public static removeCurrentUserSession(): void {
        const user = localStorage.getItem('CurrentUser');
        if (user) {
            localStorage.removeItem('CurrentUser');
        }
    }

    public static setObjectSession<T>(sessionName: string, object: any): void {
        localStorage.setItem(sessionName, JSON.stringify(object as T));
    }

    public static isObjectSessionExist(sessionName: string): boolean {
        if (localStorage.getItem(sessionName) === null)
            return false;
        return true;
    }

    public static getObjectSession<T>(sessionName: string): T | null {
        if (!this.isObjectSessionExist(sessionName))
            return null;
        else
            return localStorage.getItem(sessionName) as T;
    }

    public static removeObjectSession(sessionName: string): void {
        const user = localStorage.getItem(sessionName);
        if (user) {
            localStorage.removeItem(sessionName);
        }
    }
}