import { Typography, IconButton, Tooltip, ButtonGroup, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CustomCard, CustomAvatar, typographyStyle } from "../../styles";

export default function CandidateCard({
	candidate,
	handleCardClick,
	changeUserStatus,
	index = false,
}) {
	return (
		<Tooltip title={candidate.interview_requested ? "Interview Requested" : ""} arrow placement="top">
			<CustomCard onClick={() => handleCardClick(candidate.user)} sx={{ borderLeft: candidate.interview_requested && '3px solid #c60c30' }}>
				<Grid container alignItems="center">
					<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="h8" sx={{ ...typographyStyle, paddingRight: '10px' }}>
							{index + '.'}
						</Typography>
						<CustomAvatar src={candidate.image_url} />
						<Typography variant="h8" sx={typographyStyle}>
							{`${candidate.first_name} ${candidate.last_name}`}
						</Typography>
					</Grid>
					<Grid item sx={{ marginLeft: 'auto' }}>
						<ButtonGroup>
							<Tooltip title={candidate.interview_requested ? "" : "Remove from Shortlist"} arrow placement="top">
								<IconButton
									color="primary"
									onClick={(event) => {
										event.stopPropagation();
										changeUserStatus(
											"proposed",
											candidate.user
										);
									}}
								>
									<CancelIcon />
								</IconButton>
							</Tooltip>
						</ButtonGroup>
					</Grid>
				</Grid>
			</CustomCard>
		</Tooltip>
	);
}
