import { useState } from "react";
import { Chip } from "@mui/material";
import { darken } from '@mui/material/styles';
import XPLevelModal from "../../../../../components/modals/XPLevelModal/XPLevelModal";

export default function ExperienceLevelChip({ experienceLevel, updateProfileAPI }) {
    const [showXPModal, setShowXPModal] = useState(false);

    function getChipColor(experience_level) {
        switch (experience_level) {
            case "Junior":
                return "#37b526";
            case "Intermediate":
                return "#bf640f";
            case "Senior":
                return "#2284e7";
            default:
                return "#3d3f3f";
        }
    }

    return (
        <>
            {showXPModal && (
                <XPLevelModal
                    open={showXPModal}
                    setOpen={setShowXPModal}
                    updateProfileAPI={updateProfileAPI}
                />
            )}
            <Chip
                onClick={() => setShowXPModal(true)}
                label={experienceLevel || ''}
                size="small"
                sx={{
                    backgroundColor: getChipColor(experienceLevel),
                    color: 'white',
                    "&:hover": {
                        backgroundColor: darken(getChipColor(experienceLevel), 0.3),
                    },
                }}
            />
        </>
    )
}