import { useState } from "react";
import { Grid, Stack, Typography, Chip } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";

export default function SkillsPrint({ skillData, experienceData, isPrintMode }) {
    const [seeAllSkills, setSeeAllSkills] = useState(false);
    const [seeAllSectors, setSeeAllSectors] = useState(false);

    function filterUniqueSkills(skills) {
        const uniqueSkillsSet = new Set();
        return skills.filter((skill) => {
            if (!uniqueSkillsSet.has(skill.skill)) {
                uniqueSkillsSet.add(skill.skill);
                return true;
            }
            return false;
        });
    }
    const collectUniqueSectors = (experiences) => {
        const uniqueSectorsSet = new Set();

        experiences.forEach((experience) => {
            experience.sectors.forEach((sector) => {
                if (!uniqueSectorsSet.has(sector)) {
                    uniqueSectorsSet.add(sector);
                }
            });
        });

        return Array.from(uniqueSectorsSet);
    };

    const uniqueSkills = filterUniqueSkills(skillData);
    const uniqueSectors = collectUniqueSectors(experienceData);

    const skillsToShow = seeAllSkills ? uniqueSkills : uniqueSkills.slice(0, 5);
    const sectorsToShow = seeAllSectors ? uniqueSectors : uniqueSectors.slice(0, 5);

    return (
        <Grid container spacing={1} position={'relatove'} mt={6}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <AutoAwesomeIcon />
                    <Typography variant="h6">Skills</Typography>
                </Stack>
                <Grid item xs={12} pt={0.5}>
                    {uniqueSkills.length > 0 ? (
                        <>
                            {skillsToShow.map((uniqueSkill, index) => (
                                <Chip
                                    key={index}
                                    label={uniqueSkill.skill.charAt(0).toUpperCase() + uniqueSkill.skill.slice(1)}
                                    sx={{ marginRight: 1, marginBottom: 1, border: "1px solid #808080" }}
                                    size="medium"
                                />
                            ))}
                            {uniqueSkills.length > 5 && (
                                <Chip
                                    label={seeAllSkills ? "See Less" : `+${uniqueSkills.length - 5}`}
                                    sx={{ marginRight: 1, marginBottom: 1, border: "1px solid #808080" }}
                                    onClick={() => setSeeAllSkills(!seeAllSkills)}
                                />
                            )}
                        </>
                    ) : (
                        <Typography>No skills found</Typography>
                    )}
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <FactoryOutlinedIcon />
                    <Typography variant="h6">Sectors</Typography>
                </Stack>
                <Grid item xs={12} pt={0.5}>
                    {uniqueSectors.length > 0 ? (
                        <>
                            {sectorsToShow.map((uniqueSector, index) => (
                                <Chip
                                    key={index}
                                    label={uniqueSector}
                                    sx={{ marginRight: 1, marginBottom: 1, border: "1px solid #808080" }}
                                    size="medium"
                                />
                            ))}
                            {uniqueSectors.length > 5 && (
                                <Chip
                                    label={seeAllSectors ? "See Less" : `+${uniqueSectors.length - 5}`}
                                    sx={{ marginRight: 1, marginBottom: 1, border: "1px solid #808080" }}
                                    onClick={() => setSeeAllSectors(!seeAllSectors)}
                                />
                            )}
                        </>
                    ) : (
                        <Typography>No sectors found</Typography>
                    )}
                </Grid>
            </Grid> */}
        </Grid>
    );
}