import { createContext } from "react";
import { Grid } from "@mui/material";
import LeftBoxMobile from "./components/LeftBoxMobile";
import RightBoxMobile from "./components/RightBoxMobile";
import { SnackBarComponent2 } from "../../components/snackbar/SnackBarComponent2";

export const ProfileDataContext = createContext();

export default function ProfileMobile({
  allData,
  buttons,
  loading,
  isEditMode,
  userInfo,
  setUserInfo,
  is_admin,
  personalityData,
  showSnackbar,
  emailData,
  setEmailData,
  communicationVisible,
  setCommunicationVisible,
  uuid,
  cur_user_uuid,
  activeJobs,
  events,
  emailLoading,
}) {
  const { SnackBarComponent } = SnackBarComponent2();

  return (
    <Grid
      container
      style={{
        margin: "auto",
        justifyContent: "center",
      }}
      padding={1}
    >
      {SnackBarComponent}

      {!loading && (
        <Grid item xs={12}>
          <LeftBoxMobile
            userData={userInfo}
            setUserData={setUserInfo}
            onEditPage={isEditMode}
            isAdmin={is_admin}
            personalityData={personalityData}
            showSnackbar={showSnackbar}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        style={{
          transition: "0.4s ease-out",
        }}
        pt={1}
      >
        <RightBoxMobile
          allData={allData}
          buttons={buttons}
          isEdit={isEditMode}
          loading={loading}
          is_admin={is_admin}
          uuid={uuid}
        />
      </Grid>


    </Grid>
  );
}
