import {
    Paper,
    Grid,
    Stack,
    Typography,
    ListItemIcon,
    Chip,
    TextField,
    MenuItem,
    Link
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageVideoHandler from "../../../components/image/imageVideoHandler/ImageVideoHandler";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { useState } from "react";
import { SessionUtils } from "../../../utilities/SessionUtils";
import ThreeDSliders from "../../../components/projectStrengths/3DPersona/ThreeDSliders";
import EightStageBadges from "../../../components/projectStrengths/eightStage/EightStageBadges";
import benchStatusOptions from "../../../data/misc/miscData";
import XPLevelModal from "../../../components/modals/XPLevelModal/XPLevelModal";
// import workable_logo

export default function LeftBoxMobile({
    onEditPage = false,
    isAdmin,
    setUserData,
    userData,
    personalityData,
    showSnackbar,
}) {
    const [imageLoading, setImageLoading] = useState(
        {
            image: false, //for profile image
            banner: false,
            video: false
        }
    );
    const [videoLoading, setVideoLoading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [showXPModal, setShowXPModal] = useState(false);
    const [imageHash, setImageHash] = useState(Date.now());
    const [showBenchStatusModal, setShowBenchStatusModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [tempBenchStatus, setTempBenchStatus] = useState();
    const [benchStatusComment, setBenchStatusComment] = useState("");
    function updateProfileState(field, value, isUserField = false) {
        if (isUserField) {
            setUserData((prevData) => ({
                ...prevData,
                user: {
                    ...prevData.user,
                    [field]: value,
                },
            }));
        } else {
            setUserData((prevData) => ({
                ...prevData,
                [field]: value,
            }));
        }
    }

    async function updateProfileAPI(field, value) {
        setSaving(true);
        if (field === 'banner_url') {
            setImageLoading((prevLoading) => ({ ...prevLoading, banner: true }));
        }
        try {
            const data = {
                [field]: value,
            };
            if (benchStatusComment) {
                data.comment = benchStatusComment;
            }
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${userData.uuid}`, data);
            if (response.status === 200) {
                showSnackbar("Success", "success");
                updateProfileState(field, value);
            } else {
                console.log(response.data);
                showSnackbar("Something went wrong", "error");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showSnackbar("Something went wrong", "error");
        } finally {
            setShowBenchStatusModal(false);
            setSaving(false);
            setImageLoading((prevLoading) => ({ ...prevLoading, banner: false }));
        }
    };
    async function uploadVideo(file) {
        const formData = new FormData();
        formData.append("file", file);
        setVideoLoading(true);

        try {
            const response = await RESTUtils.POSTIMAGE(
                RESTUtils.getAPIUrl() +
                `api/user/fileUpload/?uuid=${userData.uuid
                }&file_type=${"video"}`,
                formData
            );
            if (response.status === 201) {
                setImageHash(Date.now());
                updateProfileState("video_url", response.data);
                showSnackbar("Video uploaded successfully", "success");
            } else {
                showSnackbar("Error uploading video", "error");
            }
        } catch (error) {
            showSnackbar("Error uploading video", "error");
        } finally {
            setVideoLoading(false);
        }
    };
    async function uploadImage(file, fileType = "image") {
        const formData = new FormData();
        formData.append("file", file);
        setImageLoading((prevLoading) => ({ ...prevLoading, [fileType]: true }));

        try {
            const response = await RESTUtils.POSTIMAGE(
                RESTUtils.getAPIUrl() +
                `api/user/fileUpload/?uuid=${userData.uuid
                }&file_type=${fileType}`,
                formData
            );
            if (response.status === 201) {
                setImageHash(Date.now());
                const currentUser = SessionUtils.getCurrentUserSession();
                if (currentUser.uuid === userData.uuid && fileType === 'image') {
                    const updatedUser = {
                        ...currentUser,
                        image_url: response.data,
                        imageHash: Date.now(),
                    };

                    SessionUtils.setCurrentUserSession(updatedUser);
                }
                if (fileType === 'image') {
                    updateProfileState("image_url", response.data + '?' + Date.now());
                } else if (fileType === 'banner') {
                    updateProfileState("banner_url", response.data + '?' + Date.now());
                }

                setUploadError(false);
                showSnackbar("Image uploaded successfully", "success");
            } else {
                console.log(response.data);
                setUploadError(true);
                showSnackbar("Error uploading image", "error");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            setUploadError(true);
        } finally {
            setImageLoading((prevLoading) => ({ ...prevLoading, [fileType]: false }));
        }
    };
    function getChipColor(experience_level) {
        switch (experience_level) {
            case "Junior":
                return "#37b526";
            case "Intermediate":
                return "orange";
            case "Senior":
                return "#2284e7";
            default:
                return "gray";
        }
    }

    return (
        <Grid container>
            <XPLevelModal
                open={showXPModal}
                setOpen={setShowXPModal}
                updateProfileAPI={updateProfileAPI}
            />

            <Grid container spacing={1}>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: 1 }}>
                        <Stack direction="column" alignItems="center" spacing={1}>
                            <ImageVideoHandler
                                image_url={userData.image_url}
                                video_url={userData.video_url}
                                isEdit={onEditPage}
                                uploadImage={uploadImage}
                                uploadVideo={uploadVideo}
                                uploadError={uploadError}
                                loading={imageLoading.image}
                                videoLoading={videoLoading}
                                imgHeight='10rem'
                                imgWidth="10rem"
                                imageHash={imageHash}
                                objectFit="cover"
                            />
                            <Typography variant="h5" textAlign={"center"}>
                                {userData?.first_name} {userData?.last_name}
                                {userData?.designations && userData.designations.length > 0 && (
                                    <span>, {userData.designations.join(", ")}</span>
                                )}
                                {userData.experience_level && (
                                    <Chip
                                        onClick={() => setShowXPModal(true)}
                                        label={userData.experience_level}
                                        size="small"
                                        style={{
                                            backgroundColor: getChipColor(userData.experience_level),
                                            color: "white",
                                            marginLeft: "10px",
                                        }}
                                    />
                                )}
                            </Typography>
                            <Typography variant="h6">{userData?.main_title}</Typography>
                            {(userData.city || userData.country) && (
                                <Stack direction="row" alignItems="center">
                                    <ListItemIcon
                                        sx={{
                                            color: "primary.main",
                                            marginRight: "8px",
                                            minWidth: "auto",
                                        }}
                                    >
                                        <LocationOnIcon />
                                    </ListItemIcon>
                                    <Typography variant="h7" style={{ margin: 0 }}>
                                        {userData.city && userData.country
                                            ? `${userData.city}, ${userData.country}`
                                            : userData.city || userData.country || ""}
                                    </Typography>
                                </Stack>
                            )}
                            {isAdmin && onEditPage && (
                                <Stack direction={"row"} spacing={2}>
                                    <TextField
                                        select
                                        variant="standard"
                                        value={userData.bench_status}
                                        onChange={(e) =>
                                            updateProfileAPI("bench_status", e.target.value)
                                        }
                                        InputProps={{
                                            inputProps: {
                                                readOnly: userData.bench_status === 'Pseudo',
                                                sx: { borderBottom: "1px solid lightgrey" },
                                            },
                                        }}
                                    >
                                        {benchStatusOptions.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Stack>
                            )}
                            {userData.workable_url && (
                                <Chip
                                    label="Workable"
                                    icon={
                                        <img
                                            src={"/images/workable_logo_round.png"}
                                            alt="Workable Logo"
                                            height={15}
                                        />
                                    }
                                    component="a"
                                    href={userData.workable_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    clickable
                                    size="small"
                                />
                            )}
                        </Stack>
                    </Paper>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0.75, mb: 1 }}>
                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
                            {personalityData?.personality ? (
                                <ThreeDSliders threeDData={personalityData.personality} />
                            ) : (
                                onEditPage && (
                                    <Typography sx={{ textAlign: "center" }}>
                                        Complete your{" "}
                                        <Link
                                            href="/personality_quiz"
                                            variant="body1"
                                            color={"secondary.main"}>
                                            personality assessment
                                        </Link>
                                        .
                                    </Typography>
                                )
                            )}
                        </Stack>
                    </Paper>

                    <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
                            {personalityData?.eight_stage ? (
                                <Grid container>
                                    <EightStageBadges eightStageData={personalityData.eight_stage} />
                                    {personalityData.personality && (
                                        <Grid item xs={12} textAlign={'center'}>
                                            <a
                                                href={"/personality/" + userData.uuid}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: "white" }}
                                            >
                                                Learn More
                                            </a>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                onEditPage && (
                                    <Typography sx={{ textAlign: "center" }}>
                                        Find your{" "}
                                        <Link
                                            href="/project_strengths"
                                            variant="body1"
                                            color={"secondary.main"}>
                                            project strengths
                                        </Link>
                                        .
                                    </Typography>
                                )
                            )}
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}
