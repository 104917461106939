import { useState, useRef, useEffect } from "react";
import { Card, Typography, Grid, Chip } from "@mui/material";
import dayjs from "dayjs";

export default function PastExperiences({ experienceData, curSkills }) {
    const [seeMore, setSeeMore] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    experienceData.skills = curSkills;

    const checkOverflow = () => {
        if (textRef.current) {
            const { scrollHeight, clientHeight } = textRef.current;
            setIsOverflowing(scrollHeight > clientHeight);
        }
    };
    useEffect(() => {
        checkOverflow(); // Check overflow on mount
        window.addEventListener('resize', checkOverflow); // Check overflow on resize
        return () => window.removeEventListener('resize', checkOverflow); // Clean up on unmount
    }, [experienceData.overview]);
    const start_year = dayjs(experienceData.start_date).year();
    const end_year = experienceData.end_date
        ? ` - ${dayjs(experienceData.end_date).year()}`
        : " - Current";

    let bottomLine = `${start_year}${end_year}`;
    if (experienceData.organization) {
        bottomLine += ` | ${experienceData.organization}`;
    }

    const overflowStyling = {
        paddingTop: '10px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
    }

    return (
        <Card
            sx={{
                padding: "20px",
                transition: "background-color 0.2s ease",
                position: "relative"
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">{experienceData.title}</Typography>
                    <Typography variant="body2">{bottomLine}</Typography>
                    {experienceData.location && (
                        <Typography variant="body2">{experienceData.location}</Typography>
                    )}

                    {experienceData.overview && (
                        <>
                            <Typography
                                variant="body1"
                                sx={
                                    !seeMore ?
                                        overflowStyling
                                        :
                                        { paddingTop: '10px' }}
                                ref={textRef}
                            >
                                {experienceData.overview}
                            </Typography>
                            {isOverflowing && (
                                <Typography
                                    sx={{
                                        cursor: "pointer",
                                        paddingTop: "3px",
                                        color: '#71b7fb',
                                        '&:hover': { color: "#8ed8ff" }
                                    }}
                                    onClick={() => setSeeMore((prevValue) => !prevValue)}
                                >
                                    {seeMore ? "See Less" : "See More"}
                                </Typography>
                            )}
                        </>
                    )}
                </Grid>

                {curSkills && curSkills.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Skills</Typography>
                        <div style={{ marginTop: '8px' }}>
                            {curSkills.map((skill, index) => (
                                <Chip
                                    key={index}
                                    label={skill.skill.charAt(0).toUpperCase() + skill.skill.slice(1)}
                                    variant="outlined"
                                    style={{ marginRight: '8px', marginBottom: '8px' }}
                                />
                            ))}
                        </div>
                    </Grid>
                )}

                {experienceData.sectors && experienceData.sectors.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Sectors</Typography>
                        <div style={{ marginTop: '8px' }}>
                            {experienceData.sectors.map((sector, index) => (
                                <Chip
                                    key={index}
                                    label={sector}
                                    variant="outlined"
                                    style={{ marginRight: '8px', marginBottom: '8px' }}
                                />
                            ))}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
}