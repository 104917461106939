import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { footerIconHoverStyle } from "./styles";
import { FooterComponentProps } from "./types";

function FooterWeb({ footerParams }: FooterComponentProps) {
	const {
		copyright_text,
		logo_url,
		address,
		email,
		phone,
		fb_link,
		linkedin_link,
		twitter_link,
		instagram_link,
	} = footerParams;

	return (
		<Box
			sx={{
				bgcolor: "#282728",
				borderTop: "1px solid #383838",
				textAlign: "center",
				position: "absolute",
				left: 0,
				bottom: 0,
				height: "115px",
				width: "100%",
				overflow: "hidden",
				color: "header_footer_font_color.main"
			}}
		>
			<Container>
				<Grid container>
					{logo_url && (
						<Grid item xs={4} py={3}>
							<img src={logo_url} height={60} alt="Home" />
						</Grid>
					)}

					<Grid item xs={4} py={3}>
						<Box>
							{instagram_link && (
								<Link href={instagram_link} px={0.5} target="_blank" rel="noopener noreferrer">
									<InstagramIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
							{fb_link && (
								<Link href={fb_link} px={0.5} target="_blank" rel="noopener noreferrer">
									<FacebookIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
							{linkedin_link && (
								<Link href={linkedin_link} px={0.5} target="_blank" rel="noopener noreferrer">
									<LinkedInIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
							{twitter_link && (
								<Link href={twitter_link} px={0.5} target="_blank" rel="noopener noreferrer">
									<XIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
						</Box>

						<Box>
							<Typography
								gutterBottom
								variant="caption"
								component="div"
							>
								© {new Date().getFullYear()} {copyright_text}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={4} py={3}>
						{address && (
							<Stack
								direction="row"
								gap={1}
								pb={1}
								alignItems="center"
								justifyContent="center"
							>
								<MapsHomeWorkIcon />
								<Typography variant="body2">
									{address}
								</Typography>
							</Stack>
						)}
						<Stack
							direction="row"
							gap={1}
							alignItems="center"
							justifyContent="center"
						>
							{email && (
								<>
									<MailIcon />
									<Typography variant="body2" pr={1}>
										{email}
									</Typography>
								</>
							)}
							{phone && (
								<>
									<PhoneIcon />
									<Typography variant="body2">
										{phone}
									</Typography>
								</>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default FooterWeb;
