import { Box, Tooltip } from "@mui/material";
import { DataGrid, GridRow } from "@mui/x-data-grid";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { columns } from "./columns";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";
import Buckets from "./Buckets";
import dataGridStyle from "./styles";

function TooltipedRow(props) {
    const { theme } = useThemeContext();

    const rowBackgroundGradient = theme === 'dark' ? 'linear-gradient(to right, transparent 99.7%, white 0.3%)' : 'linear-gradient(to right, transparent 99.7%, black 0.3%)';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                textAlign: 'center',
                cursor: 'pointer',
                background: `${props.row.internal ? rowBackgroundGradient : 'none'}`,
                '&:hover': {
                    backgroundColor: theme === 'dark' ? '#333' : '#f0f0f0',
                }
            }}
            onClick={() => BrowserUtils.openNewTab(`job/${props.row.uuid}`)}
        >
            <Tooltip
                arrow
                placement="right"
                title={props.row.internal && "Used Internally"}
            >
                <>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <GridRow {...props} />
                    </div>

                    <div
                        style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            paddingBottom: '10px',
                        }}

                    >
                        <Buckets bucketData={props.row.bucket_count} job_uuid={props.row.uuid} />
                    </div>
                </>
            </Tooltip>
        </Box>
    );
}

export default function JobsDataGrid({ jobData, setJobData, loading, paginationModel, setPaginationModel }) {
    const maxRows = 5;
    async function toggle_job_public(job) {
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/admin/toggle_job_public/`,
                {
                    job_uuid: job.uuid,
                    public: !job.public,
                }
            );
            if (response.status === 200) {
                const newJobs = [...jobData.jobs];
                const index = newJobs.findIndex((j) => j.uuid === job.uuid);
                newJobs[index].public = !newJobs[index].public;
                setJobData({ ...jobData, jobs: newJobs });
            }
        } catch (error) {
            console.error("Error toggling internal job:", error);
        }
    }
    async function toggle_job_active(job) {
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/admin/toggle_job_active/`,
                {
                    job_uuid: job.uuid,
                    active: !job.active,
                }
            );
            if (response.status === 200) {
                const newJobs = [...jobData.jobs];
                const index = newJobs.findIndex((j) => j.uuid === job.uuid);
                newJobs[index].active = !newJobs[index].active;
                setJobData({ ...jobData, jobs: newJobs });
            }
        } catch (error) {
            console.error("Error toggling job active:", error);
        }
    }

    return (
        <DataGrid
            autoHeight={true}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            initialState={{
                pagination: { paginationModel: { pageSize: maxRows } },
            }}
            rowCount={jobData?.count || 0}
            pageSizeOptions={[maxRows]}
            columns={columns({ 'toggle_job_public': toggle_job_public, 'toggle_job_active': toggle_job_active })}
            rows={jobData?.jobs || []}
            loading={loading}
            getRowId={(row) => row.uuid}
            slots={{
                row: TooltipedRow
            }}
            sx={dataGridStyle}
            rowHeight={50}
        />
    )
}