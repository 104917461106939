import { useState } from "react";
import {
    Grid,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    IconButton,
    Button,
    CircularProgress,
    Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExperienceModalSkills from "./ExperienceModalSkills";

export default function SkillsModal({
    open,
    experienceID,
    okButtonCallback,
    closeButtonCallback,
    okButtonLoading = false,
    setChangedSkills,
    setSkillData,
    skillData,
}) {
    const [isUpdatingSkill, setIsUpdatingSkill] = useState(false);
    function submitFn() {
        okButtonCallback();
    }
    return (
        <Dialog
            maxWidth={"md"}
            open={open}
            fullWidth
            onClose={() => closeButtonCallback()}
            PaperProps={{
                sx: {
                    height: 650,
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {"Add Skills"}
                <IconButton
                    onClick={() => closeButtonCallback()}
                    color="inherit"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider
                variant="middle"
                sx={{
                    backgroundColor: "grey",
                    marginTop: "-10px",
                }}
            />

            <DialogContent>
                <ExperienceModalSkills
                    experienceID={experienceID}
                    setChangedSkills={setChangedSkills}
                    isUpdatingSkill={isUpdatingSkill}
                    setIsUpdatingSkill={setIsUpdatingSkill}
                    skillData={skillData}
                />
            </DialogContent>

            <DialogActions>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <>
                            <Button
                                onClick={() => closeButtonCallback()}
                                variant="outlined"
                                color="secondary"
                                sx={{ mr: 2 }}
                            >
                                Cancel
                            </Button>
                            <Tooltip title={isUpdatingSkill && "Save or cancel skill edits before saving"} arrow placement="top">
                                <span>
                                    <Button
                                        onClick={submitFn}
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ mr: 2 }}
                                        disabled={okButtonLoading || isUpdatingSkill}
                                        startIcon={okButtonLoading && <CircularProgress color="inherit" size={20} />}
                                    >
                                        {okButtonLoading ? "Saving" : "Save"}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
