import {
  Paper,
  Tabs,
  Tab,
  Box,
  CircularProgress,
} from "@mui/material";
import FullProfileCard from "./profileTab/FullProfileCard";
import ResumeHandler from "../../../../profile/components/details/ResumeTab/ResumeHandler";
import Answers from "./answersTab/Answers";
import HistoryCard from "./historyTab/HistoryTab";
import { useState, useEffect, useContext } from "react";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { savedDataContext } from "../..";
import Rundown from "./Rundown";
import allUserData from "./profileTab/ProfileDataIni";
import NoteComponent from "../../../../../components/notes/NoteComponent";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";

// something pretty weird in here which isn't ideal. We get enroll data here but profile data in FullProfile card. We
// probably should do this together, but we'll just roll with it for now. Consequence is we have a 2nd state variable
// for savedEnrollData
export default function CandidateContent({ profileData, questions, curBucket, jobDetails }) {
  const [curTab, setCurTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [enrollLoading, setEnrollLoading] = useState(false);
  const { theme } = useThemeContext();

  const handleChange = (event, newValue) => {
    setCurTab(newValue);
  };
  const { savedProfiles, setSavedProfiles, savedEnrollData, setSavedEnrollData } =
    useContext(savedDataContext);

  const userData = allUserData(setSavedProfiles, profileData.user);
  const enrollId = profileData.job_enroll_id;
  const uuid = profileData.user;

  const contentMap = {
    0: profileData.user && !loading && userData.userInfo ? <FullProfileCard allUserData={userData} loading={loading} uuid={uuid} />
      :
      <div style={{ height: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>,
    1: <ResumeHandler allData={userData} />,
    2: <Answers enrollId={enrollId} questions={questions} />,
    3: <HistoryCard enrollId={enrollId} />,
    4: <NoteComponent notes={userData.noteData} setNotes={userData.setNoteData} user_uuid={uuid} job_id={jobDetails.uuid} />,
  };

  // Have to load profile data here because we want to display their notes in these tabs

  const fetchUserData = async () => {
    if (uuid !== undefined) {
      try {

        const response = await RESTUtils.GET(
          RESTUtils.getAPIUrl() +
          // `api/admin/get_user_data/?uuid=${uuid}`
          `api/user/get_profile_data/?uuid=${uuid}&edit=${true}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching training:", error);
        throw error; // Rethrow the error to be caught by the outer catch block
      }
    }
  };

  const fetchJobEnrollData = async () => {
    if (enrollId) {
      try {
        const response = await RESTUtils.GET(
          RESTUtils.getAPIUrl() +
          `api/admin/get_job_enroll_data/?enroll_id=${enrollId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    }
  };
  useEffect(() => {
    if (enrollId) {
      const fetchData = async () => {
        let enrollData;

        try {
          if (savedEnrollData[enrollId] === undefined
            //  && curBucket !== 'Search' zyzz this may have broken other things seems fine tho
          ) {
            setEnrollLoading(true);
            enrollData = await fetchJobEnrollData();
            const { users, ...enrollDataWithoutUsers } = enrollData;
            const existingUsers = savedEnrollData?.users || [];
            const filteredNewUsers = users.filter(
              (newUser) =>
                !existingUsers.some(
                  (existingUser) => existingUser.uuid === newUser.uuid
                )
            );
            const updatedUsers = [...existingUsers, ...filteredNewUsers];
            setSavedEnrollData((prevState) => ({
              ...prevState,
              ["users"]: updatedUsers,
              [enrollId]: enrollDataWithoutUsers,
            }));
            setEnrollLoading(false);
          }
        } catch (error) {
          console.error("Error in useEffect:", error);
        }
      };
      fetchData();
    }
  }, [enrollId]);

  useEffect(() => {
    const fetchData = async () => {
      let profileData;
      setLoading(true);
      try {
        if (savedProfiles[uuid] === undefined) {
          profileData = await fetchUserData();
          setSavedProfiles((prevState) => ({
            ...prevState,
            [uuid]: profileData,
          }));
        } else {
          profileData = savedProfiles[uuid];
        }
        userData.setUserInfo(profileData.user_data);
        userData.setEducationData(profileData.educations);
        userData.setExperienceData(profileData.experiences);
        userData.setAwardData(profileData.awards);
        userData.setNoteData(profileData.notes);
        userData.setSkillData(profileData.skills);
        userData.setNotableProjectsData(profileData.notable_projects);
        userData.setCertificateData(profileData.certifications);
        userData.setReferenceData(profileData.references);
        userData.setUserReferences(profileData.user_references);
        userData.setEnrollmentData(profileData.enrolls);
      } catch (error) {
        // Handle the error, e.g., show an error message or log it
        console.error("Error in useEffect:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (uuid) {
      fetchData();
    }
  }, [uuid]);

  useEffect(() => {
    if (savedProfiles[uuid] !== undefined) {
      setSavedProfiles((prevState) => ({
        ...prevState,
        [uuid]: {
          ...prevState[uuid],
          notes: userData.noteData,
        },
      }))
    }

  }, [userData.noteData])
  return (
    <>
      {userData.SnackBarComponent}
      {enrollId && (
        <Rundown enrollId={enrollId}
        // rundownVisible={rundownVisible} setRundownVisible={setRundownVisible} 
        />
      )}
      <Box
        sx={{
          // backgroundColor: "#dbdbdb",
          marginTop: "10px",
          height: "50px",
        }}
      >
        {enrollLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Tabs
            variant="fullWidth"
            value={curTab}
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              borderTop: '1px solid',
              borderRight: '1px solid',
              borderLeft: '1px solid',
              borderRadius: '3px',
              borderColor: theme === 'dark' ? '#383838' : '#989898',
              backgroundColor: theme === 'dark' ? 'transparent' : 'silver',
              '& .Mui-disabled': {
                color: theme === 'dark' ? '#888' : '#888',
              }
            }}
            textColor="inherit"
            indicatorColor="secondary"

          >
            <Tab label="Profile" />
            <Tab label="Resume" />
            <Tab label="Answers" disabled={!enrollId} />
            <Tab label="History" disabled={!enrollId} />
            <Tab label="Notes" disabled={!enrollId} />
          </Tabs>
        )}
      </Box>
      <Paper sx={{ backgroundColor: curTab == 0 && 'inherit', border: curTab == 0 && '0px' }}>
        {!enrollLoading && (
          <div style={{ minHeight: '100px', maxHeight: "550px", overflowY: "auto" }}>
            {contentMap[curTab]}
          </div>
        )}
      </Paper>
    </>
  );
}
