import { Typography } from "@mui/material";

export default function Overview({ overview }) {
    return (
        <>
            <Typography variant="h6" color={'primary.main'} sx={{ fontWeight: 'bold', mb: 2 }}>
                Overview
            </Typography>

            <Typography
                variant="body2"
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: 1.6,
                    mb: 2
                }}
            >
                {overview}
            </Typography>
        </>
    )
}