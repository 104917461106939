import { Grid, CircularProgress, Typography, Divider, Box } from "@mui/material";
import BucketBar from "./BucketBar";
import RefreshButton from "./RefreshButton";

export default function TopBar({
	jobDetails,
	bucketCount,
	setSelectedBucket,
	setSearchText,
	selectedBucket,
	setSelectedUser,
	initialLoading,
	setLoading,
	loadedBuckets,
	get_new_workable_candidates,
	bucketLoading,
}) {
	const { title, client, location } = jobDetails;
	const formattedDetails = [title, client, location]
		.filter(Boolean)
		.join(' - ');

	return (
		<>
			<Grid container sx={{ marginTop: '-10px' }}>
				<Grid item xs={12}>
					{initialLoading ? (
						<CircularProgress size={26} />
					) : (
						<Typography
							variant="h5"
							sx={{
								fontWeight: "bold",
								textAlign: "left",
							}}
						>
							{formattedDetails}
						</Typography>
					)}
				</Grid>

				<Grid item xs={12} mt={1}>
					<BucketBar
						bucketCount={bucketCount}
						setterFn={setSelectedBucket}
						selected={selectedBucket}
						setSelectedUser={setSelectedUser}
						setLoading={setLoading}
						loadedBuckets={loadedBuckets}
						setSearchText={setSearchText}
						bucketLoading={bucketLoading}
						get_new_workable_candidates={get_new_workable_candidates}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Divider sx={{ width: "100%" }} />
			</Grid>
		</>
	);
}
