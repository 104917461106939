import { Typography, Grid } from "@mui/material";
import ReferencePreview from "./preview/ReferencePreview";
import AddReferenceForm from "./AddReferenceForm";
import ReferenceAdminPreview from "./preview/ReferenceAdminPreview";
import { snakeCaseToTitleCase } from "../../../../../utilities/Helpers";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

export default function References({
    showReferenceModal = false,
    setShowReferenceModal,
    curReferenceData,
    setReferenceData,
    curExperienceData,
    closeButtonCallback,
    showSnackbar,
}) {
    const [referenceError, setReferenceError] = useState(false);
    const [showReferenceConfirmModal, setShowReferenceConfirmModal] = useState(false);
    const [okButtonLoading, setOkButtonLoading] = useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [newReference, setNewReference] = useState({
        uuid: "",
        name: "",
        email: "",
        role: "",
        status: "Awaiting Approval",
        experience: curExperienceData.id,
        experience_snapshot: curExperienceData
    });

    function handleReferenceSubmitForm() {
        const isValid = validateReference();

        if (isValid) {
            setShowReferenceConfirmModal(true);
        }
    }
    function validateReference() {
        setReferenceError(
            !newReference.name ||
            !newReference.email ||
            !newReference.role
        );
        return (
            !!newReference.name &&
            !!newReference.email &&
            !!newReference.role
        );
    }
    async function handleDeleteReference() {
        setDeleteButtonLoading(true);
        try {
            const response = await RESTUtils.DELETE(RESTUtils.getAPIUrl()
                + `api/user/delete_reference/?uuid=${curReferenceData.uuid}`);
            if (response.status === 200) {
                setShowDeleteModal(true);
            } else if (response.status === 204) {
                setReferenceData((prevData) =>
                    prevData.filter((ref) => ref.uuid !== curReferenceData.uuid)
                );
                showSnackbar("Verification deleted", "success");
            }
            else {
                console.error("Error deleting verification:", response);
                showSnackbar("Error deleting verification", "error");
            }
        } catch (error) {
            console.error("Error deleting verification:", error);
            showSnackbar("Error deleting verification", "error");
        } finally {
            setDeleteButtonLoading(false);
        }
    }
    async function handleSendReferenceRequest() {
        setOkButtonLoading(true);
        try {
            const response = await RESTUtils.POST(
                RESTUtils.getAPIUrl() + 'api/user/send_reference/',
                newReference);
            if (response.status === 201) {
                const curReference = newReference;
                curReference.uuid = response.data
                setReferenceData((prevData) => [...prevData, curReference]);
                setShowReferenceConfirmModal(false);
                setShowReferenceModal(false);
                showSnackbar("Verification request sent", "success");
            } else {
                console.error("Verification request failed:", response);
                showSnackbar("Verification request failed", "error");
            }
        } catch (error) {
            console.error("Error sending verification request:", error);
            showSnackbar("Error sending verification request", "error");
        } finally {
            setOkButtonLoading(false);
        }
    }

    return (
        <>
            <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)} fullWidth maxWidth={'sm'}>
                <DialogTitle>
                    Verification Delete Request
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        A request to delete this reference has been sent to Peter Lucas admins.
                        Once removed, a new reference request can be made for this experience.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowDeleteModal(false)}
                        variant="outlined"
                        color="secondary"
                        sx={{ m: 2 }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showReferenceModal} onClose={closeButtonCallback} fullWidth maxWidth={'xl'}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {curReferenceData ? "View Verification Request" : "Send Verification Request"}
                    <IconButton
                        onClick={closeButtonCallback}
                        color="inherit"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Divider
                    variant="middle"
                    sx={{
                        backgroundColor: "grey",
                        marginTop: "-10px",
                    }}
                />
                <DialogContent>
                    {curReferenceData ? (
                        <>
                            <Grid item xs={12} py={1}>
                                <Typography variant='h6'>
                                    Status: {snakeCaseToTitleCase(curReferenceData.status)}
                                </Typography>
                                <Typography variant='h6'>
                                    Reference sent to {curReferenceData.name} {curReferenceData.role && `(${curReferenceData.role})`}
                                    {curReferenceData.email && ` at ${curReferenceData.email}`}
                                </Typography>

                            </Grid>
                            <ReferenceAdminPreview data={curReferenceData} />
                        </>
                    ) : (
                        <>
                            <AddReferenceForm
                                error={referenceError}
                                showReferenceConfirmModal={showReferenceConfirmModal}
                                setShowReferenceConfirmModal={setShowReferenceConfirmModal}
                                okButtonLoading={okButtonLoading}
                                handleReferenceSubmitForm={handleReferenceSubmitForm}
                                handleSendReferenceRequest={handleSendReferenceRequest}
                                newReference={newReference}
                                setNewReference={setNewReference}
                            />
                            <ReferencePreview curReferenceData={curExperienceData} />
                        </>
                    )}
                </DialogContent>

                <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                    {curReferenceData ?
                        <>
                            <Button
                                onClick={handleDeleteReference}
                                variant="outlined"
                                color="secondary"
                                sx={{ m: 2 }}
                                disabled={deleteButtonLoading}
                                startIcon={deleteButtonLoading && <CircularProgress color="inherit" size={20} />}
                            >
                                {deleteButtonLoading ? "Deleting..." : "Delete"}
                            </Button>
                            <Button
                                onClick={closeButtonCallback}
                                sx={{ m: 2, float: curReferenceData ? "right" : "left" }}
                                variant="outlined"
                                color="secondary"
                            >
                                Return
                            </Button>
                        </> :
                        <>

                            <Button
                                onClick={closeButtonCallback}
                                sx={{ m: 2 }}
                                variant="outlined"
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleReferenceSubmitForm}
                                variant="outlined"
                                color="secondary"
                                sx={{ m: 2 }}
                                disabled={okButtonLoading}
                                startIcon={okButtonLoading && <CircularProgress color="inherit" size={20} />}
                            >
                                {okButtonLoading ? "Saving" : "Send Reference Request"}
                            </Button>
                        </>
                    }

                </DialogActions>
            </Dialog>
        </>
    );
}