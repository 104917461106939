import { Stack, Typography, FormControlLabel, Checkbox } from '@mui/material';
import BenchStatusSelect from '../BenchStatusSelect';
import WorkableLink from './WorkableLink';
import ExperienceLevelChip from './ExperienceLevelChip';
import LinkedInLink from './LinkedInLink';

export default function AdminRow({ direction = 'row', userData, updateProfileAPI, changeBenchStatus }) {
    console.log(userData)

    return (
        <Stack direction={direction} alignItems={'center'} py={1} spacing={1.75}>
            <Typography variant="h6">Admin:</Typography>
            <ExperienceLevelChip
                experienceLevel={userData.experience_level}
                updateProfileAPI={updateProfileAPI}
            />
            <BenchStatusSelect
                bench_status={userData.bench_status}
                changeBenchStatus={changeBenchStatus}
            />
            {userData.workable_url &&
                <WorkableLink
                    workable_url={userData.workable_url}
                />
            }
            {userData.linkedIn_url &&
                <LinkedInLink
                    linkedIn_url={userData.linkedIn_url}
                />
            }
            <FormControlLabel
                control={
                    <Checkbox
                        checked={userData.interviewed}
                        onChange={() => updateProfileAPI('interviewed', !userData.interviewed)}
                        name="interviewed"
                        sx={{
                            color: "primary.contrastText",
                            "&.Mui-checked": {
                                color: "primary.contrastText",
                            },
                        }}
                    />
                }
                label="Interviewed"
            />
        </Stack>
    )
}