import {
  Divider,
  Grid,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import CandidateListCard from "./CandidateListCard";
import RejectedCard from "./RejectedCard";

export default function CandidateListWeb({
  userList,
  handleCardClick,
  changeUserStatus,
}) {

  const shortlisted = userList.filter(
    (candidate) => candidate.status === "shortlisted"
  );
  const proposed = userList.filter(
    (candidate) => candidate.status === "proposed"
  );
  const rejected = userList.filter(
    (candidate) => candidate.status === "rejected"
  );



  return (
    <Grid container alignItems="center" padding={1.5} spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <GroupsIcon />
          <Typography
            variant="h6"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                marginRight: "0.3rem",
              }}
            >
              CANDIDATE
            </span>
            <span style={{ color: "#C60C30" }}>LIST</span>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography>{`${shortlisted.length + proposed.length
          } Candidate${shortlisted.length + proposed.length !== 1 ? "s" : ""
          }`}</Typography>
      </Grid>

      {/* XXXXXXXXXXXXXX SHORTLISTED XXXXXXXXXXXXXX */}
      {shortlisted.map((candidate, index) => (
        <CandidateListCard
          handleCardClick={handleCardClick}
          candidate={candidate}
          key={index}
          shortListStyling={{ marginTop: "-8px", marginBottom: "8px" }} // For the red short list styling
        />
      ))}

      {/* XXXXXXXXXXXXXX PROPOSED XXXXXXXXXXXXXX */}
      {proposed.map((candidate, index) => (
        <CandidateListCard
          handleCardClick={handleCardClick}
          candidate={candidate}
          key={index}
        />
      ))}

      {/* XXXXXXXXXXXXXX REJECTED XXXXXXXXXXXXXX */}
      {rejected.length > 0 && (
        <>
          <Grid item xs={12} my={1}>
            <Divider
              sx={{
                backgroundColor: "inherit",
                "&::before, &::after": {
                  borderColor: "#c60c30",
                },
              }}
            >
              <Chip
                label={`Disqualified`}
                sx={{ fontSize: "15px" }}
              />
            </Divider>
          </Grid>

          {rejected.map((candidate, index) => (
            <RejectedCard candidate={candidate} changeUserStatus={changeUserStatus} key={index} />
          ))}
        </>
      )}
    </Grid>
  );
}
