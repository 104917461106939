import { useState } from "react";
import { Card, Typography } from "@mui/material";

export default function PastProject({
	onClickFn,
	isEdit,
	projectData,
	addMargin,
	isPrintMode = false,
}) {
	const {
		title,
		organization,
		start_date,
		end_date,
		ongoing,
		project_role,
		description,
		cost,
	} = projectData;
	const [isHovered, setIsHovered] = useState(false);

	const line1 =
		title +
		(organization ? " | " + organization : "") +
		(cost ? " - " + cost : "");
	const endDate = ongoing ? "Ongoing" : end_date || "";
	const dateRange =
		start_date && (end_date || ongoing)
			? `${start_date} - ${endDate}`
			: start_date || endDate;
	const line2 = dateRange + (project_role ? " | " + project_role : "");

	return (
		<Card
			onClick={isEdit ? () => onClickFn(projectData) : undefined}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			sx={{
				padding: isPrintMode ? '10px' : "20px",
				backgroundColor: isEdit && isHovered && "secondary.cardHover",
				transition: "background-color 0.2s ease",
				cursor: isEdit ? "pointer" : "default",
				marginBottom: addMargin ? "10px" : "0",
			}}
		>
			<Typography variant="h6">{line1}</Typography>
			<Typography variant="body2">{line2}</Typography>
			{description && (
				<Typography variant="body1">{description}</Typography>
			)}
		</Card>
	);
}
