import { Stack, Tooltip, IconButton, ButtonGroup } from '@mui/material';
import { stepsData } from './data';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export default function StatusStepper({ steps, curStep, setCurStep }) {
    return (
        <Stack direction="row" spacing={1} sx={{ overflow: 'auto' }}>
            <ButtonGroup size="small" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {stepsData.map((step, index) => (
                    <Tooltip key={index} title={step.title} arrow placement="top">
                        <IconButton
                            onClick={() => { setCurStep(index); }}
                            sx={{
                                flexGrow: 1,
                                flexShrink: 1,
                                maxWidth: 'calc(100% / 9)',
                            }}
                        >
                            {steps[step.id] ? (
                                <CheckCircleIcon sx={{ color: curStep === index ? 'primary.main' : 'inherit' }} />
                            ) : (
                                <CircleOutlinedIcon sx={{ color: curStep === index ? 'primary.main' : 'inherit' }} />
                            )}
                        </IconButton>
                    </Tooltip>
                ))}
            </ButtonGroup>
        </Stack>
    );
}
