import axios, { AxiosResponse } from "axios";
import { SessionUtils } from "./SessionUtils";
import { BrowserUtils } from "./BrowserUtils";
import { URLS } from "../urls";
export class RESTUtils {
    private static _API_URL: string = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL as string : '';
    private static _config = {
        headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": ""
        }
    };
    private static _configIMAGE = {
        headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            "Authorization": ""
        }
    };
    private static _addBearerTokenFromStorage(): void {
        const user = SessionUtils.getCurrentUserSession();
        if (user && user.access_token) {
            this._config.headers["Authorization"] = "Bearer " + user.access_token;
        }
    }
    private static _addBearerTokenFromStorageIMAGE(): void {
        const user = SessionUtils.getCurrentUserSession();
        if (user && user.access_token) {
            this._configIMAGE.headers["Authorization"] = "Bearer " + user.access_token;
        }
    }
    public static getAPIUrl(): string {
        return this._API_URL;
    }

    public static async GET(url: string, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        const response = await axios.get(url, Config).catch(function (error) {
            if (error.response.data.detail === 'Given token not valid for any token type') {
                SessionUtils.removeCurrentUserSession();
                BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
            }

            return error.response;

        });
        return response;
    }


    public static async POST(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        const response = await axios.post(url, body, Config).catch(function (error) {
            if (error.response.data.detail === 'Given token not valid for any token type') {
                SessionUtils.removeCurrentUserSession();
                BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
            }
            return error.response;
        });
        return response;
    }
    public static async POSTIMAGE(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._configIMAGE, config);
        this._addBearerTokenFromStorageIMAGE();
        const response = await axios.post(url, body, Config).catch(function (error) {
            if (error.response.data.detail === 'Given token not valid for any token type') {
                SessionUtils.removeCurrentUserSession();
                BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
            }
            return error.response;
        });
        return response;
    }
    public static async PUT(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        const response = await axios.put(url, body, Config).catch(function (error) {
            if (error.response.data.detail === 'Given token not valid for any token type') {
                SessionUtils.removeCurrentUserSession();
                BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
            }
            return error.response;
        });
        return response;
    }
    public static async DELETE(url: string, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        const response = await axios.delete(url, Config).catch(function (error) {
            if (error.response.data.detail === 'Given token not valid for any token type') {
                SessionUtils.removeCurrentUserSession();
                BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
            }
            return error.response;
        });
        return response;
    }
}