import React, { useState } from "react";
import {
	Container,
	CssBaseline,
	Paper,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Link,
	TextField,
	Typography,
	Tooltip,
	IconButton,
	InputAdornment,
	CircularProgress,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Modal from "../../../components/modals/TwoButtonModal";
import { URLS } from "../../../urls";
import { RegistrationProps } from "./types";
import { RESTUtils } from "../../../utilities/RESTUtils";
import ReCaptchaComponent from "../../../components/recaptcha";
import TermsOfServiceModalContent from "./TermsOfServiceModalContent";
import CheckIcon from '@mui/icons-material/Check';

export default function RegistrationPage() {
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [captchaValue, setCaptchaValue] = useState<string>("");
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false);
	const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>(""); // Email is used to resend email verification, so must be accessed outside of form submission

	const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const email = data.get("email") ? (data.get("email") as string) : "";
		setEmail(email);
		const password = data.get("password")
			? (data.get("password") as string)
			: "";
		const confirmPassword = data.get("confirmPassword")
			? (data.get("confirmPassword") as string)
			: "";
		const agreement = data.get("agreement") ? true : false;

		const user: RegistrationProps = {
			username: email,
			email: email,
			password1: password,
			password2: confirmPassword,
			agreement: agreement,
			captcha: captchaValue,
		};

		if (formValidation(user)) {
			setButtonLoading(true);
			const isSuccess = await submitUserItem(user);
			if (isSuccess) {
				setRegistrationSuccess(true);
			}
			setButtonLoading(false);
		}
	};
	const submitUserItem = async (item: RegistrationProps) => {
		const response = await RESTUtils.POST(
			RESTUtils.getAPIUrl() + "api/users/register/",
			item
		);

		if (response.status === 201) {
			return true;
		} else {
			if (response.data.error === "captcha failed") {
				setErrorMessage(
					"Please complete the reCaptcha."
				)
			} else {
				setRecaptchaError(true);
				setErrorMessage(response.data.email || "An error occurred. Please try again. ");
			}
			return false;
		}
	};

	const formValidation = (item: RegistrationProps): boolean => {
		const emailRegex = new RegExp(
			/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
			"gm"
		);
		const passwordRegex =
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/; // See https://ihateregex.io/expr/password/

		if (
			!item.email ||
			!item.password1 ||
			!item.agreement
		) {
			setErrorMessage("Please complete all required fields");
			return false;
		} else if (!emailRegex.test(item.email)) {
			setErrorMessage("Invalid Email");
			return false;
		} else if (item.password1 !== item.password2) {
			setErrorMessage("Password does not match");
			return false;
		} else if (!passwordRegex.test(item.password1)) {
			setErrorMessage(
				"Password must be at least 8 characters long and contain at least 1 capital letter, 1 number and 1 special character"
			);
			return false;
		}

		setErrorMessage("");
		return true;
	};

	function handleResendEmail() {
		RESTUtils.POST(
			RESTUtils.getAPIUrl() + 'api/user/resend-email-verification/',
			{ email: email }
		);
	}

	return (
		<Container component="main" maxWidth="sm" sx={{ height: isMobile ? '60vh' : '73vh' }}>
			<Modal
				show={showModal}
				modalTitle={"Terms of Use"}
				modalMessage={<TermsOfServiceModalContent />}
				closeButtonText="Exit"
				closeButtonCallback={() => {
					setShowModal(false);
				}}
			/>

			<Paper
				elevation={6}
				sx={{
					// ml: { md: 9 },
					width: { md: "435px" },
					position: "absolute",
					top: isMobile ? "42%" : '45%',
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			>
				<CssBaseline />
				<Box
					sx={{
						padding: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
					component="form"
					onSubmit={handleSubmit}
					noValidate
					id="form-step-6"
				>
					<Typography component="h1" variant="h5">
						{registrationSuccess ? "Registration Successful" : "Registration"}
					</Typography>
					{registrationSuccess && (<CheckIcon sx={{ fontSize: 60, color: "green" }} />)}

					{errorMessage && (
						<Typography
							component="p"
							variant="body1"
							color={"error.main"}
							fontWeight={"bold"}
							paddingTop={3}
							textAlign={"center"}
						>
							{errorMessage}
						</Typography>
					)}
					<Box sx={{ mt: 3 }}>

						<Grid container spacing={1} textAlign={"center"} sx={{ display: registrationSuccess ? "none" : null }}>
							<Grid item xs={12}>
								<TextField
									required
									size="small"
									id="email"
									name="email"
									label="Email Address"
									fullWidth
									autoComplete="email"
									inputProps={{ maxLength: 254 }}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									size="small"
									id="password"
									name="password"
									label="Password"
									fullWidth
									autoComplete="password"
									type={showPassword ? "text" : "password"}
									helperText="8 characters long with 1 capital letter, 1 number and 1 special character"
									sx={{ "& p": { color: "secondary.main" } }}
									InputProps={{
										style: {
											fontFamily: "Arial",
										},
										endAdornment: (
											<InputAdornment
												position="end"
												sx={{
													color: "secondary.main",
												}}
											>
												<Tooltip
													title={
														showPassword
															? "Hide Password"
															: "Show Password"
													}
													arrow
													placement="top"
												>
													<IconButton
														onClick={() => {
															setShowPassword(!showPassword);
														}}
													>
														{showPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									size="small"
									id="confirmPassword"
									name="confirmPassword"
									label="Confirm Password"
									fullWidth
									autoComplete="confirmPassword"
									type={showPassword ? "text" : "password"}
									InputProps={{
										style: {
											fontFamily: "Arial",
										},
										endAdornment: (
											<InputAdornment
												position="end"
												sx={{
													color: "secondary.main",
												}}
											>
												<Tooltip
													title={
														showPassword
															? "Hide Password"
															: "Show Password"
													}
													arrow
													placement="top"
												>
													<IconButton
														onClick={() => {
															setShowPassword(!showPassword);
														}}
													>
														{showPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											name="agreement"
											value="yes"
											sx={{
												color: "primary.contrastText",
												"&.Mui-checked": {
													color: "primary.contrastText",
												},
											}}
										/>
									}
									label="I agree to the "
									sx={{ marginRight: "3px" }}
								/>
								<Link
									color="secondary"
									onClick={() => setShowModal(true)}
									sx={{
										verticalAlign: 'middle'
									}}
								>
									Terms of Use
								</Link>
							</Grid>

							<Grid item xs={12}>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<ReCaptchaComponent setCaptchaValue={setCaptchaValue} recaptchaError={recaptchaError} setRecaptchaError={setRecaptchaError} />
								</div>

								<Button
									type="submit"
									fullWidth
									variant="outlined"
									color="secondary"
									sx={{ mt: 3, mb: 2 }}
									disabled={buttonLoading}
									startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
								>
									{buttonLoading ? "Registering" : "Register"}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Link
									href={URLS.AUTH.LOGIN}
									variant="body2"
									color={"secondary"}
								>
									{"Already have an account? Login."}
								</Link>
							</Grid>
						</Grid>

						{registrationSuccess && (
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography >
										An email verification link has been sent to your email.
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Typography>
										Don't see the email? <a href="#" onClick={handleResendEmail} style={{ color: 'DodgerBlue' }}>Click here to resend</a>
									</Typography>
								</Grid>
							</Grid>
						)}
					</Box>
				</Box>
			</Paper>
		</Container>
	);
}
