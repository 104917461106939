import { TaskProps, ModalProps } from "./types";
import React, { useState } from "react";

export function TaskHook(currentUser: number): [TaskProps, React.Dispatch<React.SetStateAction<TaskProps>>] {
	const [taskData, setTaskData] = useState<TaskProps>({
		title: "",
		description: "",
		assigned: null,
		date_string: null,
		id: 0,
		author: currentUser,
		active: true,
	});

	return [taskData, setTaskData];
}

export function ModalHook(): [ModalProps, React.Dispatch<React.SetStateAction<ModalProps>>] {
	const [modalVars, setModalVars] = useState<ModalProps>({
		modalTitle: "",
		okButtonText: "",
	});

	return [modalVars, setModalVars];
}
