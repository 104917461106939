import { Typography } from "@mui/material";
import { StyledSlider, sliderStyleWeb } from "./styles";

export default function ThreeDSliderWeb({ label, value }) {
    const grey = "#4D4D4D";
    const red = "#71071b";
    return (
        <div style={{ position: "relative" }}>
            <Typography style={{ marginLeft: "10px", left: 0, ...sliderStyleWeb }}>
                {value || 0}% {label}
            </Typography>
            <StyledSlider
                value={value || 0}
                disabled
                sx={{
                    "& .MuiSlider-track": {
                        backgroundColor: value >= 50 ? red : grey,
                    },
                    "& .MuiSlider-rail": {
                        backgroundColor: value < 50 ? red : grey,
                    },
                }}
            />
            <Typography style={{ marginRight: "10px", right: 0, ...sliderStyleWeb }}>
                {label === "Extrovert"
                    ? `Introvert ${100 - (value || 0)}%`
                    : `${label === "Thinking" ? "Feeling" : "Dynamic"} ${100 - (value || 0)
                    }%`}
            </Typography>
        </div>
    );
}