import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  CircularProgress
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export default function IntroVideoModal({
  video_url,
  show = false,
  setShowVideoModal,
  openVideoFileInput,
  handleVideoDelete,
  videoLoading,
  isEdit
}) {
  return (
    <Dialog
      open={show}
      onClose={() => {
        setShowVideoModal(false);
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Introduction Video
        <IconButton
          onClick={() => {
            setShowVideoModal(false);
          }}
        >
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <Divider
        variant="middle"
        sx={{
          backgroundColor: "grey",
          marginTop: "-10px",
        }}
      />

      <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {videoLoading ?
          <CircularProgress /> :
          (
            <video width="100%" height="100%" controls>
              <source src={video_url} type="video/mp4" />
              <source src={video_url} type="video/webm" />
              <source src={video_url} type="video/ogg" />
              <source src={video_url} type="video/mkv" />
              <source src={video_url} type="video/avi" />
              <source src={video_url} type="video/mov" />
              Your browser does not support HTML video.
            </video>
          )}
      </DialogContent>

      {isEdit && (
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", marginTop: '-25px' }}>
          {/* <Button
          onClick={handleVideoDelete}
          sx={{ m: 2 }}
          variant="outlined"
          color="primary"
        >
          Remove
        </Button> */}
          <Button
            onClick={openVideoFileInput}
            sx={{ m: 2 }}
            variant="outlined"
            color="primary"
          >
            Edit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
