import { useReducer } from "react";
import { reducer, initialState, emptyReference } from "./referenceReducer";
import {
    Grid,
    Table,
    TableBody,
    Button,
    TableRow,
    TableCell,
    TableContainer
} from "@mui/material";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import ReferenceModalContent from "./ReferenceModalContent";
import ReferencesHeader from "./referencesTable/ReferencesHeader";
import ReferenceRow from "./referencesTable/ReferenceRow";

export default function ReferencesTab({ allData, isEdit, is_admin }) {
    let userReferences, setUserReferences, userInfo, showSnackbar, uuid;
    if (!isEdit) {
        ({ userReferences } = allData);
    } else {
        ({ userReferences, setUserReferences, userInfo, showSnackbar } = allData);
        uuid = userInfo.uuid;
    }

    const [state, dispatch] = useReducer(reducer, initialState(uuid, is_admin));
    const { curReferenceData, showModal, error, isNewReference, buttonLoading } = state;

    function openNewReferenceModal() {
        dispatch({ type: 'SET_IS_NEW_REFERENCE', payload: true });
        dispatch({ type: 'SET_CURRENT_REFERENCE_DATA', payload: emptyReference(uuid, is_admin) });
        dispatch({ type: 'TOGGLE_MODAL', payload: true });
    }

    function editReferenceModal(item) {
        dispatch({ type: 'SET_IS_NEW_REFERENCE', payload: false });
        dispatch({ type: 'SET_CURRENT_REFERENCE_DATA', payload: item });
        dispatch({ type: 'TOGGLE_MODAL', payload: true });
    }

    const closeModal = () => {
        dispatch({ type: 'TOGGLE_MODAL', payload: false });
        dispatch({ type: 'SET_ERROR', payload: false });
    };

    function validateUserReference() {
        dispatch({ type: 'SET_ERROR', payload: !curReferenceData.name || !curReferenceData.reference_title || !curReferenceData.company });
        return curReferenceData.name && curReferenceData.reference_title && curReferenceData.company;
    }

    async function createUserReference() {
        if (validateUserReference()) {
            dispatch({ type: 'SET_BUTTON_LOADING', payload: { okButton: true } });
            try {
                const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + "api/user/user_references/", curReferenceData);
                if (response.status === 201) {
                    curReferenceData.id = response.data.id;
                    setUserReferences(prevUserReferenceData => [
                        ...prevUserReferenceData,
                        curReferenceData
                    ])
                    closeModal();
                    showSnackbar('Reference created', 'success');
                } else {
                    if (response.data && response.data.email) {
                        showSnackbar("Please enter a valid email address", "error");
                    } else {
                        showSnackbar('Error creating reference', "error");
                    }
                }
            } catch (error) {
                console.log(error);
                showSnackbar('Error creating reference', "error");
            } finally {
                dispatch({ type: 'SET_BUTTON_LOADING', payload: { okButton: false } });
            }
        }
    }
    async function editUserReference() {
        if (validateUserReference()) {
            dispatch({ type: 'SET_BUTTON_LOADING', payload: { okButton: true } });
            try {
                const response = await RESTUtils.PUT(RESTUtils.getAPIUrl() + `api/user/user_references/${curReferenceData.id}`, curReferenceData);
                if (response.status === 200) {
                    setUserReferences(prevUserReferenceData => {
                        const updatedIndex = prevUserReferenceData.findIndex(
                            reference => reference.id === curReferenceData.id
                        );
                        if (updatedIndex !== -1) {
                            const updatedReferenceData = [...prevUserReferenceData];
                            updatedReferenceData[updatedIndex] = curReferenceData;
                            return updatedReferenceData;
                        }
                        return prevUserReferenceData;
                    })
                    closeModal();
                    showSnackbar('Reference edited', 'success');
                } else {
                    showSnackbar('Error editing reference', 'error');
                }
            } catch (error) {
                console.log(error);
                showSnackbar('Error editing reference', 'error');
            } finally {
                dispatch({ type: 'SET_BUTTON_LOADING', payload: { okButton: false } });
            }
        }
    }
    async function deleteUserReference() {
        dispatch({ type: 'SET_BUTTON_LOADING', payload: { deleteButton: true } });
        try {
            const response = await RESTUtils.DELETE(RESTUtils.getAPIUrl() + `api/user/user_references/${curReferenceData.id}`);
            if (response.status === 200) {
                setUserReferences(prevUserReferenceData =>
                    prevUserReferenceData.filter(reference => reference.id !== curReferenceData.id))
                closeModal();
                showSnackbar('Reference deleted successfully', 'success');
            } else {
                showSnackbar('Error deleting reference', "error");
            }
        } catch (error) {
            console.log(error);
            showSnackbar('Error deleting reference', "error");
        } finally {
            dispatch({ type: 'SET_BUTTON_LOADING', payload: { deleteButton: false } });
        }
    }

    return (
        <Grid container>
            <TwoButtonModal
                show={showModal}
                modalTitle={isNewReference ? "Add Reference" : "Edit Reference"}
                modalMessage={
                    <ReferenceModalContent
                        curReferenceData={curReferenceData}
                        dispatch={dispatch}
                        is_admin={is_admin}
                        error={error}
                    />
                }
                closeButtonText="Cancel"
                closeButtonCallback={closeModal}
                okButtonText={isNewReference ? "Add" : "Edit"}
                okButtonCallback={isNewReference ? createUserReference : editUserReference}
                deleteButton={!isNewReference}
                deleteButtonCallBack={deleteUserReference}
                okButtonLoading={buttonLoading.okButton}
                deleteButtonLoading={buttonLoading.deleteButton}
            />

            <Grid item xs={12}>
                {isEdit && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={openNewReferenceModal}
                    >
                        Add Reference
                    </Button>
                )}

                <TableContainer>
                    <Table>
                        <ReferencesHeader is_admin={is_admin} />
                        <TableBody>
                            {userReferences.length > 0 ? (
                                userReferences.slice().sort((a, b) => new Date(b.year_awarded) - new Date(a.year_awarded)).map((row, index) => (
                                    <ReferenceRow
                                        key={index}
                                        row={row}
                                        isEdit={isEdit}
                                        openReferenceModal={editReferenceModal}
                                        is_admin={is_admin}
                                    />
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={is_admin ? 6 : 5} >No references found.</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    );
}
