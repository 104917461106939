import { Box, Container, CssBaseline, Paper, Typography } from '@mui/material';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';

function UnauthorizedPage() {

    return (
        <div>
            <Container component="main" maxWidth="sm">
                <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 2 } }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <BlockTwoToneIcon color='primary' sx={{ fontSize: 200 }} />
                        <Typography variant="h4" color={'primary.main'}>
                            Unauthorized
                        </Typography>
                        <Typography variant="h6" style={{ color: 'error.main' }} textAlign={'center'} paddingY={3}>
                            You do not have permission to access this page.
                        </Typography>
                    </Box>
                </Paper>
            </Container>
        </div>
    )
}

export default UnauthorizedPage