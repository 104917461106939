import { Grid, Typography, Divider } from "@mui/material";
import { useContext } from "react";
import { savedDataContext } from "../../..";
import Answer from "./Answer";

export default function Answers({ enrollId, questions }) {
	const { savedEnrollData } = useContext(savedDataContext);
	const answers = savedEnrollData[enrollId]?.answers;

	return (
		<Grid container padding={2}>
			{questions.map((question, index) => (
				<Grid item xs={12} key={index} padding={1}>
					<Grid item xs={12}>
						<Typography>{question}</Typography>
					</Grid>

					{answers ? (
						<Grid item xs={12} pt={1} pl={2}>
							<Answer answer={answers[index]} />
						</Grid>
					) : (
						<Grid item xs={12} pt={1} pl={2}>
							<Answer answer={undefined} />
						</Grid>
					)}

					<Grid item xs={12} pt={2}>
						<Divider />
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}
