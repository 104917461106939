import { Tooltip, IconButton } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshButtn({ bucketLoading, get_new_workable_candidates }) {
    return (
        <Tooltip title={bucketLoading ? "Refreshing" : "Refresh"} arrow placement="top">
            <IconButton
                onClick={bucketLoading ? () => { } : get_new_workable_candidates}
                sx={{
                    '@keyframes spin': {
                        from: {
                            transform: 'rotate(0deg)',
                        },
                        to: {
                            transform: 'rotate(360deg)',
                        },
                    },
                    animation: bucketLoading ? 'spin 0.75s linear infinite' : 'none',
                }}
            >
                <RefreshIcon />
            </IconButton>
        </Tooltip>
    )
}