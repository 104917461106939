import React from "react";
import {
	Button,
	TextField,
	Grid,
	Tooltip,
	InputAdornment,
	IconButton,
	Divider,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useState, useEffect } from "react";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

const NewJob = () => {
	const [PLNumber, setPLNumber] = useState();
	const [toolTipText, setToolTipText] = useState("Copy to Clipboard");
	const [jobTitle, setJobTitle] = useState("");
	const [loading, setLoading] = useState(false);

	async function generateRandomNumber() {
		setLoading(true);
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() + `api/admin/get_new_PL_id/`
			);
			if (response.status === 200) {
				setPLNumber(response.data);
			} else {
				console.log(response.data);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		} finally {
			setLoading(false);
		}

		// setPLNumber(Math.floor(Math.random() * 90000) + 10000);
	}
	function changeToolTipText() {
		setToolTipText("Copied!");
		setTimeout(() => {
			setToolTipText("Copy to Clipboard");
		}, 3000);
	}

	useEffect(() => {
		generateRandomNumber();
	}, []);

	const jobString = jobTitle + " - PL#" + PLNumber;

	return (
		<Grid container>
			<Grid item md={7.5} lg={8.5} pr={0.5} pb={1}>
				<TextField
					variant="outlined"
					size="small"
					label="Job Title"
					value={jobTitle}
					fullWidth
					onChange={(e) => setJobTitle(e.target.value)}
					type="text"
					autofill="off"
				/>
			</Grid>
			<Grid item md={4.5} lg={3.5} pl={0.5}>
				<Button
					onClick={() =>
						BrowserUtils.openNewTab(
							`https://peterlucas.workable.com/backend/jobs/new`
						)
					}
					variant="outlined"
					color="secondary"
					fullWidth
					style={{ whiteSpace: "nowrap", height: "40px" }}
				>
					Create New Job
				</Button>
			</Grid>

			<Grid item xs={12} container alignItems="center">
				<TextField
					variant="outlined"
					fullWidth
					size="small"
					value={jobString}
					InputProps={{
						readOnly: true,
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip
									title={toolTipText}
									arrow
									placement="top"
								>
									<IconButton
										sx={{ color: "secondary.main" }}
										onClick={() => {
											navigator.clipboard.writeText(jobString);

											changeToolTipText();
										}}
									>
										<ContentCopyIcon />
									</IconButton>
								</Tooltip>

								<Divider
									sx={{
										height: 28,
										m: 0.5,
										backgroundColor: "secondary.main",
									}}
									orientation="vertical"
								/>

								<Tooltip
									title={"Generate new ID"}
									arrow
									placement="top"
								>
									<IconButton

										onClick={loading ? () => { } : generateRandomNumber}

										sx={{
											'@keyframes spin': {
												from: {
													transform: 'rotate(0deg)',
												},
												to: {
													transform: 'rotate(360deg)',
												},
											},
											animation: loading ? 'spin 0.75s linear infinite' : 'none',
										}}
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
					label="Workable Job Title"
				/>
			</Grid>
		</Grid>
	);
};

export default NewJob;
