import { Grid, TextField, MenuItem, Typography, } from "@mui/material";

const frequencyOptions = ["Daily", "Weekly", "Monthly"];

export default function AddEditSkillComponent({
	curSkill,
	setCurSkill,
	error,
	curSkills,
}) {

	function handleChange(field, value) {
		setCurSkill((prevSkill) => ({
			...prevSkill,
			[field]: value,
		}));
	}

	return (
		<Grid container spacing={2} pt={1.25}>
			<Grid item xs={12} md={6}>
				<TextField
					variant="outlined"
					label="Skill"
					required
					autoFocus
					autoComplete="off"
					value={curSkill.skill ? curSkill.skill.charAt(0).toUpperCase() + curSkill.skill.slice(1) : ""}
					onChange={(event) =>
						handleChange("skill", event.target.value)
					}
					fullWidth
					error={error && !curSkill.skill}
					inputProps={{ maxLength: 255 }}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					variant="outlined"
					select
					label="Frequency"
					required
					value={curSkill.frequency || ""}
					onChange={(event) =>
						handleChange("frequency", event.target.value)
					}
					fullWidth
					error={error && !curSkill.frequency}
				>
					{frequencyOptions.map((option) => (
						<MenuItem key={option} value={option}>
							{option.charAt(0).toUpperCase() + option.slice(1)}
						</MenuItem>
					))}
				</TextField>
			</Grid>

			<Grid item xs={12}>
				<TextField
					label="Description"
					name="description"
					value={curSkill.description || ""}
					minRows={3}
					multiline
					fullWidth
					onChange={(event) =>
						handleChange("description", event.target.value)
					}
					variant="outlined"
					inputProps={{ maxLength: 400 }}
				/>
			</Grid>
			{error && (
				<Typography color="error">
					Please fill all required fields
				</Typography>
			)}
		</Grid>
	);
}
