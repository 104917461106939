import { useState, useEffect } from "react";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { SessionUtils } from "../../../utilities/SessionUtils";

export const ClientDashboardState = () => {
	const user = SessionUtils.getCurrentUserSession();
	const [jobPostings, setJobPostings] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [interestedList, setInterestedList] = useState([])
	const [profileProgress, setProfileProgress] = useState([]);
	const [loading, setLoading] = useState(true);
	const [announcements, setAnnouncements] = useState([]);
	const [socialPosts, setSocialPosts] = useState([]);

	useEffect(() => {
		async function getExpressedInterest() {
			try {
				const response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() +
					`api/admin/get_expressed_interest/?user_uuid=${user.uuid}`
				);
				setNotifications(response.data.notifications);
				setInterestedList(response.data.interested_list);
				setProfileProgress(response.data.profile_progress);
			} catch (error) {
				console.error("Error fetching training:", error);
			}
		}
		async function getJobs() {
			try {
				const response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() + `api/admin/get_all_public_jobs/`
				);

				setJobPostings(response.data);
			} catch (error) {
				console.error("Error fetching active enrolls:", error);
			}
		}
		async function get_social_posts() {
			try {
				const response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() + `api/user/get_social_posts/`
				);
				if (response.status === 200) {
					setSocialPosts(response.data.socials);
					setAnnouncements(response.data.announcements);
				} else {
					console.error("Error fetching social posts:", response);
				}

			} catch (error) {
				console.error("Error fetching social posts:", error);
			}
		}

		setLoading(true);
		Promise.all([
			getExpressedInterest(),
			getJobs(),
			get_social_posts(),
		])
			.catch((error) => {
				console.error("Error fetching data:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [user.uuid]);

	return {
		jobPostings,
		notifications,
		setNotifications,
		interestedList,
		setInterestedList,
		profileProgress,
		loading,
		announcements,
		socialPosts,
	};
};
