import { Button, TextField, CircularProgress } from "@mui/material";
import { RESTUtils } from "../../utilities/RESTUtils";
import { useState } from "react";
import { SnackBarComponent2 } from "../snackbar/SnackBarComponent2";

export default function Interview({ user_uuid, interview_notes }) {
    const [interviewNotes, setInterviewNotes] = useState(interview_notes);
    const { showSnackbar, SnackBarComponent } = SnackBarComponent2();
    const [loading, setLoading] = useState(false);

    async function handleSave() {
        setLoading(true);
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${user_uuid}`,
                {
                    interview_notes: interviewNotes,
                });
            if (response.status === 200) {
                showSnackbar("Success", "success");
            }
            else {
                showSnackbar("Something went wrong", "error");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showSnackbar("Something went wrong", "error");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ paddingTop: '16px', paddingLeft: '8px', paddingRight: '8px' }}>
            {SnackBarComponent}
            <TextField
                fullWidth
                multiline
                minRows={15}
                value={interviewNotes}
                onChange={(e) => setInterviewNotes(e.target.value)}
            />
            <Button
                sx={{ mt: 1 }}
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleSave}
                disabled={loading}
                startIcon={loading && <CircularProgress color="inherit" size={20} />}
            >
                {loading ? "Saving" : "Save"}
            </Button>
        </div>
    )
}