import RightBox2 from "../../../../../profile/components/RightBox";

const FullProfileCard = ({ allUserData, loading, uuid }) => {
  return (
    <div style={{ minHeight: '80px' }}>
      <RightBox2
        allData={allUserData}
        buttons={["experience", "notable projects", "education", "awards", "admin"]}
        isEdit={true}
        loading={loading}
        is_admin={true}
        uuid={uuid}
        fromCandidateSearch={true}
      />
    </div>
  );
};

export default FullProfileCard;
