export const designationOptions = [
    "A.Sc.T",
    "CAPM",
    "EGBC",
    "Engineer-in-Training",
    "FEC",
    "P.Eng.",
    "P.Geo. / P.Geol.",
    "P.L.Eng",
    "P.L.Geo",
    "PMP"
];

export const checkBoxData = [
    { label: "Indigenous", name: "indigenous" },
    { label: "Woman", name: "woman" },
    { label: "Person With Disability", name: "disability" },
    { label: "Visible Minority", name: "visible_minority" },
    { label: "Prefer Not to Say", name: "prefer_not_to_say" }
];