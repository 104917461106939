import { useState } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import emptyProject from "./emptyProject";
import Modal from "../../../../components/modals/TwoButtonModal";
import ProjectModalContent from "./ProjectModalContent";
import PastProject from "./PastProject";

export default function NotableProjectsTab({
	isEdit,
	allData,
	isPrintMode,
}) {
	const { userInfo, showSnackbar, notableProjectsData, setNotableProjectsData, experienceData } = allData;
	const uuid = userInfo.uuid;
	const [curProjectData, setCurProjectData] = useState(emptyProject(uuid));
	const [error, setError] = useState(false);
	const [isNewProject, setIsNewProject] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState({
		ok: false,
		delete: false,
	});

	const closeModal = () => {
		setShowModal(false);
		setError(false);
	};

	function validateProject() {
		setError(!curProjectData.title);
		return !!curProjectData.title;
	}

	async function createProject() {
		if (validateProject()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			try {
				const response = await RESTUtils.POST(
					RESTUtils.getAPIUrl() + "api/user/notable_projects/",
					curProjectData
				);
				if (response.status === 201) {
					curProjectData.id = response.data;
					setNotableProjectsData((projectData) => [
						...projectData,
						curProjectData,
					]);
					closeModal();
					showSnackbar("Project created", "success");
				} else {
					console.log(response.data);
					showSnackbar("Something went wrong", "error");
				}
			} catch (error) {
				console.log("Error:", error);
				showSnackbar("Something went wrong", "error");
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		}
	}
	async function editProject() {
		if (validateProject()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			const { userDataRef, ...strippedProjectData } = curProjectData;
			try {
				const response = await RESTUtils.PUT(
					RESTUtils.getAPIUrl() +
					`api/user/notable_projects/${curProjectData.id}`,
					strippedProjectData
				);
				if (response.status === 200) {
					setNotableProjectsData((prevProjectData) => {
						const updatedIndex = prevProjectData.findIndex(
							(project) => project.id === curProjectData.id
						);
						if (updatedIndex !== -1) {
							const updatedProjectData = [...prevProjectData];
							updatedProjectData[updatedIndex] = curProjectData;
							return updatedProjectData;
						}
						return prevProjectData;
					});
					closeModal();
					showSnackbar("Project Updated", "success");
				} else {
					showSnackbar("Something went wrong", "error");
				}
			} catch (error) {
				console.log("Error:", error);
				showSnackbar("Something went wrong", "error");
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		} else {
			setError(true);
		}
	}
	async function handleDeleteProject() {
		try {
			setButtonLoading((prevState) => ({ ...prevState, delete: true }));
			const response = await RESTUtils.DELETE(
				RESTUtils.getAPIUrl() +
				`api/user/notable_projects/${curProjectData.id}`
			);
			if (response.status === 200) {
				closeModal();
				showSnackbar("Project deleted", "success");
				setNotableProjectsData((prevProjectData) =>
					prevProjectData.filter(
						(project) => project.id !== curProjectData.id
					)
				);
			} else {
				showSnackbar("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Error deleting project:", error);
			showSnackbar("Something went wrong", "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, delete: false }));
		}
	}

	function openNewProjectModal() {
		setIsNewProject(true);
		setCurProjectData(emptyProject(uuid));
		setShowModal(true);
	}
	function editProjectModal(item) {
		setIsNewProject(false);
		setCurProjectData(item);
		setShowModal(true);
	}

	const projectContent =
		notableProjectsData.length > 0 ? (
			notableProjectsData
				.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
				.map((data, index) => (
					<PastProject
						key={data.id}
						projectData={data}
						isEdit={isEdit}
						onClickFn={editProjectModal}
						addMargin={index !== notableProjectsData.length - 1}
						isPrintMode={isPrintMode}
					/>
				))
		) : (
			<Typography padding={2} >
				No notable projects found.
			</Typography>
		);

	return (
		<Grid container padding={isPrintMode ? 1 : 2}>
			<Modal
				show={showModal}
				modalTitle={isNewProject ? "Add Project" : "Edit Project"}
				modalMessage={
					<ProjectModalContent
						projectData={curProjectData}
						setProjectData={setCurProjectData}
						error={error}
						experienceData={experienceData}
					/>
				}
				closeButtonText="Cancel"
				closeButtonCallback={closeModal}
				okButtonText={isNewProject ? "Add" : "Save"}
				okButtonCallback={isNewProject ? createProject : editProject}
				deleteButton={!isNewProject}
				deleteButtonCallBack={handleDeleteProject}
				okButtonLoading={buttonLoading.ok}
				deleteButtonLoading={buttonLoading.delete}
				onClickOff={isNewProject ? undefined : () => { }}
			/>

			{isEdit && notableProjectsData.length === 0 && (
				<Typography paddingLeft={2}>
					Don't worry if this section is empty, it won't appear on your profile.
				</Typography>
			)}

			{isEdit && (
				<Grid item xs={12} pb={2}>
					<Button
						sx={{ marginTop: notableProjectsData.length === 0 && "16px", marginLeft: notableProjectsData.length === 0 && "16px" }}
						variant="outlined"
						color="secondary"
						onClick={openNewProjectModal}
					>
						Add Project
					</Button>
				</Grid>
			)}

			<Grid item xs={12}>
				{projectContent}
			</Grid>
		</Grid>
	);
}
