import { Row, Section, Text, Link, Img } from "@react-email/components";

export default function EmailSignature() {
    return (
        <Section>
            <Row style={{ margin: 0, padding: 0 }}>
                <Text
                    style={{
                        fontSize: 14,
                        lineHeight: "18px",
                        color: "rgb(107,114,128)",
                        fontWeight: 'bold',
                        margin: '0'
                    }}
                >
                    The Peter Lucas Recruitment Team
                </Text>
            </Row>

            <Row style={{ margin: 0, padding: 0 }}>
                <Text
                    style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        color: 'rgb(107,114,128)',
                        display: 'inline',
                        margin: '0'
                    }}
                >
                    <Link href="https://www.linkedin.com/company/peter-lucas-project-management/">
                        LinkedIn
                    </Link>
                    {' '}
                    <span style={{ color: 'rgb(107,114,128)' }}>
                        |
                    </span>
                    {' '}
                    <Link href="https://www.peterlucas.ca/">
                        www.peterlucas.ca
                    </Link>
                </Text>
            </Row>

            <Row style={{ paddingBottom: '10px' }}>
                <Text
                    style={{
                        fontSize: 12,
                        lineHeight: "18px",
                        margin: '0',
                        color: 'rgb(107,114,128)'
                    }}
                >
                    904 - 201 21st St E, Saskatoon, SK, S7K 0B8 | +1.306.954.8550
                </Text>
            </Row>

            <Row>
                <Link href="https://www.peterlucas.ca/" >
                    <Img
                        src="https://benchbucketplpm.s3.ca-central-1.amazonaws.com/static/assets/PL+2+LINE+LOGO+2+COLOUR_LT+GREY.png"
                        width="150"
                        height="40"
                        alt="Peter Lucas"
                    />
                </Link>
            </Row>
        </Section>
    );
}
