import { TableCell, TableRow } from "@mui/material";
import { tableRowBorderStyle } from "../../experiencesModal/skills/styles";

export default function ReferenceSkillsRow({row_data}) {
    return (
        <TableRow>
            <TableCell
                align="left"
                sx={tableRowBorderStyle}
                key={row_data.id}
            >
                {row_data.skill}
            </TableCell>

            <TableCell align="left" sx={tableRowBorderStyle}>
                {row_data.frequency}
            </TableCell>

            <TableCell align="left" sx={tableRowBorderStyle}>
                {row_data.description}
            </TableCell>
        </TableRow>
    )
}