import { Alert, Snackbar } from '@mui/material';
import React from 'react'
import { SnackbarComponentProps } from './types';

// Snackbar component with their default values
function SnackbarComponent({
    show = false,
    message,
    severity,
    positionVertical = 'bottom',
    positionHorizontal = 'left',
    autoHideDuration = null,
    handleCloseCallback
}: SnackbarComponentProps) {
    return (
        <Snackbar 
            open={show} 
            autoHideDuration={autoHideDuration} 
            onClose={handleCloseCallback}
            anchorOrigin={{ 
                vertical: positionVertical, 
                horizontal: positionHorizontal 
            }} >
            <Alert 
                onClose={handleCloseCallback} 
                severity={severity} 
                sx={{ width: '100%' }}
                variant="filled">
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarComponent;