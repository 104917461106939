import { Box, Typography } from "@mui/material";

export default function NoCandidatesBox() {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography
				variant="h6"
				color="#E2E3E4"
				style={{ fontWeight: "bold" }}
			>
				ADD CANDIDATES TO SHORT LIST
			</Typography>
		</Box>
	);
}
