import {
	Grid,
	TextField,
	Typography,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function ProjectModalContent({
	projectData,
	setProjectData,
	error,
	experienceData,
}) {
	function handleChange(event) {
		const { name, value } = event.target;
		setProjectData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}
	function handleDateChange(event) {
		const { name, value } = event.target;
		setProjectData((prevData) => ({
			...prevData,
			[name]: dayjs(value).format("YYYY-MM-DD"),
		}));
	}
	function handleCheckboxChange(event) {
		const { checked } = event.target;
		setProjectData((prevData) => ({
			...prevData,
			ongoing: checked,
			end_date: checked ? null : prevData.end_date
		}));
	}

	return (
		<>
			<form>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4.25}>
						<TextField
							label="Project Title"
							name="title"
							autoFocus
							value={projectData.title || ""}
							onChange={handleChange}
							fullWidth
							error={
								error &&
								(!projectData.title ||
									projectData.title.trim().length === 0)
							}
							required
							inputProps={{ maxLength: 100 }}
						/>
					</Grid>
					<Grid item xs={12} md={4.25}>
						<TextField
							label="Organization"
							name="organization"
							value={projectData.organization || ""}
							onChange={handleChange}
							fullWidth
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs={12} md={3.5}>
						<TextField
							label="Cost"
							name="cost"
							value={projectData.cost || ""}
							onChange={handleChange}
							fullWidth
							inputProps={{ maxLength: 24 }}
						/>
					</Grid>

					<Grid item xs={6} md={4.25}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={
									projectData.start_date === null
										? null
										: dayjs(projectData.start_date) || null
								}
								label={"Start Date"}
								views={["year", "month", "day"]}
								sx={{ width: "100%" }}
								format="YYYY-MM-DD"
								onChange={(newValue) =>
									handleDateChange({
										target: {
											name: "start_date",
											value: newValue,
										},
									})
								}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={6} md={4.25}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={
									projectData.end_date === null
										? null
										: dayjs(projectData.end_date) || null
								}
								label={"End Date"}
								views={["year", "month", "day"]}
								sx={{ width: "100%" }}
								format="YYYY-MM-DD"
								disabled={projectData.ongoing}
								onChange={(newValue) =>
									handleDateChange({
										target: {
											name: "end_date",
											value: newValue,
										},
									})
								} W
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={12} md={3.5} sx={{ mt: { md: 1 } }} textAlign={'right'} alignItems={'flex-end'}>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										color: "primary.contrastText",
										"&.Mui-checked": {
											color: "primary.contrastText",
										},
									}}
									onChange={handleCheckboxChange}
									checked={projectData.ongoing}
								/>
							}
							label={"Ongoing Project"}
						/>
					</Grid>

					<Grid item xs={6} md={6}>
						<TextField
							label="Role"
							name="project_role"
							value={projectData.project_role || ""}
							onChange={handleChange}
							fullWidth
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs={6} md={6}>
						<TextField
							select
							label="Experience"
							name="experience"
							value={projectData.experience || ""}
							onChange={handleChange}
							fullWidth
						>
							{experienceData.map((experience) => (
								<MenuItem
									key={experience.id}
									value={experience.id}
								>
									{experience.title}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item xs={12}>
						<TextField
							label="Project Description"
							name="description"
							value={projectData.description || ""}
							onChange={handleChange}
							fullWidth
							multiline
							minRows={3}
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>


				</Grid>
			</form>
			{error && (
				<Typography color="error" sx={{ paddingTop: "10px" }}>
					Please fill in all required fields
				</Typography>
			)}
		</>
	);
}
