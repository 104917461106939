export const newUserData = [
  {
    uuid: "65c3bee6-cb44-495b-8ea5-fcb3324b6b05",
    name: "John Smith",
    bench_status: "New",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c361-4a8f-47c6-aebd-28eeac93a132",
    name: "Michael Whitbread",
    bench_status: "New",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c362-4a8f-47c6-aebd-28eeac93a132",
    name: "Alex Sacher",
    bench_status: "New",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Jane Doe",
    bench_status: "New",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c364-4a8f-47c6-aebd-28eeac93a132",
    name: "Fred White",
    bench_status: "New",
    jobs: [],
    buckets: []
  },
  // Add more unique data objects as needed
];

export const awaitingReviewData = [
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    first_name: "Emma",
    last_name: "Smith",
    bench_status: "Awaiting Review",
    jobs: ["Project Manager", "Business Analyst"],
    buckets: ['Expressed Interest', 'Confirm Interest']
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Daniel Lee",
    bench_status: "Awaiting Review",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Sophie Johnson",
    bench_status: "Awaiting Review",
    jobs: ["Project Manager"],
    buckets: ['Disqualified']
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Ryan Garcia",
    bench_status: "Awaiting Review",
    jobs: ["Project Analyst", "Project Coordinator"],
    buckets: ['On Client Portal', 'On Client Portal']
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Isabella Taylor",
    bench_status: "Awaiting Review",
    jobs: ["Project Manager", "Scrum Master"],
    buckets: ['Short List', 'On Client Portal']
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Ethan Miller",
    bench_status: "Awaiting Review",
    jobs: ["Project Coordinator"],
    buckets: ['Confirm Interest']
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Ava Martinez",
    bench_status: "Awaiting Review",
    jobs: [],
    buckets: []
  },
  {
    uuid: "f2f7c363-4a8f-47c6-aebd-28eeac93a132",
    name: "Noah Brown",
    bench_status: "Awaiting Review",
    jobs: ["Project Manager", "Business Analyst", "Scrum Master"],
    buckets: ['Expressed Interest', 'Confirm Interest', 'Disqualified']
  },
  // Add more unique data objects as needed
];

export const benchStatus = [
  "New",
  "Pseudo",
  "In Progress",
  "Awaiting Review",
  "Pending Revisions",
  "Pending Verification",
  "Fully Verified",
];

export const buckets = [
  'Unknown',
  'International',
  'Disqualified',
  'Sourced',
  'Applied',
  'Expressed Interest',
  'Short List',
  'Confirm Interest',
  'On Client Portal'
]

export const applicationData = [
  {
    title: "Safety Specialist (2403303)",
    uuid: "75892c1c-3c32-409a-b849-468791db4fcc",
    applications: [
      {
        name: "Jim Bob",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Fully Verified",
        bucket: "Expressed Interest"
      }, {
        name: "Sally Sue",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Under Construction",
        bucket: "On Client Portal"
      }, {
        name: "John Smith",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Pending Revisions",
        bucket: "Short List"
      }, {
        name: "Jane Doe",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Awaiting Review",
        bucket: "Applied"
      }, {
        name: "Fred White",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "New",
        bucket: "Applied"
      }
    ]
  }, {
    title: "Project Controller (240206) -2",
    uuid: "5a142278-2cba-40b5-a96c-c7485424e3f2",
    applications: [
      {
        name: "John Smith",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Pending Revisions",
        bucket: "Short List"
      }, {
        name: "Jane Doe",
        uuid: 'f2f7c363-4a8f-47c6-aebd-28eeac93a132',
        benchStatus: "Awaiting Review",
        bucket: "Applied"
      }
    ]
  }
]

export default awaitingReviewData;
