import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
const headerStyle = {
	textAlign: "center",
	fontWeight: "bold",
}

export default function TableHeader() {
	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Typography
						variant="subtitle1"
						sx={headerStyle}
					>
						Award Title
					</Typography>
				</TableCell>
				<TableCell>
					<Typography
						variant="subtitle1"
						sx={headerStyle}
					>
						Organization
					</Typography>
				</TableCell>
				<TableCell>
					<Typography
						variant="subtitle1"
						sx={headerStyle}
					>
						Year Awarded
					</Typography>
				</TableCell>
				</TableRow>
		</TableHead>
	);
}
