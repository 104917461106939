import {
	Box,
	Typography,
	Grid,
	FormControl,
	MenuItem,
	OutlinedInput,
	TextField,
} from "@mui/material";
import { referralOptions } from "../data";

export default function ReferralForm({ formData, setFormData }) {
	let showMoreInfo =
		formData.referral === "Employee Referral" || formData.referral === "Peter Lucas Reached Out to Me" || formData.referral === "Other";
	let moreInfoLabel =
		formData.referral === "Employee Referral"
			? "Who referred you?"
			: formData.referral === "Peter Lucas Reached Out to Me"
				? "Who reached out to you?"
				: "Please describe";

	return (
		<Box display={"inherit"}>
			<Typography
				variant="h6"
				component="div"
				paddingBottom={2}
				textAlign="center"
			>
				How did you hear about Peter Lucas?
			</Typography>

			<Grid item xs={12} paddingBottom={1}>
				<FormControl fullWidth>
					<TextField
						select
						label='Please Select'
						id="years"
						value={formData.referral || ""}
						onChange={(e) => setFormData({ ...formData, referral: e.target.value })}
						input={<OutlinedInput label="Please Select" />}
					>
						{referralOptions.map((name) => (
							<MenuItem key={name} value={name}>
								{name}
							</MenuItem>
						))}
					</TextField>
				</FormControl>
			</Grid>

			{showMoreInfo && (
				<Grid item xs={12}>
					<TextField
						fullWidth
						multiline
						minRows={4}
						value={formData.referral_info || ""}
						label={moreInfoLabel}
						onChange={(e) => setFormData({ ...formData, referral_info: e.target.value })}
						inputProps={{ maxLength: 254 }}
					/>
				</Grid>
			)}
		</Box>
	);
}