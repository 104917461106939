import { CircularProgress, Container, Grid, Paper, IconButton } from "@mui/material";
import { useCandidateSearch } from "./hooks";
import CandidateCard from "./components/center/CandidateCard";
import JobDetails from "./components/right/JobDetails";
import SearchResultsBar from "./components/center/SearchResultsBar";
import CandidateContent from "./components/center/CandidateContent";
import LeftTabs from "./components/left/LeftTabs";
import { useParams } from "react-router";
import { useState, useEffect, createContext } from "react";
import Loading from "../../../components/loading/Loading";
import TopBar from "./components/top/TopBar";
import { SessionUtils } from "../../../utilities/SessionUtils";
import { RESTUtils } from "../../../utilities/RESTUtils";
import MessageTab from "../../../components/communication/messages/MessageTab";
import CloseIcon from '@mui/icons-material/Close';
// master page for candidate search. This page will be job specific,
//so it needs job data which we can pull from uuid in url (useParams).

//Left search area: Currently the populated options for selects are housed in criteriaData
//file. In the future we may want to a) grab these from a database and b) allow for custom entries

//Middle candidate cards: Need to receive array of objects with all data. Idea is to expand the
// cards to show full profile for now we'll just link to their profile.

//Right card: job data
export const savedDataContext = createContext();
export default function AdminCandidateSearchPage() {
	const { job_uuid, bucket } = useParams();
	const [isSendingMessage, setIsSendingMessage] = useState(false);
	const [resultCount, setResultCount] = useState(0);
	const currentUser = SessionUtils.getCurrentUserSession();
	const {
		jobDetails,
		setJobDetails,
		candidates,
		setCandidates,
		bucketCount,
		setBucketCount,
		loading,
		setLoading,
		leftLoading,
		setLeftLoading,
		keyWords,
		setKeyWords,
		selectedUser,
		setSelectedUser,
		selectedBucket,
		setSelectedBucket,
		standardSearchCriteria,
		searchResults,
		setSearchResults,
		clearAllFilter,
		initialLoading,
		getCandidatesFromBucket,
		curPage,
		setCurPage,
		get_new_workable_candidates,
		bucketLoading,
		activeJobs,
	} = useCandidateSearch(job_uuid, bucket, setResultCount);

	// loadedBuckets keeps track of which buckets have been clicked on, so we don't need to do multiple
	// back end calls to load the candidate data. savedProfiles does the same but with profile data,
	// savedEnrollData does the same, but for the enroll data (history, messages and notes)
	const [loadedBuckets, setLoadedBuckets] = useState([]);
	const [savedProfiles, setSavedProfiles] = useState({});
	const [savedEnrollData, setSavedEnrollData] = useState({});
	const [searchText, setSearchText] = useState('');
	function getCurrentTimestamp() {
		const now = new Date();
		const timestamp = now.toISOString(); // Returns timestamp in UTC

		// Format the timestamp according to the desired format
		const formattedTimestamp = timestamp.slice(0, 23) + "Z";

		return formattedTimestamp;
	}
	useEffect(() => {
		if (
			!loadedBuckets.includes(selectedBucket) &&
			selectedBucket !== "Search"
		) {
			loadedBuckets.push(selectedBucket);
			getCandidatesFromBucket(selectedBucket);
		}
	}, [selectedBucket]);
	function closeMessageTab() {

		setIsSendingMessage((prev) => !prev)
	}
	async function changeBucket(users, newBucket, profileData) {
		setLeftLoading(true);
		const job_enroll_data = {
			user_uuids: users,
			job: jobDetails.id,
			admin: currentUser.uuid,
			bucket: newBucket,
		};

		try {
			const response = await RESTUtils.POST(
				RESTUtils.getAPIUrl() + `api/admin/move_to_bucket/`,
				job_enroll_data
			);
			if (response.status === 200) {
				const usersMoved = users.length;
				const newEnrollIDs = response.data.job_enroll_ids;
				const oldBucket = response.data.previous_bucket
					.replace(/\s+/g, "_")
					.toLowerCase();
				//update bucket count
				setBucketCount((prevCount) => {
					const updatedCount = {
						...prevCount,
						[newBucket]: (prevCount[newBucket] || 0) + usersMoved,
					};
					if (oldBucket !== "none") {
						updatedCount[oldBucket] =
							(prevCount[oldBucket] || 0) - usersMoved;
					}
					return updatedCount;
				});

				if (oldBucket !== "none") {
					users.forEach((userUUID) => {
						// this assumes candidates have been loaded already for the previous bucket
						const index = candidates[oldBucket].findIndex(
							(element) => element.user === userUUID
						);
						if (index !== -1) {
							const removedElement = candidates[oldBucket].splice(
								index,
								1
							)[0];
							candidates[newBucket].push(removedElement);

						} else { // if candidates[oldBucket] is empty
							candidates[newBucket].push(profileData);
						}
						if (candidates[oldBucket].length > 0 && selectedBucket !== 'Search') {
							handleUserCardClick(0);
						}
						// we also need to update local state on front end if they are currently
						// in search results
					});
				} else {
					// this seems weird, and maybe I'm wrong, but to reach this code, previous bucket must be 'none'. This cannot
					// occur for a batch operation, and therefore, we can safely set this to the first element in array
					profileData.job_enroll_id = newEnrollIDs[0];
					candidates[newBucket].push(profileData);
				}
				users.forEach((userUUID) => {
					// update search results
					const newSearchResults = searchResults.map((user) => {
						if (user.user === userUUID) {
							user.bucket = newBucket;
						}
					});
				});
				newEnrollIDs.map((enrollId) => {
					//update enrollData.history if their data has been loaded. Need to make this custom cause
					// we don't send the object from the backend... could change this, but its ok.

					if (savedEnrollData[enrollId] !== undefined) {
						const newHistory = {
							admin: currentUser.uuid,
							job_enroll: enrollId,
							previous_bucket: oldBucket,
							new_bucket: newBucket,
							timestamp: getCurrentTimestamp(),
						};
						if (
							!savedEnrollData?.users?.some(
								(user) => user.uuid === currentUser.uuid
							)
						) {
							const author = {
								first_name: currentUser.first_name,
								last_name: currentUser.last_name,
								image_url: currentUser.image_url,
								uuid: currentUser.uuid,
							};
						}

						setSavedEnrollData((prevData) => {
							const newState = { ...prevData };
							newState[enrollId].history.push(newHistory);
							if (
								!savedEnrollData?.users?.some(
									(user) => user.uuid === currentUser.uuid
								)
							) {
								newState.users.push({
									first_name: currentUser.first_name,
									last_name: currentUser.last_name,
									image_url: currentUser.image_url,
									uuid: currentUser.uuid,
								});
							}
							return newState;
						});
					}
				});
			}
		} catch (error) {
			console.error("Error changing bucket", error);
		}
		setLeftLoading(false);
	}
	const handleUserCardClick = async (
		index,
		event = false,
		disqualified = false
	) => {
		if (event) {
			event.stopPropagation();
		}
		if (disqualified) {
			setSelectedUser(candidates["disqualified"][index]);
		} else {
			setSelectedUser(
				candidates[selectedBucket.replace(/\s+/g, "_").toLowerCase()][
				index
				]
			);
		}
	};

	return (
		<savedDataContext.Provider
			value={{
				savedProfiles,
				savedEnrollData,
				setSavedProfiles,
				setSavedEnrollData,
				isSendingMessage,
				setIsSendingMessage,
			}}
		>
			<Container
				component="main"
				maxWidth={false}
				sx={{ p: { xs: 2, md: 3 }, minHeight: "1000px" }}
				style={{ transition: "0.4s ease-out" }}
			>
				<Grid container style={{ transition: "0.4s ease-out" }}>
					{/* |--------------- TOP ---------------| */}
					<Grid item xs={12} paddingBottom={1}>
						<TopBar
							jobDetails={jobDetails}
							bucketCount={bucketCount}
							setSelectedBucket={setSelectedBucket}
							setSearchText={setSearchText}
							selectedBucket={selectedBucket}
							setSelectedUser={setSelectedUser}
							initialLoading={initialLoading}
							setLoading={setLoading}
							loadedBuckets={loadedBuckets}
							get_new_workable_candidates={get_new_workable_candidates}
							bucketLoading={bucketLoading}
						/>
					</Grid>

					{/* |--------------- LEFT ---------------| */}

					<Grid item xs={3} display={isSendingMessage ? 'none' : 'block'} style={{ transition: "0.4s ease-out" }}>
						<LeftTabs
							clearAllFilter={clearAllFilter}
							standardSearchCriteria={standardSearchCriteria}
							keyWords={keyWords}
							setKeyWords={setKeyWords}
							users={
								candidates[
								selectedBucket
									.replace(/\s+/g, "_")
									.toLowerCase()
								]
							}
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
							curBucket={selectedBucket}
							setLoading={setLoading}
							setLeftLoading={setLeftLoading}
							leftLoading={leftLoading}
							changeBucket={changeBucket}
							handleUserCardClick={handleUserCardClick}
							dqUsers={candidates.disqualified}
							bucketCount={bucketCount}
							searchText={searchText}
							setSearchText={setSearchText}
							jobDetails={jobDetails}
						/>
					</Grid>


					{/* |--------------- MIDDLE ---------------| */}
					<Grid item xs={6} px={1} style={{ transition: "0.4s ease-out" }}>
						{selectedBucket === "Search" ? (
							<SearchResultsBar resultCount={resultCount} curPage={curPage} setCurPage={setCurPage} />
						) : null}

						{loading ? (
							<div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<CircularProgress />
							</div>
						) : selectedBucket !== "Search" ? (
							<>
								{/* candidate card for buckets, see less should be disabled */}
								<CandidateCard
									profileData={selectedUser}
									curBucket={selectedBucket}
									setCandidates={setCandidates}
									keywords={[]}
									selectedUser={selectedUser}
									setSelectedUser={setSelectedUser}
									changeBucket={changeBucket}
									seeLess={
										selectedBucket === "Search"
											? true
											: false
									}
									activeJobs={activeJobs}
								/>
								{!loading && (
									<>
										<CandidateContent
											jobDetails={jobDetails}
											profileData={selectedUser}
											questions={jobDetails.questions}
											curBucket={selectedBucket}
										/>
									</>
								)}
							</>
						) : (
							selectedUser ? (<>
								<CandidateCard
									profileData={selectedUser}
									curBucket={selectedBucket}
									setCandidates={setCandidates}
									keywords={[]}
									selectedUser={selectedUser}
									setSelectedUser={setSelectedUser}
									changeBucket={changeBucket}
									seeLess={true}
									activeJobs={activeJobs}
								/>
								<CandidateContent
									jobDetails={jobDetails}
									profileData={selectedUser}
									questions={jobDetails.questions}
									curBucket={selectedBucket}
								/>

							</>) : (
								<>
									{searchResults?.map((candidate, index) => (
										<Grid item xs={12} pb={0.5} key={index}>
											<CandidateCard
												profileData={candidate}
												setCandidates={setCandidates}
												curBucket={selectedBucket}
												changeBucket={changeBucket}
												keywords={keyWords}
												setSelectedUser={setSelectedUser}
												selectedUser={selectedUser}
												activeJobs={activeJobs}
											/>
										</Grid>
									))}
								</>
							)
						)}
					</Grid>
					{/* |--------------- RIGHT ---------------| */}
					{!isSendingMessage &&
						<Grid item xs={3} style={{ transition: "0.4s ease-out" }}>
							<Paper sx={{ padding: 1.5 }}>
								<JobDetails
									jobDetails={jobDetails}
									setJobDetails={setJobDetails}
									initialLoading={initialLoading}
								/>
							</Paper>
						</Grid>
					}
					{isSendingMessage &&
						<Grid item xs={6} style={{ transition: "0.4s ease-out" }}>
							<Paper>
								<IconButton onClick={closeMessageTab} sx={{ ml: 'auto', display: 'block' }}>
									<CloseIcon />
								</IconButton>
								{!savedEnrollData[selectedUser?.job_enroll_id] ?
									<div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<CircularProgress />
									</div> :
									<MessageTab
										emailData={savedEnrollData[selectedUser?.job_enroll_id]?.emails}
										setEmailData={setSavedEnrollData}
										user={
											{
												'uuid': selectedUser.user,
												'image_url': selectedUser.image_url,
												'name': selectedUser.first_name + ' ' + selectedUser.last_name,
												'job_enroll_id': selectedUser.job_enroll_id,
												'email': selectedUser.email
											}}
										emailLoading={false}
										showSnackbar={() => { }}
										showSendEmail={true}
										isSendingNewEmail={true}
										setIsSendingNewEmail={setIsSendingMessage}
										job={jobDetails.title}
										fromCS={true}
									/>
								}
							</Paper>
						</Grid>
					}
				</Grid>
			</Container>
		</savedDataContext.Provider >
	);
}
