import { useState, useMemo } from "react";
import { skillOptions, sectorOptions } from "../../../../data/searchCriteria/searchCriteriaData";
export const StandardSearchCriteriaHook = () => {
  const [titles, setTitles] = useState([]);
  const [cities, setCities] = useState([]);
  const [skills, setSkills] = useState([]);
  const [benchStatuses, setBenchStatuses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [experience, setExperience] = useState([]);
  const [workArrangement, setWorkArrangement] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [designations, setDesignations] = useState([]);

  const benchStatusOptions = [
    'Pseudo',
    'On Bench',
    'In Progress',
    'Awaiting Review',
    'Awaiting Interview',
    'Awaiting Verification',
    'Fully Verified'
  ];

  const titleOptions = [
    'Business Analyst',
    'Construction Manager',
    'Drafter/Designer',
    'Operations Manager',
    'Procurement Professional',
    'Project Administrator',
    'Project Controller',
    'Project Coordinator',
    'Project Engineer',
    'Project Manager',
    'Project Planner',
    'Project Scheduler',
    'Project Specialist',
    'QA/QC, Quality',
    'Safety Manager',
    'Trainer',
  ];
  const cityOptions = [
    'Toronto',
    'Montreal',
    'Vancouver',
    'Calgary',
    'Edmonton',
    'Ottawa',
    'Quebec City',
    'Winnipeg',
    'Hamilton',
    'Kitchener',
    'London',
    'St. Catharines',
    'Halifax',
    'Oshawa',
    'Victoria',
    'Windsor',
    'Saskatoon',
    'Regina',
    'Sherbrooke',
    'St. John\'s',
    'Barrie',
    'Kelowna',
    'Abbotsford',
    'Greater Sudbury',
  ];

  const companyOptions = [
    'Nutrien',
    'Mosaic',
    'Teck Resources',
    'Cameco',
    'New Gold',
    'BHP',
    'AECON',

  ]
  const designationOptions = [
    // 'A.Sc.T.',
    // 'C.E.T.',
    // 'C.Tech.',
    // 'ETC',
    // 'ASTTBC',
    // 'ASET',
    // 'TPS',
    // 'CTTAM',
    // 'OACETT',
    // 'OTPQ',
    // 'AETTNL',
    // 'TechNova',
    // 'NBSCETT',
    // 'ITP',
    'P.Eng.',
    'P.Geo., P.Geol.',
    'P.L.Eng',
    'P.L.Geo',
    'FEC',
    'EGBC',
    'APEGA',
    'APEGS',
    'APEGM',
    'PEO',
    'PGO',
    'OIQ',
    'PEGNL',
    'APENS',
    'APEGNB',
    'APEPEI',
    'APEY',
    'NAPEG',
    'PMP',
  ];
  const experienceOptions = [
    'Junior',
    'Intermediate',
    'Senior',
  ]
  const workArrangementOptions = [
    'Employee',
    'Sub-contractor'
  ]
  const standardSearchCriteria = useMemo(() => [
    {
      'label': 'Bench Status',
      'name': 'bench_status',
      'array': benchStatuses,
      'setterFn': setBenchStatuses,
      'options': benchStatusOptions
    },
    {
      'label': 'Job Title',
      'name': 'title',
      'array': titles,
      'setterFn': setTitles,
      'options': titleOptions
    },
    {
      'label': 'City',
      'name': 'city',
      'array': cities,
      'setterFn': setCities,
      'options': cityOptions
    },
    {
      'label': 'Skills',
      'name': 'skills',
      'array': skills,
      'setterFn': setSkills,
      'options': skillOptions
    },
    {
      'label': 'Sectors',
      'name': 'sectors',
      'array': sectors,
      'setterFn': setSectors,
      'options': sectorOptions
    },
    {
      'label': 'Company',
      'name': 'organization',
      'array': companies,
      'setterFn': setCompanies,
      'options': companyOptions
    },
    {
      'label': 'Designations',
      'name': 'designations',
      'array': designations,
      'setterFn': setDesignations,
      'options': designationOptions
    },
    {
      'label': 'Experience Level',
      'name': 'experience_level',
      'array': experience,
      'setterFn': setExperience,
      'options': experienceOptions
    },
    {
      'label': 'Work Type',
      'name': 'work_type',
      'array': workArrangement,
      'setterFn': setWorkArrangement,
      'options': workArrangementOptions
    },
  ], [benchStatuses, titles, cities, skills, sectors, companies, designations, experience, workArrangement]);

  return standardSearchCriteria
}
