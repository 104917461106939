
export const projectStrengthQuestions = {
  identify: [
    "I prefer doing deep dives into the background of a new project before jumping in, even if it causes a delay.",
    "I enjoy spending time identifying potential risks and opportunities more than planning the details of a project.",
    "I tend to focus more on improving existing processes than generating entirely new ideas.",
    "I prefer to challenge assumptions, even if it may cause friction.",
    "When trying to solve a problem, I usually focus on the big picture rather than the details."
  ],
  invent: [
    "I often start thinking about a new idea, even before the last one has been fully developed.",
    "I am naturally open-minded to new possibilities, even if some of them make me appear foolish.",
    "I prefer spending time fleshing out a general concept rather than focusing on the details.",
    "I would rather brainstorm in a meeting than work independently on a new idea.",
    "I'd rather work with uncertainty and ambiguity than several constraints."
  ],
  evaluate: [
    "I tend to rely on my gut feeling as well as objective rationale to make decisions.",
    "I often find myself researching several alternatives, even if it may add unnecessary complexity to the decision.",
    "I believe decisions should be made as a group, encompassing as many voices as possible.",
    "I would rather work with stakeholders to arrive at a decision rather than making the final call independently.",
    "I enjoy systematically comparing alternatives, even when many assumptions must be made."
  ],
  plan: [
    "I prefer to create a detailed plan, even when it may take significant time.",
    "I often find myself breaking down objectives into detailed work packages, even when the tasks are obvious.",
    "I find it worthwhile to create highly detailed schedules, even when knowing the project will change significantly.",
    "I enjoy fleshing out the fine details of a plan or project rather than creating new plans or ideas.",
    "Some people may think I spend too much time planning and organizing."
  ],
  lead: [
    "I prefer having more control even if it means my workload will be larger.",
    "I prefer to delegate tasks to my team, even when I am best suited to complete them.",
    "I often find myself taking on new responsibilities and later regretting it.",
    "I enjoy pushing a new idea forward, even when others are not on board.",
    "During challenging discussions, it is more important to think of an individual's feelings rather than the objective facts."
  ],
  coordinate: [
    "I enjoy reaching out to vendors and contractors to build connections, even when they may not benefit a project.",
    "I prefer to chat with contractors directly rather than receive reports.",
    "I often find myself getting to know the contractors personally, even if it may appear unprofessional.",
    "I find it easy to deliver bad news.",
    "Though it may detract from my own performance, I enjoy providing assistance to team members."
  ],
  control: [
    "I enjoy keeping all documentation up to date and orderly, even if it's time consuming.",
    "I find it worthwhile to dedicate significant time to monitoring a schedule, even when things appear to be going well.",
    "I'm willing to sacrifice project outcomes to ensure all documentation is completed accurately.",
    "When encountering an issue, I prefer to think on my feet rather than rely on contingency plans.",
    "I believe in prioritizing reporting even when there are other pressing matters."
  ],
  finish: [
    "I prefer finishing a task completely before moving on to another, even if it reduces overall efficiency.",
    "I feel more pride successfully managing a project than coming up with a creative solution to a problem.",
    "I generally feel more positive finishing a task than starting one.",
    "I feel it is more important to manage relationships than to meet all project objectives.",
    "It's sometimes justified to make an enemy to meet your objectives."
  ],
};

export const groupedPairs = [
  [['identify', 2], ['control', 0], ['coordinate', 4], ['finish', 3], ['lead', 1], ['evaluate', 0], ['invent', 4], ['plan', 2]],
  [['coordinate', 1], ['control', 4], ['evaluate', 3], ['identify', 1], ['lead', 2], ['invent', 0], ['finish', 2], ['plan', 4]],
  [['finish', 1], ['control', 3], ['identify', 3], ['evaluate', 4], ['lead', 0], ['plan', 3], ['coordinate', 2], ['invent', 1]],
  [['lead', 4], ['plan', 1], ['coordinate', 0], ['identify', 4], ['control', 1], ['invent', 3], ['finish', 0], ['evaluate', 2]],
  [['evaluate', 1], ['lead', 3], ['coordinate', 3], ['control', 2], ['finish', 4], ['identify', 0], ['plan', 0], ['invent', 2]]
];
