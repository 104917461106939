import { Grid, TextField, Typography, Select, MenuItem } from "@mui/material";

export default function AwardModalContent({
	curAwardData,
	setCurAwardData,
	isEdit = false,
	error,
}) {
	function handleChange(event) {
		const { name, value } = event.target;
		setCurAwardData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	const yearsArray = Array.from({ length: 55 }, (_, index) => 2024 - index);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8.5}>
				{isEdit ? (
					<TextField
						label="Title"
						name="title"
						autoFocus
						value={curAwardData.title || ""}
						onChange={handleChange}
						fullWidth
						required
						error={error && !curAwardData.title}
						inputProps={{ maxLength: 255 }}
					/>
				) : (
					<Typography variant="subtitle1">
						{curAwardData.title || ""}
					</Typography>
				)}
			</Grid>

			<Grid item xs={12} md={3.5}>
				{isEdit ? (
					<TextField
						select
						label="Year Awarded"
						name="year_awarded"
						value={curAwardData.year_awarded || ""}
						onChange={handleChange}
						fullWidth
					>
						{yearsArray.map((year) => (
							<MenuItem key={year} value={year}>
								{year}
							</MenuItem>
						))}
					</TextField>
				) : (
					<Typography variant="subtitle1">
						{curAwardData.year_awarded || ""}
					</Typography>
				)}
			</Grid>

			<Grid item xs={12}>
				{isEdit ? (
					<TextField
						label="Organization"
						name="organization"
						value={curAwardData.organization || ""}
						onChange={handleChange}
						required
						fullWidth
						error={error && !curAwardData.organization}
						inputProps={{ maxLength: 255 }}
					/>
				) : (
					<Typography variant="body1">
						{curAwardData.organization || ""}
					</Typography>
				)}
			</Grid>

			{error && (
				<Typography color="error" sx={{ paddingTop: '10px', paddingLeft: '15px' }}>
					Please fill in all required fields.
				</Typography>
			)}
		</Grid>
	);
}
