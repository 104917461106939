import { useState } from "react";
import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Stack,
    Button,
    IconButton,
    Tooltip,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import AddCommentIcon from '@mui/icons-material/AddComment';
import EditIcon from '@mui/icons-material/Edit';
import { useMediaQuery, useTheme } from '@mui/material';


export default function Assessment({
    value,
    index,
    setValues,
    comments,
    setComments,
    setValidationError
}) {
    const [showModal, setShowModal] = useState(false);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    const radioStyle = {
        color: "#e2e3e4",
        "&.Mui-checked": {
            color: "primary.main",
        },
        padding: isMobile ? '2px' : "5px"
    };
    const labels = ['Strongly Agree', 'Agree', 'Disagree', 'Strongly Disagree'];
    const numOfOptions = 4;
    const options = Array.from({ length: numOfOptions }, (_, idx) => idx + 1);

    function handleChange(event) {
        setValidationError(false);
        setValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = parseInt(event.target.value);
            return newValues;
        });
    }

    return (
        <>
            {showModal &&
                <Dialog
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    fullWidth
                    maxWidth='md'
                >
                    <DialogTitle>Add Comment</DialogTitle>
                    <DialogContent sx={{ p: 3 }}>
                        <TextField
                            label='Comment'
                            multiline
                            minRows={5}
                            fullWidth
                            value={comments[index]}
                            onChange={(e) => {
                                const newComments = [...comments];
                                newComments[index] = e.target.value;
                                setComments(newComments);
                            }}
                            sx={{ marginTop: 1 }}

                            inputProps={{ maxLength: 254 }}

                        >
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowModal(false)}>OK</Button>
                    </DialogActions>
                </Dialog >
            }
            <Grid container >
                <Grid container={isMobile} item xs={12} justifyContent={isMobile && 'center'} >
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={value}
                        onChange={handleChange}
                    >
                        <Stack direction="row" spacing={1} >
                            {options.map((option) => (

                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={
                                        <Tooltip title={labels[option - 1]} arrow placement="top">
                                            <Radio sx={radioStyle} />
                                        </Tooltip>

                                    }
                                    label=""
                                />

                            ))}
                            {isMobile ?
                                null
                                :
                                <TextField
                                    label='Comment'
                                    multiline
                                    rows={2}
                                    fullWidth
                                    value={comments[index]}
                                    onChange={(e) => {
                                        const newComments = [...comments];
                                        newComments[index] = e.target.value;
                                        setComments(newComments);
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: "0.85rem",
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: "0.85rem",

                                        },
                                        shrink: true,
                                    }}
                                >
                                </TextField>
                            }
                        </Stack>
                    </RadioGroup>
                </Grid>

                {isMobile &&
                    <Grid container item xs={12} justifyContent={'center'} mt={1}>
                        <IconButton
                            variant="outlined"
                            color='secondary'
                            onClick={() => { setShowModal(!showModal) }}
                            size="small">
                            {comments[index] ? <EditIcon /> : <AddCommentIcon />}
                        </IconButton>
                    </Grid>
                }
            </Grid>
        </>
    )
}
