import {
	BarPlot,
	BarElement,
	ChartsTooltip,
	ChartsXAxis,
	ChartsYAxis,
	ResponsiveChartContainer,
} from "@mui/x-charts";

// Taken from https://github.com/mui/mui-x/issues/11277
const BarCustomElement = (props) => {
	const { data, ...other } = props;
	const color =
		data[other.ownerState.dataIndex] > 1
			? "Chartreuse"
			: data[other.ownerState.dataIndex] >= 0.1
				? "green"
				: "grey";

	// work around BarElement export issue
	return (
		<BarElement
			{...other}
			style={{
				...props.style,
				fill: color,
			}}
			id={other.ownerState.dataIndex}
			dataIndex={other.ownerState.dataIndex}
		/>
	);
};

export default function EightStageGraph({ eightStageData, showAxesLabel = true, height = 350 }) {
	// Any value of 0 disappears on the graph. We transform any values of 0 to 0.1 to avoid this.
	const data = eightStageData.totals.map((value) =>
		value === 0 ? 0.1 : value
	);

	return (
		<ResponsiveChartContainer
			height={height}
			series={[{ type: "bar", data: data }]}
			xAxis={[
				{
					scaleType: "band",
					data: [
						"Identify",
						"Invent",
						"Evaluate",
						"Plan",
						"Lead",
						"Coordinate",
						"Control",
						"Finish"
					],
				},
			]}
		// sx={{
		// 	[`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
		// 		transform: "rotateZ(90deg)",
		// 	}
		// }}
		>
			<BarPlot
				skipAnimation
				slots={{ bar: BarCustomElement }}
				slotProps={{
					bar: {
						data,
					},
					label: {
						color: "white",
					}
				}}
			/>
			{showAxesLabel &&
				<>
					<ChartsXAxis />
					<ChartsYAxis />
				</>
			}

			<ChartsTooltip
				slotProps={
					{
						popper: {
							sx: {
								["& .MuiChartsTooltip-root"]: { backgroundColor: 'white', },
								["& .MuiChartsTooltip-markCell"]: { display: 'none' },
								["& .MuiChartsTooltip-row"]: { display: 'none' },
								["& .MuiChartsTooltip-valueCell"]: { display: 'none' },
							} // Assuming 'popper' is the right slot
						},
						itemContent: {
							style: { color: 'green', backgroundColor: 'white' } // Assuming 'itemContent' is the right slot
						}
					}

				}
			/>
		</ResponsiveChartContainer>
	);
}
