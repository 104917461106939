import { Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function CertificateModalContent({
    setCertificateData,
    certificateData,
    error,
    errorMessage,
}) {
    const handleDateChange = (value) => {
        setCertificateData((prevData) => ({
            ...prevData,
            year_acquired: value ? value.year() : null,
        }));
    };
    return (
        <>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Certificate or Association"
                            name="title"
                            autoFocus
                            value={certificateData.title || ""}
                            onChange={(event) =>
                                setCertificateData((prevData) => ({
                                    ...prevData,
                                    title: event.target.value,
                                }))
                            }
                            fullWidth
                            error={
                                error &&
                                (!certificateData.title ||
                                    certificateData.title.trim().length === 0)
                            }
                            required
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Institution"
                            name="institution"
                            value={certificateData.institution || ""}
                            onChange={(event) =>
                                setCertificateData((prevData) => ({
                                    ...prevData,
                                    institution: event.target.value,
                                }))
                            }
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Year Acquired"
                                views={['year']}
                                value={certificateData.year_acquired ? dayjs().year(certificateData.year_acquired) : null}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        error: false,
                                        id: "year_acquired",
                                        fullWidth: true,
                                    },
                                }}
                                format="YYYY"
                                onChange={handleDateChange}
                            // renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiry Date"
                                value={dayjs(certificateData.expiry_date)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        error: false,
                                        id: "expiry_date",
                                        fullWidth: true,
                                    },
                                }}
                                format="YYYY-MM-DD"
                                onChange={(value) =>
                                    setCertificateData((prevData) => ({
                                        ...prevData,
                                        expiry_date:
                                            dayjs(value).format("YYYY-MM-DD"),
                                    }))
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </form >
            {error && <Typography color="error">{errorMessage}</Typography>
            }
        </>
    );
}
