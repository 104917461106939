import { Divider, Typography, Chip, ListItem } from "@mui/material";

export default function Profile({ candidateData }) {
	const { user_data = {}, skills = [] } = candidateData || {};
	const { rundown, overview, qualifications = [] } = user_data;

	return (
		<>
			<Typography sx={{ fontWeight: "bold", marginBottom: 1 }}>
				Rundown
			</Typography>
			<Divider />
			<Typography sx={{ marginTop: 1 }}>
				{rundown || "No rundown found."}
			</Typography>
			<Typography
				sx={{ fontWeight: "bold", marginTop: 2, marginBottom: 1 }}
			>
				Overview
			</Typography>
			<Divider />
			<Typography sx={{ marginTop: 1 }}>
				{overview || "No overview found."}
			</Typography>
			<Typography
				sx={{ fontWeight: "bold", marginTop: 2, marginBottom: 1 }}
			>
				Qualifications
			</Typography>
			<Divider />
			<Typography sx={{ marginTop: 1 }}>
				{!qualifications || qualifications.length === 0 || qualifications.every(qualification => !qualification.trim()) ? (
					"No qualifications found."
				) : (
					qualifications.map((qualification, index) => (
						qualification &&
						<ListItem style={{ marginTop: "-10px" }} key={index}>&#8226; {qualification}</ListItem>
					))
				)}
			</Typography>
			<Typography
				sx={{ fontWeight: "bold", marginTop: 2, marginBottom: 1 }}
			>
				Skills
			</Typography>
			<Divider sx={{ marginBottom: "10px" }} />

			{skills && skills.length > 0 ? (
				skills.map((skill, index) => (
					<Chip
						key={index}
						label={skill.skill.charAt(0).toUpperCase() + skill.skill.slice(1)}
						style={{
							marginRight: 6,
							marginBottom: 6,
							border: "1px solid #808080",
						}}
					/>
				))
			) : (
				<Typography>No skills found.</Typography>
			)}
		</>
	);
}
