import { useState, useEffect } from "react";
import {
	Grid,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	IconButton,
	Button,
	CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExperienceModalDetailsPage from "./ExperienceModalDetails";

export default function ExperiencesModal({
	open,
	curExperienceData,
	setCurExperienceData,
	okButtonCallback,
	deleteButton,
	deleteButtonCallBack = null,
	closeButtonCallback,
	experienceID,
	okButtonLoading = false,
	deleteButtonLoading = false,
	onClickOff = closeButtonCallback,
}) {
	const [error, setError] = useState(false);
	const [isOngoing, setIsOngoing] = useState(
		curExperienceData.end_date === null
	);

	const [dataChanged, setDataChanged] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (!firstRender) {
			setDataChanged(true);
		} else {
			setFirstRender(false);
		}
	}, [curExperienceData]);


	function isExperienceValid(curExperienceData) {
		const { title, organization, startYear, endYear } = curExperienceData;
		// check if title, organization, and dates are OK
		const isInvalid =
			!title ||
			title.trim().length === 0 ||
			!organization ||
			organization.trim().length === 0 ||
			!startYear ||
			(!endYear && !isOngoing);
		return !isInvalid;
	}

	const handleChange = (event) => {
		const { name, value } = event.target;
		setCurExperienceData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	function submitFn() {
		if (isExperienceValid(curExperienceData)) {
			okButtonCallback();
			setError(false);
		} else {
			setError(true);
		}

	}
	return (
		<Dialog
			maxWidth={"md"}
			open={open}
			fullWidth
			onClose={onClickOff}
			PaperProps={{
				sx: {
					minHeight: 650,
				},
			}}
		>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{experienceID ? "Edit Experience" : "Add Experience"}
				<IconButton
					onClick={() => closeButtonCallback(false, dataChanged)}
					color="inherit"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<Divider
				variant="middle"
				sx={{
					backgroundColor: "grey",
					marginTop: "-10px",
				}}
			/>

			<DialogContent>
				<ExperienceModalDetailsPage
					curExperienceData={curExperienceData}
					setCurExperienceData={setCurExperienceData}
					isOngoing={isOngoing}
					setIsOngoing={setIsOngoing}
					handleChange={handleChange}
					error={error}
				/>
			</DialogContent>

			<DialogActions>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item>
						{!deleteButton && (
							<Button
								onClick={deleteButtonCallBack}
								sx={{ ml: 2 }}
								variant="outlined"
								color="primary"
								disabled={deleteButtonLoading}
								startIcon={deleteButtonLoading && <CircularProgress color="inherit" size={20} />}
							>
								{deleteButtonLoading ? "Deleting" : "Delete"}
							</Button>
						)}
					</Grid>
					<Grid item>
						<>
							<Button
								onClick={() => closeButtonCallback(false, dataChanged)}
								variant="outlined"
								color="secondary"
								sx={{ mr: 2 }}
							>
								Cancel
							</Button>
							<Button
								onClick={submitFn}
								variant="outlined"
								color="secondary"
								sx={{ mr: 2 }}
								disabled={okButtonLoading}
								startIcon={okButtonLoading && <CircularProgress color="inherit" size={20} />}
							>
								{okButtonLoading ? "Saving" : "Save"}
							</Button>
						</>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
