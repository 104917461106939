import { useState } from "react";
import { Tabs, Tab, Paper } from "@mui/material";
// import Announcements from "../announcements/Announcements";
import LinkedInFeed from "./LinkedInFeed";
import CanDashNotifications from "../notifications/CanDashNotifications";
export default function MediaFeedParent({ announcements, socialPosts, notifications, setNotifications }) {
    const [index, setIndex] = useState(0);
    function handleTabChange(event, newIndex) {
        setIndex(newIndex);
    }

    const contentMap = {
        0: <CanDashNotifications notifications={notifications} setNotifications={setNotifications} />,
        // 0: <Announcements announcements={announcements} />,
        1: <LinkedInFeed socialPosts={socialPosts} />,
    };

    return (
        <>
            <Tabs
                value={index}
                onChange={handleTabChange}
                textColor="inherit"
                indicatorColor="primary"
            >
                <Tab label="Notifications" sx={{ mb: 0.5, mt: 0.5 }} />
                {/* <Tab label="Announcements" /> */}
                <Tab label="LinkedIn Feed" sx={{ mb: 0.5, mt: 0.5 }} />
            </Tabs>

            <Paper sx={{ maxHeight: '77.3ch', overflowY: 'auto' }}>
                {contentMap[index]}
            </Paper>
        </>

    );
}