import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function ReferenceModalContent({
    curReferenceData,
    dispatch,
    is_admin,
    error,
}) {
    function handleChange(event) {
        const { name, value } = event.target;
        dispatch({
            type: 'SET_CURRENT_REFERENCE_DATA',
            payload: {
                ...curReferenceData,
                [name]: value,
            },
        });
    };
    function handleDateChange(selectedDate) {
        dispatch({
            type: 'SET_CURRENT_REFERENCE_DATA',
            payload: {
                ...curReferenceData,
                notes_date: dayjs(selectedDate).format("YYYY-MM-DD"),
            },
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <TextField
                    label="Name"
                    name="name"
                    autoFocus
                    value={curReferenceData.name || ""}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={error && !curReferenceData.name}
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    label="Company"
                    name="company"
                    value={curReferenceData.company || ""}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={error && !curReferenceData.company}
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    label="Title"
                    name="reference_title"
                    value={curReferenceData.reference_title || ""}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={error && !curReferenceData.reference_title}
                    inputProps={{ maxLength: 254 }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Email"
                    name="email"
                    value={curReferenceData.email || ""}
                    onChange={handleChange}
                    fullWidth
                    error={error && !curReferenceData.email}
                    inputProps={{ maxLength: 254 }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Phone Number"
                    name="phone"
                    value={curReferenceData.phone || ""}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Relationship"
                    name="relationship"
                    value={curReferenceData.relationship || ""}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={2}
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>

            {is_admin && (
                <>
                    <Grid item xs={12}>
                        <TextField
                            label="Notes"
                            name="notes"
                            value={curReferenceData.notes || ""}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            minRows={2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Reference Check Date"
                                value={
                                    curReferenceData.notes_date === null
                                        ? null
                                        : dayjs(curReferenceData.notes_date) || null
                                }
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        error: false,
                                        id: "notes_date",
                                        fullWidth: true,
                                    },
                                }}
                                format="YYYY-MM-DD"
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            )}
        </Grid>
    )
}