import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
} from "@mui/material";
import AwaitingReviewRow from "./AwaitingReviewRow";

export default function AwaitingReviewTable({
	users,
	setUsers,
	loading,
	error,
}) {
	return (
		<TableContainer
			style={{
				minHeight: 300,
				maxHeight: 300,
				overflowY: "auto",
			}}
		>
			<Table size="small" stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell
							sx={{
								width: "30%",
								borderBottom: "1px solid #6c6e70",
								py: 0.25,
							}}
						>
							Name
						</TableCell>
						<TableCell
							sx={{
								width: "10%",
								borderBottom: "1px solid #6c6e70",
								py: 0.25,
							}}
						>
							Rundown
						</TableCell>
						<TableCell
							sx={{
								width: "20%",
								borderBottom: "1px solid #6c6e70",
								py: 0.25,
							}}
						>
							Bench Status
						</TableCell>
						<TableCell
							sx={{
								width: "20%",
								borderBottom: "1px solid #6c6e70",
								py: 0.25,
							}}
						>
							Job
						</TableCell>
						<TableCell
							sx={{
								width: "20%",
								borderBottom: "1px solid #6c6e70",
								py: 0.25,
							}}
						>
							Bucket
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{loading ? (
						<TableRow>
							<TableCell colSpan={4}>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "260px",
									}}
								>
									<CircularProgress />
								</div>
							</TableCell>
						</TableRow>
					) : error ? (
						<TableRow>
							<TableCell colSpan={4} sx={{ borderBottom: "0px" }}>
								<Box sx={{ minHeight: "200px" }}>
									<Typography color="error">
										Error fetching users. Please try again.
									</Typography>
								</Box>
							</TableCell>
						</TableRow>
					) : users.length === 0 ? (
						<TableRow>
							<TableCell colSpan={4} sx={{ borderBottom: "0px" }}>
								<Box sx={{ minHeight: "200px" }}>
									<Typography>
										No users found.
									</Typography>
								</Box>
							</TableCell>
						</TableRow>
					) : (
						users.map((row) => (
							<AwaitingReviewRow
								row={row}
								setUsers={setUsers}
								key={row.uuid}
							/>
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
