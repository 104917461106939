import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from "react-router-dom";
import { SnackBarComponent2 } from '../../components/snackbar/SnackBarComponent2';
import { RESTUtils } from '../../utilities/RESTUtils';
import { SessionUtils } from '../../utilities/SessionUtils';
import checkIsAdmin from '../../components/authentication';
import ProfileWeb from './ProfileWeb';
import ProfileMobile from './ProfileMobile';
import ProfileWeb2 from './ProfileWeb2';

export default function MasterProfile() {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    const { uuid } = useParams();
    let cur_user_uuid = false;
    let is_admin = false;
    const { SnackBarComponent, showSnackbar } = SnackBarComponent2();
    try {
        cur_user_uuid = SessionUtils.getCurrentUserSession().uuid;
        is_admin = checkIsAdmin(SessionUtils.getCurrentUserSession().access_token);
    } catch { }
    // const cur_user_uuid = SessionUtils.getCurrentUserSession().uuid || false;
    const isEditMode = window.location.pathname.includes("/edit/");
    const isPrintMode = window.location.pathname.includes("/print/");

    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [experienceData, setExperienceData] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [notableProjects, setNotableProjects] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [certificateData, setCertificateData] = useState([]);
    const [awardData, setAwardData] = useState([]);
    const [communicationVisible, setCommunicationVisible] = useState(false);
    const [emailLoading, setEmailLoading] = useState(true);
    const [emailData, setEmailData] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [events, setEvents] = useState([]);
    const [personalityData, setPersonalityData] = useState({});
    const [enrollments, setEnrollments] = useState([]);
    const [noteData, setNoteData] = useState([]);
    const [userReferences, setUserReferences] = useState([]);
    const [profileCompletion, setProfileCompletion] = useState(false);
    const allData = {
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        personalityData: personalityData,
        experienceData: experienceData,
        setExperienceData: setExperienceData,
        skillData: skillData,
        setSkillData: setSkillData,
        notableProjectsData: notableProjects,
        setNotableProjectsData: setNotableProjects,
        educationData: educationData,
        setEducationData: setEducationData,
        certificateData: certificateData,
        setCertificateData: setCertificateData,
        awardData: awardData,
        setAwardData: setAwardData,
        enrollmentData: enrollments,
        setEnrollmentData: setEnrollments,
        showSnackbar: showSnackbar,
        referenceData: referenceData,
        setReferenceData: setReferenceData,
        userReferences: userReferences,
        setUserReferences: setUserReferences,
        profileCompletion: profileCompletion,
    };

    const buttons = [
        "experience",
        isEditMode || allData.notableProjectsData.length > 0 ? "notable projects" : null,
        "education",
        isEditMode || allData.awardData.length > 0 ? "awards" : null,
    ].filter(button => button !== null);
    if (is_admin && isEditMode) {
        buttons.push("admin");
        allData.noteData = noteData;
        allData.setNoteData = setNoteData;
    } else if (isEditMode) {
        buttons.push("info");
    }
    async function fetchUserData(is_admin, isEditMode) {
        try {
            const response = await RESTUtils.GET(
                RESTUtils.getAPIUrl() +
                `api/user/get_profile_data/?uuid=${uuid}&edit=${isEditMode}`
            );

            setUserInfo(response.data.user_data);
            setEducationData(response.data.educations);
            setExperienceData(response.data.experiences);
            setAwardData(response.data.awards);
            setSkillData(response.data.skills);
            setNotableProjects(response.data.notable_projects);
            setCertificateData(response.data.certifications);
            setReferenceData(response.data.references);
            setUserReferences(response.data.user_references);
            if (is_admin) {
                setNoteData(response.data.notes);
                setEnrollments(response.data.enrolls);
            }
            setEvents({});
            setPersonalityData(response.data.personality);
            if (isEditMode) setProfileCompletion(response.data.user_data.profile_completion_steps.every((step) => step));
        } catch (error) {
            console.error("Error fetching training:", error);
        } finally {
            setLoading(false);
        }
    }

    async function fetchUserEmails() {
        if (is_admin && cur_user_uuid !== uuid && isEditMode) {
            console.log(isEditMode)
            try {
                const response = await RESTUtils.GET(
                    RESTUtils.getAPIUrl() + `api/admin/get_user_emails/?user_uuid=${uuid}`
                );
                if (response.status === 200) {
                    setEmailData(response.data.emails);
                    setEvents({});
                }
            } catch (error) {
                console.error("Error fetching training:", error);
            } finally {
                setEmailLoading(false);
            }
        }
    }

    async function fetchActiveJobs(is_admin) {
        if (is_admin) {
            try {
                const response = await RESTUtils.GET(
                    RESTUtils.getAPIUrl() +
                    `api/admin/get_all_active_jobs/`
                );
                if (response.status === 200) {
                    setActiveJobs(response.data);
                }
            } catch (error) {
                console.error("Error fetching details:", error);
            }
        }
    }

    useEffect(() => {
        Promise.all([
            fetchUserData(is_admin, isEditMode),
            fetchUserEmails(is_admin),
            fetchActiveJobs(is_admin),
        ])
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally();
    }, []);

    return (
        <>
            {SnackBarComponent}
            {
                isMobile && !isPrintMode ?
                    <ProfileMobile
                        allData={allData}
                        buttons={buttons}
                        loading={loading}
                        isEditMode={isEditMode}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        is_admin={is_admin}
                        personalityData={personalityData}
                        showSnackbar={showSnackbar}
                        emailData={emailData}
                        setEmailData={setEmailData}
                        communicationVisible={communicationVisible}
                        setCommunicationVisible={setCommunicationVisible}
                        uuid={uuid}
                        cur_user_uuid={cur_user_uuid}
                        activeJobs={activeJobs}
                        events={events}
                        emailLoading={emailLoading}
                    />
                    :
                    <ProfileWeb2
                        allData={allData}
                        buttons={buttons}
                        loading={loading}
                        isEditMode={isEditMode}
                        isPrintMode={isPrintMode}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        is_admin={is_admin}
                        personalityData={personalityData}
                        showSnackbar={showSnackbar}
                        emailData={emailData}
                        setEmailData={setEmailData}
                        communicationVisible={communicationVisible}
                        setCommunicationVisible={setCommunicationVisible}
                        uuid={uuid}
                        cur_user_uuid={cur_user_uuid}
                        activeJobs={activeJobs}
                        events={events}
                        emailLoading={emailLoading}
                    />
            }
        </>
    )
}