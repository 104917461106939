import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, Typography } from "@mui/material";

export default function Answer({ answer }) {
	return (
		<Grid container alignItems="center">
			<Grid item pl={1} sx={{ flexGrow: 1 }}>
				{answer === null || answer === undefined || answer === '' ? (
					<Typography sx={{ paddingLeft: "10px" }}>
						-
					</Typography>
				) : answer === "True" ||
					answer === "False" ? (
					<div style={{ display: "flex", alignItems: "center" }}>
						{answer === "True" ? (
							<>
								<CheckCircleIcon style={{ color: "green" }} />
								<Typography sx={{ paddingLeft: "10px" }}>
									Yes
								</Typography>
							</>
						) : (
							<>
								<CancelIcon style={{ color: "red" }} />
								<Typography sx={{ paddingLeft: "10px" }}>
									No
								</Typography>
							</>
						)}
					</div>
				) : (
					<div style={{ display: "flex" }}>
						<Typography sx={{ paddingLeft: "10px" }}>
							{answer}
						</Typography>
					</div>
				)}
			</Grid>
		</Grid>
	);
}
