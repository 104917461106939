export const profileData = {
    professionalRegistration: [
        {
            title: 'Project Management Professional',
            institute: 'Project Management Institute'
        },
        {
            title: 'Professional Engineer',
            institute: 'Associate of Professional Engineers and Geoscientists of Saskatchewan'
        }
    ],
    education: [
        {
            title: 'Saskatchewan Mine Supervisors Ticket',
            institute: 'Nutrien Allan Mine Site, SK, Canada 2021',
            date: '2021'
        },
        {
            title: 'ABB Certified Associate Project Manager',
            institute: 'ABB South Africa, Johannesburg, Gauteng, South Africa 2015',
            date: '2015'
        },
        {
            title: 'NQF Level 7',
            institute: 'Certificate in Business Project Management, Wits Business School, Johannesburg, Gauteng, South Africa 2012',
            date: '2012'
        },
        {
            title: 'Bachelor of Technology, Mechanical Engineering',
            institute: 'Tshwane University of Technology, Pretoria, Gauteng, South Africa 2007',
            date: '2007'
        },
    ],
    overview: `Ryan Dingwall is a passionate and determined mentor,
skilled in creating logical structures, improving systems,
and fostering growth, all while meticulously planning every facet of his life.

Throughout his 18-year Project Management career, Ryan has successfully managed multi-disciplines,
local and international projects such as mine hoists/winders, shafts, headframes, underground and surface infrastructure
construction, industrial gas tanks, and pipelines through various project lifecycles. He is a certified PMP and Mechanical Professional Engineer
and has been fortunate enough to wear various hats as a supplier, EPCM Consultant, and Client Representative with a variety of Canadian and
South African companies. He is now the Lead Operations & Project Manager with Peter Lucas.

Ryan is a shutdown/turnaround specialist who encourages camaraderie amongst co-workers and leads by example. Originally from Johannesburg, South Africa, Ryan has been living in Saskatoon, Canada, for the past eight years with his wife, two teenage daughters, and three cats.`,
    professionalHistory: [
        {
            title: 'Lead Operations & Project Manager',
            info: 'Peter Lucas | 2023 - Present'
        },
        {
            title: 'Project Manager, Mining, Minerals, & Metals',
            info: 'Stantec | 2023'
        },
        {
            title: 'Senior Project Manager, Mine Maintenance Engineering Department',
            info: 'Nutrien Allan Mine | 2021 - 2023'
        },
        {
            title: 'Project Manager, Mine Maintenance Engineering Department',
            info: 'Nutrien Allan Mine | 2019 - 2021'
        },
    ]
};
