import { useState } from "react";
import { useEffect } from "react";
import {
	Typography,
	Chip,
	Divider,
	Box,
	Grid,
	TextField,
	Autocomplete,
} from "@mui/material";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";

const boxStyle = {
	display: "flex",
	justifyContent: "space-between",
	paddingTop: 0,
	paddingBottom: 0,
	padding: "2px",
	margin: "2px",
};

export default function SearchSidebar({
	clickableTextStyle,
	criteriaName,
	filterArray,
	setterFn,
	options,
}) {
	const [value, setValue] = useState("");
	const [inputValue, setInputValue] = useState("");
	const { theme } = useThemeContext();

	function handleDelete(indexToRemove) {
		const updatedTitles = filterArray.filter(
			(_, index) => index !== indexToRemove
		);
		setterFn(updatedTitles);
	}

	function clearFilter() {
		setterFn([]);
		setValue("");
	}
	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};
	useEffect(() => {
		if (!filterArray.includes(value) && value) {
			setValue("");
			setterFn([...filterArray, value]);
		}
	}, [value, filterArray, setterFn]);

	const handleValueChange = (newValue) => {
		setInputValue('')
		setValue(newValue);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleValueChange(inputValue);
		}
	};
	return (
		<>
			<Divider />

			<Box style={boxStyle}>
				<Grid container>
					<Grid
						container
						sx={{ padding: "5px", alignItems: "center" }}
					>
						<Grid item xs={6}>
							<Typography variant="h8">{criteriaName}</Typography>
						</Grid>
						<Grid item xs={6} align="right">
							<Typography
								variant="h10"
								align="right"
								onClick={() => clearFilter()}
								sx={clickableTextStyle}
							>
								Clear
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Autocomplete
								freeSolo
								value={inputValue}
								inputValue={inputValue}
								clearOnEscape
								onInputChange={handleInputChange}
								onChange={(event, newValue) =>
									handleValueChange(newValue)
								}
								id="clear-on-escape"
								options={options}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										onKeyDown={handleKeyDown}
										sx={{
											"& .MuiInput-underline:before": {
												borderBottomColor: "secondary.main",
											},
											"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
												borderBottomColor: theme === 'dark' ? "white" : "black",
											},
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>

					{filterArray.map((item, index) => (
						<Chip
							key={index}
							label={item}
							style={{ margin: "3px" }}
							variant="outlined"
							onClick={() => handleDelete(index)}
						/>
					))}
				</Grid>
			</Box>
			<Divider />
		</>
	);
}
