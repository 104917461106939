import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  FormGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { SessionUtils } from "../../../../../utilities/SessionUtils";
import { useState } from "react";
import DesignationsMultiSelect from "./DesignationsMultiSelect";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";
import DeacivateModalContent from "./DeactivateModalContent";
import DeleteModalContent from "./DeleteModalContent";
import { checkBoxData } from "./data";
import PhoneMaster from "../../../../../components/phoneInput/PhoneMaster";

export default function InfoForm({ allData }) {
  const { userInfo, setUserInfo, showSnackbar } = allData;
  const [userInfoCopy, setuserInfoCopy] = useState({ ...userInfo }); // lowercase cause I'm lazy
  const [loading, setLoading] = useState({
    save: false,
    deactivate: false,
    delete: false,
  });
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  async function handleSaveUserProfile() {
    setLoading((prevState) => ({ ...prevState, save: true }));
    try {
      const response = await RESTUtils.PUT(
        RESTUtils.getAPIUrl() +
        `api/user/edit_user_data/?uuid=${userInfoCopy.uuid}`,
        {
          ...Object.fromEntries(
            textFields.map((field) => [field.name, userInfoCopy[field.name]])
          ),
          ...Object.fromEntries(
            checkBoxData.map((checkBox) => [
              checkBox.name,
              userInfoCopy[checkBox.name],
            ])
          ),
          designations: userInfoCopy.designations,
        }
      );
      if (response.status === 200) {
        const currentUser = SessionUtils.getCurrentUserSession();
        if (currentUser.uuid === userInfo.uuid) {
          const updatedUser = {
            ...currentUser,
            first_name: userInfoCopy.first_name,
            last_name: userInfoCopy.last_name,
          };
          SessionUtils.setCurrentUserSession(updatedUser);
        }

        showSnackbar("Saved", "success");
        setUserInfo(userInfoCopy);
      } else {
        console.log(response.data);
        showSnackbar("error", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading((prevState) => ({ ...prevState, save: false }));
    }
  }

  function handleInputChange(event) {
    setuserInfoCopy((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  function handleDesignationsClick(event, designation) {
    // Handle the case where event might be null
    if (event) {
      event.stopPropagation();
    }

    setuserInfoCopy((prev) => {
      const updatedDesignations = prev.designations.includes(designation)
        ? prev.designations.filter((value) => value !== designation)
        : [...prev.designations, designation];

      return { ...prev, designations: updatedDesignations };
    });
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setuserInfoCopy((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: checked,
    }));
  };

  async function handleDeactivateAccount(new_state) {
    setLoading((prevState) => ({ ...prevState, deactivate: true }));
    try {
      const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + "api/user/change_user_state/",
        {
          user_uuid: userInfo.uuid,
          state: new_state
        });
      if (response.status === 200) {
        showSnackbar(`Account ${new_state === 'active' ? 'Reactivated' : 'Deactivated'}`, "success");
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          bench_status: new_state === 'active' ? 'In Progress' : 'Inactive',
        }));
        setShowDeactivateModal(false);
        setLoading((prevState) => ({ ...prevState, deactivate: false }));
      } else {
        console.log(response.data);
        showSnackbar("error", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading((prevState) => ({ ...prevState, deactivate: false }));
    }

  }

  async function handleDeleteAccount() {
    setLoading((prevState) => ({ ...prevState, delete: true }));
    try {
      const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + "api/user/delete_user/",
        {
          user_uuid: userInfo.uuid,
        });
      if (response.status === 200) {
        SessionUtils.removeCurrentUserSession();
        window.location.href = "/login/deleted";
      } else {
        console.log(response.data);
        showSnackbar("error", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading((prevState) => ({ ...prevState, delete: false }));
    }
  }

  function handlePhoneChange(value, country, event, formattedValue) {
    setuserInfoCopy((prev) => ({
      ...prev,
      phone: value,
    }));
  }

  function createTextfield(xsSize, mdSize, label, name, value, length) {
    return (
      <Grid item xs={xsSize} md={mdSize} key={name}>
        {name === 'phone' ?
          <PhoneMaster value={value || ""} handlePhoneChange={handlePhoneChange} />
          :
          <TextField
            label={label}
            name={name}
            fullWidth
            InputProps={{
              readOnly: name === "email",
            }}
            size="small"
            value={value || ""}
            onChange={handleInputChange}
            inputProps={{
              maxLength: length || 255,
            }}
          />
        }
      </Grid>
    );
  }

  const textFields = [
    {
      xsSize: 6,
      mdSize: 4,
      label: "First name",
      name: "first_name",
      value: userInfoCopy.first_name,
      length: 254,
    },
    {
      xsSize: 6,
      mdSize: 4,
      label: "Last Name",
      name: "last_name",
      value: userInfoCopy.last_name,
      length: 254,
    },
    {
      xsSize: 12,
      mdSize: 4,
      label: "Preferred Title",
      name: "main_title",
      value: userInfoCopy.main_title,
      length: 50
    },
    // { xsSize: 6, label: "Address", name: "address", value: userInfoCopy.address },
    {
      xsSize: 12,
      mdSize: 4,
      label: "City",
      name: "city",
      value: userInfoCopy.city,
      length: 254,
    },
    {
      xsSize: 6,
      mdSize: 4,
      label: "Province / Region",
      name: "region",
      value: userInfoCopy.region,
      length: 254,
    },
    {
      xsSize: 6,
      mdSize: 4,
      label: "Country",
      name: "country",
      value: userInfoCopy.country,
      length: 254,
    },
    {
      xsSize: 12,
      mdSize: 3,
      label: "Phone Number",
      name: "phone",
      value: userInfoCopy.phone,
      length: 254,
    },
    {
      xsSize: 12,
      mdSize: 3,
      label: "LinkedIn URL",
      name: "linkedIn_url",
      value: userInfoCopy.linkedIn_url,
      length: 254,
    },
    {
      xsSize: 12,
      mdSize: 6,
      label: "Email",
      name: "email",
      value: userInfoCopy.email,
      length: 254,
    },
    // { xsSize: 6, label: "Designations", name: "designations", value: userInfoCopy.designations },
    {
      xsSize: 12,
      mdSize: 3,
      label: "Referral",
      name: "referral",
      value: userInfoCopy.referral,
      length: 254,
    },
    {
      xsSize: 12,
      mdSize: 3,
      label: "Referral Info",
      name: "referral_info",
      value: userInfoCopy.referral_info,
      length: 254,
    },
  ];

  return (
    <>
      {showDeactivateModal && (
        <TwoButtonModal
          show={showDeactivateModal}
          modalTitle={"Deactivate Account"}
          modalMessage={<DeacivateModalContent />}
          closeButtonText="Cancel"
          closeButtonCallback={() => { setShowDeactivateModal(false) }}
          okButtonText={"Confirm"}
          okButtonCallback={() => handleDeactivateAccount('inactive')}
          okButtonLoading={loading.deactivate}
        />
      )}
      {showDeleteModal && (
        <TwoButtonModal
          show={showDeleteModal}
          modalTitle={"Delete Account"}
          modalMessage={<DeleteModalContent />}
          closeButtonText="Cancel"
          closeButtonCallback={() => { setShowDeleteModal(false) }}
          okButtonText={"Confirm"}
          okButtonCallback={handleDeleteAccount}
          okButtonLoading={loading.delete}
        />
      )}

      <Grid container spacing={1}>
        {textFields.map((field) =>
          createTextfield(
            field.xsSize,
            field.mdSize,
            field.label,
            field.name,
            field.value,
            field.length
          )
        )}

        <Grid item xs={12} md={6}>
          <DesignationsMultiSelect
            values={userInfoCopy.designations || []}
            handleDesignationsClick={handleDesignationsClick}
            maxValues={3}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            {checkBoxData.map((data, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    name={data.name}
                    checked={userInfoCopy[data.name]}
                    onChange={handleCheckboxChange}
                  />
                }
                label={data.label}
              />
            ))}
          </FormGroup>
        </Grid>

        <Grid item xs={12} textAlign={"left"} style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ textAlign: isMobile ? 'left' : 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={loading.deactivate && userInfo.bench_status === 'Inactive' && <CircularProgress color="inherit" size={20} />}
              onClick={userInfo.bench_status === 'Inactive' ?
                () => handleDeactivateAccount('active') :
                () => setShowDeactivateModal(true)
              }
              sx={{ mr: 2 }}
            >
              {userInfo.bench_status === 'Inactive' ? 'Reactivate Account' : 'Deactivate Account'}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Account
            </Button>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSaveUserProfile}
            disabled={loading.save}
            startIcon={loading.save && <CircularProgress color="inherit" size={20} />}
            style={{ marginBottom: isMobile && '16px', marginTop: isMobile && '10px' }}
          >
            {loading.save ? "Saving" : "Save"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
