import { Typography } from "@mui/material";
import ProfessionalHistory from "./ProfessionalHistory";

export default function ProfessionalHistories({ professionalHistoryData }) {
    return (
        <>
            <Typography variant="h6" color={'primary.main'} sx={{ fontWeight: 'bold', my: 2 }}>
                Professional History
            </Typography>

            {professionalHistoryData.map((history, index) => (
                <ProfessionalHistory key={index} professionalHistory={history} />
            ))}
        </>
    )
}