import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Tooltip } from "@mui/material";

export default function ExperienceMultiSelect({
  options,
  values,
  handleChange,
  maxValues,
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    disablePortal: true,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <Tooltip
      title={`Please select up to ${maxValues} sectors`}
      arrow
      placement="top"
    >
      <FormControl fullWidth>
        <InputLabel id="sectors">Sectors</InputLabel>
        <Select
          labelId="sectors"
          name={"sectors"}
          multiple
          value={values}
          variant="outlined"
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Sectors" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  style={{ border: "1px solid #808080" }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          sx={{
            color: "white",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#E2E2E4', // white just normal
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E2E2E4',// thicker white on last selected
              borderWidth: 2,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': { 
              borderColor: '#E2E2E4',// thicker white on hover
              borderWidth: 2,
            },
            '.MuiSvgIcon-root ': {
              fill: "white !important",
            }
          }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
              disabled={values.length >= maxValues && !values.includes(name)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
}
