import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

const stzuminusTheme : Theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: '#1E4F65',
            },
            error: {
                main: '#d32f2f',
            },
            background: {
                default: '#f5f5f5',
            },
            header_footer_background: {
                main: '#1E4F65',
            },
            header_footer_font_color: {
                main: '#ffffff',
                dark: '#000000',
            },
            header_footer_hover_button_color: {
                main: '#E1B37E',
            },
            company_logo_url: "images/stzuminus-logo-white.svg",
        },

    })
);

export default stzuminusTheme;