import {
    Menu,
    MenuItem,
    Grid,
    Box,
    IconButton,
} from "@mui/material";
import ImageVideoHandler from "../../../../../components/image/imageVideoHandler/ImageVideoHandler";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import { useState } from "react";
import { SessionUtils } from "../../../../../utilities/SessionUtils";
import BenchStatusModal from "../BenchStatusModal";
import Banner from "../Banner";
import ClientDetails from "../CandidateDetails";
import { SnackBarComponent2 } from "../../../../../components/snackbar/SnackBarComponent2";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SkillsPrint from "./SkillsPrint";

export default function TopBox({
    onEditPage = false,
    isAdmin,
    setUserData,
    userData,
    personalityData,
    allData,
    isPrintMode = false,
    sectionsOpen,
    setSectionsOpen
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mediaLoading, setMediaLoading] = useState(
        {
            image: false, //for profile image
            banner: false,
            video: false
        }
    );
    const [uploadError, setUploadError] = useState(false);
    const [imageHash, setImageHash] = useState(Date.now());
    const [showBenchStatusModal, setShowBenchStatusModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [tempBenchStatus, setTempBenchStatus] = useState();
    const [benchStatusComment, setBenchStatusComment] = useState("");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const { SnackBarComponent, showSnackbar } = SnackBarComponent2();

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    };
    function handleMenuClose() {
        setAnchorEl(null);
    };

    function updateProfileState(field, value, isUserField = false) {
        if (isUserField) {
            setUserData((prevData) => ({
                ...prevData,
                user: {
                    ...prevData.user,
                    [field]: value,
                },
            }));
        } else {
            setUserData((prevData) => ({
                ...prevData,
                [field]: value,
            }));
        }
    }
    function changeBenchStatus(value) {
        if (value === "Pseudo") {
            showSnackbar("Pseudo status cannot be set in this way", "error");
            return;
        }
        setShowBenchStatusModal(true);
        setTempBenchStatus(value);
    }

    async function updateProfileAPI(field, value) {
        setSaving(true);
        if (field === 'banner_url') {
            setMediaLoading((prevLoading) => ({ ...prevLoading, banner: true }));
        }
        try {
            const data = {
                [field]: value,
            };
            if (benchStatusComment) {
                data.comment = benchStatusComment;
            }
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${userData.uuid}`, data);
            if (response.status === 200) {
                showSnackbar("Success", "success");
                updateProfileState(field, value);
            } else {
                console.log(response.data);
                showSnackbar("Something went wrong", "error");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showSnackbar("Something went wrong", "error");
        } finally {
            setShowBenchStatusModal(false);
            setBenchStatusComment("");
            setSaving(false);
            setMediaLoading((prevLoading) => ({ ...prevLoading, banner: false }));
        }
    };
    async function uploadVideo(file) {
        const formData = new FormData();
        formData.append("file", file);
        setMediaLoading((prevLoading) => ({ ...prevLoading, video: true }));

        try {
            const response = await RESTUtils.POSTIMAGE(
                RESTUtils.getAPIUrl() +
                `api/user/fileUpload/?uuid=${userData.uuid
                }&file_type=${"video"}`,
                formData
            );
            if (response.status === 201) {
                setImageHash(Date.now());
                updateProfileState("video_url", response.data);
                showSnackbar("Video uploaded successfully", "success");
            } else {
                showSnackbar("Error uploading video", "error");
            }
        } catch (error) {
            showSnackbar("Error uploading video", "error");
        } finally {
            setMediaLoading((prevLoading) => ({ ...prevLoading, video: false }));
            setShowUploadModal(false);
        }
    };
    async function uploadImage(file, fileType = "image") {
        const formData = new FormData();
        formData.append("file", file);
        setMediaLoading((prevLoading) => ({ ...prevLoading, [fileType]: true }));

        try {
            const response = await RESTUtils.POSTIMAGE(
                RESTUtils.getAPIUrl() +
                `api/user/fileUpload/?uuid=${userData.uuid
                }&file_type=${fileType}`,
                formData
            );
            if (response.status === 201) {
                setImageHash(Date.now());
                const currentUser = SessionUtils.getCurrentUserSession();
                if (currentUser.uuid === userData.uuid && fileType === 'image') {
                    const updatedUser = {
                        ...currentUser,
                        image_url: response.data,
                        imageHash: Date.now(),
                    };

                    SessionUtils.setCurrentUserSession(updatedUser);
                }
                if (fileType === 'image') {
                    updateProfileState("image_url", response.data + '?' + Date.now());
                } else if (fileType === 'banner') {
                    updateProfileState("banner_url", response.data + '?' + Date.now());
                }

                setUploadError(false);
                showSnackbar("Image uploaded successfully", "success");
            } else {
                console.log(response.data);
                setUploadError(true);
                showSnackbar("Error uploading image", "error");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            showSnackbar("Error uploading image", "error");
            setUploadError(true);
        } finally {
            setMediaLoading((prevLoading) => ({ ...prevLoading, [fileType]: false }));
            setShowUploadModal(false);
        }
    };

    return (
        <>
            {SnackBarComponent}

            {showBenchStatusModal && (
                <BenchStatusModal
                    showBenchStatusModal={showBenchStatusModal}
                    setShowBenchStatusModal={setShowBenchStatusModal}
                    benchStatusComment={benchStatusComment}
                    setBenchStatusComment={setBenchStatusComment}
                    updateProfileAPI={updateProfileAPI}
                    tempBenchStatus={tempBenchStatus}
                    saving={saving}
                />
            )}

            <>
                <Banner
                    onEditPage={onEditPage}
                    banner_url={userData.banner_url}
                    uploadImage={uploadImage}
                    updateProfileAPI={updateProfileAPI}
                    bannerLoading={mediaLoading.banner}
                    imageHash={imageHash}
                />
                {onEditPage && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 135,
                            right: 0,
                            zIndex: 1,
                        }}
                    >
                        <IconButton onClick={handleMenuClick}>
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => window.open(`/profile/view/${userData.public_uuid}`, '_blank')}>
                                View-Only Page
                            </MenuItem>
                            <MenuItem onClick={() => window.open(`/profile/print/${userData.public_uuid}`, '_blank')}>
                                PDF View
                            </MenuItem>
                        </Menu>
                    </Box>
                )}

                <Grid container>
                    <Grid item xs={5.5}>
                        <ClientDetails userData={userData} isPrintMode={isPrintMode} sectionsOpen={sectionsOpen} setSectionsOpen={setSectionsOpen} />
                    </Grid>
                    <Grid item xs={6.5} pt={0.5} alignItems="center">
                        <SkillsPrint
                            skillData={allData.skillData}
                            experienceData={allData.experienceData}
                            isPrintMode={isPrintMode}
                        />
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        position: 'absolute',
                        top: '4ch',
                        left: '2ch',
                        zIndex: 3,
                    }}
                >
                    <ImageVideoHandler
                        image_url={userData.image_url}
                        video_url={userData.video_url}
                        isEdit={onEditPage}
                        uploadImage={uploadImage}
                        uploadVideo={uploadVideo}
                        uploadError={uploadError}
                        imageLoading={mediaLoading.image}
                        videoLoading={mediaLoading.video}
                        imgHeight='10rem'
                        imgWidth="10rem"
                        imageHash={imageHash}
                        objectFit="cover"
                        showUploadModal={showUploadModal}
                        setShowUploadModal={setShowUploadModal}
                        isPrintMode={isPrintMode}
                    />
                </Box>
            </>
        </>
    );
}
