import { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import EnrollmentRow from "./EnrollmentRow";

export default function EnrollmentTable({ allData }) {
  const [buttonLoading, setButtonLoading] = useState(false);

  const { enrollmentData, setEnrollmentData, showSnackbar } = allData;
  const headers = [
    { label: 'Job', width: '35%' },
    { label: 'Rundown', width: '5%' },
    { label: 'Client', width: '25%' },
    { label: 'Location', width: '20%' },
    { label: 'Status', width: '15%' },
  ];

  function handleRundownChange(index, event) {
    setEnrollmentData((prev) => {
      const updatedData = [...prev]; // Create a copy of the previous state array
      updatedData[index].cover_letter = event.target.value; // Update the value at the specified index
      return updatedData; // Return the updated state
    });
  }
  async function saveRundown(enrollId, rundown) {
    setButtonLoading(true);
    try {
      const rundownData = {
        rundown: rundown,
        enroll_id: enrollId,
      };
      const response = await RESTUtils.PUT(
        RESTUtils.getAPIUrl() + "api/admin/update_rundown/",
        rundownData
      );

      if (response.status === 200) {
        showSnackbar("Rundown updated successfully", "success");
      } else {
        console.log(response.data);
        showSnackbar("Something went wrong", "error");
      }
    } catch (error) {
      console.log("Error:", error);
      showSnackbar("Something went wrong", "error");
    } finally {
      setButtonLoading(false);
    }
  }

  return (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map(({ label, width }) => (
              <TableCell key={label} sx={{ width, borderBottom: '1px solid #6c6e70', py: 0.25 }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {enrollmentData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography sx={{ padding: "15px" }}>
                  No Enrollments found.
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            enrollmentData.map((enrollment, index) => (
              <EnrollmentRow
                key={index}
                index={index}
                enrollment={enrollment}
                saveRundown={saveRundown}
                handleRundownChange={handleRundownChange}
                buttonLoading={buttonLoading}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
