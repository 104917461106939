import { useState } from "react";
import { Grid, TextField, Button, CircularProgress } from "@mui/material";

import { SessionUtils } from "../../utilities/SessionUtils";
import ApiFunctions from "./APIFunctions";
import TwoButtonModal from "../modals/TwoButtonModal";
import NoteModalContent from "./NoteModalContent";
import SnackbarComponent from "../snackbar";
import Note from "./Note";
import admins from "../../data/misc/adminData";
import AdminListModal from "../modals/AdminListModal/AdminListModal";

export default function Notes2({ notes, setNotes, user_uuid, job_id = null }) {
	const [message, setMessage] = useState("");
	const [curNoteData, setCurNoteData] = useState({ message: "" });
	const [isNewNote, setIsNewNote] = useState(true);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");
	const [errorMessage, setErrorMessage] = useState("");
	const [modalErrorMessage, setModalErrorMessage] = useState("");
	const [showAdminDiaglog, setShowAdminDiaglog] = useState(false);
	const [buttonLoading, setButtonLoading] = useState({
		creating: false,
		updating: false,
		deleting: false,
	});
	const [tags, setTags] = useState([]);

	function setSnackbar(type) {
		if (type === "error") {
			setSnackbarMessage("Something went wrong");
			setSnackbarSeverity("error");
			setShowSnackbar(true);
		} else {
			setSnackbarMessage("Saved");
			setSnackbarSeverity("success");
			setShowSnackbar(true);
		}
	}
	const closeModal = () => {
		setIsNewNote(true);
		setShowModal(false);
	};
	const APIFunctions = ApiFunctions(setNotes, setSnackbar, closeModal, setMessage, setTags);
	const currentUser = SessionUtils.getCurrentUserSession();

	const openEditNoteModal = (item) => {
		setIsNewNote(false);
		setErrorMessage("");
		setCurNoteData(item);
		setShowModal(true);
	};


	function validateNote(message) {
		if (!message || message.trim().length === 0) {
			isNewNote ? setErrorMessage("Please fill all required fields.") : setModalErrorMessage("Please fill all required fields.");
		} else {
			isNewNote ? setErrorMessage("") : setModalErrorMessage("");
			isNewNote ? APIFunctions.addNote(message, user_uuid, currentUser, tags, job_id, setButtonLoading) : APIFunctions.editNote(curNoteData.id, message, setButtonLoading);
		}
	}

	function handleCloseSnackbar() {
		setShowSnackbar(false);
		setSnackbarMessage("");
	}

	const handleMessageChange = (event) => {
		const { value } = event.target;
		setMessage(value)
		const lastChar = value[value.length - 1];
		if (lastChar === '@' && value.length > message.length) {
			handleClickOpen();
		}
		// try to handle the case they deleted a tag I guess
		const updatedTags = tags.filter(tag => {
			const name = '@' + tag.first_name + ' ' + tag.last_name;
			return value.includes(name);
		});
		setTags(updatedTags);
	};

	const handleClickOpen = () => {
		setShowAdminDiaglog(true);
	};

	const handleClose = () => {
		setShowAdminDiaglog(false);
	};

	const handleListItemClick = (value) => {
		setMessage((prevMessage) => prevMessage + value.first_name + ' ' + value.last_name + ' ');
		setShowAdminDiaglog(false);
		setTags((prevTags) => [...prevTags, value]);
	};

	return (
		<Grid container justifyContent={"center"}>
			{showAdminDiaglog && (
				<AdminListModal
					admins={admins.filter(admin => !tags.some(tag => tag.id === admin.id))}
					dialogTitle={"Tag an Admin"}
					handleListItemClick={handleListItemClick}
					open={showAdminDiaglog}
					onClose={handleClose}
				/>)}

			{showModal && (
				<TwoButtonModal
					show={showModal}
					modalTitle="Edit Note"
					modalMessage={
						<NoteModalContent
							curNoteData={curNoteData}
							setCurNoteData={setCurNoteData}
							errorMessage={modalErrorMessage}
						/>
					}
					closeButtonText="Cancel"
					closeButtonCallback={closeModal}
					okButtonText='Save'
					okButtonCallback={() => validateNote(curNoteData.message)}
					okButtonLoading={buttonLoading.editing}
					deleteButton={true}
					deleteButtonCallBack={() => APIFunctions.deleteNote(curNoteData, setButtonLoading)}
					deleteButtonLoading={buttonLoading.deleting}
				/>
			)}

			<SnackbarComponent
				show={showSnackbar}
				message={snackbarMessage}
				severity={snackbarSeverity}
				positionVertical="bottom"
				positionHorizontal="center"
				autoHideDuration={5000}
				handleCloseCallback={handleCloseSnackbar}
			/>
			<Grid item xs={12} px={1} pt={2}>
				<TextField
					id="add-note"
					label="Add Note"
					name="message"
					value={message || ""}
					onChange={handleMessageChange}
					variant="outlined"
					fullWidth
					multiline
					minRows={2}
					error={errorMessage !== ""}
				/>
				Tags: {tags.map((tag, index) => `${tag.first_name + ' ' + tag.last_name}${index !== tags.length - 1 ? ',' : ''} `)}
			</Grid>
			<Grid item xs={12} px={1} py={1}>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => validateNote(message)}
					disabled={buttonLoading.creating}
					startIcon={buttonLoading.creating && <CircularProgress color="inherit" size={20} />}
				>
					{buttonLoading.creating ? "Adding" : "Add Note"}
				</Button>
			</Grid>

			{notes.map((noteData, index) => (
				<Grid container px={1} py={.75} alignItems="center" key={index}>
					<Grid item xs={12}>
						<Note
							noteData={noteData}
							onClickFn={openEditNoteModal}
						/>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}
