import { useState } from "react";
import { Grid, Button, Typography, Stack } from "@mui/material";
import emptyEducation from "./emptyEducation";
import EducationModalContent from "./EducationModalContent";
import PastEducations from "./PastEducations";
import Modal from "../../../../../components/modals/TwoButtonModal";
import { ModalHook } from "../../../../../components/modals/Hooks/hooks";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

export default function EducationTab({
	isEdit,
	allData,
	isPrintMode = false,
}) {
	const { educationData, setEducationData, userInfo, showSnackbar } = allData;
	const uuid = userInfo.uuid;
	const [curEducationData, setCurEducationData] = useState(
		emptyEducation(uuid)
	);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		"Please fill all required fields."
	);
	const [isNewEducation, setIsNewEducation] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [modalVars, setModalVars] = ModalHook();
	const [buttonLoading, setButtonLoading] = useState({
		ok: false,
		delete: false,
	});

	const closeModal = () => {
		setShowModal(false);
		setError(false);
	};

	const validateEducation = () => {
		const hasEndDate = !!curEducationData.endYear || !!curEducationData.endMonth || !!curEducationData.endDay;
		let isValid = true;
		let message = '';

		if (!curEducationData.degree || !curEducationData.organization || !curEducationData.startYear) {
			isValid = false;
			message = 'Please fill out all required fields.';
		} else if (hasEndDate && (!curEducationData.endYear)) {
			isValid = false;
			message = 'End date must include an end year.';
		}

		setError(!isValid);
		setErrorMessage(message);

		return isValid;
	};

	function getEducationData() {
		const education_data = curEducationData;
		education_data.start_date = `${curEducationData.startYear}-${curEducationData.startMonth || '01'}-${curEducationData.startDay || '01'}`;

		if (curEducationData.end_date || curEducationData.endYear || curEducationData.endMonth || curEducationData.endDay) {
			education_data.end_date = `${curEducationData.endYear}-${curEducationData.endMonth || '01'}-${curEducationData.endDay || '01'}`;
		}

		return education_data;
	}

	async function createEducation() {
		if (validateEducation()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			const education_data = getEducationData();
			try {
				const response = await RESTUtils.POST(
					RESTUtils.getAPIUrl() + "api/user/createEducation/",
					education_data
				);
				if (response.status === 201) {
					curEducationData.id = response.data;
					setEducationData((prevEducationData) => [
						...prevEducationData,
						curEducationData,
					]);

					closeModal();
					showSnackbar("Education created", "success");
				} else {
					console.log(response.data);
					showSnackbar("Something went wrong", "error");
				}
			} catch (error) {
				console.log("Error:", error);
				showSnackbar("Something went wrong", "error");
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		}
	}

	async function editEducation() {
		if (validateEducation()) {
			setButtonLoading((prevState) => ({ ...prevState, ok: true }));
			const education_data = getEducationData();
			try {
				const response = await RESTUtils.PUT(
					RESTUtils.getAPIUrl() +
					`api/user/editEducation/?q=${curEducationData.id}`,
					education_data
				);
				if (response.status === 200) {
					setEducationData((prevEducationData) => {
						const updatedIndex = prevEducationData.findIndex(
							(education) => education.id === curEducationData.id
						);
						if (updatedIndex !== -1) {
							const updatedEducationData = [...prevEducationData];
							updatedEducationData[updatedIndex] =
								curEducationData;
							return updatedEducationData;
						}

						return prevEducationData;
					});
					closeModal();
					showSnackbar("Education Updated", "success");
				} else {
					showSnackbar("Something went wrong", "error");
					setError(true);
					setErrorMessage(
						"Something went wrong. Please try again later."
					);
				}
			} catch (error) {
				console.log("Error:", error);
				showSnackbar("Something went wrong", "error");
				setError(true);
				setErrorMessage(
					"Something went wrong. Please try again later."
				);
			} finally {
				setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			}
		} else {
			setError(true);
		}
	}

	async function handleDeleteEducation() {
		setButtonLoading((prevState) => ({ ...prevState, delete: true }));
		try {
			const response = await RESTUtils.DELETE(
				RESTUtils.getAPIUrl() +
				`api/user/deleteEducation/?q=${curEducationData.id}`
			);
			if (response.status === 200) {
				closeModal();
				showSnackbar("Education deleted successfully", "success");
				setEducationData((prevEducationData) =>
					prevEducationData.filter(
						(education) => education.id !== curEducationData.id
					)
				);
			} else {
				showSnackbar("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Error deleting education:", error);
			showSnackbar("Something went wrong", "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, delete: false }));
		}
	}

	function openNewEducationModal() {
		setIsNewEducation(true);
		setCurEducationData(emptyEducation(uuid));
		setModalVars({
			modalTitle: "Add Education",
			okButtonText: "Add",
		});
		setShowModal(true);
	}

	function editEducationModal(item) {
		if (item.start_date) {
			item.startYear = item.start_date.substring(0, 4);
			item.startMonth = item.start_date.substring(5, 7);
			item.startDay = item.start_date.substring(8, 10);
		} else {
			item.startYear = "";
			item.startMonth = "";
			item.startDay = "";
		}

		if (item.end_date) {
			item.endYear = item.end_date.substring(0, 4);
			item.endMonth = item.end_date.substring(5, 7);
			item.endDay = item.end_date.substring(8, 10);
		} else {
			item.endYear = "";
			item.endMonth = "";
			item.endDay = "";
		}

		setIsNewEducation(false);
		setCurEducationData(item);
		setModalVars({
			modalTitle: "Edit Education",
			okButtonText: "Save",
		});
		setShowModal(true);
	}

	const educationContent =
		educationData.length > 0 ? (
			educationData
				.slice()
				.map((data, index) => (
					<PastEducations
						key={data.id}
						educationData={data}
						isEdit={isEdit}
						onClickFn={editEducationModal}
						addMargin={index !== educationData.length - 1}
						isPrintMode={isPrintMode}
					/>
				))
		) : (
			<Typography paddingLeft={1}>No educations found!</Typography>
		);

	return (
		<Grid container padding={isPrintMode ? 1 : 2}>
			<Grid item xs={12} pb={2} >
				<Stack direction={'row'} alignItems={'center'} spacing={1}>
					{/* <HistoryEduIcon /> */}
					<Typography variant="h6">Degrees / Diplomas</Typography>
				</Stack>
			</Grid>
			{isEdit && (
				<Grid item xs={12} paddingBottom={2}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={openNewEducationModal}
					>
						Add Education
					</Button>
				</Grid>
			)}

			<Modal
				show={showModal}
				modalTitle={modalVars.modalTitle}
				modalMessage={
					<EducationModalContent
						educationData={curEducationData}
						setEducationData={setCurEducationData}
						error={error}
						errorMessage={errorMessage}
						isEdit={isEdit}
					/>
				}
				closeButtonText="Cancel"
				closeButtonCallback={closeModal}
				okButtonText={modalVars.okButtonText}
				okButtonCallback={
					isNewEducation ? createEducation : editEducation
				}
				deleteButton={!isNewEducation}
				deleteButtonCallBack={handleDeleteEducation}
				okButtonLoading={buttonLoading.ok}
				deleteButtonLoading={buttonLoading.delete}
				maxWidth="sm"
				onClickOff={isNewEducation ? undefined : () => { }}
			/>

			<Grid item xs={12}>
				{educationContent}
			</Grid>
		</Grid>
	);
}
