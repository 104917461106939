import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { years, months, days } from '../../experiences/experiencesModal/details/data';

export default function EducationModalContent({
	setEducationData,
	educationData,
	error,
	errorMessage,
}) {
	const handle_change = (event) => {
		const { name, value } = event.target;
		setEducationData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	function handleDateChange(event) {
		const { name, value } = event.target;
		setEducationData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}
	return (
		<>
			<form>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label="Degree / Diploma"
							name="degree"
							autoFocus
							value={educationData.degree || ""}
							onChange={handle_change}
							fullWidth
							error={
								error &&
								(!educationData.degree ||
									educationData.degree.trim().length === 0)
							}
							required
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<TextField
							label="Institution" // Changed from "Organization" as per Orrin's request
							name="organization"
							value={educationData.organization || ""}
							error={
								error &&
								(!educationData.organization ||
									educationData.organization.trim().length ===
									0)
							}
							onChange={handle_change}
							fullWidth
							required
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<TextField
							label="Field of Study"
							name="field_of_study"
							value={educationData.field_of_study || ""}
							onChange={handle_change}
							fullWidth
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={12} sx={{ mt: -1 }}>
							<Typography variant="subtitle1" >Start Date</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Year"
								value={educationData.startYear}
								required
								name="startYear"
								error={error && !educationData.startYear}
								onChange={handleDateChange}
							>
								{years.map((year) => (
									<MenuItem key={year} value={year}>
										{year}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Month"
								value={educationData.startMonth}
								onChange={handleDateChange}
								name="startMonth"
							>
								{months.map((month) => (
									<MenuItem key={month.value} value={month.value}>
										{month.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Day"
								value={educationData.startDay}
								onChange={handleDateChange}
								name="startDay"
							>
								{days.map((day) => (
									<MenuItem key={day} value={day < 10 ? `0${day}` : `${day}`}>
										{day}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={12} sx={{ mt: -1 }}>
							<Typography variant="subtitle1">End Date</Typography>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Year"
								value={educationData.endYear}
								name="endYear"
								onChange={handleDateChange}
							>
								{years.map((year) => (
									<MenuItem key={year} value={year}>
										{year}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Month"
								value={educationData.endMonth}
								onChange={handleDateChange}
								name="endMonth"
							>
								{months.map((month) => (
									<MenuItem key={month.value} value={month.value}>
										{month.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								label="Day"
								value={educationData.endDay}
								onChange={handleDateChange}
								name="endDay"
							>
								{days.map((day) => (
									<MenuItem key={day} value={day < 10 ? `0${day}` : `${day}`}>
										{day}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</Grid>
			</form >
			{error && <Typography color="error">{errorMessage}</Typography>
			}
		</>
	);
}
