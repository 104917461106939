import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

export default function SelfDeclarationsForm({ formData, setFormData }) {
    return (
        <Box display="inherit">
            <Typography
                variant="h6"
                component="div"
                paddingBottom={2}
                textAlign="center"
            >
                Enter any self-declarations. You may proceed if none apply.
            </Typography>

            <Grid item xs={12} justifyContent="left" textAlign="left">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.woman}
                            onChange={(e) => setFormData({ ...formData, woman: e.target.checked })}
                            sx={{
                                color: "primary.contrastText",
                                "&.Mui-checked": {
                                    color: "primary.contrastText",
                                },
                            }}
                        />}
                    label="Female"
                />
            </Grid>

            <Grid item xs={12} justifyContent="left" textAlign="left">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.is_visible_minority}
                            onChange={(e) => setFormData({ ...formData, visible_minority: e.target.checked })}
                            sx={{
                                color: "primary.contrastText",
                                "&.Mui-checked": {
                                    color: "primary.contrastText",
                                },
                            }}
                        />}
                    label="Visible Minority"
                />
            </Grid>

            <Grid item xs={12} justifyContent="left" textAlign="left">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.indigenous}
                            onChange={(e) => setFormData({ ...formData, indigenous: e.target.checked })}
                            sx={{
                                color: "primary.contrastText",
                                "&.Mui-checked": {
                                    color: "primary.contrastText",
                                },
                            }}
                        />}
                    label="Indigenous"
                />
            </Grid>

            <Grid item xs={12} justifyContent="left" textAlign="left">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.disability}
                            onChange={(e) => setFormData({ ...formData, disability: e.target.checked })}
                            sx={{
                                color: "primary.contrastText",
                                "&.Mui-checked": {
                                    color: "primary.contrastText",
                                },
                            }}
                        />}
                    label="Person with Disability"
                />
            </Grid>

            <Grid item xs={12} justifyContent="left" textAlign="left">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.prefer_not_to_say}
                            onChange={(e) => setFormData({ ...formData, prefer_not_to_say: e.target.checked })}
                            sx={{
                                color: "primary.contrastText",
                                "&.Mui-checked": {
                                    color: "primary.contrastText",
                                },
                            }}
                        />}
                    label="Prefer Not to Say"
                />
            </Grid>
        </Box>
    )
}