import { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  Stack,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import Profile from "./Profile";
import ExperiencesTab from "./ExperiencesTab";
import PastProject from "../../../../profile/components/notableProjects/PastProject";
import EducationParent from "../../../../profile/components/educations/EducationParent";
import AwardTable from "../../../../profile/components/awards/AwardTab";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";
import NoteModal from "../noteModal/NoteModal";
import InterviewModal from "../interviewModal/interviewModal";
import SpotlightActions from "./SpotlightActions";
import ImageVideoHandler from "../../../../../components/image/imageVideoHandler/ImageVideoHandler";
import ThreeDSliders from "../../../../../components/projectStrengths/3DPersona/ThreeDSliders";
import EightStageBadges from "../../../../../components/projectStrengths/eightStage/EightStageBadges";
import Banner from "../../../../profile/components/topBox/Banner";
import CandidateDetails from "./CandidateDetails";

export default function Spotlight({
  candidateData,
  selectedTab,
  handleTabChange,
  changeUserStatus,
  setCandidateData,
  user,
  isMobile,
  numCandidates
}) {
  const [menuOpen, setMenuOpen] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const user_uuid = candidateData.user_data.user;
  const tabs = [
    "Profile",
    "Experience",
    "Projects",
    "Education",
    "Awards",
  ];

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }

  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const pastProjects =
    candidateData?.notable_projects.length > 0 ? (
      candidateData?.notable_projects.map((data, index) => (
        <Grid item xs={12} py={0.5} key={index}>
          <PastProject
            key={index}
            projectData={data}
            isEdit={false}
            addMargin={false}
          />
        </Grid>
      ))
    ) : (
      <Typography sx={{ paddingLeft: 1 }}>No projects found.</Typography>
    );

  const contentMap = [
    <Profile candidateData={candidateData} />,
    <ExperiencesTab experiences={candidateData?.experiences} skills={candidateData?.skills} />,
    pastProjects,
    <EducationParent
      allData={
        { userInfo: { user: {} }, 'educationData': candidateData.educations, 'certificateData': candidateData.certifications }}
      isEdit={false}
      fromClientPortal={true}
    />,
    <AwardTable allData={{ 'awardData': candidateData?.awards }} isEdit={false} />,
  ];

  function handleViewProfile() {
    const url = `${window.location.origin}/profile/view/${user_uuid}`;
    BrowserUtils.openNewTab(url);
  }

  return (
    <>
      <InterviewModal
        users={user}
        showModal={showInterviewModal}
        setShowModal={setShowInterviewModal}
        fromSpotlight={true}
      />
      <Grid container alignItems="center" padding={1.5}>
        <TwoButtonModal
          show={showModal}
          modalTitle={"Notes"}
          modalMessage={
            <NoteModal
              noteData={candidateData.notes}
              enrollID={user.id}
              setCandidateData={setCandidateData}
              userUUID={user_uuid}
            />
          }
          closeButtonCallback={closeModal}
          onClickOff={() => { }}
        // okButtonText={"Close"}
        // okButtonCallback={closeModal}
        />

        <Grid
          item
          xs={12}
          container
          // justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8} >
            <Grid item xs={9} display="flex" alignItems="center">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: '1.5em',
                  height: '3em',
                  alignContent: 'center',
                  alignItems: "center",
                  // width: 'fit-content'
                }}
              >
                {candidateData?.user_data?.first_name} {candidateData?.user_data?.last_name}
              </Typography>
              {/* {candidateData.user_data.experience_level && (

                <Chip
                  label={candidateData.user_data.experience_level}
                  size="small"
                  sx={{
                    backgroundColor: getChipColor(candidateData.user_data.experience_level),
                    color: 'white',
                    ml: 1,
                  }}
                />
              )} */}
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <SpotlightActions
              handleViewProfile={handleViewProfile}
              candidateData={candidateData}
              openModal={openModal}
              user={user}
              setShowInterviewModal={setShowInterviewModal}
              changeUserStatus={changeUserStatus}
              user_uuid={user_uuid}
            />
          </Grid>
        </Grid>


        <Grid item xs={12} pb={1.5}>
          <Divider />
        </Grid>

        <Grid container item xs={12} justifyContent="space-between" alignItems="center" spacing={1} style={{ position: 'relative' }}>
          <Grid item xs={12} py={1}>
            <Banner onEditPage={false} roundEdges={false} banner_url={candidateData?.user_data?.banner_url} />
          </Grid>
          <Grid item container height={'17ch'}>
            <Grid
              item
              xs={4}
            // height={'17ch'}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '6ch',
                  left: '2ch',
                  zIndex: 3,
                }}
              >
                <ImageVideoHandler
                  image_url={candidateData?.user_data?.image_url}
                  video_url={candidateData?.user_data?.video_url}
                  isEdit={false}
                  imgHeight={'9rem'}
                  imgWidth={'9rem'}
                  objectFit="cover"
                />
              </Box>
              <CandidateDetails user_data={candidateData.user_data} fromCP={true} />
            </Grid>
            <Grid item xs={candidateData?.personality?.eight_stage ? 4 : 8} pl={1}>
              {candidateData?.personality?.personality && (
                <ThreeDSliders useMobile={true} threeDData={candidateData?.personality?.personality} />
              )}
            </Grid>
            {candidateData?.personality?.eight_stage && (
              <Grid item xs={4} container alignItems={'center'}>
                <EightStageBadges useMobile={isMobile} fromCP={true} eightStageData={candidateData?.personality?.eight_stage} numCandidates={numCandidates} />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12} pt={1} justifyContent={isMobile ? 'left' : "center"}>
          {isMobile ?
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  paddingTop: 0.4,
                  paddingLeft: 3,
                }}
              >
                {tabs[selectedTab]}
              </Typography>

              <Menu
                anchorEl={menuOpen}
                open={Boolean(menuOpen)}
                onClose={handleMenuClose}
              >
                {tabs?.map((label, idx) => (
                  <MenuItem
                    key={idx}
                    selected={idx === selectedTab}
                    onClick={(event) => {
                      handleTabChange(event, idx);
                      setMenuOpen(null);
                    }}                  >
                    <Stack direction="row" alignItems="center">
                      <ListItemText
                        primary={label}
                      />
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
            </>
            :
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              sx={{
                mt: -1
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  sx={{
                    fontSize: '1.1rem',
                    minWidth: 0,
                  }}
                />
              ))}
            </Tabs>
          }
          <Grid item xs={12} pt={1.5}>
            {contentMap[selectedTab]}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
