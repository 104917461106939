import { useState } from "react";
import {
    Chip,
    Typography,
    Stack,
    Tooltip,
    IconButton
} from "@mui/material";
import fullAdminList from '../../../../../data/misc/adminData';
import AddIcon from '@mui/icons-material/Add';
import AdminListModal from '../../../../../components/modals/AdminListModal/AdminListModal';
import { RESTUtils } from "../../../../../utilities/RESTUtils";

const JobAdminsComponent = ({ jobDetails, setJobDetails }) => {
    const [showAdminDiaglog, setShowAdminDiaglog] = useState(false);

    const handleRemoveAdmin = (admin) => {
        const adminIDtoRemove = admin.id;
        const response = changeJobAdmin(jobDetails.uuid, adminIDtoRemove, 'remove');
        if (response) {
            setJobDetails((prevJobDetails) => ({
                ...prevJobDetails,
                admins: prevJobDetails.admins.filter((admin) => admin.id !== adminIDtoRemove),
            }));
        }
    };

    function handleAddAdmin(admin) {
        try {
            const response = changeJobAdmin(jobDetails.uuid, admin.id, 'add');
            if (response) {
                setJobDetails((prevJobDetails) => ({
                    ...prevJobDetails,
                    admins: [...prevJobDetails.admins, admin],
                }));
                setShowAdminDiaglog(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    async function changeJobAdmin(job_uuid, admin_id, action) {
        const response = await RESTUtils.PUT(RESTUtils.getAPIUrl() + 'api/admin/change_job_admins/',
            {
                'job_uuid': job_uuid,
                'admin_id': admin_id,
                'action': action
            });
        if (response.status === 200) {
            return response;
        } else {
            return false;
        }

    }
    const handleAddButtonClick = () => {
        setShowAdminDiaglog(true);
    };
    const handleClose = () => {
        setShowAdminDiaglog(false);
    };
    return (
        <div>
            {showAdminDiaglog && (
                <AdminListModal
                    admins={fullAdminList.filter(admin => !jobDetails.admins.some(jobAdmin => jobAdmin.id === admin.id))}
                    dialogTitle={"Add an admin"}
                    handleListItemClick={handleAddAdmin}
                    open={showAdminDiaglog}
                    onClose={handleClose}
                />)}
            <Stack direction="row" spacing={1} >
                <Typography variant="body1" pt={'5px'} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',

                }}> <strong>Admins:</strong></Typography>
                <div>

                    {jobDetails.admins?.map((admin) => (
                        <Tooltip title="Remove" placement="top" key={admin.uuid}>
                            <Chip
                                label={admin.first_name + ' ' + admin.last_name}
                                variant="outlined"
                                onClick={() => handleRemoveAdmin(admin)}
                                style={{ marginRight: '8px' }}
                            />
                        </Tooltip>
                    ))}
                    <Tooltip title="Add" placement="top">
                        <IconButton variant="contained" onClick={handleAddButtonClick}>
                            <AddIcon style={{ marginRight: '8px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Stack>
        </div>
    );
};

export default JobAdminsComponent;
