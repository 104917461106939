import { SimplePaletteColorOptions } from "@mui/material";

export interface CustomPaletteExtensions {
    header_footer_background: SimplePaletteColorOptions;
    header_footer_font_color: SimplePaletteColorOptions;
    header_footer_hover_button_color: SimplePaletteColorOptions;
    company_logo_url: string;
}

export const AvailableTheme = {
    Default: "default",
    Dark: 'dark',
    Light: "light",
    Stzuminus: "stzuminus",
};