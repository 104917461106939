import { Grid, Typography } from "@mui/material";

export default function BottomPage({ profileData }) {
    return (
        <div style={{ height: '10.8in' }}>
            <Grid container justifyContent={'space-between'}>
                <Grid item xs={3.5}>
                    <Typography variant={'h6'}>Bottom Page Left</Typography>
                </Grid>
                <Grid item xs={7.5}>
                    <Typography variant={'h6'}>Bottom Page Right</Typography>
                </Grid>
            </Grid>
        </div>
    )
}