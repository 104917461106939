import { useState } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import TwoButtonModal from '../../../../../components/modals/TwoButtonModal';
import AnnouncementRow from './AnnouncementRow';
import DOMPurify from "dompurify";

export default function Announcements({ announcements }) {
    const [showModal, setShowModal] = useState(false);
    const [curAnnouncement, setCurAnnouncement] = useState({});

    // Replace newline characters with <br /> tags
    const formattedBody = curAnnouncement?.body?.replace(/\n/g, '<br />');

    return (
        <>
            {showModal &&
                <TwoButtonModal
                    show={showModal}
                    modalTitle={"View Announcement"}
                    modalMessage={
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(formattedBody),
                            }}
                        />
                    }
                    closeButtonCallback={() => { setShowModal(false) }}
                    onClickOff={() => { setShowModal(false) }}
                />
            }

            <Typography variant="h6" sx={{ p: 1.5, }}>
                Announcements
            </Typography>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid container padding={0} sx={{ overflowY: 'auto' }}>
                {announcements &&
                    announcements.map((announcement, index) => (
                        <Grid item xs={12} key={index}>
                            <AnnouncementRow announcement={announcement} index={index} setCurAnnouncement={setCurAnnouncement} setShowModal={setShowModal} />
                        </Grid>
                    ))}
                {announcements.length === 0 && (
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ p: 1.5 }}>
                            No announcements found.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};


