import { useState, useRef, useEffect, useContext } from 'react';
import { Divider, Typography, IconButton } from "@mui/material"
import { TemplateContext } from '../..';
import Entry from '../Entry';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';


export default function LeftColumn() {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const divRef = useRef(null);
    const { templateData, setTemplateData } = useContext(TemplateContext);
    function checkOverflow() {
        if (divRef.current) {
            const { scrollHeight } = divRef.current;
            setIsOverflowing(scrollHeight > 959);
        }
    };
    const sections = ['awards', 'certifications', 'educations']
    useEffect(() => {
        const checkOverflowAndResize = () => {
            checkOverflow();
            window.addEventListener('resize', checkOverflow);
        };

        checkOverflowAndResize();
        return () => window.removeEventListener('resize', checkOverflow);
    }, [templateData.certifications.entries, templateData.awards, templateData.educations]);
    function toggleSectionVisibility(section) {
        setTemplateData((prevData) => {
            return {
                ...prevData,
                [section]: {
                    ...prevData[section],
                    visible: !prevData[section].visible
                }
            };
        });
    }
    function addEntry(section) {
        setTemplateData((prevData) => {
            return {
                ...prevData,
                [section]: {
                    ...prevData[section], // Spread the existing data of the section
                    entries: [
                        ...prevData[section].entries, // Spread existing entries
                        {
                            visible: true,
                            title: {
                                visible: true,
                                text: 'New Title'
                            },
                            details: {
                                visible: true,
                                text: 'New Details'
                            },
                            description: {
                                visible: true,
                                text: 'New Description'
                            }

                        }
                    ]
                }
            };
        });
    }
    return (
        <div style={{ border: isOverflowing ? '2px solid red' : '2px solid green', padding: '5px', minHeight: '10.2in' }}>
            <div ref={divRef} style={{ border: '2px solid black' }}>
                {sections.map((section, index) => (
                    templateData[section].visible && (
                        <div key={index}>
                            <Typography variant="h6" color={'primary.main'} sx={{ lineHeight: 1.2, fontWeight: 'bold', mb: 2 }}>
                                {section}
                                <IconButton color="primary" aria-label="add" onClick={() => addEntry(section)}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton color="primary" aria-label="toggle" onClick={() => toggleSectionVisibility(section)}>
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </Typography>
                            {templateData[section].entries.map((entry, idx) => (
                                entry.visible && (
                                    <Entry key={idx} entryData={entry} section={section} index={idx} />
                                )
                            ))}
                        </div>
                    )
                ))}
            </div>
        </div>

    )
}