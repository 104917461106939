import { Grid, Typography, Container, Paper, Box } from "@mui/material";
import SnackbarComponent from "../../../components/snackbar";
import TwoButtonModal from "../../../components/modals/TwoButtonModal";
import PasswordResetModalContent from "./PasswordResetModalContent";
import SignInForm from "./SignInForm";
import { useContext } from "react";
import { LoginContext } from ".";

export default function LoginMobile() {

    const { closeModal, showSnackbar, snackbarMessage, snackbarSeverity, handleCloseSnackbar,
        showModal, recoveryEmailSuccess, handleResetPassword } = useContext(LoginContext);
    return (
        <>
            <SnackbarComponent
                show={showSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                positionVertical="top"
                positionHorizontal="center"
                autoHideDuration={5000}
                handleCloseCallback={handleCloseSnackbar}
            />
            <TwoButtonModal
                show={showModal}
                modalTitle={recoveryEmailSuccess ? "Email Sent" : "Forgot Password?"}
                modalMessage={
                    <PasswordResetModalContent />
                }
                closeButtonCallback={closeModal}
                okButtonText={recoveryEmailSuccess ? "Exit" : "Submit"}
                okButtonCallback={recoveryEmailSuccess ? closeModal : handleResetPassword}
            />

            <Grid container alignItems="center" py={2}>
                <Grid item xs={12} >
                    <Container component="main">
                        <Paper
                            elevation={6}
                            sx={{
                                p: { xs: 1 },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography>
                                <SignInForm />
                            </Box>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}