import React from "react";
import { Button, Divider } from "@mui/material";
import { styled } from "@mui/system";
import RefreshButton from "./RefreshButton";

const StyledButton = styled(Button)(({ selected }) => ({
	borderRadius: 3,
	minWidth: "80px",
	whiteSpace: "nowrap",
	"&.searchButton": {
		minWidth: "120px",
		marginRight: "auto",
	},
	"&.grayBackground": {
		backgroundColor: "gray",
	},
}));

const bucketLabels = {
	search: "Search",
	unknown: "Unknown",
	international: "International",
	disqualified: "Disqualified",
	sourced: "Sourced",
	applied: "Applied",
	expressed_interest: "Expressed Interest",
	short_list: "Short List",
	confirm_interest: "Confirm Interest",
	on_client_portal: "On Client Portal",
};

export default function BucketTopBar({
	bucketCount,
	selected,
	setterFn,
	setSelectedUser,
	setLoading,
	loadedBuckets,
	setSearchText,
	bucketLoading,
	get_new_workable_candidates,
}) {
	const handleBucketClick = (label) => {
		if (label !== selected) {
			setterFn(label);
			if (label !== "Search" && !loadedBuckets.includes(label)) {
				setLoading(true);
			}
			if (label === "Search") {
				setSelectedUser("");
			}
		}
		setSearchText("");
	};

	return (
		<div style={{ display: "flex" }}>
			{/* Search + Refresh */}
			<div style={{ display: "flex", marginRight: "auto" }}>
				<StyledButton
					variant="outlined"
					color="secondary"
					size="small"
					className={`searchButton ${selected === "Search" ? "grayBackground" : ""}`}
					onClick={() => handleBucketClick("Search")}
				// disabled={!bucketCount.search}
				>
					Search
				</StyledButton>
				<RefreshButton bucketLoading={bucketLoading} get_new_workable_candidates={get_new_workable_candidates} />
			</div>

			{/* Other Buttons */}
			{Object.entries(bucketLabels).map(([key, label], index, array) => (
				label !== "Search" && (
					<React.Fragment key={key}>
						<StyledButton
							variant="outlined"
							color="secondary"
							size="small"
							className={`${selected === label ? "grayBackground" : ""}`}
							onClick={() => handleBucketClick(label)}
							disabled={!bucketCount[key]}
						>
							{label} {bucketCount[key] ? `(${bucketCount[key]})` : ""}
						</StyledButton>
						{index < array.length - 1 &&
							!bucketCount[key] &&
							!bucketCount[array[index + 1][0]] && (
								<Divider orientation="vertical" flexItem />
							)}
					</React.Fragment>
				)
			))}
		</div>
	);
}
