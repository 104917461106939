const dataGridStyle = {
    "& .MuiDataGrid-withBorderColor": {
        border: "0px solid #c60c30",
    },
    "& .MuiDataGrid-row": {
        border: "0px solid #6c6e70",
        backgroundColor: 'transparent',
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: 'transparent'
    },
    "& .MuiDataGrid-row:first-of-type": {
        borderTop: "1px solid #6c6e70",
    },
    "&.MuiDataGrid-root": {
        border: "none",
        height: '585px'
    },
    "& .MuiDataGrid-overlay": {
        backgroundColor: 'transparent',
    },
}

export default dataGridStyle