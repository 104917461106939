import { Grid } from "@mui/material";
import EducationTab from "./educations/EducationTab";
import CertificateTab from "./certificates/CertificateTab";
import CertificateTab2 from "./certificates/CertificateTab2";

export default function EducationParent({
	isEdit,
	allData,
	fromClientPortal = false,
	isPrintMode = false
}) {
	return (
		<Grid container>
			<Grid item xs={12} md={fromClientPortal ? 12 : 6}>
				<EducationTab
					isEdit={isEdit}
					allData={allData}
					isPrintMode={isPrintMode}
				/>
			</Grid>
			<Grid item xs={12} md={fromClientPortal ? 12 : 6}>
				{/* <CertificateTab
					isEdit={isEdit}
					allData={allData}
					fromClientPortal={fromClientPortal}
				/> */}
				<CertificateTab2
					isEdit={isEdit}
					allData={allData}
					isPrintMode={isPrintMode}
				/>
			</Grid>
		</Grid>
	);
}
