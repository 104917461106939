import {
	Typography,
	TextField,
	Autocomplete,
	CircularProgress,
	Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import NewMessagesTable from "./NewMessagesTable";
import QuickSearchIcons from "./QuickSearchIcons";
import AddAnnouncement from "../addAnnouncement/AddAnnouncements";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";
import { SnackBarComponent2 } from "../../../../../components/snackbar/SnackBarComponent2";
import MessageTab from "../../../../../components/communication/messages/MessageTab";

export default function UserQuickSearch() {
	const [userSearchLoading, setUserSearchLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(true);
	const [messagesLoading, setMessagesLoading] = useState(true); // for selectedUser loading
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedOption, setSelectedOption] = useState(null);
	const [open, setOpen] = useState(false);
	const [userSearchRowData, setUserSearchRowData] = useState([]);
	const [showSendEmail, setShowSendEmail] = useState(false);
	const [unreadMessages, setUnreadMessages] = useState([]);
	const [curUserMessages, setCurUserMessages] = useState([]);
	const [showAddAnnouncement, setShowAddAnnouncement] = useState(false);
	const { SnackBarComponent, showSnackbar } = SnackBarComponent2();
	const [searchInternational, setSearchInternational] = useState(false);

	const fetchData = async (cleared = false) => {
		setUserSearchLoading(true);
		try {
			let response;
			if (cleared) {
				response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() + `api/admin/CandidateQuickSearch/?int=${searchInternational}`
				);
			} else {
				response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() +
					`api/admin/CandidateQuickSearch/?q=${searchQuery}&int=${searchInternational}`
				);
			}
			setUserSearchRowData(response.data);
		} catch (error) {
			console.error("Error fetching search results:", error);
		}
		setUserSearchLoading(false);
	};


	async function getUnreadMessages() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() + "api/admin/get_unread_messages/"
			);
			if (response.status === 200) {
				setUnreadMessages(response.data.emails);
				setTableLoading(false);
			}
		} catch (error) {
			console.error("Error fetching new emails:", error);
		}
	}

	async function getUserEmails(user, type) {
		setMessagesLoading(true);
		try {
			const queryParam = type === 'email' ? `user_email=${user}` : `user_uuid=${user}`;
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
				`api/admin/get_user_emails/?${queryParam}`
			);
			if (response.status === 200) {
				console.log(response.data.emails);
				setCurUserMessages(response.data.emails);
				setSelectedOption((prev) => ({
					...prev,
					image_url: response.data.image_url,
				}));
				setMessagesLoading(false);
			}
		} catch (error) {
			console.error("Error fetching new emails:", error);
		}
		setMessagesLoading(false);
	}
	useEffect(() => {
		fetchData();
	}, [searchQuery, searchInternational]);

	useEffect(() => {
		getUnreadMessages();
	}, [])
	const handleClearClick = () => {
		setShowSendEmail(false);
		setSearchQuery("");
		setSelectedOption(null);
	};

	const handleTableRowClick = (rowData) => {
		setMessagesLoading(true);
		getUserEmails(rowData.sender.emailAddress.address, 'email');
		setSearchQuery('test');
		setSelectedOption(rowData);
	};

	function UserSearchChange(value) {
		setSelectedOption(value);
		if (value?.uuid) {
			getUserEmails(value.uuid, 'uuid');
		}
	}

	return (
		<>
			{SnackBarComponent}
			{showAddAnnouncement &&
				<TwoButtonModal
					show={showAddAnnouncement}
					modalTitle={"Add Announcement"}
					modalMessage={<AddAnnouncement
						closeFn={() => { setShowAddAnnouncement(false) }}
						showSnackbar={showSnackbar}
					/>}
					closeButtonCallback={() => { setShowAddAnnouncement(false) }}
					onClickOff={() => { setShowAddAnnouncement(false) }}
				/>
			}

			<div style={{ display: "flex", marginBottom: "10px" }}>
				<Autocomplete
					id="user-search"
					size="small"
					sx={{
						width: "40%",
						paddingLeft: "15px",
						paddingTop: "10px",
					}}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					getOptionLabel={(option) =>
						option.name || ""
					}
					options={userSearchRowData}
					loading={userSearchLoading}
					value={selectedOption}
					onChange={(event, value) => UserSearchChange(value)}
					onInputChange={(event, value) => setSearchQuery(value)}
					renderOption={(props, option) => (
						<li {...props} key={option.uuid}>
							<Typography>
								{option.name}
							</Typography>
						</li>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Search Candidates"
							fullWidth
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{userSearchLoading && (
											<CircularProgress
												color="secondary"
												size={20}
											/>
										)}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>


				<QuickSearchIcons
					selectedOption={selectedOption}
					showSendEmail={showSendEmail}
					setShowSendEmail={setShowSendEmail}
					handleClearClick={handleClearClick}
					searchInternational={searchInternational}
					setSearchInternational={setSearchInternational}
				/>

				<Button
					variant="outlined"
					size="small"
					color="secondary"
					onClick={() => { setShowAddAnnouncement(!showAddAnnouncement) }}
					sx={{
						marginTop: '8px',
						display: 'block',
						marginRight: 1,
						marginLeft: 'auto',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				>
					Create Announcement
				</Button>
			</div>

			<div style={{ height: 300, overflowY: 'auto' }}>
				{!selectedOption && (
					<NewMessagesTable
						tableLoading={tableLoading}
						unreadMessages={unreadMessages}
						handleTableRowClick={handleTableRowClick}
					/>
				)}

				{selectedOption && (
					<MessageTab
						emailData={curUserMessages}
						setEmailData={setCurUserMessages}
						user={selectedOption}
						emailLoading={messagesLoading}
						showSnackbar={showSnackbar}
						isSendingNewEmail={showSendEmail}
						setIsSendingNewEmail={setShowSendEmail}
						fromAD={true}
					/>

				)}
			</div>
		</>
	);
}
