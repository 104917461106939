import { useState, useEffect, useCallback, useMemo } from "react";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { StandardSearchCriteriaHook } from "./data/criteriaData";

export function useCandidateSearch(job_uuid, bucket, setResultCount) {
  const [candidates, setCandidates] = useState({
    unknown: [],
    international: [],
    disqualified: [],
    sourced: [],
    applied: [],
    expressed_interest: [],
    short_list: [],
    confirm_interest: [],
    on_client_portal: [],
  });
  const [bucketCount, setBucketCount] = useState({});
  const [leftLoading, setLeftLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [keyWords, setKeyWords] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedBucket, setSelectedBucket] = useState(bucket || "Search");
  const standardSearchCriteria = StandardSearchCriteriaHook();
  const [jobDetails, setJobDetails] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [bucketLoading, setBucketLoading] = useState(false);
  const [activeJobs, setActiveJobs] = useState([]);
  const bucketLabels = {
    international: "International",
    unknown: "Unknown",
    search: "Search",
    sourced: "Sourced",
    applied: "Applied",
    expressed_interest: "Expressed Interest",
    short_list: "Short List",
    confirm_interest: "Confirm Interest",
    on_client_portal: "On Client Portal",
  };
  const keyWordsChanged = useMemo(() => keyWords, [keyWords]);
  const pageChanged = useMemo(() => curPage, [curPage]);
  const filtersChanged = useMemo(
    () => standardSearchCriteria,
    [standardSearchCriteria]
  );
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };


  const searchFn = useCallback(async () => {
    setLoading(true);
    setSelectedUser("");
    const filteredCriteria = standardSearchCriteria
      .filter(({ array }) => array.length > 0)
      .reduce((result, { name, array }) => {
        result[name] = array;
        return result;
      }, {});

    if (keyWords.length > 0) {
      filteredCriteria.key_words = keyWords;
    }
    if (isEmptyObject(filteredCriteria)) {
      setLoading(false);
      setResultCount(0);
      setSearchResults([]);
      setCurPage(1);
      return;
    }
    filteredCriteria.page = curPage;
    filteredCriteria.job_uuid = job_uuid;
    const queryParams = new URLSearchParams(filteredCriteria).toString();

    const response = await RESTUtils.GET(
      RESTUtils.getAPIUrl() + `api/admin/candidate_search/?${queryParams}`
    );
    setResultCount(response.data.count);
    if (response.data.count < 5) {
      setCurPage(1);
    }
    setSearchResults(response.data.data);
    setLoading(false);
  }, [standardSearchCriteria, keyWords, curPage]);

  function clearAllFilter() {
    standardSearchCriteria.forEach((criterion) => criterion.setterFn([]));
    setKeyWords([]);
  }
  async function fetchActiveJobs() {

    try {
      const response = await RESTUtils.GET(
        RESTUtils.getAPIUrl() +
        `api/admin/get_all_active_jobs/`
      );
      if (response.status === 200) {
        setActiveJobs(response.data);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }

  }
  async function getJobDetails() {
    try {
      const response = await RESTUtils.GET(
        RESTUtils.getAPIUrl() +
        `api/admin/get_job_details/?job_uuid=${job_uuid}`
      );

      setJobDetails(response.data.job_data);
      setBucketCount(response.data.bucket_count);
    } catch (error) {
      console.error("Error fetching job details:", error);
    } finally {
      setInitialLoading(false);
    }
  }
  async function get_new_workable_candidates() {
    try {
      setBucketLoading(true);
      const response = await RESTUtils.GET(
        RESTUtils.getAPIUrl() + `api/admin/get_new_workable_candidates/?job_uuid=${job_uuid}`
      );
      if (response.status === 200) {
        setBucketCount(response.data);
      } else {
        // setBucketCount(tempBucketCount);
        console.error("Error fetching new workable candidates:", response);
      }
    } catch (error) {
      console.error("Error fetching new workable candidates:", error);
    } finally {
      setBucketLoading(false);
    }
  }

  // function addEmptyArrays() {
  //   setCandidates((prevCandidates) => {
  //     const candidatesArray = { ...prevCandidates };
  //     Object.keys(bucketLabels).forEach((key) => {
  //       if (!candidatesArray.hasOwnProperty(key)) {
  //         candidatesArray[key] = [];
  //       }
  //     });
  //     return candidatesArray;
  //   });
  // }

  async function getCandidatesFromBucket(bucketString) {
    setLoading(true);
    // setLeftLoading(true)
    const bucket = bucketString.replace(/\s+/g, "_").toLowerCase();
    try {
      const response = await RESTUtils.GET(
        RESTUtils.getAPIUrl() +
        `api/admin/get_bucket_candidates/?job_uuid=${job_uuid}&bucket=${bucket}`
      );
      if (response.status === 200) {
        setCandidates((prevState) => ({
          ...prevState,
          [bucket]: [...new Set([...prevState[bucket], ...response.data])].filter(
            (user, index, self) =>
              index === self.findIndex((u) => u.user === user.user)
          ),
        }));

      }

    } catch (error) {
      console.error("Error fetching job candidates:", error);
    } finally {
      // addEmptyArrays();
      // setLoading(false);
      // setLeftLoading(false)
    }
  }
  useEffect(() => {
    searchFn();
  }, [keyWordsChanged, filtersChanged, pageChanged]);

  useEffect(() => {
    fetchActiveJobs();
    getJobDetails();
    // getBucketCandidates();
  }, []);

  return {
    candidates,
    bucketCount,
    loading,
    setLoading,
    getCandidatesFromBucket,
    keyWords,
    selectedUser,
    selectedBucket,
    standardSearchCriteria,
    jobDetails,
    setJobDetails,
    searchResults,
    setSearchResults,
    searchFn,
    clearAllFilter,
    setSelectedBucket,
    setSelectedUser,
    setKeyWords,
    setBucketCount,
    initialLoading,
    leftLoading,
    setLeftLoading,
    setCandidates,
    curPage,
    setCurPage,
    get_new_workable_candidates,
    bucketLoading,
    activeJobs,
  };
}
