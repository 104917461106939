import { Grid, Box, Typography, Paper, Chip, useMediaQuery, useTheme } from "@mui/material";
import EightStageGraph from "./EightStageGraph";
import data from "./data";
import Badge from "../../../../components/projectStrengths/eightStage/Badge";

export default function EightStage({ eightStageData, theme }) {
	const projectStrengthIndex = eightStageData.totals.indexOf(Math.max(...eightStageData.totals));
	const projectStrength = data[Object.keys(data)[projectStrengthIndex]];

	const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6} textAlign="left">
				<Paper sx={{ padding: 1, height: '80px', width: '90%' }}>
					<Typography
						variant="h4"
						style={{
							fontWeight: "bold",
							textAlign: "left",
						}}
					>
						8 Stage Results
					</Typography>
				</Paper>
				<Paper
					sx={{
						backgroundColor: "#FFFFFF",
						width: '80%',
						marginTop: '-25px',
						marginLeft: 'auto',
						mr: 5
					}}>
					<Typography
						color="#C60C30"
						sx={{ paddingTop: "10px", paddingLeft: "20px" }}
					>
						Your Top Project Strength is:
					</Typography>
					<Typography
						color="#1C1B1C"
						sx={{
							fontSize: 20,
							fontWeight: "bold",
							paddingBottom: "10px",
							paddingLeft: "20px",
						}}
					>
						{projectStrength.name}
					</Typography>
				</Paper>

				<Paper sx={{ mt: 2, padding: 2, height: isMobile ? null : '210px' }}>
					<Grid container spacing={2}>
						<Grid item xs={9}>
							<Typography sx={{ marginBottom: "7px", fontSize: 20, }}>
								{projectStrength.description}
							</Typography>

							<Typography sx={{ fontSize: 25 }}>
								Traits
							</Typography>
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', pt: 1 }}>
								{projectStrength.traits && projectStrength.traits.map((trait, index) => (
									<Chip key={index} label={trait} sx={{ backgroundColor: theme === 'dark' ? '#555' : '#aaa', }} />
								))}
							</Box>
						</Grid>
						<Grid container item xs={3} justifyContent={'center'} alignItems={'center'}>
							<Badge sortedIndex={projectStrengthIndex} width="75%" />
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={12} md={6} sx={{ marginTop: '-30px' }}>
				{eightStageData.totals && (
					<Paper sx={{ mt: 4 }}>
						<EightStageGraph eightStageData={eightStageData} />
					</Paper>
				)}
			</Grid>
		</Grid >
	);
}
