import { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';
import InfoModal from './modalContent'; // Adjust the import path as necessary
import { RESTUtils } from '../../../utilities/RESTUtils';
import { BrowserUtils } from '../../../utilities/BrowserUtils';
import { SessionUtils } from '../../../utilities/SessionUtils';
import { URLS } from '../../../urls';

const PseudoMergeComponent = () => {
    const user = SessionUtils.getCurrentUserSession();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState({});
    const [cancelButtonLoading, setCancelButtonLoading] = useState(false);
    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

    const handleClose = async () => {
        setCancelButtonLoading(true);
        const response = await RESTUtils.POST(
            RESTUtils.getAPIUrl() + `api/user/clear_pseudo/?uuid=${user?.uuid}`,);

        if (response.status === 200) {
            user.isPseudo = false;
            user.uuid = response.data;
            SessionUtils.setCurrentUserSession(user);
            BrowserUtils.redirect(URLS.AUTH.INTAKE);
        }

    };
    const handleConfirm = async () => {
        setConfirmButtonLoading(true);
        const response = await RESTUtils.POST(
            RESTUtils.getAPIUrl() + `api/user/merge_pseudo/?uuid=${user?.uuid}`,);
        if (response.status === 200) {
            user.isPseudo = false;
            user.intake_complete = true;
            user.updated = true;
            SessionUtils.setCurrentUserSession(user);
            BrowserUtils.redirect(URLS.USER.DASHBOARD);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const response = await RESTUtils.GET(
                RESTUtils.getAPIUrl() + `api/user/get_profile_preview/?q=${user?.email}`);
            if (response.status === 200) {
                setProfileData(response.data);
                setOpen(true);
                setLoading(false);
            } else if (response.status === 404) {
                BrowserUtils.redirect(URLS.AUTH.INTAKE);
            } else {  // zyzz not sure what we should do here
                BrowserUtils.redirect(URLS.AUTH.INTAKE);
            }
        }
        fetchData();
    }, []);
    return (
        <div>

            <InfoModal
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                profileData={profileData}
                cancelButtonLoading={cancelButtonLoading}
                confirmButtonLoading={confirmButtonLoading}
            />
            {loading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}> <CircularProgress size={60} /> </Box>}
        </div>
    );
};

export default PseudoMergeComponent;
