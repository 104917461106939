function getAnnouncementsStyle(index, theme) {
    return {
        padding: 1,
        height: '50px',
        backgroundColor: index % 2 === 0
            ? (theme === 'dark'
                ? '#333333'
                : '#eee')
            : 'inherit',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme === 'dark'
                ? (index % 2 === 0
                    ? '#555'
                    : '#444')
                : (index % 2 === 0
                    ? '#ddd'
                    : '#eee')
        },
        borderBottom: theme === 'dark' ? '1px solid #555555' : '1px solid #ccc',
    }
}

export default getAnnouncementsStyle;