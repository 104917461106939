export const userMenuLinkStyle = {
    display: 'block',
    color: 'primary.contrastText',
    paddingX: "40px",
    paddingY: "10px",
    textAlign: "center",
    ":hover": {
        color: 'white !important',
        '&.MuiMenuItem-root': {
            backgroundColor: 'primary.dark',
        }
    },
    /*
    '&.Mui-focusVisible': {
        backgroundColor: 'primary.dark',
        color: 'white !important'
    }
    */
};

export const switchStyle = {
    '& .MuiSwitch-track': {
        bgcolor: 'lightgray',
    },
}