import { Dialog, DialogTitle, Avatar, List, ListItem, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';

export default function AdminListModal({ admins, dialogTitle, handleListItemClick, open, onClose }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {admins.map(admin => (
          <ListItem disableGutters key={admin.uuid}>
            <ListItemButton onClick={() => handleListItemClick(admin)}>
              <ListItemAvatar>
                <Avatar
                  src={admin.image_url || "/images/profile_image_placeholder.png"}
                />
              </ListItemAvatar>
              <ListItemText primary={admin.first_name + ' ' + admin.last_name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
