import { AppBar, Toolbar } from "@mui/material";
import React from "react";
import Logo from "./logo";
import HeaderNavigation from "./headerNavigation";
import UserMenu from "./userMenu";
import { HeaderComponentProps } from "./types";
import { SessionUtils } from "../../utilities/SessionUtils";
import { useLocation } from 'react-router-dom';

function HeaderWeb({ headerParams, user }: HeaderComponentProps) {
	const { logo_url, headerNavigationItems } = headerParams;
	// const user = SessionUtils.getCurrentUserSession();
	const location = useLocation();
	const isPrintMode = location.pathname.includes('/profile/print/');
	return (
		<AppBar
			position="static"
			sx={{ paddingTop: '5px', paddingBottom: '5px', borderBottom: "1px solid #383838", borderTop: '0px' }}
		>
			<Toolbar
				disableGutters
				sx={{ width: '85%', margin: 'auto' }}
			>
				<Logo logo_url={logo_url} alt="Home" isPrintMode={isPrintMode} />
				{user ? (
					!location.pathname.includes('/profile/print/') && <UserMenu user={user} />
				) : (
					<HeaderNavigation items={headerNavigationItems} />
				)}
			</Toolbar>
		</AppBar>
	);
}

export default HeaderWeb;
