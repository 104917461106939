const emptyCertificate = (uuid) => {
	const emptyCertificate = {
		id: -9999,
		user_data: uuid,
		title: "",
		expiry_date: null,
		year_acquired: null,
		institution: "",
	};

	return emptyCertificate;
};

export default emptyCertificate;