import { Box, IconButton, Modal, Grid, Button, CircularProgress } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';


export default function Banner({
    onEditPage,
    roundEdges = true,
    banner_url,
    imageHash = null,
    uploadImage,
    updateProfileAPI,
    bannerLoading,
}) {
    const [open, setOpen] = useState(false);


    function handleBannerUpload(event) {
        const file = event.target.files[0];
        const maxSizeInBytes = 7 * 1024 * 1024; // 5MB limit (change as needed)

        if (file) {
            if (file.size > maxSizeInBytes) {
                alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
            } else {
                uploadImage(file, 'banner');
            }
        };
        setOpen(false);
    }

    function handleBannerSelect(banner_url) {
        updateProfileAPI('banner_url', banner_url);
    }

    function handleBannerImageChange(event) {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    const banners = [
        "/images/banners/linkedinBanner.jpg",
        "/images/banners/bridge.jpg",
        "/images/banners/Bench Profile Header_aerialphoto1.jpg",
        "/images/banners/Abstract Texture 1.jpg",
        "/images/banners/Abstract Texture 2.jpg",
        "/images/banners/Aerial Photo 2.jpg",
        "/images/banners/PL Brain Pattern 1.jpg",
        "/images/banners/PL Swirl Pattern 1.jpg",

    ];
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                    }}
                >
                    <h2 id="modal-title">Select a banner or upload your own</h2>
                    <Grid container spacing={2}>
                        {banners.map((item, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <img
                                    src={item}
                                    style={{ width: '100%', height: '60px', display: 'block', cursor: 'pointer' }}
                                    onClick={() => {
                                        handleBannerSelect(item);
                                        setOpen(false);
                                    }}
                                    alt="banner"
                                />

                            </Grid>
                        ))}
                        <Grid container item justifyContent={'center'}>

                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleBannerUpload}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    component="span"
                                    disabled={bannerLoading}
                                    startIcon={bannerLoading && <CircularProgress size={20} />}
                                >
                                    Upload
                                </Button>
                            </label>

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Box sx={{ position: 'relative' }}>
                <Box
                    component="img"
                    src={banner_url ? banner_url + '?' + imageHash : "/images/banners/linkedinBanner.jpg"}
                    sx={{
                        width: '100%',
                        height: '15ch',
                        objectFit: 'cover',
                        borderRadius: roundEdges && '3px 3px 0 0',
                    }}
                    alt="banner"
                />
                {onEditPage && (
                    <IconButton
                        onClick={handleBannerImageChange}
                        disabled={bannerLoading}
                        sx={{
                            position: 'absolute',
                            bottom: 6,
                            right: 0,
                            margin: '8px',
                            border: "1px solid white",
                            // backgroundColor: "gray",
                            backgroundColor: "transparent",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "lightgrey",
                                color: "white",
                            },
                        }}
                    >
                        {bannerLoading ? <CircularProgress size={24} /> : <EditIcon />}

                    </IconButton>
                )}
            </Box>
        </>
    )
}