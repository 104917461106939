import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RESTUtils } from "../../../utilities/RESTUtils";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const { uid, token } = useParams();

  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/; // See https://ihateregex.io/expr/password/

  const passwordInputProps = {
    style: {
      fontFamily: "Arial",
    },
    startAdornment: (
      <InputAdornment
        position="start"
        sx={{
          color: "secondary.main",
        }}
      >
        <LockIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment
        position="end"
        sx={{
          color: "secondary.main",
        }}
      >
        <Tooltip
          title={showPassword ? "Hide Password" : "Show Password"}
          arrow
          placement="top"
        >
          <IconButton
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  function isPasswordValid(newPassword, confirmPassword) {
    if (!newPassword || !confirmPassword) {
      setError(true);
      setErrorMessage("Please fill in all required fields");
      return false;
    } else if (newPassword !== confirmPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
      return false;
    } else if (!passwordRegex.test(newPassword)) {
      setError(true);
      setErrorMessage(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return false;
    }

    setError(false);
    setErrorMessage("");
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPassword = data.get("password");
    const confirmPassword = data.get("confirmPassword");

    if (isPasswordValid(newPassword, confirmPassword)) {
      setResetLoading(true);
      try {
        const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/user/reset_password_confirm/',
          {
            'uid': uid,
            'token': token,
            'new_password1': newPassword,
            'new_password2': confirmPassword
          });
        if (response.status === 200) {
          setResetSuccess(true);
        } else {
          setError(true);
          setErrorMessage('Failed to reset password. Please try again')
        }
      } catch (error) {
        setError(true);
        setErrorMessage('Failed to reset password. Please try again')
      } finally {
        setResetLoading(false);
      }
    }
  }

  useEffect(() => {
    let countdownInterval;
    if (resetSuccess) {
      countdownInterval = setInterval(() => {
        if (secondsLeft > 1) {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        } else {
          window.location.href = "/login/password_reset";
        }
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [resetSuccess, secondsLeft]);

  return (
    <Container component="main" sx={{ p: { xs: 2, md: 3 }, height: '75vh' }} maxWidth={false}>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Enter new password</Typography>
        </Grid>
        <Grid item xs={12} py={1}>
          <Divider />
        </Grid>

        {resetSuccess && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" color="success">
              Password successfully reset. Redirecting you to login in{" "}
              {secondsLeft} {secondsLeft === 1 ? 'second' : 'seconds'}...
            </Typography>
            <CheckCircleIcon sx={{ color: 'green' }} />
          </Stack>
        )}

        {!resetSuccess && (
          <form onSubmit={handleSubmit} style={{ width: '75%' }}>
            <Grid item xs={12} pt={2}>
              <TextField
                label="New Password *"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={passwordInputProps}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} pt={2}>
              <TextField
                label="Confirm Password *"
                id="confirmPassword"
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={passwordInputProps}
                autoComplete="new-password"
              />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" pt={1.5} >
              {error && (
                <Grid item xs={12} pb={1.5}>
                  <Typography variant="body1" color="error">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  disabled={resetLoading}
                  startIcon={resetLoading && <CircularProgress color="inherit" size={20} />}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Container >
  );
}
