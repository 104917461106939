import { useState } from "react"
import { Button } from "@mui/material"
import EnrollmentTable from "./EnrollmentTable"
import AddToJobModal from "../../../../../components/modals/AddToJob/AddToJobModal"

export default function EnrollmentsTab({ allData, activeJobs }) {
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    console.log(allData)
    return (
        <>
            {showAddJobModal &&
                <AddToJobModal
                    showAddJobModal={showAddJobModal}
                    setShowAddJobModal={setShowAddJobModal}
                    uuid={allData.userInfo.uuid}
                    activeJobs={activeJobs}
                    showSnackbar={allData.showSnackbar}
                    setEnrollmentData={allData.setEnrollmentData}
                />
            }

            <Button
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ mb: 1 }}
                onClick={() => setShowAddJobModal(true)}
            >
                Add to Job
            </Button>
            <EnrollmentTable allData={allData} />
        </>

    )
}