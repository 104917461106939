import { useState, useEffect } from "react";
import { Grid, Paper, Container } from "@mui/material";
import { RESTUtils } from "../../../utilities/RESTUtils";
import UserQuickSearch from "./components/userQuickSearch/UserQuickSearch";
import UserActions from "./components/userActions/UserActions";
import AdminDashJobs from "./components/AdminDashJobs/AdminDashJobs";
import Notifications from "./components/notifications/Notifications";

export default function AdminDashboard() {
	async function refresh_jobs() {
		try {
			await RESTUtils.GET(
				RESTUtils.getAPIUrl() + "api/admin/refresh_jobs/"
			);

		} catch (error) {
			console.error("Error fetching user tasks:", error);
		}
	}

	useEffect(() => {
		refresh_jobs();
	}, []);
	return (
		<Container
			component="main"
			maxWidth={"false"}
			sx={{ p: { xs: 2, md: 3 } }}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Paper>
						<UserQuickSearch />
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper>
						<UserActions />
					</Paper>
				</Grid>

				<Grid item xs={12} md={9}>
					<AdminDashJobs />
				</Grid>
				<Grid item xs={12} md={3}>
					<Notifications />
				</Grid>
			</Grid>
		</Container>
	);
}
