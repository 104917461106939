import { TableRow, TableCell } from "@mui/material";
import { rowStyle } from "../../../awards/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ReferenceRow({ row, isEdit, openReferenceModal, is_admin }) {
    return (
        <TableRow onClick={() => isEdit && openReferenceModal(row)} sx={isEdit && rowStyle}>
            <TableCell sx={{ textAlign: "center" }}>{row?.name}</TableCell>
            <TableCell sx={{ textAlign: "center" }}>{row?.company}</TableCell>
            <TableCell sx={{ textAlign: "center" }}>{row.reference_title}</TableCell>
            <TableCell sx={{ textAlign: "center" }}>{row.email}</TableCell>
            <TableCell sx={{ textAlign: "center" }}>{row.phone}</TableCell>

            {is_admin && (
                <TableCell sx={{ textAlign: "center" }}>
                    {row.notes && row.notes.length > 0 ? <CheckCircleIcon style={{ color: 'green' }} /> : ""}
                </TableCell>
            )}
        </TableRow>
    );
}
