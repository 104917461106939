import { Grid, Paper, Typography } from "@mui/material";
import SearchSidebar from "./SearchSidebar";
import KeywordBox from "./KeywordBox";

export default function CandidateSearch({
	clearAllFilter,
	standardSearchCriteria,
	keyWords,
	setKeyWords,
}) {
	const clickableTextStyle = {
		cursor: "pointer",
		"&:hover": {
			color: "grey",
		},
		"&:hover span": {
			color: "grey",
		},
	};

	return (
		<Paper>
			<Grid item padding={1}>
				<Grid align="right" padding="0.5rem">
					<Typography
						variant="h8"
						onClick={() => clearAllFilter()}
						sx={clickableTextStyle}
					>
						Clear All
					</Typography>
				</Grid>
				<KeywordBox
					criteriaName={"Key words"}
					filterArray={keyWords}
					setterFn={setKeyWords}
					clickableTextStyle={clickableTextStyle}
				/>
				{standardSearchCriteria.map((criterion, index) => (
					<SearchSidebar
						key={index}
						criteriaName={criterion.label}
						filterArray={criterion.array}
						setterFn={criterion.setterFn}
						options={criterion.options}
						clickableTextStyle={clickableTextStyle}
					/>
				))}
			</Grid>
		</Paper>
	);
}
