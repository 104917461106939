import { TableRow, TableCell } from "@mui/material";
import { rowStyle } from "./styles";

export default function AwardRow({ row, isEdit, openAwardModal }) {
  return (
    <TableRow onClick={() => isEdit && openAwardModal(row)} sx={isEdit ? rowStyle : undefined}>
      <TableCell sx={{ textAlign: "center" }}>{row.title}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row?.organization}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.year_awarded}</TableCell>
    </TableRow>
  );
}
