import { Html, Head, Body, Text } from "@react-email/components";
import EmailSignature from "../components/EmailSignature";
import { textStyle } from "../styles";
import DOMPurify from 'dompurify';

export default function RecruitmentEmail({ message }) {

    return (
        <Html>
            <Head />

            <Body>
                <Text
                    style={{ ...textStyle, paddingTop: '10px', paddingBottom: '10px' }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
                />

                <EmailSignature />
            </Body>
        </Html>
    )
}