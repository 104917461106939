import { useState } from "react";
import {
    Grid,
    TextField,
    Button,
    Typography,
    Divider,
    MenuItem,
    CircularProgress,
} from "@mui/material";
import SnackbarComponent from "../../../snackbar";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats, emailTemplates } from "./data";
import { render } from "@react-email/render";
import RecruitmentEmail from "../../../react-email/emails/RecruitmentEmail";

export default function SendEmailForm({
    previousMessage = false,
    emailData,
    setEmailData,
    users,
    setShow = false,
    setShowGlobal = false,
    job = false,
    fromAD,
    fromMassEmail = false,
}) {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const templateNames = Object.keys(emailTemplates);

    function handleCloseSnackbar() {
        setShowSnackbar(false);
        setSnackbarMessage("");
    }
    function setSnackbar(type) {
        if (type === "error") {
            setSnackbarMessage("Something went wrong");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
        } else {
            setSnackbarMessage("Sent");
            setSnackbarSeverity("success");
            setShowSnackbar(true);
        }
    }
    function handleTemplateChange(event) {
        const selectedTemplate = event.target.value;
        setTemplate(selectedTemplate);
        let template = emailTemplates[selectedTemplate] || '';

        if (users.length > 1) {
            template = template.replace(/{role}/g, removePLNumber(job));
        } else {
            template = template.replace(/{name}/g, users[0].name).replace(/{role}/g, removePLNumber(job));
        }

        setMessage(template);
    }
    function stripHtmlTags(str) {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    function messageValid() {
        const isEmpty = (str) => {
            if (str) {
                return !str.trim().length;
            } else {
                return true;
            }
        };
        const messageEmpty = isEmpty(stripHtmlTags(message));

        let isValid;
        if (previousMessage) {
            isValid = !messageEmpty;
        } else {
            const subjectEmpty = isEmpty(subject);
            isValid = !messageEmpty && !subjectEmpty;
        }
        return isValid;
    }
    function clearForm() {
        setSubject("");
        setMessage("");
        setError(false);
    }
    function removePLNumber(title) {
        if (title.includes("PL#")) {
            const indexToTrimFrom = title.indexOf("PL#") - 3;
            return title.slice(0, indexToTrimFrom).trim();
        }

        return title;
    };
    async function handleSendEmail() {
        if (messageValid()) {
            setLoading(true);
            try {
                const email = {
                    sender: { 'emailAddress': { 'name': 'Recruitment', 'address': 'recruitment@peterlucas.ca' } },
                    recipientEmails: users.map(user => user.email),
                    recipientNames: users.map(user => user.name),
                    body: {
                        'content': await render(<RecruitmentEmail message={message} />, {
                            pretty: true,
                        })
                    },
                    sentDateTime: new Date().toISOString(),
                };
                if (previousMessage) {
                    email.previousMessageID = previousMessage.id;
                    email.subject = previousMessage.subject;
                } else {
                    email.subject = subject;
                }
                const response = await RESTUtils.POST(
                    RESTUtils.getAPIUrl() + "api/admin/send_email/",
                    email
                );
                if (response.status === 201) {
                    if (!job) { //not from CS
                        const updatedEmailData = [...emailData];
                        updatedEmailData.unshift(email);
                        setEmailData(updatedEmailData);
                    } else {
                        setEmailData((prevState) => {
                            const newState = { ...prevState };
                            for (let i = 0; i < users.length; i++) {
                                newState[users[i].job_enroll_id].emails.unshift(email);
                            }
                            return newState;
                        });
                    }
                    setSnackbar("success");
                    clearForm();
                } else {

                }
            } catch (error) {
                console.error("Error sending email:", error);
                setSnackbar("error");
            } finally {
                setLoading(false);
            }
        } else {
            setError(true);
        }
    }

    return (
        <Grid container spacing={0.75} pt={1} pl={1}>
            <SnackbarComponent
                show={showSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                positionVertical="bottom"
                positionHorizontal="center"
                autoHideDuration={5000}
                handleCloseCallback={handleCloseSnackbar}
            />

            {!previousMessage && (
                <Grid item xs={fromAD ? 10.5 : 12} px={1}>
                    <TextField
                        label="Subject"
                        size="small"
                        required
                        fullWidth
                        error={error && !subject}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        autoComplete="off"
                    />
                </Grid>
            )}

            {!previousMessage && fromAD && (
                <Grid item xs={1.5} textAlign={'center'} pr={1}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleSendEmail}
                        size="small"
                        disabled={loading}
                        startIcon={loading && <CircularProgress color="inherit" size={20} />}
                    >
                        {previousMessage ? 'Reply' : 'Send'}
                    </Button>
                </Grid>
            )}

            <Grid item xs={12} ml={0.1}>
                <Typography>Subject Preview : {previousMessage ? previousMessage.subject : subject}</Typography>
            </Grid>
            {job && (
                <Grid item xs={12} py={1} px={1}>
                    <TextField
                        select
                        fullWidth
                        label="Template (Optional)"
                        size="small"
                        onChange={handleTemplateChange}
                        value={template || ''}
                    >
                        {templateNames.map((templateName) => (
                            <MenuItem key={templateName} value={templateName}>
                                {templateName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>)}

            <Grid item xs={12}>
                {error && !stripHtmlTags(message) && <Typography sx={{ color: 'red' }}>Enter a message</Typography>}
                <ReactQuill
                    // theme={'snow'}
                    onChange={(content) => {
                        setMessage(content);
                    }}
                    value={message}
                    modules={modules}
                    formats={formats}
                // bounds={'.app'}
                />
            </Grid>

            <Grid justifyContent={'space-between'} container sx={{ padding: 1 }}>
                {!job && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            setShow(false);
                            setShowGlobal(false);
                        }}
                        size="small"
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSendEmail}
                    size="small"
                    disabled={loading}
                    startIcon={loading && <CircularProgress color="inherit" size={20} />}
                    sx={{ ml: 'auto' }} // This ensures the button floats right
                >
                    {previousMessage ? 'Reply' : 'Send'}
                </Button>
            </Grid>

            {!fromMassEmail && (
                <Grid item xs={12} my={0}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    );
}
