import { Box, Container, CssBaseline, Paper, Typography } from '@mui/material';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

function ErrorPage() {

    return (
        <div>
            <Container component="main" maxWidth="sm">
                <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 2 } }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <ErrorOutlineTwoToneIcon color='primary' sx={{ fontSize: 200 }} />
                        <Typography variant="h4" color={'primary.main'}>
                            Page Not Found
                        </Typography>
                        <Typography variant="h6" textAlign={'center'} paddingY={3}>
                            This page may have been removed or is temporarily unavailable.
                        </Typography>
                    </Box>
                </Paper>
            </Container>

        </div>
    )
}

export default ErrorPage;