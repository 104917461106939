// profileCompletionHooks.js
import { useState, useEffect } from 'react';
import { RESTUtils } from '../../../../utilities/RESTUtils';

export function CompletionUseEffects(
    allData,
    tasks,
    isProfileComplete,
    setIsProfileComplete
) {
    const { userInfo, setUserInfo, } = allData;
    const { profile_completion_steps, uuid } = userInfo;
    const [steps, setSteps] = useState({
        media: null,
        quizzes: null,
        details: null,
        experience: null,
        notableProjects: null,
        education: null,
        awards: null,
        references: null,
    });

    // XXXXXXXXXXXXXXXXXXXXXXXXXXXXX Completed Criteria XXXXXXXXXXXXXXXXXXXXXXXXXXXXX 
    async function updateProfileCompletion(criteria_index, isComplete) {
        try {
            const response = await RESTUtils.POST(
                RESTUtils.getAPIUrl() + `api/user/update_profile_progress/`,
                {
                    user_uuid: uuid,
                    criteria_index: criteria_index,
                    is_complete: isComplete,
                }
            );
            if (response.status === 200) {
                setUserInfo((prevInfo) => {
                    const updatedSteps = [...prevInfo.profile_completion_steps];
                    updatedSteps[criteria_index] = isComplete;
                    return {
                        ...prevInfo,
                        profile_completion_steps: updatedSteps,
                    };
                });
            } else {
                console.log(response.data);

            }
        }
        catch (error) {
            console.error("An error occurred:", error);
        }
    }

    useEffect(() => {
        if (tasks.image_url != null) {
            const mediaBool = Boolean(tasks.image_url && tasks.video_url && tasks.banner_url);
            const mediaIndex = 0;
            setSteps(prevStep => ({
                ...prevStep,
                media: mediaBool,
            }));
            if (mediaBool != profile_completion_steps[mediaIndex]) {
                updateProfileCompletion(mediaIndex, mediaBool)

            }
        }
    }, [tasks.image_url, tasks.video_url, tasks.banner_url]);


    useEffect(() => {
        if (tasks.image_url != null) {
            const quizzesBool = Boolean(tasks.eight_stage && tasks.personality);
            const quizzesIndex = 1;
            setSteps(prevStep => ({
                ...prevStep,
                quizzes: quizzesBool,
            }));
            if (quizzesBool != profile_completion_steps[quizzesIndex]) {
                updateProfileCompletion(quizzesIndex, quizzesBool)
            }
        }
    }, [tasks.eight_stage, tasks.personality]);


    useEffect(() => {
        if (tasks.image_url != null) {
            const detailsBool = Boolean(tasks.overview && tasks.qualifications);
            const detailsIndex = 2;
            setSteps(prevStep => ({
                ...prevStep,
                details: tasks.overview && tasks.qualifications,
            }));
            if (detailsBool != profile_completion_steps[detailsIndex]) {
                updateProfileCompletion(detailsIndex, detailsBool)
            }
        }
    }, [tasks.overview, tasks.qualifications]);


    useEffect(() => {

        if (tasks.image_url != null) {
            const experienceBool = Boolean((tasks.experience && tasks.skills) || userInfo.not_applicable[3]);
            const experienceIndex = 3;
            setSteps(prevStep => ({
                ...prevStep,
                experience: experienceBool,
            }));
            if (experienceBool != profile_completion_steps[experienceIndex]) {
                updateProfileCompletion(experienceIndex, experienceBool)
            }
        }
    }, [tasks.experience, tasks.skills, userInfo.not_applicable[3]]);


    useEffect(() => {
        if (tasks.image_url != null) {
            const notableProjectsIndex = 4;
            const notableProjectsBool = Boolean(tasks.notableProjects || userInfo.not_applicable[notableProjectsIndex]);
            setSteps(prevStep => ({
                ...prevStep,
                notableProjects: notableProjectsBool,
            }));
            if (notableProjectsBool != profile_completion_steps[notableProjectsIndex]) {
                updateProfileCompletion(notableProjectsIndex, notableProjectsBool)
            }
        }
    }, [tasks.notableProjects, userInfo.not_applicable[4]]);


    useEffect(() => {
        if (tasks.image_url != null) {
            const educationBool = Boolean((tasks.education && tasks.certifications) || userInfo.not_applicable[5]);
            const educationIndex = 5;
            setSteps(prevStep => ({
                ...prevStep,
                education: educationBool,
            }));
            if (educationBool != profile_completion_steps[educationIndex]) {
                updateProfileCompletion(educationIndex, educationBool)
            }
        }
    }, [tasks.education, tasks.certifications, userInfo.not_applicable[5]]);


    useEffect(() => {
        if (tasks.image_url != null) {
            const awardsIndex = 6;
            const awardsBool = Boolean(tasks.awards || userInfo.not_applicable[awardsIndex]);
            setSteps(prevStep => ({
                ...prevStep,
                awards: awardsBool,
            }));
            if (awardsBool != profile_completion_steps[awardsIndex]) {
                updateProfileCompletion(awardsIndex, awardsBool)
            }
        }
    }, [tasks.awards, userInfo.not_applicable[6]]);

    useEffect(() => {
        if (tasks.image_url != null) {
            const referencesIndex = 7;
            const referencesBool = Boolean((tasks.references && tasks.userReferences) || userInfo.not_applicable[referencesIndex]);
            setSteps(prevStep => ({
                ...prevStep,
                references: referencesBool,
            }));
            if (referencesBool != profile_completion_steps[referencesIndex]) {
                updateProfileCompletion(referencesIndex, referencesBool)
            }
        }
    }, [tasks.references, tasks.userReferences, userInfo.not_applicable[7]]);
    // -------------------- Overall Completion --------------------

    useEffect(() => {
        const isComplete = Object.values(steps).every(step => step);

        setIsProfileComplete(isComplete);
    }, [steps]);

    useEffect(() => {
        if (isProfileComplete) {
            console.log("Complete");
        }
    }, [isProfileComplete]);
    return { steps };
}
