import { useThemeContext } from "../../themes/ThemeProviderWrapper";
import PhoneInputDark from "./PhoneInputDark";
import PhoneInputLight from "./PhoneInputLight";

export default function PhoneMaster({ value, handlePhoneChange }) {
    const { theme } = useThemeContext();

    return (
        theme === 'dark' ?
            <PhoneInputDark value={value} handlePhoneChange={handlePhoneChange} />
            :
            <PhoneInputLight value={value} handlePhoneChange={handlePhoneChange} />
    )
}