const EmptyEducationComponent = (uuid) => {
  let emptyEducation = {
    degree: "",
    organization: "",
    field_of_study: "",
    start_date: null,
    end_date: null,
    startYear: "",
    endYear: "",
    startMonth: "",
    endMonth: "",
    startDay: "",
    endDay: "",
    user_data: uuid,
    id: null,
  };
  return emptyEducation;
};

export default EmptyEducationComponent;
