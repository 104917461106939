import { TextField, Typography, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { LoginContext } from ".";

export default function PasswordResetModalContent() {
    const { recoveryEmail, setRecoveryEmail, recoveryEmailLoading, recoveryEmailError,
        recoveryEmailErrorMessage, recoveryEmailSuccess } = useContext(LoginContext);
    return (
        <>
            {recoveryEmailSuccess ? (
                <Typography variant="subtitle1" component="div">
                    A password reset email has been sent to {recoveryEmail}
                </Typography>
            ) : (
                <>
                    <Typography variant="subtitle1" component="div">
                        Please, enter your email and we will send a link for
                        password recovery.
                    </Typography>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="email_recovery"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={recoveryEmail}
                        onChange={(e) => setRecoveryEmail(e.target.value)}
                        InputProps={{
                            inputProps: {
                                sx: { borderBottom: "1px solid lightgrey" },
                            },
                            endAdornment: recoveryEmailLoading && <CircularProgress size={24} />,
                        }}
                    />

                    {recoveryEmailError && (
                        <Typography variant="body2" color="error" sx={{ paddingTop: '3px' }}>
                            {recoveryEmailErrorMessage}
                        </Typography>
                    )}
                </>
            )}
        </>
    );
}
