import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { LogoProps } from './types';
import { URLS } from '../../../urls';
import { SessionUtils } from '../../../utilities/SessionUtils';

function Logo({ logo_url, alt = "", isPrintMode = false }: LogoProps) {
    const user = SessionUtils.getCurrentUserSession();
    return (
        <Box sx={{ display: 'flex', flexGrow: isPrintMode ? null : 1, marginLeft: isPrintMode ? 'auto' : 0 }}>
            <Link to={user ? user.is_admin ? URLS.DASHBOARD.ADMIN : URLS.USER.DASHBOARD : URLS.AUTH.LOGIN}>
                <img src={"/images/PL_Logo.png"} height={isPrintMode ? 25 : 50} alt={alt} />
            </Link>
        </Box>
    )
}

export default Logo;