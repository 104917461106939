import { Grid, Typography, Container, Paper, Box, Stack } from "@mui/material";
import SnackbarComponent from "../../../components/snackbar";
import TwoButtonModal from "../../../components/modals/TwoButtonModal";
import PasswordResetModalContent from "./PasswordResetModalContent";
import SignInForm from "./SignInForm";
import { useContext } from "react";
import { LoginContext } from ".";
import { useThemeContext } from "../../../themes/ThemeProviderWrapper";

export default function LoginWeb() {
    const { closeModal, showSnackbar, snackbarMessage, snackbarSeverity, handleCloseSnackbar,
        showModal, recoveryEmailSuccess, handleResetPassword } = useContext(LoginContext);
    const { theme } = useThemeContext();

    return (
        <>
            <SnackbarComponent
                show={showSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                positionVertical="top"
                positionHorizontal="center"
                autoHideDuration={5000}
                handleCloseCallback={handleCloseSnackbar}
            />
            <TwoButtonModal
                show={showModal}
                modalTitle={recoveryEmailSuccess ? "Email Sent" : "Forgot Password?"}
                modalMessage={
                    <PasswordResetModalContent />
                }
                closeButtonCallback={closeModal}
                okButtonText={recoveryEmailSuccess ? "Exit" : "Submit"}
                okButtonCallback={recoveryEmailSuccess ? closeModal : handleResetPassword}
            />

            <Grid container alignItems="center" style={{ minHeight: "77vh" }}>
                <Grid container item xs={6} justifyContent="center">
                    <Stack spacing={1} direction="column" alignItems="left">
                        <Typography variant="h3">
                            Build your Legacy <br /> on{" "}
                            <span
                                style={{
                                    fontWeight: "bold",
                                    // color: "#c60c30", //THIS STAYS
                                }}
                            >
                                <span style={{ paddingRight: "1px" }}>T</span>he
                                Bench
                            </span>
                        </Typography>

                        <Typography variant="h6">
                            <a
                                href="https://www.peterlucas.ca"
                                rel="noopener noreferrer"
                                style={{ color: theme === 'dark' ? 'white' : 'black', textDecoration: 'underline' }}
                            >
                                Visit peterlucas.ca
                            </a>
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} pr={30}>
                    <Container component="main" sx={{ width: "400px" }}>
                        <Paper
                            elevation={6}
                            sx={{
                                p: { xs: 1 },
                            }}
                        >
                            <Box
                                sx={{
                                    padding: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography>
                                <SignInForm />
                            </Box>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}