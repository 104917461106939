const data = {
	identify: {
		name: "Identifying",
		description:
			"This is where a deeper understanding of the systems occurs, and where the need for improvement is identified.",
		traits: ["Inquisitive", "Curious", "Explorational", "Observational"],
	},
	invent: {
		name: "Inventing",
		description:
			"This stage is where ideas and potential solutions for realizing the opportunity are generated.",
		traits: [
			"Open minded", "Big picture thinking", "Innovative", "Creative",],
	},
	evaluate: {
		name: "Evaluating",
		description:
			"This is where the merits of possible solutions are compared to decide on the path forward.",
		traits: ["Data-driven", "Pragmatic", "Realistic", "Critical thinking"],
	},
	plan: {
		name: "Planning",
		description:
			"This stage is where you create the final plans for the selected alternative.",
		traits: ["Goal oriented", "Organized", "Collaborative", "Logical"],
	},
	lead: {
		name: "Leading",
		description:
			"This project strength demonstrates the ability to guide others and push the project forward.",
		traits: ["Proactive", "Inspirational", "Collaborative", "Encouraging"],
	},
	coordinate: {
		name: "Coordinating",
		description:
			"This stage reflects communication and relationship building.",
		traits: ["Empathetic", "Encouraging", "Adaptable", "Resourceful"],
	},
	control: {
		name: "Controlling",
		description:
			"This is where the scope is controlled to ensure the final deliverables are met.",
		traits: ["Disciplined", "Organized", "Precise", "Reliable"],
	},
	finish: {
		name: "Finishing",
		description:
			"This is the stage where the final details are wrapped up and the project is closed out.",
		traits: ["Meticulous", "Methodical", "Results-oriented", "Persistent"],
	},
};

export default data;
