
import { TableRow, TableCell, Typography, IconButton, Collapse, Box, TextField, Button, CircularProgress } from '@mui/material';
import { BrowserUtils } from '../../../../../utilities/BrowserUtils';
import { snakeCaseToTitleCase } from '../../../../../utilities/Helpers';
import { useState } from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const cellStyle = {
  borderBottom: '1px solid #6c6e70',
};

const EnrollmentRow = ({ enrollment, saveRundown, handleRundownChange, index, buttonLoading }) => {
  const [rundownVisible, setRundownVisible] = useState(false);

  const handleJobClick = (destination) => {
    BrowserUtils.openNewTab(`${window.location.origin}/${destination}/${enrollment.job.uuid}`);
  };


  return (
    <>
      <TableRow>
        <TableCell
          style={{ cursor: 'pointer', ...cellStyle }}
          onClick={() => handleJobClick('job')}
        >
          <Typography fontSize={14} sx={{ color: 'primary.contrastText', '&:hover': { color: 'primary.main' } }}>
            {enrollment.job.title}
          </Typography>
        </TableCell>
        <TableCell style={{
          cursor: "pointer",
          borderBottom: "1px solid #6c6e70",
        }}
        >
          <IconButton
            onClick={() => setRundownVisible(!rundownVisible)}
          >
            {rundownVisible ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell style={cellStyle}>
          {enrollment.job.client}
        </TableCell>
        <TableCell style={cellStyle}>
          {enrollment.job.city}
        </TableCell>
        {enrollment.bucket === 'on_client_portal' ? (
          <TableCell
            style={{ cursor: 'pointer', ...cellStyle }}
            onClick={() => handleJobClick('client_portal')}
          >
            <Typography fontSize={14} sx={{ color: 'primary.contrastText', '&:hover': { color: 'primary.main' } }}>
              {snakeCaseToTitleCase(enrollment.bucket)}
            </Typography>
          </TableCell>
        ) : (
          <TableCell style={cellStyle}>
            {snakeCaseToTitleCase(enrollment.bucket)}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={5}
          sx={{
            borderBottom: "1px solid #6c6e70",
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse in={rundownVisible}>
            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <TextField
                multiline
                rows={4}
                fullWidth
                name="rundown"
                label="Rundown"
                value={enrollment.cover_letter || ""}
                onChange={(e) => handleRundownChange(index, e)}
              />
              <Button
                onClick={() => saveRundown(enrollment.id, enrollment.cover_letter)}
                variant="outlined"
                color="secondary"
                sx={{ marginTop: 1 }}
                disabled={buttonLoading}
                startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
              >
                Save
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EnrollmentRow;
