import { Grid, Typography } from "@mui/material";
import { iconNames, strengths } from "./data";

export default function Badge({ sortedIndex, width = "25%", isMobile = false }) {

  return (
    <Grid container textAlign={'center'}>
      <Grid item xs={12}>
        <img
          style={{ width: width }}
          src={`${process.env.PUBLIC_URL}/images/eightStageBadges/${iconNames[sortedIndex]}.png`}
          alt={`${strengths[sortedIndex]}`}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant={isMobile ? 'caption' : 'body1'}>{strengths[sortedIndex]}</Typography>
      </Grid>
    </Grid>
  );
}
