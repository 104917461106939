const data = [
    'Software Engineer - PL#12345',
    'Marketing Manager - PL#154524',
    'Data Analyst - PL#98765',
    'Graphic Designer - PL#65432',
    'Sales Representative - PL#87954',
    'Project Manager - PL#34678'
];

export const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image',],
    ],
}

export const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];
export const emailTemplates = {
    Introduction: `<p>Hi {name},</p>  <p><br></p>
                      
                    <p>Thank you for applying for our role of {role}. After reviewing your application, it appears you may be a great fit for a role with our company.
                    I would like to set up a virtual interview for this week or next if you’re available to discuss your application and suitability for a Peter Lucas project.
                    Please let us know a few times that work well, and one of our HR professionals will send along a meeting invite with a Teams link. </p> <p><br></p>

                    <p>At Peter Lucas, we do our hiring a little differently in that we request candidates fill out a self-assessment survey and profile before their interview,
                    which means less time is spent getting to understand where your skills are, and more time can be spent getting to know each other better and making sure that it will be a good fit.
                    Please follow the link provided to complete your profile before the interview: https://www.peterlucas-bench.com/ </p> <p><br></p>
                    
                    <p>Thank you for applying and I look forward to meeting you. </p>
                    <p>Talk soon,</p>
                    <p>HR Team </p>` ,

    Reject: `<p>Dear {name},</p> <p><br></p>

                <p>Thank you for your interest in our role of {role}. We have reviewed your application and we regret to inform you that it has not been selected for further consideration.
                We wish you every success with your job search and thank you for your interest in our company.</p><p><br></p>

                <p>Sincerely,</p>
                <p>HR Team</p>`,

};

export default data;