import { Alert, Snackbar, Typography } from '@mui/material';

import { useState } from 'react';

export function SnackBarComponent2() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [positionVertical, setPositionVertical] = useState('bottom');
    const [positionHorizontal, setPositionHorizontal] = useState('center');
    const [autoHideDuration, setAutoHideDuration] = useState(null);

    const handleCloseCallback = () => {
        setOpen(false);
    };

    function showSnackbar(message, severity) {
        setMessage(message);
        setSeverity(severity);
        // setPositionVertical(positionVertical);
        // setPositionHorizontal(positionHorizontal);
        // setAutoHideDuration(autoHideDuration);
        setOpen(true);
    }
    const SnackBarComponent = (
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleCloseCallback}
            anchorOrigin={{
                vertical: positionVertical,
                horizontal: positionHorizontal
            }} >
            <Alert
                onClose={handleCloseCallback}
                severity={severity}
                sx={{ width: '100%' }}
                variant="filled">
                {message}
            </Alert>
        </Snackbar>
    )


    return { SnackBarComponent, showSnackbar };
}