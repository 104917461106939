import { useState } from "react";
import {
	Box,
	Collapse,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { tableRowBorderStyle, iconStyle } from "./styles";
import AddSkillComponent from "./AddEditSkillComponent";

export default function SkillsTableRow({
	row_data,
	skillData,
	setSkillData,
	setChangedSkills,
	isUpdatingSkill,
	setIsUpdatingSkill,
}) {
	const [open, setOpen] = useState(false);
	const [selectedSkill, setSelectedSkill] = useState({ row_data });
	const [isUpdatingCurSkill, setIsUpdatingCurSkill] = useState(false);
	const [error, setError] = useState(false);
	function skillSelected(skill) {
		setIsUpdatingSkill(true);
		setIsUpdatingCurSkill(true);
		setOpen(false);
		setSelectedSkill(skill);
	}

	function handleSaveSkill() {
		const updatedSelectedSkill = {
			...selectedSkill,
			skill: selectedSkill.skill.trim()
		};

		if (updatedSelectedSkill.skill === null || updatedSelectedSkill.frequency === null) {
			setError(true);
		} else {
			const indexToUpdate = skillData.findIndex((skill) => skill.id === updatedSelectedSkill.id);

			// Create a copy of the skillData array
			const updatedSkillData = [...skillData];
			updatedSkillData[indexToUpdate] = updatedSelectedSkill;

			setSkillData(updatedSkillData);
			setChangedSkills((prevSkills) => ({
				...prevSkills,
				edited: [...new Set([...prevSkills.edited, updatedSelectedSkill])],
			}));
			setIsUpdatingSkill(false);
			setIsUpdatingCurSkill(false);
		}
	}


	function handleDeleteSkill(updatedSkill) {
		setIsUpdatingSkill(false);
		setIsUpdatingCurSkill(false);
		setSelectedSkill({});
		setChangedSkills((prevSkills) => ({
			...prevSkills,
			deleted: [...prevSkills.deleted, updatedSkill],
		}));
		setSkillData((prevSkills) =>
			prevSkills.filter((skill) => skill.id !== updatedSkill.id)
		);
	}

	return (
		<>
			<TableRow
				sx={{
					cursor: "pointer",
					transition: "background-color 0.3s",
					"&:hover": {
						backgroundColor: "secondary.rowHover",
					},
				}}
				onClick={() => setOpen(!open)}
			>
				<TableCell
					align="left"
					sx={tableRowBorderStyle}
					key={row_data.id}
				>
					{row_data.skill}
				</TableCell>

				<TableCell align="left" sx={tableRowBorderStyle}>
					{row_data.frequency}
				</TableCell>

				<TableCell sx={tableRowBorderStyle}>
					<Tooltip
						title={isUpdatingCurSkill ? "Cancel" : "Delete Skill"}
						arrow
						placement="top"
					>
						<IconButton
							onClick={
								isUpdatingCurSkill
									? (event) => {
										event.stopPropagation();
										setIsUpdatingSkill(false);
										setIsUpdatingCurSkill(false);
									}
									: (event) => {
										event.stopPropagation();
										handleDeleteSkill(row_data);
									}
							}
							sx={iconStyle}
						>
							{isUpdatingCurSkill ? (
								<ClearIcon />
							) : (
								<DeleteIcon />
							)}
						</IconButton>
					</Tooltip>
				</TableCell>

				<TableCell sx={tableRowBorderStyle}>
					{isUpdatingCurSkill ? (
						<Tooltip title="Save Skill" arrow placement="top">
							<IconButton
								onClick={(event) => {
									event.stopPropagation();
									handleSaveSkill();
								}}
								sx={iconStyle}
							>
								<CheckIcon />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip title="Edit Skill" arrow placement="top">
							<IconButton
								onClick={(event) => {
									event.stopPropagation();
									skillSelected(row_data);
								}}
								sx={iconStyle}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					)}
				</TableCell>
			</TableRow>

			{/* Collapsible detail info has been placed here as another table row */}
			<TableRow
				onClick={() => {
					if (!isUpdatingCurSkill) {
						setOpen(!open);
					}
				}}
			>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
				>
					<Collapse
						in={open && !isUpdatingCurSkill}
						timeout="auto"
						unmountOnExit
					>
						<Box sx={{ margin: 1 }}>
							<Typography
								variant="subtitle2"
								gutterBottom
								component="div"
							>
								{row_data.description}
							</Typography>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{isUpdatingCurSkill && (
				<TableRow>
					<TableCell colSpan={4}>
						<AddSkillComponent
							curSkill={selectedSkill}
							setCurSkill={setSelectedSkill}
							error={error}
						/>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}
