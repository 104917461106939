import { RESTUtils } from "../../utilities/RESTUtils";

export default function ApiFunctions(setNotes, setSnackbar, closeModal, setMessage, setTags) {
    async function addNote(message, user_uuid, currentUser, tags, job_id = false, setButtonLoading) {
        setButtonLoading((prevState) => ({ ...prevState, creating: true }));
        const newNote = {
            message: message,
            candidate: user_uuid, // for recipient
            author: currentUser.uuid,
            tags: tags.map(tag => tag.uuid)
        }
        if (job_id) {
            newNote.job = job_id;
        }
        try {
            const response = await RESTUtils.POST(
                RESTUtils.getAPIUrl() + "api/admin/notes/",
                newNote
            );

            if (response.status === 201) {
                const createdNote = response.data;
                const author_data = {
                    first_name: currentUser.first_name,
                    last_name: currentUser.last_name,
                    image_url: currentUser.image_url,
                    uuid: currentUser.uuid,
                };
                createdNote.author = author_data;
                setNotes((prevNoteData) => [createdNote, ...prevNoteData]);
                setSnackbar("success");
                setMessage("");
                setTags([]);
            } else {
                console.log(response.data);
                setSnackbar("error");
            }
        } catch (error) {
            console.log("Error:", error);
            setSnackbar("error");
        } finally {
            setButtonLoading((prevState) => ({ ...prevState, creating: false }));
        }
    }

    async function editNote(note_id, message, setButtonLoading) {
        setButtonLoading((prevState) => ({ ...prevState, editing: true }));
        const curNoteData = {
            id: note_id,
            message: message,
        };
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() +
                `api/admin/notes/${curNoteData.id}`,
                curNoteData
            );
            if (response.status === 200) {
                setNotes((prevNoteData) =>
                    prevNoteData.map((note) =>
                        note.id === curNoteData.id ? { ...note, message: curNoteData.message } : note
                    )
                );
                setSnackbar("success");
                closeModal();
            } else {
                console.log(response.data);
                setSnackbar("error");
            }
        } catch (error) {
            console.log("Error:", error);
            setSnackbar("error");
        } finally {
            setButtonLoading((prevState) => ({ ...prevState, editing: false }));
        }
    }

    async function deleteNote(curNoteData, setButtonLoading) {
        setButtonLoading((prevState) => ({ ...prevState, deleting: true }));
        try {
            const response = await RESTUtils.DELETE(
                RESTUtils.getAPIUrl() +
                `api/admin/notes/${curNoteData.id}`
            );

            if (response.status === 200) {
                setNotes((prevNoteData) =>
                    prevNoteData.filter((note) => note.id !== curNoteData.id)
                );
                setSnackbar("success");
                closeModal();
            } else {
                console.log(response.data);
                setSnackbar("error");
            }
        } catch (error) {
            console.log("Error:", error);
            setSnackbar("error");
        } finally {
            setButtonLoading((prevState) => ({ ...prevState, deleting: false }));
        }
    }

    return {
        addNote, editNote, deleteNote
    }
}