import { Typography } from "@mui/material";
import Education from "./Education";

export default function Educations({ education }) {
    return (
        <>
            <Typography variant="h6" color={'primary.main'} sx={{ fontWeight: 'bold', my: 2 }}>
                Education
            </Typography>
            {education.map((education, index) => (
                <Education key={index} education={education} />
            ))}
        </>
    )
}