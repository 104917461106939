const emptyProject = (uuid) => {
	const emptyProject = {
        id: null,
		user_data: uuid,
		title: "",
		description: "",
		project_role: "",
		cost: "",
		organization: "",
		experience: null,
		ongoing: false,
		start_date: null,
		end_date: null,
	};

	return emptyProject;
};

export default emptyProject;
