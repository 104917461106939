import { useState } from "react";
import { Grid, Button, Typography, Stack } from "@mui/material";
import emptyCertificate from "./emptyCertificate";
import CertificateModalContent from "./CertificateModalContent";
import PastCertificates from "./PastCertificates";
import Modal from "../../../../../components/modals/TwoButtonModal";
import { RESTUtils } from "../../../../../utilities/RESTUtils";

export default function CertificateTab({
    isEdit,
    allData,
    isPrintMode
}) {
    const { certificateData, setCertificateData, userInfo, showSnackbar } = allData;
    const [seeAllCerts, setSeeAllCerts] = useState(false);
    const uuid = userInfo.uuid;
    const [curCertificateData, setCurCertificateData] = useState(
        emptyCertificate(uuid)
    );
    const [error, setError] = useState(false);
    const [isNewCertificate, setIsNewCertificate] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState({
        ok: false,
        delete: false,
    });

    function closeModal() {
        setShowModal(false);
        setError(false);
    };

    function validateCertificate() {
        const isValid = !!curCertificateData.title;

        setError(!isValid);
        return isValid;
    };

    async function createCertificate() {
        if (validateCertificate()) {
            setButtonLoading({ ...buttonLoading, ok: true });
            setCertificateData((prevData) => [...prevData, curCertificateData]);
            setCurCertificateData(emptyCertificate(uuid));
            try {
                const response = await RESTUtils.POST(
                    RESTUtils.getAPIUrl() + `api/user/certifications/`,
                    curCertificateData
                );
                if (response.status === 201) {
                    setCertificateData((prevData) =>
                        prevData.map((certificate) => {
                            if (certificate.id === -9999) { // Replace 9999 with the desired certificate ID
                                return { ...certificate, id: response.data.id };
                            } else {
                                return certificate;
                            }
                        })
                    );
                    setShowModal(false);
                    showSnackbar("Certificate created", "success");
                } else {
                    setCertificateData((prevData) => prevData.filter((item) => item.id !== 9999));
                    showSnackbar("Something went wrong", "error");
                }
            } catch (error) {
                setCertificateData((prevData) => prevData.filter((item) => item.id !== 9999));
                console.error("Error creating certificate:", error);
                showSnackbar("Something went wrong", "error");
            } finally {
                setButtonLoading({ ...buttonLoading, ok: false });
            }
        }
    }
    async function editCertificate() {
        if (validateCertificate()) {
            setButtonLoading({ ...buttonLoading, ok: true });
            try {
                const response = await RESTUtils.PUT(
                    RESTUtils.getAPIUrl() + `api/user/certifications/${curCertificateData.id}`,
                    curCertificateData
                );
                if (response.status === 200) {
                    setCertificateData((prevData) =>
                        prevData.map((certificate) => {
                            if (certificate.id === curCertificateData.id) {
                                return curCertificateData;
                            } else {
                                return certificate;
                            }
                        })
                    );
                    setShowModal(false);
                    showSnackbar("Certificate updated", "success");
                } else {
                    showSnackbar("Something went wrong", "error");
                }
            } catch (error) {
                console.error("Error updating certificate:", error);
                showSnackbar("Something went wrong", "error");
            } finally {
                setButtonLoading({ ...buttonLoading, ok: false });
            }
        }
    }
    async function deleteCertificate() {
        setButtonLoading({ ...buttonLoading, delete: true });
        try {
            const response = await RESTUtils.DELETE(
                RESTUtils.getAPIUrl() + `api/user/certifications/${curCertificateData.id}`);
            if (response.status === 200) {
                setCertificateData((prevData) => prevData.filter((item) => item.id !== curCertificateData.id));
                setCurCertificateData(emptyCertificate(uuid));
                setShowModal(false);
                showSnackbar("Certificate deleted", "success");
            } else {
                showSnackbar("Something went wrong", "error");
            }
        } catch (error) {
            console.error("Error creating certificate:", error);
            showSnackbar("Something went wrong", "error");
        } finally {
            setButtonLoading({ ...buttonLoading, delete: false });
        }
    }

    function openNewcertificateModal() {
        setIsNewCertificate(true);
        setCurCertificateData(emptyCertificate(uuid));
        setShowModal(true);
    }

    function editcertificateModal(item) {
        setIsNewCertificate(false);
        setCurCertificateData(item);
        setShowModal(true);
    }

    const displayedCertificates = seeAllCerts ? certificateData : certificateData.slice(0, 3);
    const handleSeeMore = () => setSeeAllCerts(true);
    const handleSeeLess = () => setSeeAllCerts(false);
    const certificateContent = certificateData.length > 0 ? (
        <>
            {displayedCertificates.map((data, index) => (
                <PastCertificates
                    key={data.id}
                    certificateData={data}
                    isEdit={isEdit}
                    onClickFn={editcertificateModal}
                    addMargin={index !== displayedCertificates.length - 1}
                    isPrintMode={isPrintMode}
                />
            ))}
            {certificateData.length > 3 && (
                <Typography
                    sx={{
                        cursor: "pointer",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 1.5,
                        '&:hover': { textDecoration: "underline" }
                    }}
                    onClick={() => seeAllCerts ? handleSeeLess() : handleSeeMore()}
                >
                    {seeAllCerts ? "See Less" : "See More"}
                </Typography>
            )}
        </>
    ) : (
        <Typography paddingLeft={1}>No certificates found!</Typography>
    );


    return (
        <Grid container padding={isPrintMode ? 1 : 2}>
            <Grid item xs={12} pb={2} >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {/* <HistoryEduIcon /> */}
                    <Typography variant="h6">Certificates / Associations</Typography>
                </Stack>
            </Grid>
            {isEdit && (
                <Grid item xs={12} paddingBottom={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={openNewcertificateModal}
                    >
                        Add Certificate
                    </Button>
                </Grid>
            )}

            <Modal
                show={showModal}
                modalTitle={isNewCertificate ? "Add Certificate" : "Edit Certificate"}
                modalMessage={
                    <CertificateModalContent
                        certificateData={curCertificateData}
                        setCertificateData={setCurCertificateData}
                        error={error}
                        errorMessage={"Please fill all required fields."}
                        isEdit={isEdit}
                    />
                }
                closeButtonText="Cancel"
                closeButtonCallback={closeModal}
                okButtonText={isNewCertificate ? "Add" : "Save"}
                okButtonCallback={
                    isNewCertificate ? createCertificate : editCertificate
                }
                deleteButton={!isNewCertificate}
                deleteButtonCallBack={deleteCertificate}
                okButtonLoading={buttonLoading.ok}
                deleteButtonLoading={buttonLoading.delete}
                maxWidth="sm"
                onClickOff={isNewCertificate ? undefined : () => { }}
            />

            <Grid item xs={12}>
                {certificateContent}
            </Grid>
        </Grid>
    );
}
