import { Grid, Typography } from "@mui/material";
import DOMPurify from "dompurify";

export default function ClientViewOpportunityModal({ curJobData }) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
					Title
				</Typography>
				{curJobData.title}
			</Grid>

			<Grid item xs={6}>
				<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
					Location
				</Typography>
				{curJobData.city}
			</Grid>

			<Grid item xs={12}>
				<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
					Requirements
				</Typography>
				{curJobData.requirements ? (
					<Typography
						sx={{ marginTop: "-15px" }}
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(curJobData.requirements) }}
					/>
				) : (
					"No requirements found"
				)}
			</Grid>

			<Grid item xs={12}>
				<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
					Description
				</Typography>
				{curJobData.description ? (
					<Typography
						sx={{ marginTop: "-15px" }}
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(curJobData.description) }}
					/>
				) : (
					"No description found"
				)}
			</Grid>
		</Grid>
	);
}
