import { Typography } from "@mui/material";
import ProfessionalRegistration from "./ProfessionalRegistration";

export default function ProfessionalRegistrations({ professionalRegistration }) {
    return (
        <>
            <Typography variant="h6" color={'primary.main'} sx={{ lineHeight: 1.2, fontWeight: 'bold', mb: 2 }}>
                Professional Registrations
            </Typography>
            {professionalRegistration.map((registration, index) => (
                <ProfessionalRegistration key={index} professionalRegistration={registration} />
            ))}
        </>
    )
}