import React, { useState, useEffect } from 'react';
import { SnackBarComponent2 } from '../../../../../../components/snackbar/SnackBarComponent2';

const useUserData = (setSavedProfiles, user_uuid) => {
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [noteData, setNoteData] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [notableProjects, setNotableProjects] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [awardData, setAwardData] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const [certificateData, setCertificateData] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [userReferences, setUserReferences] = useState([]);
    const [enrollments, setEnrollments] = useState([]);
    const { SnackBarComponent, showSnackbar } = SnackBarComponent2();

    useEffect(() => {
        setSavedProfiles((prevState) => ({
            ...prevState,
            [user_uuid]: {
                awards: awardData,
                educations: educationData,
                experiences: experienceData,
                notes: noteData,
                skills: skillData,
                notable_projects: notableProjects,
                certifications: certificateData,
                references: referenceData,
                user_references: userReferences,
                enrolls: enrollments,
                user_data: userInfo,
            },
        }));
    }, [userInfo, awardData, educationData, experienceData, noteData, skillData, notableProjects, certificateData, referenceData, userReferences, enrollments]);

    const allUserData = {
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        experienceData: experienceData,
        setExperienceData: setExperienceData,
        skillData: skillData,
        setSkillData: setSkillData,
        notableProjectsData: notableProjects,
        setNotableProjectsData: setNotableProjects,
        noteData: noteData,
        setNoteData: setNoteData,
        educationData: educationData,
        setEducationData: setEducationData,
        awardData: awardData,
        setAwardData: setAwardData,
        SnackBarComponent,
        certificateData,
        setCertificateData,
        showSnackbar,
        referenceData: referenceData,
        setReferenceData: setReferenceData,
        userReferences: userReferences,
        setUserReferences: setUserReferences,
        enrollmentData: enrollments,
        setEnrollmentData: setEnrollments,
    };

    return allUserData;
};

export default useUserData;
