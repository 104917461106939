import { Card, Typography } from "@mui/material";

export default function PastEducation({
	onClickFn,
	isEdit,
	educationData,
	addMargin,
	isPrintMode
}) {
	const { degree, field_of_study, start_date, end_date, organization } =
		educationData;
	const startYear = start_date ? start_date.slice(0, 4) : null;
	const endYear = end_date ? end_date.slice(0, 4) : null;

	const topLine = field_of_study ? (degree ? `${degree} - ${field_of_study}` : field_of_study) : (degree || '');

	let bottomLine =
		(startYear && endYear && `${startYear} - ${endYear}`) ||
		(startYear && !endYear && startYear) ||
		(!startYear && endYear && endYear) ||
		"";

	if (organization && (startYear || endYear)) {
		bottomLine += ` | ${organization}`;
	} else if (organization && !startYear && !endYear) {
		bottomLine = organization;
	}

	return (
		<Card
			onClick={isEdit ? () => onClickFn(educationData) : undefined}
			sx={{
				padding: isPrintMode ? '10px' : "20px",
				transition: "background-color 0.2s ease",
				cursor: isEdit ? "pointer" : "default",
				marginBottom: addMargin ? "10px" : "0",
				'&:hover': {
					backgroundColor: isEdit ? 'secondary.cardHover' : null,
				}
			}}
		>
			<Typography variant="body1">{topLine}</Typography>
			<Typography variant="body2">{bottomLine}</Typography>
		</Card>
	);
}
