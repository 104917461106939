import React, { useState } from 'react';
import { CircularProgress, Typography, Card, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { RESTUtils } from '../../utilities/RESTUtils';
import { SessionUtils } from '../../utilities/SessionUtils';
import { useLocation } from 'react-router-dom';
import { SnackBarComponent2 } from '../snackbar/SnackBarComponent2';

const ReportAnIssueComponent = () => {
    const user = SessionUtils.getCurrentUserSession();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const { SnackBarComponent, showSnackbar } = SnackBarComponent2();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [description, setDescription] = useState('');
    const overlayStyle = {
        padding: '5px',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        transition: "background-color 0.2s ease",
        cursor: "pointer",
    };
    async function report_issue() {
        setButtonLoading(true);
        try {
            const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/public/submit_issue/', {
                description: description,
                error_type: 'reported issue',
                page: location.pathname,
                user: user?.uuid || null
            });
            if (response.status === 201) {
                showSnackbar("Issue reported", "success");
                setOpen(false);
            } else {
                // lie, it would be too embarrassing 
                showSnackbar("Issue reported", "success");
                setOpen(false);
            }

        } catch (error) {
            console.error(error);
            showSnackbar("Issue reported", "success");
            setOpen(false);
        } finally {
            setButtonLoading(false);
            setDescription('');
        }
    }
    if (!user && !location.pathname.includes('client_portal')) return null;
    if (location.pathname.includes('/print/')) return null;
    return (
        <>
            {SnackBarComponent}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle>Report an Issue</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Issue Description"
                        type="text"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        minRows={4}
                        inputProps={{ maxLength: 500 }}
                    />
                </DialogContent>
                <DialogActions sx={{ mx: '16px' }} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => report_issue()}
                        color="secondary"
                        disabled={buttonLoading}
                        startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Card sx={{ "&:hover": { backgroundColor: "#C60C30", color: '#e2e3e4' } }} style={overlayStyle} onClick={() => setOpen(true)}>
                <Typography>Report an Issue </Typography>
            </Card>
        </>
    );
}

export default ReportAnIssueComponent;
