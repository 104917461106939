import React, { useState } from 'react';
import { Stack, Typography, ListItemIcon, Menu, MenuItem, FormControlLabel, Switch } from '@mui/material';
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function CandidatetDetails({ userData, isPrintMode, sectionsOpen, setSectionsOpen }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleChange = (event) => {
        setSectionsOpen({
            ...sectionsOpen,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <Stack
            sx={{
                position: 'relative',
                zIndex: 2,
                backgroundColor: 'transparent',
                padding: '1rem',
            }}
            alignItems="left"
            mt={6}
        >
            {isPrintMode ? (
                <>
                    <Typography variant="h5" onClick={handleClick} style={{ cursor: 'pointer', display: 'inline' }}>
                        {userData?.first_name} {userData?.last_name}

                        {userData?.designations && userData.designations.length > 0 && (
                            <>
                                {userData.designations.map((designation, index) => (
                                    <React.Fragment key={designation}>
                                        {', '}
                                        <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                            {designation}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </Typography>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sectionsOpen.projects}
                                        onChange={handleToggleChange}
                                        name="projects"
                                    />
                                }
                                label="Notable Projects"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sectionsOpen.educations}
                                        onChange={handleToggleChange}
                                        name="educations"
                                    />
                                }
                                label="Educations / Certifications"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sectionsOpen.awards}
                                        onChange={handleToggleChange}
                                        name="awards"
                                    />
                                }
                                label="Awards"
                            />
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <Typography variant="h5" style={{ display: 'inline' }}>
                    {userData?.first_name} {userData?.last_name}

                    {userData?.designations && userData.designations.length > 0 && (
                        <>
                            {userData.designations.map((designation, index) => (
                                <React.Fragment key={designation}>
                                    {', '}
                                    <Typography variant="h5" style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                        {designation}
                                    </Typography>
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Typography>
            )}

            <Typography variant="h6">{userData?.main_title}</Typography>

            {(userData.city || userData.country) && (
                <Stack direction="row" alignItems="center">
                    <ListItemIcon sx={{ color: 'primary.main', marginRight: '8px', minWidth: 'auto' }}>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <Typography variant="subtitle1" style={{ margin: 0 }}>
                        {userData.city && userData.country
                            ? `${userData.city}, ${userData.country}`
                            : userData.city || userData.country || ''}
                    </Typography>
                </Stack>
            )}
        </Stack>
    )
}