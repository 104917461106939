import { Grid, TextField, Typography } from "@mui/material";

export default function NoteModalContent({
	curNoteData,
	setCurNoteData,
	errorMessage
}) {

	const handleChange = (event) => {
		const { name, value } = event.target;
		setCurNoteData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	return (
		<>
			<form>
				<Grid container>
					<Grid item xs={12} paddingTop={2}>
						<TextField
							label="Message"
							name="message"
							value={curNoteData.message}
							onChange={handleChange}
							fullWidth
							multiline
							minRows={4}
							error={!!errorMessage}
							required
						/>
					</Grid>
				</Grid>
			</form>
			{errorMessage && <Typography color="error">{errorMessage}</Typography>}
		</>
	);
}
