import {
	Box,
	Grid,
	Button,
	Container,
	CssBaseline,
	MobileStepper,
	Paper,
	Typography,
	CircularProgress
} from "@mui/material";
import { useState } from "react";
import { SessionUtils } from "../../../../utilities/SessionUtils";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import { BrowserUtils } from "../../../../utilities/BrowserUtils";
import CircleRadio from "../components/CircleRadio";
import {
	personalityQuestions,
	groupedPairs,
	personaDictionary,
	letterArray,
} from "./data";
import ThreeDSliders from "../../../../components/projectStrengths/3DPersona/ThreeDSliders";

export default function PersonalityQuizComponent() {
	const user = SessionUtils.getCurrentUserSession();
	const [onIntro, setOnIntro] = useState(true);
	const [activeStep, setActiveStep] = useState(0);
	const [values, setValues] = useState({
		extrovert: Array(12).fill(3),
		thinking: Array(12).fill(3),
		assertive: Array(12).fill(3),
	});
	const [buttonLoading, setButtonLoading] = useState(false);

	const NUM_OPTIONS = 5;
	const totalSteps = 4;

	function handleNext() {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	function handleBack() {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	async function handleSubmit() {
		setButtonLoading(true);
		//do it this way for the specific order that we can then convert to array
		const stages = ["extrovert", "thinking", "assertive"];
		const totals = Array(3);
		const letters = Array(3);
		let count = 0;

		for (const category of stages) {
			totals[count] = values[category].reduce(
				(acc, value) => acc + value,
				0
			);
			letters[count] =
				totals[count] >= 36
					? letterArray[count][0]
					: letterArray[count][1];
			count++;
		}
		const persona = personaDictionary[letters.join("")];

		values.totals = totals;
		values.persona = persona;
		values.user_data = user.uuid;
		const response = await RESTUtils.POST(
			RESTUtils.getAPIUrl() + `api/users/savePersonality/`,
			values
		);
		if (response.status === 200 || response.status === 201) {
			BrowserUtils.redirect(`/profile/edit/${user.uuid}`);
		} else {
			console.log(response.data);
		}
		setButtonLoading(false);
	};

	function pageContent() {
		const curPage = groupedPairs[activeStep].map((question) => {
			const category = question[0];
			const index = question[1];

			return (
				<Grid
					item
					key={`${category}-${index}`}
					xs={12}
					textAlign="center"
				>
					<CircleRadio
						key={`${category}-${index}`}
						value={values[category][index]}
						category={category}
						index={index}
						question={
							personalityQuestions[category][index].question
						}
						inverse={personalityQuestions[category][index].inverse}
						setValues={setValues}
						NUM_OPTIONS={NUM_OPTIONS}
					/>
				</Grid>
			);
		});

		// Now render the array of components
		return (
			<Grid container spacing={2}>
				{curPage}
			</Grid>
		);
	};

	const threeDData = {
		"totals": [
			38,
			34,
			36
		],
		"persona": "Assertive Altruist"
	}

	return (
		<Container component="main" maxWidth="sm" sx={{ pt: 1 }}>
			<Paper
				elevation={6}
				sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
			>
				<CssBaseline />

				{onIntro === true ? (
					<Grid container padding={2} justifyContent={'center'}>
						<Grid item xs={12} textAlign={'center'}>
							<Typography variant="h5">
								Personality Quiz
							</Typography>
						</Grid>
						<Grid item xs={12} textAlign={'center'} py={2}>
							<Typography variant="body1" textAlign={'left'}>
								Please answer the following questions to help us get to know you better.
								This quiz will determine your personality type based on the answers you provide.
								Peter Lucas uses three axes to create a persona.
							</Typography>
							<Typography variant="body1" textAlign={'left'} py={2} sx={{ textDecoration: 'underline' }}>
								Sample Personality Results:
							</Typography>
							<ThreeDSliders threeDData={threeDData} />
							<Typography>
								Click begin to start the quiz.
							</Typography>
						</Grid>
						<Button variant="outlined" color="secondary" onClick={() => { setOnIntro(false) }}>
							Begin
						</Button>
					</Grid>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
						component="form"
					>
						<Box
							sx={{
								textAlign: "center",
								backgroundColor: "transparent",
								color: "secondary.main",
							}}
						>
							<Typography
								component="p"
								variant="h6"
								color={"secondary.main"}
							>
								Personality Questionnaire
							</Typography>

						</Box>
						<MobileStepper
							variant="dots"
							steps={totalSteps}
							position="static"
							activeStep={activeStep}
							sx={{
								flexGrow: 1,
								width: "100%",
								backgroundColor: "transparent",
								"& .MuiMobileStepper-dot": {
									backgroundColor: "grey",
								},
								"& .MuiMobileStepper-dotActive": {
									backgroundColor: "primary.main",
								},
								border: "0px",
							}}
							nextButton={
								<>
									<Button
										onClick={handleNext}
										disabled={activeStep === totalSteps - 1}
										variant="outlined"
										size="small"
										color="secondary"
									>
										Next
									</Button>
								</>
							}
							backButton={
								<Button
									onClick={activeStep === 0 ? () => setOnIntro(true) : handleBack}
									variant="outlined"
									size="small"
									color="secondary"
								>
									Back
								</Button>
							}
						/>

						{pageContent()}

						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								paddingTop: "15px",
							}}
						>
							<Button
								onClick={activeStep === 0 ? () => setOnIntro(true) : handleBack}
								variant="outlined"
								size="small"
								color="secondary"
							>
								Back
							</Button>
							{activeStep !== totalSteps - 1 ? (
								<Button
									onClick={handleNext}
									disabled={activeStep === totalSteps - 1}
									variant="outlined"
									size="small"
									color="secondary"
								>
									Next
								</Button>
							) : (
								<Button
									onClick={handleSubmit}
									variant="outlined"
									size="small"
									disabled={!(activeStep === totalSteps - 1) || buttonLoading}
									startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
								>
									Submit
								</Button>
							)}
						</Box>
					</Box>
				)}
			</Paper>
		</Container>
	);
};
