import { useState } from "react";
import {
  Radio,
  FormControlLabel,
  Tooltip,
  Typography,
  Grid,
  RadioGroup,
  FormControl
} from "@mui/material";
import { useThemeContext } from "../../../../themes/ThemeProviderWrapper";

export default function CircleRadio({
  value,
  category,
  index,
  setValues,
  question,
  inverse = false,
  NUM_OPTIONS,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const { theme } = useThemeContext();

  const color = theme === 'dark' ?
    isFocused ? "#e2e3e4" : "#6c6e70" :
    isFocused ? "#000" : "#7f7f7f";

  const labels = [
    "Strongly Agree",
    "Agree",
    // "Slightly Agree",
    "Neither Agree nor Disagree",
    // "Slightly Disagree",
    "Disagree",
    "Strongly Disagree",
  ];

  function handleChange(event) {
    const selectedValue = parseInt(event.target.value, 10);
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      if (inverse) {
        newValues[category][index] = selectedValue;
      } else {
        newValues[category][index] = (NUM_OPTIONS + 1) - selectedValue;
      }
      newValues[category][`${index}_selected`] = selectedValue !== value;
      return newValues;
    });
  }

  return (
    <FormControl component="fieldset" sx={{ alignItems: "center" }}>
      <Typography>{question}</Typography>
      <RadioGroup
        aria-label="options"
        name="options"
        value={inverse ? value : (NUM_OPTIONS + 1) - value}
        onChange={handleChange}
      >
        <Grid container alignItems="center" padding={1} spacing={1} justifyContent="center">
          <Grid item xs={2}>
            <Typography sx={{ color: color }}>
              Agree
            </Typography>
          </Grid>
          {labels.map((label, idx) => (
            <Grid item key={idx} xs={8 / NUM_OPTIONS}>
              <Tooltip title={label} arrow>
                <FormControlLabel
                  value={idx + 1}
                  control={
                    <Radio
                      sx={{
                        color: color,
                        "&.Mui-checked": {
                          color: "primary.main",
                        },
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      size="small"
                    />
                  }
                  label=""
                />
              </Tooltip>
            </Grid>
          ))}
          <Grid item xs={2}>
            <Typography sx={{ color: color }}>
              Disagree
            </Typography>
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}