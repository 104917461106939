import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import PastExperiences from "./PastExperiences";

export default function ExperienceTab({ experiences, skills }) {
    // Replace experiences with experiences prop when done
    const [seeMore, setSeeMore] = useState(false);

    const hasInvisibleExperiences = experiences.some(experience => experience.hidden);
    const hasAnyExperiences = experiences.length > 0;
    const shouldShowAllExperiences = !experiences.some(experience => !experience.hidden) && hasAnyExperiences; // Show all experiences if none are visible, as a fallback
    const displayedExperiences = shouldShowAllExperiences || seeMore
        ? experiences
        : experiences.filter(experience => !experience.hidden);

    return (
        <>
            {displayedExperiences.length > 0 ? (
                displayedExperiences.map((data, index) => {
                    const filteredSkills = skills.filter(
                        (skill) => skill.experience === data.id
                    );

                    return (
                        <Grid item xs={12} py={0.5} key={index}>
                            <PastExperiences
                                key={index}
                                experienceData={data}
                                curSkills={filteredSkills}
                            />
                        </Grid>
                    );
                })
            ) : (
                <Typography paddingLeft={1}>No experiences found.</Typography>
            )}

            {hasInvisibleExperiences && !shouldShowAllExperiences && (
                <Grid container justifyContent="center">
                    <Typography
                        sx={{
                            cursor: "pointer",
                            pt: 1,
                            color: '#71b7fb',
                            '&:hover': { color: "#8ed8ff" }
                        }}
                        onClick={() => setSeeMore((prevValue) => !prevValue)}
                    >
                        {seeMore ? "View Less" : "View All Experiences"}
                    </Typography>
                </Grid>
            )}
        </>
    )
}