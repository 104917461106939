import ThreeDSliders from "../../../../components/projectStrengths/3DPersona/ThreeDSliders";
import { Typography, Grid, Chip, Paper } from "@mui/material";
import { data } from "../../../../components/projectStrengths/3DPersona/data";
import { personaDictionary } from "../../quizzes/personalityQuiz/data";

export default function ThreeDPersona({ threeDData, theme }) {
	function getLetterForStage(total, halfMax, index) {
		const lettersMap = ['E', 'T', 'A'];
		const alternativeLettersMap = ['I', 'F', 'D'];

		if (total >= halfMax) {
			return lettersMap[index];
		} else {
			return alternativeLettersMap[index];
		}
	}
	function getPersonaFromTotals(totals) {
		const halfMax = 36;
		const letters = totals.map((total, index) => getLetterForStage(total, halfMax, index));

		const personaKey = letters.join('');
		return personaDictionary[personaKey];
	}

	const persona = threeDData?.persona ? threeDData?.persona : getPersonaFromTotals(threeDData?.totals);
	const traits = data[persona] || [];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Paper sx={{ padding: 2 }}>
					<Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: '8px' }}>Personality</Typography>
					<ThreeDSliders threeDData={threeDData} />
				</Paper>
			</Grid>

			<Grid container item xs={12} md={6} justifyContent={'right'} textAlign={'right'}>
				<Paper sx={{ padding: 1, height: '80px', width: '90%' }}>
					<Typography
						variant="h4"
						style={{
							fontWeight: "bold",
							textAlign: "right",
						}}
					>
						3D Persona Results
					</Typography>
				</Paper>
				<Paper
					sx={{
						backgroundColor: "#FFFFFF",
						width: '80%',
						marginTop: '-25px',
						marginRight: 'auto',
						ml: 5,
					}}>
					<Typography
						color="#C60C30"
						sx={{ paddingTop: "10px", paddingRight: "20px" }}
					>
						Your 3-D Persona is:
					</Typography>
					<Typography
						color="#1C1B1C"
						sx={{
							fontSize: 20,
							fontWeight: "bold",
							paddingBottom: "10px",
							paddingRight: "20px",
						}}
					>
						{persona}
					</Typography>
				</Paper>

				<Grid container spacing={1} mt={1} pl={5} >
					{traits && traits.map((item, index) => (
						<Grid item key={index}>
							<Chip
								label={item}
								sx={{
									backgroundColor: theme === 'dark' ? '#555' : '#aaa',
								}}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
}