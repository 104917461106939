import PhoneInput from 'react-phone-input-2'
import { styled } from '@mui/material';
import 'react-phone-input-2/lib/style.css';

const StyledPhoneInput = styled(PhoneInput)`
    .custom-input {
        background: transparent !important;
        color: white !important;
        width: 100% !important;
        height: 40px !important;
        border: 1px solid #6c6e70 !important;
        padding-left: 10px;
    }
    .custom-input:hover {
        border: 1px solid #D4D5D6 !important;
    }
    .custom-input:focus {
        border: 2px solid #fff !important;
    }
`;
export default function PhoneInputDark({ value, handlePhoneChange }) {

    return (
        <StyledPhoneInput
            country={'ca'}
            value={value || ""}
            onChange={handlePhoneChange}
            inputClass="custom-input"
            disableDropdown={true}
            disableSearchIcon={true}
            placeholder='Phone Number'
            inputProps={{
                name: 'phone',
                required: true,
            }}
            buttonStyle={{ display: 'none' }}
        />
    )
}