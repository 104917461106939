import React from "react";
import {
	Paper,
	Container,
	Button,
	Grid,
	Box,
	CircularProgress,
	Typography,
} from "@mui/material";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SidebarComponent from "../../../components/sidebar";
import Tabs from "./components/Tabs";
import OverviewTab from "./components/OverviewTab";
import UsersTab from "./components/UsersTab";
import usersData from "./usersData";
import EnrollmentsTab from "./components/EnrollmentsTab";
import TasksTab from "./components/TasksTab";
import { convertUserData, convertData, compileData } from "./convertingFns";

export default function AdminAnalyticsPage() {
	const [data, setData] = React.useState({});
	const [error, setError] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const [index, setIndex] = React.useState(0);
	const [dates, setDates] = React.useState({
		start_date: "2011-01-01",
		end_date: "2024-01-01",
	});
	// Data organized in arrays for excel
	const [enrollmentData, setEnrollmentData] = React.useState([]);
	const [taskData, setTaskData] = React.useState([]);
	const [overviewData, setOverviewData] = React.useState([]);
	const [userData, setUserData] = React.useState([]);
	const [excelData, setExcelData] = React.useState([]);
	function handleDateChange(event) {
		const { name, value } = event.target;
		setDates((prevData) => ({
			...prevData,
			[name]: dayjs(value).format("YYYY-MM-DD"),
		}));
	}

	function renderContent() {
		if (isLoading) {
			return (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "150px",
					}}
				>
					<CircularProgress />
				</Box>
			);
		} else if (error) {
			return <Typography error>Error</Typography>;
		} else {
			return tabContent[index];
		}
	}

	async function loadData() {
		setIsLoading(true);

		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
					`api/admin/getStats/?start=${dates.start_date}&end=${dates.end_date}`
			);
			setUserData(convertUserData(response.data.users));
			setOverviewData(convertData(response.data.overview));
			setTaskData(convertData(response.data.tasks));
			setEnrollmentData(convertData(response.data.enrollments));
			setExcelData(
				compileData(overviewData, userData, enrollmentData, taskData)
			);
			setData(response.data);

			setError(false);
		} catch (error) {
			setError(true);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}
	function exportToExcel() {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			excelData.map((row) => row.join(",")).join("\n");
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute(
			"download",
			"Bench Metrics for " +
				dates.start_date +
				" to " +
				dates.end_date +
				".csv"
		);
		document.body.appendChild(link);
		link.click();
	}
	React.useEffect(() => {
		loadData();
	}, []);

	const tabContent = [
		<OverviewTab data={data.overview} />,
		<UsersTab userData={userData} />,
		<EnrollmentsTab data={data.enrollments} />,
		<TasksTab data={data.tasks} />,
	];

	return (
		<Container component="main" sx={{ p: { xs: 2, md: 3 } }}>
			<Grid
				wrap="nowrap"
				container
				style={{ height: "100%", paddingTop: "20px" }}
			>
			
				<Grid container>
					<Paper
						style={{
							paddingBottom: "15px",
							padding: "20px",
							width: "100%",
						}}
					>
						<Grid container>
							<Grid item xs={5} md={5} paddingBottom={2} paddingRight={1}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DatePicker
										label="Start Date"
										format="YYYY-MM-DD"
										onChange={(newValue) =>
											handleDateChange({
												target: {
													name: "start_date",
													value: newValue,
												},
											})
										}
										slotProps={{
											textField: { fullWidth: true },
										}}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={5} md={5} paddingLeft={1}>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DatePicker
										label="End Date"
										format="YYYY-MM-DD"
										onChange={(newValue) =>
											handleDateChange({
												target: {
													name: "end_date",
													value: newValue,
												},
											})
										}
										slotProps={{
											textField: { fullWidth: true },
										}}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={2} md={2} paddingLeft={2}>
								<Button
									variant="contained"
									onClick={loadData}
									sx={{ marginTop: "10px" }}
								>
									Ok
								</Button>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Tabs
								index={index}
								setIndex={setIndex}
								isLoading={isLoading}
							/>
						</Grid>
						<Paper style={{ minHeight: "450px" }}>
							<Grid item xs={12} padding={2}>
								{renderContent()}
							</Grid>
						</Paper>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								marginTop: "16px",
							}}
						>
							<Button onClick={exportToExcel} variant="contained">
								Export to Excel
							</Button>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
}
