import { Container } from "@mui/material";
import { containerStyle } from "./styles";
import { profileData } from "./data";
import TopPage from "./components/top";
import BottomPage from "./components/bottom";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { useEffect, useState, createContext } from "react";
import { useParams } from "react-router-dom";

export const TemplateContext = createContext();
export default function PDFEdit() {
    // const [profileData, setProfileData] = useState(profileData);
    const [templateData, setTemplateData] = useState(profileData);
    const { user_uuid, template_uuid } = useParams();

    // async function getPDFTemplateData() {
    //     try {
    //         const response = await RESTUtils.GET(RESTUtils.getAPIUrl() + `api/admin/get_pdf_template/?template_uuid=${template_uuid}`);
    //         if (response.status === 200) {
    //             setTemplateData(response.data);
    //         } else {
    //             console.error("Error retrieving PDF template");

    //         }
    //     } catch (error) {
    //         console.error("Error retrieving PDF template:", error);

    //     }

    // }
    // async function fetchUserData() {
    //     try {
    //         const response = await RESTUtils.GET(RESTUtils.getAPIUrl() + `api/admin/get_user_data/?user_uuid=${user_uuid}&edit_mode=true`);
    //         if (response.status === 200) {
    //             setProfileData(response.data);
    //         } else {
    //             console.error("Error retrieving user data");
    //         }
    //     } catch (error) {
    //         console.error("Error retrieving user data:", error);
    //     }
    // }

    // useEffect(() => {
    //     getPDFTemplateData();
    //     fetchUserData();
    // }, []);
    // API to fetch raw data
    // concatenate fields and add visible field 
    // ???
    // profit


    return (
        <TemplateContext.Provider value={{ templateData, setTemplateData }}>
            <Container style={containerStyle}>
                <TopPage />
                {/* <BottomPage /> */}
            </Container>
        </TemplateContext.Provider>
    )
}