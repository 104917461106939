import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography, Divider, Stack, CircularProgress, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RESTUtils } from "../../../utilities/RESTUtils";

export default function EmailVerified() {
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { key } = useParams();


  async function verifyEmail() {
    try {
      const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + 'api/user/account-confirm-email/', {
        key: key
      });
      if (response.status === 200) {
        setIsEmailVerified(true);
      }
    } catch (error) {
      console.error('An error occurred while verifying email:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  useEffect(() => {
    let countdownInterval;
    if (isEmailVerified) {
      countdownInterval = setInterval(() => {
        if (secondsLeft > 1) {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        } else {
          window.location.href = "/login";
        }
      }, 1000);
      return () => clearInterval(countdownInterval);
    }

  }, [secondsLeft, isEmailVerified]);

  return (
    <Container component="main" sx={{ p: { xs: 2, md: 3 } }} maxWidth={false}>
      <Grid container padding={2}>
        {loading ? (
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <CircularProgress />
              <Typography variant="body1">Verifying Email...</Typography>
            </Stack>
          </Grid>
        ) : (
          <>
            {!isEmailVerified ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">Email Verification Failed <br /> Please try logging in again and a
                    new verification email will be sent. </Typography>

                </Grid>
                <Grid item xs={12} py={1}>
                  <Button variant="outlined" color="secondary" href="/login">Go To Login</Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">Email Successfully Verified</Typography>
                </Grid>
                <Grid item xs={12} py={1}>
                  <Divider />
                </Grid>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body1" color="success">
                    Redirecting you to login in{' '}
                    {secondsLeft} {secondsLeft === 1 ? 'second' : 'seconds'}...
                  </Typography>
                  <CheckCircleIcon sx={{ color: 'green' }} />
                </Stack>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );

}