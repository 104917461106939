import { Grid, CircularProgress } from "@mui/material";
import { loadingStyles } from "./styles";

export default function Loading() {
	return (
		<Grid container sx={loadingStyles}>
			<CircularProgress />
		</Grid>
	);
}
