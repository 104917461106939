import { useState, useContext } from "react";
import {
	TableRow,
	TableCell,
	MenuItem,
	Typography,
	TextField,
	IconButton,
	Button,
	Collapse,
	Box,
} from "@mui/material";
import { benchStatus, buckets } from "../data";
import { UserActionsContext } from "../UserActions";
import { snakeCaseToTitleCase } from "../../../../../../utilities/Helpers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function AwaitingReviewRow({ row, setUsers }) {
	const [jobIndex, setJobIndex] = useState(0);
	const { changeJobBucket, changeBenchStatus, handleNameClick, saveRundown } =
		useContext(UserActionsContext);
	const [rundownVisible, setRundownVisible] = useState(false);

	function changeJob(row, job) {
		setJobIndex(row.jobs.indexOf(job));
	}
	function handleRundownChange(event) {
		setUsers((prev) => {
			const newUsers = [...prev];
			const index = newUsers.findIndex((user) => user.uuid === row.uuid);
			newUsers[index].rundowns[jobIndex] = event.target.value;
			return newUsers;
		});
	}

	function saveCurRundown() {
		saveRundown(row.enroll_ids[jobIndex], row.rundowns[jobIndex]);
	}
	function changeUserBenchStatus(user_uuid, status) {
		if (changeBenchStatus(user_uuid, status)) {
			setUsers((prev) => {
				const newUsers = [...prev];
				const index = newUsers.findIndex(
					(user) => user.uuid === user_uuid
				);
				newUsers[index].bench_status = status;
				return newUsers;
			});
		}
	}
	function changeUserBucket(user_uuid, jobID, bucket) {
		if (changeJobBucket(user_uuid, jobID, bucket)) {
			setUsers((prev) => {
				const newUsers = [...prev];
				const index = newUsers.findIndex(
					(user) => user.uuid === user_uuid
				);
				newUsers[index].buckets[jobIndex] = bucket;
				return newUsers;
			});
		}
	}

	return (
		<>
			<TableRow key={row.uuid}>
				<TableCell
					style={{
						cursor: "pointer",
						borderBottom: "1px solid #6c6e70",
					}}
					onClick={() => handleNameClick(row.uuid)}
				>
					<Typography
						sx={{
							color: "primary.contrastText",
							"&:hover": {
								color: "primary.main",
							},
						}}
					>
						{row.first_name + " " + row.last_name}
					</Typography>
				</TableCell>
				<TableCell
					style={{
						cursor: "pointer",
						borderBottom: "1px solid #6c6e70",
					}}
				> 
				{row.job_ids.length > 0 && (
					<IconButton
						onClick={() => setRundownVisible(!rundownVisible)}
					>
						{rundownVisible ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				)}
					
				</TableCell>
				<TableCell sx={{ borderBottom: "1px solid #6c6e70" }}>
					<TextField
						select
						variant="standard"
						value={row.bench_status || ""}
						size="small"
						onChange={(e) =>
							changeUserBenchStatus(row.uuid, e.target.value)
						}
						InputProps={{
							inputProps: {
								sx: { borderBottom: "1px solid lightgrey" },
							},
						}}
						fullWidth
					>
						{benchStatus.map((status) => (
							<MenuItem key={status} value={status} dense>
								{status}
							</MenuItem>
						))}
					</TextField>
				</TableCell>
				<TableCell sx={{ borderBottom: "1px solid #6c6e70" }}>
					{row.jobs.length <= 1 ? (
						<Typography>{row.jobs.join(", ")}</Typography>
					) : (
						<TextField
							select
							variant="standard"
							value={row.jobs[jobIndex] || ""}
							fullWidth
							size="small"
							InputProps={{
								inputProps: {
									sx: { borderBottom: "1px solid lightgrey" },
								},
							}}
							onChange={(e) => changeJob(row, e.target.value)}
						>
							{row.jobs.map((job) => (
								<MenuItem key={job} value={job}>
									{job}
								</MenuItem>
							))}
						</TextField>
					)}
				</TableCell>
				<TableCell sx={{ borderBottom: "1px solid #6c6e70" }}>
					{row.buckets[jobIndex] && (
						<TextField
							select
							variant="standard"
							fullWidth
							size="small"
							InputProps={{
								inputProps: {
									sx: { borderBottom: "1px solid lightgrey" },
								},
							}}
							value={
								snakeCaseToTitleCase(row?.buckets[jobIndex]) ||
								""
							}
							onChange={(e) =>
								changeUserBucket(
									row.uuid,
									row.job_ids[jobIndex],
									e.target.value
								)
							}
						>
							{buckets.map((bucket, index) => (
								<MenuItem key={index} value={bucket}>
									{bucket}
								</MenuItem>
							))}
						</TextField>
					)}
				</TableCell>
			</TableRow>

			<TableRow>
				<TableCell
					colSpan={5}
					sx={{
						borderBottom: "1px solid #6c6e70",
						paddingBottom: 0,
						paddingTop: 0,
					}}
				>
					<Collapse in={rundownVisible}>
						<Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
							<TextField
								multiline
								rows={4}
								fullWidth
								name="rundown"
								label="Rundown"
								value={row.rundowns[jobIndex] || ""}
								onChange={handleRundownChange}
							/>
							<Button
								onClick={() => saveCurRundown()}
								variant="outlined"
								color="secondary"
								sx={{ marginTop: 1 }}
							>
								Save
							</Button>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
