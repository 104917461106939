import { Typography } from "@mui/material";


export default function DeleteModalContent() {
    return (
        <Typography>
            Are you sure you want to delete your account?
            Deleting your account will permanently remove all of your data, and this action cannot be undone.
        </Typography>
    )
}