import React, { useState } from "react";
import {
    Divider,
    Grid,
    Typography,
    Chip,
    Stack,
    Menu,
    MenuItem,
    IconButton
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CandidateListCard from "./CandidateListCard";
import RejectedCard from "./RejectedCard";

export default function CandidateListMobile({
    userList,
    handleCardClick,
    changeUserStatus,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const shortlisted = userList.filter(
        (candidate) => candidate.status === "shortlisted"
    );
    const proposed = userList.filter(
        (candidate) => candidate.status === "proposed"
    );
    const rejected = userList.filter(
        (candidate) => candidate.status === "rejected"
    );

    return (
        <Grid container alignItems="center" padding={1.5} spacing={1}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <IconButton
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                        size="large"
                    >
                        <MenuIcon fontSize="large" />
                    </IconButton>
                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        {/* XXXXXXXXXXXXXX SHORTLISTED XXXXXXXXXXXXXX */}
                        {shortlisted.map((candidate, index) => (
                            <MenuItem onClick={handleMenuClose} key={index}>
                                <CandidateListCard
                                    handleCardClick={handleCardClick}
                                    candidate={candidate}
                                />
                            </MenuItem>
                        ))}

                        {/* XXXXXXXXXXXXXX PROPOSED XXXXXXXXXXXXXX */}
                        {proposed.map((candidate, index) => (
                            <MenuItem onClick={handleMenuClose} key={index}>
                                <CandidateListCard
                                    handleCardClick={handleCardClick}
                                    candidate={candidate}
                                />
                            </MenuItem>
                        ))}

                        {/* XXXXXXXXXXXXXX REJECTED XXXXXXXXXXXXXX */}
                        {rejected.length > 0 && (
                            <Divider
                                sx={{
                                    backgroundColor: "#282728",
                                    "&::before, &::after": {
                                        borderColor: "#c60c30",
                                    },
                                }}
                            >
                                <Chip
                                    label={`Disqualified`}
                                    sx={{ fontSize: "15px" }}
                                />
                            </Divider>
                        )}
                        {rejected.map((candidate, index) => (
                            <div style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px' }}>
                                <RejectedCard candidate={candidate} changeUserStatus={changeUserStatus} key={index} />
                            </div>
                        ))}
                    </Menu>
                    <Typography
                        variant="h6"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "bold",
                                marginRight: "0.3rem",
                            }}
                        >
                            CANDIDATE
                        </span>
                        <span style={{ color: "#C60C30" }}>LIST</span>
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}
