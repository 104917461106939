import { useMediaQuery, useTheme, Grid, Typography, Box, Button, Avatar } from "@mui/material";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import { stepData } from "./data";
export default function StepInfoBox({ curStep, uuid }) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  function handleGoToProfileClick() {
    BrowserUtils.redirect(`/profile/edit/${uuid}`);
  }
  function handleGoToPersonalityQuizClick() {
    BrowserUtils.redirect(`/personality_quiz`);
  }
  function handleGoToProjectStrengthsQuizClick() {
    BrowserUtils.redirect(`/project_strengths`);
  }

  return (
    <Grid container spacing={1} p={1}>
      <Grid item xs={12} md={stepData[curStep].leftXs} textAlign={"left"} pt={1} >
        <Grid item xs={12}>
          <Typography variant="h5">{stepData[curStep].title}</Typography>
        </Grid>
        <Grid item xs={12} pt={1}>
          <Typography variant="body1">{stepData[curStep].description}</Typography>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid
          item
          xs={stepData[curStep].rightXs}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {curStep === 0 ? (
            <Avatar
              src={stepData[curStep].image}
              alt="profile"
              style={{ height: '16ch', width: '16ch' }}
            />
          ) :
            <Box
              component={'img'}
              src={stepData[curStep].image}
              alt="step"
              style={{
                height: stepData[curStep].imgHeight,
                width: 'auto',
              }}
            />
          }
        </Grid>
      )}

      <Grid item xs={12} textAlign={"center"} alignItems={'flex-end'}>
        {curStep === 1 ? (
          <div style={{ paddingTop: '10px' }}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleGoToPersonalityQuizClick}
              sx={{ mr: 1 }}
            >
              Go to Personality Quiz
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleGoToProjectStrengthsQuizClick}
              sx={{ mr: 1 }}
            >
              Go to Project Strengths Quiz
            </Button>
          </div>
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleGoToProfileClick}
            sx={{ marginTop: '10px' }}
          >
            Go to Profile
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
