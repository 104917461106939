import { Grid, TextField, Typography } from "@mui/material";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";

export default function AddReferenceForm({
    error,
    showReferenceConfirmModal,
    setShowReferenceConfirmModal,
    okButtonLoading,
    newReference,
    setNewReference,
    handleSendReferenceRequest
}) {

    function handleChange(event) {
        const { name, value } = event.target;
        setNewReference((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    return (
        <>
            <TwoButtonModal
                show={showReferenceConfirmModal}
                modalTitle="Confirm Verification Request"
                modalMessage={
                    <>
                        <Typography>
                            Sending a reference request will send an email to the intended reference. Make sure the below details are correct.
                            Modifying this experience after the reference has confirmed the details will nullify the reference.
                        </Typography>
                        <Typography sx={{ paddingTop: '5px' }}>
                            <b>Contact Name:</b> {newReference.name} <br />
                            <b>Email:</b> {newReference.email} <br />
                            <b>Title:</b> {newReference.role}
                        </Typography>
                    </>
                }
                closeButtonText="Cancel"
                closeButtonCallback={() => { setShowReferenceConfirmModal(false) }}
                okButtonText="Confirm"
                okButtonCallback={handleSendReferenceRequest}
                okButtonLoading={okButtonLoading}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Please add a reference for this position. Please note that this will send a reference request email to the given contact.</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="name"
                        name="name"
                        label="Contact Name"
                        size="small"
                        value={newReference.name}
                        onChange={handleChange}
                        fullWidth
                        error={error && !newReference.name}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="role"
                        name="role"
                        label="Role to you"
                        size="small"
                        value={newReference.role}
                        onChange={handleChange}
                        fullWidth
                        error={error && !newReference.role}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        size="small"
                        value={newReference.email}
                        onChange={handleChange}
                        fullWidth
                        error={error && !newReference.email}
                    />
                </Grid>
            </Grid>
        </>
    )
}