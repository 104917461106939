import { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import emptyExperience from "./emptyExperience";
import ExperiencesModal from "./experiencesModal/details";
import PastExperiences from "./PastExperiences";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import UnsavedChangesDialog from "./experiencesModal/UnsavedChangesDialog";
// import ExperiencesModal from "./experiencesModal/ExperienceModal";



export default function ExperienceTab({
	isEdit,
	allData,
	isPrintMode = false,
}) {
	const { userInfo, showSnackbar, setSkillData, setExperienceData, experienceData, skillData, referenceData, setReferenceData } = allData;
	const [curExperienceData, setCurExperienceData] = useState(
		emptyExperience(userInfo.uuid)
	);
	const [experienceID, setExperienceID] = useState();
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [unsavedChangesShowModal, setUnsavedChangesShowModal] =
		useState(false);
	const [buttonLoading, setButtonLoading] = useState({
		ok: false,
		delete: false,
	});

	const visibleExperiences = isPrintMode ? experienceData.filter((data) => !data.hidden) : experienceData;

	function closeDetailsModal(fromSaved = false, dataChanged = false) {
		if (fromSaved) {
			setShowDetailsModal(false);
		}
		else if (dataChanged) {
			setUnsavedChangesShowModal(true);
		} else {
			setShowDetailsModal(false);
		}
	}

	function closeModals() {
		setUnsavedChangesShowModal(false);
		setShowDetailsModal(false);
	}

	function getExperienceData() {
		const experience_data = curExperienceData;
		experience_data.start_date = `${curExperienceData.startYear}-${curExperienceData.startMonth || '01'}-${curExperienceData.startDay || '01'}`;

		if (curExperienceData.end_date !== null) {
			experience_data.end_date = `${curExperienceData.endYear}-${curExperienceData.endMonth || '01'}-${curExperienceData.endDay || '01'}`;
		}

		return experience_data;
	}

	async function handleCreateExperience() {
		setButtonLoading((prevState) => ({ ...prevState, ok: true }));
		try {
			const experience_data = getExperienceData();

			const response = await RESTUtils.POST(
				RESTUtils.getAPIUrl() + "api/user/createExperience/",
				experience_data
			);
			if (response.status === 201) {
				curExperienceData.id = response.data;
				setExperienceData((prevExperienceData) => [
					...prevExperienceData,
					curExperienceData,
				]);
				closeDetailsModal(true);
				showSnackbar("Experience Created", "success");
			} else {
				showSnackbar("Something went wrong", "error");
			}
		} catch (error) {
			console.log(error);
			showSnackbar("Something went wrong", "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, ok: false }));
		}
	}

	async function handleEditExperience() {
		setButtonLoading((prevState) => ({ ...prevState, ok: true }));
		try {
			const experience_data = getExperienceData();

			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() +
				`api/user/editExperience/?q=${curExperienceData.id}`,
				experience_data
			);
			if (response.status === 200) {
				setExperienceData((prevExperienceData) => {
					const updatedIndex = prevExperienceData.findIndex(
						(experience) => experience.id === curExperienceData.id
					);
					if (updatedIndex !== -1) {
						const updatedExperienceData = [...prevExperienceData];
						updatedExperienceData[updatedIndex] = curExperienceData;
						return updatedExperienceData;
					}

					return prevExperienceData;
				});
				closeDetailsModal(true);
				showSnackbar("Experience Updated", "success");
			} else {
				console.log(response.data);
				showSnackbar("Something went wrong", "error");
			}
		} catch (error) {
			console.log("Error:", error);
			showSnackbar("Something went wrong", "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, ok: false }));
		}
	}

	async function handleDeleteExperience() {
		setButtonLoading((prevState) => ({ ...prevState, delete: true }));
		const removedSkills = skillData.filter((skill) => skill.experience === curExperienceData.id);
		try {
			const response = await RESTUtils.DELETE(
				RESTUtils.getAPIUrl() +
				`api/user/deleteExperience/?q=${curExperienceData.id}`
			);
			if (response.status === 200) {
				closeDetailsModal(true);
				showSnackbar("Experience Deleted", "success");
				removedSkills.forEach((deletedSkill) => {
					setSkillData((prevSkillData) =>
						prevSkillData.filter((skill) => skill.id !== deletedSkill.id)
					);
				});
				setExperienceData((prevExperienceData) =>
					prevExperienceData.filter(
						(experience) => experience.id !== curExperienceData.id
					)
				);

			} else {
				console.log(response.data);
				showSnackbar("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Error deleting experience:", error);
			showSnackbar("Something went wrong", "error");
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, delete: false }));
		}
		return;
	}

	function openNewExperienceModal() {
		setCurExperienceData(emptyExperience(userInfo.uuid));
		setExperienceID(0);
		setShowDetailsModal(true);
	};

	function editExperienceModal(item) {
		if (item.start_date) {
			item.startYear = item.start_date.substring(0, 4);
			item.startMonth = item.start_date.substring(5, 7);
			item.startDay = item.start_date.substring(8, 10);
		} else {
			item.startYear = "";
			item.startMonth = "";
			item.startDay = "";
		}

		if (item.end_date) {
			item.endYear = item.end_date.substring(0, 4);
			item.endMonth = item.end_date.substring(5, 7);
			item.endDay = item.end_date.substring(8, 10);
		} else {
			item.endYear = "";
			item.endMonth = "";
			item.endDay = "";
		}
		// item.start_date = '';
		// item.end_date = '';
		setCurExperienceData(item);
		setExperienceID(item.id);
		setShowDetailsModal(true);
	};

	function setClickedExperience(item) {
		setCurExperienceData(item);
		setExperienceID(item.id);
	}
	return (
		<Grid container padding={isPrintMode ? 1 : 2}>
			{isEdit && (
				<Grid item xs={12} paddingBottom={2}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={openNewExperienceModal}
					>
						Add Experience
					</Button>
				</Grid>
			)}

			{unsavedChangesShowModal && (
				<UnsavedChangesDialog
					open={unsavedChangesShowModal}
					setOpen={setUnsavedChangesShowModal}
					closeModalCallback={closeModals}
				/>
			)}
			{showDetailsModal && (
				<ExperiencesModal
					open={showDetailsModal}
					curExperienceData={curExperienceData}
					setCurExperienceData={setCurExperienceData}
					experienceID={experienceID}
					closeButtonCallback={closeDetailsModal}
					okButtonCallback={
						experienceID
							? handleEditExperience
							: handleCreateExperience
					}
					deleteButton={experienceID === 0}
					deleteButtonCallBack={handleDeleteExperience}
					okButtonLoading={buttonLoading.ok}
					deleteButtonLoading={buttonLoading.delete}
					onClickOff={experienceID === 0 ? undefined : () => { }}
				/>
			)}


			<Grid item xs={12}>
				{visibleExperiences.length > 0 ? (
					visibleExperiences
						.slice()
						.map((data, index) => {
							const filteredSkills = skillData.filter(
								(skill) => skill.experience === data.id
							);

							return (
								<PastExperiences
									key={index}
									experienceData={data}
									isEdit={isEdit}
									onClickFn={editExperienceModal}
									addMargin={
										index !== experienceData.length - 1
									}
									curSkills={filteredSkills}
									curReferenceData={referenceData.find(reference => reference.experience === data.id)}
									setReferenceData={setReferenceData}
									showSnackbar={showSnackbar}
									buttonLoading={buttonLoading}
									setSkillData={setSkillData}
									setClickedExperience={setClickedExperience}
									userInfo={userInfo}
									isPrintMode={isPrintMode}
									index={index}
								/>
							);
						})
				) : (
					<Typography paddingLeft={1}>
						No experiences found!
					</Typography>
				)}
			</Grid>
		</Grid>
	);
}
