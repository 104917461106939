import { useState } from "react";
import {
    Container,
    Grid,
    Paper,
    CircularProgress,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { SessionUtils } from "../../../utilities/SessionUtils";
import { RESTUtils } from "../../../utilities/RESTUtils";
import SnackbarComponent from "../../../components/snackbar";
import ClientAllOpportunitiesDG from "./components/ClientAllOpportunitiesDG";
import { ClientDashboardState } from "./hooks";
import ProfileStatusDashboard from "./components/completionStatusDashboard/ProfileStatusDashboard";
import MediaFeedParent from "./components/mediaFeed/MediaFeedParent";
import Announcements from "./components/announcements/Announcements";
import ViewOpportunityModal from "./components/viewOppoModal/ViewOpportunityModal";

export default function CandidateDashboardPage() {
    const [showOppoModal, setShowOppoModal] = useState(false);
    const [curJobData, setCurJobData] = useState({});
    const user = SessionUtils.getCurrentUserSession();
    const { jobPostings, notifications, setNotifications, interestedList, setInterestedList,
        profileProgress, loading, announcements, socialPosts } = ClientDashboardState();
    const [okButtonLoading, setOkButtonLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    const closeModal = () => {
        setShowOppoModal(false);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
        setSnackbarMessage("");
    };

    async function handleExpressInterest(job_title, date_created, remove) {
        setOkButtonLoading(true);
        try {
            if (remove) {
                setInterestedList((prevTitles) =>
                    prevTitles.filter((title) => title !== (job_title + '-' + date_created.substring(0, 10)))
                );
            } else {
                setInterestedList((prev) => [...prev, (job_title + '-' + date_created.substring(0, 10))]);
            }
            const job_enroll_data = {
                user_uuid: user.uuid,
                job_title: job_title,
                date_created: date_created,
                remove: remove,
            };
            const response = await RESTUtils.POST(
                RESTUtils.getAPIUrl() + `api/users/express_interest/`,
                job_enroll_data
            );

            if (response.status === 200) {
                if (remove) {
                    setSnackbarMessage("Interest Removed");
                } else {
                    setSnackbarMessage("Interest Expressed");
                }

                setSnackbarSeverity("success");
                setShowSnackbar(true);
                return true;
            } else {
                console.log(response.data);
                setSnackbarMessage("Something went wrong");
                setSnackbarSeverity("error");
                setShowSnackbar(true);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setSnackbarMessage("Something went wrong");
            setSnackbarSeverity("error");
            setShowSnackbar(true);
        } finally {
            setOkButtonLoading(false);
        }
    }

    return (
        <Container component="main" sx={{ p: { xs: 0, md: 2 }, pt: { xs: 0.05 } }}>
            <SnackbarComponent
                show={showSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                positionVertical="bottom"
                positionHorizontal="center"
                autoHideDuration={5000}
                handleCloseCallback={handleCloseSnackbar}
            />

            {showOppoModal && (
                <ViewOpportunityModal
                    showViewOppoModal={showOppoModal}
                    okButtonText={
                        interestedList.includes(curJobData.title + '-' + curJobData.date_created.substring(0, 10))
                            ? "Remove Interest"
                            : "Express Interest"
                    }
                    okButtonCallback={
                        interestedList.includes(curJobData.title + '-' + curJobData.date_created.substring(0, 10))
                            ? () => handleExpressInterest(curJobData.title, curJobData.date_created, true)
                            : () => handleExpressInterest(curJobData.title, curJobData.date_created, false)
                    }
                    closeButtonCallback={closeModal}
                    curJobData={curJobData}
                    okButtonLoading={okButtonLoading}
                />
            )}

            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <Grid container py={2}>
                    <Grid item xs={12} md={9}>
                        <Grid container>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="stretch"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <Paper style={{ height: '35.2ch', overflowY: 'auto' }}>
                                        <ProfileStatusDashboard uuid={user.uuid} profileProgress={profileProgress} />
                                    </Paper>
                                </Grid>

                                {!isMobile && (
                                    <>
                                        <Grid item xs={4}>
                                            <Paper style={{ height: '35.2ch', overflow: 'auto' }}>
                                                {/* <CanDashNotifications notifications={notifications} setNotifications={setNotifications} /> */}
                                                <Announcements announcements={announcements} />
                                            </Paper>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} pt={2}>
                                <Paper>
                                    <ClientAllOpportunitiesDG
                                        jobs={jobPostings}
                                        interestedList={interestedList}
                                        setInterestedList={setInterestedList}
                                        handleExpressInterest={handleExpressInterest}
                                        setShowOppoModal={setShowOppoModal}
                                        setCurJobData={setCurJobData}
                                        loading={loading}
                                        isMobile={isMobile}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    {!isMobile && (
                        <Grid item xs={12} md={3} pl={2} >
                            <Paper>
                                <MediaFeedParent setNotifications={setNotifications} notifications={notifications} announcements={announcements} socialPosts={socialPosts} />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            )}
        </Container>
    );
}
