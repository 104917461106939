import { Grid } from "@mui/material";
import LeftColumn from "./left/LeftColumn";
import RightColumn from "./right/RightColumn";

export default function TopPage({ profileData }) {

    return (
        <div style={{ height: '10.8in' }}>
            <Grid container justifyContent={'space-between'} sx={{ height: '10in' }}>
                <Grid item xs={3.5}>
                    <LeftColumn
                        professionalRegistration={profileData.professionalRegistration}
                        education={profileData.education}
                    />
                </Grid>
                <Grid item xs={7.5}>
                    <RightColumn
                        overview={profileData.overview}
                        professionalHistory={profileData.professionalHistory}
                    />
                </Grid>
            </Grid>
        </div>
    )
}