import { styled, Card, Avatar } from "@mui/material";

export const loadingStyle = {
	height: "100px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

export const CustomCard = styled(Card)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "10px",
	transition: "transform 0.3s",
	"&:hover": {
		backgroundColor: "secondary.cardHover",
		cursor: "pointer",
	},
});

export const CustomCardNoHover = styled(Card)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "10px",
	transition: "transform 0.3s",
});

export const CustomAvatar = styled(Avatar)({
	width: "40px",
	height: "40px",
	marginRight: "16px",
});
export const typographyStyle = {
	flexGrow: 1,
	overflow: "hidden",
	textOverflow: "ellipsis",
};

