import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import React from "react";

const UsersTab = ({userData}) => {
  return (
    <TableContainer component={Paper}>
      <Table style={{ borderCollapse: 'collapse' }}>
        <TableBody>
          {userData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, colIndex) => (
                <TableCell
                  key={colIndex}
                  style={{
                    border: '1px solid #dddddd',
                    borderBottom: rowIndex % 2 === 0 ? '2px solid black': '1px solid #dddddd',
                    padding: 8,
                    textAlign: 'center',
                    fontWeight: rowIndex % 2 === 0 ? 'bold' : 'normal',
                    width: '100px'

                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTab;
