import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClientViewOpportunityModal from "./ClientViewOpportunityModal";

export default function ViewOpportunityModal({
    showViewOppoModal,
    okButtonCallback,
    okButtonText,
    closeButtonCallback,
    curJobData,
    okButtonLoading
}) {
    return (
        <Dialog open={showViewOppoModal} onClose={closeButtonCallback} fullWidth maxWidth={'md'}>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                View Job Posting
                <IconButton
                    onClick={closeButtonCallback}
                    color="inherit"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider
                variant="middle"
                sx={{
                    backgroundColor: "grey",
                    marginTop: "-10px",
                }}
            />

            <DialogContent>
                <ClientViewOpportunityModal curJobData={curJobData} />
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
                <div>
                    <Button
                        onClick={closeButtonCallback}
                        sx={{ mr: 2 }}
                        variant="outlined"
                        color="secondary"
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={okButtonCallback}
                        variant="outlined"
                        color="secondary"
                        sx={{ mr: 2 }}
                        disabled={okButtonLoading}
                        startIcon={okButtonLoading && <CircularProgress color="inherit" size={20} />}
                    >
                        {okButtonLoading ? "Saving" : okButtonText}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}