import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { footerIconHoverStyle } from "./styles";
import { FooterComponentProps } from "./types";

function FooterMobile({ footerParams }: FooterComponentProps) {
	const {
		copyright_text,
		logo_url,
		address,
		email,
		phone,
		fb_link,
		linkedin_link,
		twitter_link,
	} = footerParams;

	return (
		<Box sx={{ bgcolor: "primary.paper" }}>
			<Container sx={{ color: "header_footer_font_color.main" }}>
				<Grid container textAlign={"center"}>
					{logo_url && (
						<Grid item xs={12} md={6} pt={2}>
							<img src={logo_url} height={60} alt="Home" />
						</Grid>
					)}

					<Grid item xs={12} md={6} pt={2}>
						{address && (
							<Stack
								direction="row"
								gap={1}
								pb={1}
								alignItems="center"
								justifyContent="center"
							>
								<MapsHomeWorkIcon />
								<Typography variant="body2">
									{address}
								</Typography>
							</Stack>
						)}
						<Stack
							direction="row"
							gap={1}
							alignItems="center"
							justifyContent="center"
						>
							{email && (
								<>
									<MailIcon />
									<Typography variant="body2" pr={1}>
										{email}
									</Typography>
								</>
							)}
							{phone && (
								<>
									<PhoneIcon />
									<Typography variant="body2">
										{phone}
									</Typography>
								</>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={12}>
						<Box>
							{fb_link && (
								<Link href={fb_link} px={0.5}>
									<FacebookIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
							{linkedin_link && (
								<Link href={linkedin_link} px={0.5}>
									<LinkedInIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
							{twitter_link && (
								<Link href={twitter_link} px={0.5}>
									<TwitterIcon
										fontSize="large"
										sx={footerIconHoverStyle}
									/>
								</Link>
							)}
						</Box>
						<Box>
							<Typography
								gutterBottom
								variant="caption"
								component="div"
							>
								© {new Date().getFullYear()} {copyright_text}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default FooterMobile;
