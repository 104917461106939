const benchStatusOptions = [
  'Pseudo',
  'Wix Account',
  'In Progress',
  'Pending Revisions',
  'Awaiting Review',
  'Awaiting Interview',
  'Awaiting Verification',
  'Fully Verified',
  'Inactive'
];

export default benchStatusOptions;