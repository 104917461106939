const intakeFormData = {
    first_name: "",
    last_name: "",
    resume_url: "",
    phone: '',
    country: "",
    region: "",
    city: "",
    main_title: "",
    designations: [],
    no_resume: false,
    woman: false,
    visible_minority: false,
    indigenous: false,
    disability: false,
    prefer_not_to_say: false,

    referral: "",
    referral_info: "",
}

export default intakeFormData;