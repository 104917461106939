import { useEffect, useState } from "react";
import {
	Box,
	Button,
	Container,
	MobileStepper,
	Paper,
	Typography,
	CircularProgress
} from "@mui/material";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { SessionUtils } from "../../../utilities/SessionUtils";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { getSteps } from "./data";
import validateIntakeForm from "./validation";
import intakeFormData from "./formData";
import CheckIcon from '@mui/icons-material/Check';

export default function IntakePage() {
	const [activeStep, setActiveStep] = useState(0);
	const [enableNextButton, setEnableNextButton] = useState(false);
	const user = SessionUtils.getCurrentUserSession();
	const [formData, setFormData] = useState(intakeFormData);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [secondsLeft, setSecondsLeft] = useState(5);

	const steps = getSteps(formData, setFormData);

	useEffect(() => {
		const isValidate = validateIntakeForm(activeStep, formData);
		setEnableNextButton(isValidate);
	}, [formData]);

	function handleNext() {
		const isValidate = validateIntakeForm(activeStep + 1, formData);
		setEnableNextButton(isValidate);
		setActiveStep(activeStep + 1);
	};
	function handleBack() {
		const isValidate = validateIntakeForm(activeStep - 1, formData);
		setEnableNextButton(isValidate);
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	function DisplayForm() {
		return steps[activeStep].step_form;
	};

	const handleSubmit = async () => {
		setButtonLoading(true);
		const response = await RESTUtils.PUT(
			RESTUtils.getAPIUrl() +
			`api/user/finish_intake/?uuid=${user?.uuid}`,
			formData
		);
		if (response.status === 200) {
			if (user) {
				user.intake_complete = true;
				user.first_name = formData.first_name;
				user.last_name = formData.last_name;
				SessionUtils.setCurrentUserSession(user);
				getWorkableCandidate();
			}

		} else {
			console.log(response.data);
		}
		setButtonLoading(false);
	};
	async function getWorkableCandidate() {
		const response = await RESTUtils.GET(
			RESTUtils.getAPIUrl() +
			`api/user/getWorkableCandidate/?email=${user.email}&uuid=${user.uuid}`
		);

		if (response.status === 200) {
			console.log(response.data);
		} else {
			console.log(response.data);
		}
	}
	useEffect(() => {
		let countdownInterval;
		if (user?.intake_complete) {
			countdownInterval = setInterval(() => {
				if (secondsLeft > 1) {
					setSecondsLeft((prevSeconds) => prevSeconds - 1);
				} else {
					window.location.href = "/dashboard";
				}
			}, 1000);
			return () => clearInterval(countdownInterval);
		}

	}, [secondsLeft, user?.intake_complete]);

	return (
		<Container component="main" maxWidth="sm" sx={{ pt: 1 }}>
			<Paper sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}>
				{user?.intake_complete ? (
					<>
						<Box
							sx={{
								// padding: 1,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
							component="form"
						>
							<Typography component="h1" variant="h5">
								Intake Questionnaire Complete!
							</Typography>
							<CheckIcon sx={{ fontSize: 60, color: "green" }} />
							<Typography variant="body1" color="success">
								Redirecting you to dashboard in{' '}
								{secondsLeft} {secondsLeft === 1 ? 'second' : 'seconds'}...
							</Typography>
						</Box>
					</>
				) : (
					<Box
						sx={{
							padding: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
						component="form"
					>
						<Typography component="h1" variant="h5">
							Intake Questionnaire
						</Typography>

						<Box
							sx={{
								textAlign: "center",
								backgroundColor: "transparent",
								p: 1,
								minWidth: "50%",
								color: "primary.main",
								marginTop: 2.5,
								marginBottom: -2.9,
							}}
						>
							<Typography component="p" variant="h6" color={'secondary'}>
								{steps[activeStep].step_header}
							</Typography>

						</Box>
						<MobileStepper
							variant="dots"
							steps={steps.length}
							position="static"
							activeStep={activeStep}
							sx={{
								flexGrow: 1,
								width: "100%",
								backgroundColor: "transparent",
								"& .MuiMobileStepper-dot": {
									backgroundColor: "grey",
								},
								"& .MuiMobileStepper-dotActive": {
									backgroundColor: "primary.main",
								},
								border: "0px",
							}}
							nextButton={
								<>
									<Button
										onClick={handleNext}
										disabled={
											activeStep === steps.length - 1 ||
											!enableNextButton
										}
										variant="outlined"
										color='secondary'
									>
										<KeyboardArrowRight />
									</Button>
								</>
							}
							backButton={
								<Button
									onClick={handleBack}
									disabled={activeStep === 0}
									variant="outlined"
									color='secondary'
								>
									<KeyboardArrowLeft />
								</Button>
							}
						/>

						<Box width={"100%"}>
							<>{DisplayForm()}</>
						</Box>

						<Box
							sx={{
								justifyContent: "right",
								width: "100%",
								pt: 2,
								display:
									activeStep === steps.length - 1
										? "inline-flex"
										: "none",
							}}
						>
							<Button
								onClick={handleSubmit}
								sx={{ mr: 1 }}
								variant="outlined"
								color='secondary'
								size="large"
								disabled={(activeStep === steps.length - 1 && !enableNextButton) || buttonLoading}
								startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
							>
								{buttonLoading ? "Submitting" : "Submit"}
							</Button>
						</Box>
					</Box>
				)}
			</Paper>
		</Container>
	);
}
