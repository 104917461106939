import { useState } from "react";
import {
    Grid,
    Avatar,
    Typography,
    Divider,
    Tooltip,
    IconButton,
} from "@mui/material";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import { useThemeContext } from "../../../../themes/ThemeProviderWrapper";
import dateOptions from "../../../../pages/admin/candidateSearch/components/center/dateOptions";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import ReplyIcon from '@mui/icons-material/Reply';
import DOMPurify from "dompurify";
import AttachmentIcon from '@mui/icons-material/Attachment';
import SendEmailForm from "../sendMessage/SendEmailForm";

export default function GraphEmail({
    message,
    user,
    isReplying,
    emailData,
    setEmailData,
    setIsReplying,
    isSendingNewEmail,
    showSnackbar,
}) {
    const adminImage = "/images/profile_image_placeholder.png";
    const isFromAdmin = message.sender.emailAddress.address === "recruitment@peterlucas.ca";
    const parsedDate = new Date(message.sentDateTime);
    const formattedTimestamp = parsedDate.toLocaleString("en-US", dateOptions);
    const [isReplyingLocal, setIsReplyingLocal] = useState(false);
    const { theme } = useThemeContext();

    function handleReplyClick() {
        if (!isReplying && !isSendingNewEmail) {
            setIsReplyingLocal((prevState) => !prevState);
            setIsReplying(true);
        }
    }

    async function markMessageRead(message, read = true) {
        const message_id = message.id;
        try {
            const response = await RESTUtils.PUT(
                RESTUtils.getAPIUrl() +
                `api/admin/toggle_read/?message_id=${message_id}&read=${read}`
            );
            if (response.status === 200) {
                showSnackbar("Success", "success");

                const updatedMessages = emailData.map((message) =>
                    message.id === message_id ? { ...message, isRead: read } : message
                );
                setEmailData(updatedMessages);
            }
        } catch (error) {
            console.error("Error marking email as read:", error);
        }
    }
    return (
        <>
            {isReplyingLocal && (
                <SendEmailForm
                    previousMessage={message}
                    setShow={setIsReplyingLocal}
                    setShowGlobal={setIsReplying}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    users={[user]}
                />
            )}
            <Grid
                container
                px={0.5}
                pt={1}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <Avatar
                            sx={{ marginLeft: "10px", marginRight: "10px" }}
                            src={isFromAdmin ? adminImage : user.image_url}
                            alt={"Profile Image"}
                        />

                        <Grid item>
                            <Typography fontSize={14}>
                                <strong>
                                    {message.sender.emailAddress.name}
                                </strong>{" "}

                            </Typography>
                            <Typography fontSize={12} color="secondary">
                                {formattedTimestamp}
                            </Typography>
                        </Grid>

                    </Grid>
                    <Typography fontSize={18} color="secondary" sx={{ marginLeft: "10px", marginRight: "10px", mt: '5px' }}>
                        {message.subject}
                    </Typography>

                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            {message.sender.emailAddress.address !== 'recruitment@peterlucas.ca' && (
                                <Tooltip title="Reply" arrow placement="top">
                                    <IconButton color="primary" onClick={() => handleReplyClick(message)}>
                                        <ReplyIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {message.hasAttachments && (
                                <Tooltip title="Email has attachments" arrow placement="top">
                                    <IconButton color="primary" sx={{
                                        color: 'primary.main',
                                        cursor: 'default', '&:hover': {
                                            color: 'primary.main',
                                            backgroundColor: 'transparent', // To ensure no background color change on hover
                                        },
                                    }} >
                                        <AttachmentIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {message.sender.emailAddress.address !== 'recruitment@peterlucas.ca' && (
                                message.isRead ?
                                    <Tooltip title="Mark as Unread" arrow placement="top">
                                        <IconButton color="primary" onClick={() => markMessageRead(message, false)}>
                                            <MarkAsUnreadIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Mark as Read" arrow placement="top">
                                        <IconButton color="primary" onClick={() => markMessageRead(message, true)}>
                                            <MarkEmailReadIcon />
                                        </IconButton>
                                    </Tooltip>

                            )
                            }

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} py={0.5} mt={1} sx={{ backgroundColor: theme === 'dark' ? '#444' : '#ddd', borderRadius: '5px' }}>
                    <Typography
                        fontSize={14}
                        paddingX={1.5}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.body.content) }}
                    />

                </Grid>
                <Grid item xs={12} mt={0.75}>
                    <Divider />
                </Grid>
            </Grid>
        </>
    );
}
