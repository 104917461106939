import React, { useState, useContext } from "react";
import {
    Paper,
    Typography,
    Grid,
    Avatar,
    Chip,
    Menu,
    MenuItem,
    ButtonGroup,
    IconButton,
    Tooltip,
    Stack,
} from "@mui/material";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CancelIcon from "@mui/icons-material/Cancel";
import PublicIcon from "@mui/icons-material/Public";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AddToJobModal from "../../../../../components/modals/AddToJob/AddToJobModal";
import { SnackBarComponent2 } from "../../../../../components/snackbar/SnackBarComponent2";
import { useThemeContext } from "../../../../../themes/ThemeProviderWrapper";
import { savedDataContext } from "../..";

export default function CandidateCard({
    profileData,
    setCandidates,
    curBucket,
    keywords,
    setSelectedUser,
    selectedUser,
    changeBucket,
    savedEnrollData,
    seeLess = true,
    activeJobs,
}) {
    const { showSnackbar, SnackBarComponent } = SnackBarComponent2();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [tooltipText, setTooltipText] = useState("Copy to clipboard");

    const { theme } = useThemeContext();
    const { isSendingMessage, setIsSendingMessage } = useContext(savedDataContext);


    function handleEmailChipClick() {
        navigator.clipboard.writeText(profileData.email);
        setTooltipText("Copied");
        setTimeout(() => {
            setTooltipText("Copy to clipboard");
        }, 3000);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        changeBucket([profileData.user], buckets[index], profileData);
    };
    function handleViewProfile(uuid) {
        BrowserUtils.openNewTab(`/profile/edit/${uuid}`);
    }
    const handleDQClick = (event, index) => {
        setSelectedIndex(index);
        changeBucket([profileData.user], "disqualified", profileData);
    };
    let curUserBucket;
    // since we reuse this for both the search results and the top part of the selected user view, we want to have
    // a variable that keeps track of the user's bucket (because we can't count on the curBucket, as it may be in search)
    if (curBucket === "Search" && profileData.bucket != null) {
        curUserBucket =
            profileData.bucket.charAt(0).toUpperCase() +
            profileData.bucket.slice(1);
        curUserBucket = curUserBucket.replace(
            /_./g,
            (match) => ` ${match.charAt(1).toUpperCase()}`
        );
    }
    async function toggleInternational(event, index) {
        let newBucket;
        let international;

        if (profileData.international) {
            newBucket = "applied"; //send to applied from international
            international = false; //user should NOT be marked as international
        } else {
            newBucket = "international"; //send to international
            international = true; //user should be marked as international
        }
        changeBucket([profileData.user], newBucket, profileData);

        const response = await RESTUtils.PUT(
            RESTUtils.getAPIUrl() +
            `api/admin/toggle_international/?user_uuid=${profileData.user}&international=${international}`
        );
        // if (response.status === 200) {
        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            international: !prevSelectedUser.international,
        }));
        setCandidates((prevCandidates) => {
            const updatedCandidates = { ...prevCandidates };
            for (const field in updatedCandidates) {
                updatedCandidates[field] = updatedCandidates[field].map(
                    (item) => {
                        if (item.user === profileData.user) {
                            return {
                                ...item,
                                international: !item.international,
                            };
                        }
                        return item;
                    }
                );
            }
            return updatedCandidates;
        });
    }

    const topLine = [
        [profileData.first_name, profileData.last_name].join(" "),
        profileData.designations,
    ]
        .filter(Boolean)
        .join(", ");

    const employment = profileData.latest_experience || profileData.main_title;
    const education = profileData.main_education || {};

    let secondLine = [];
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };

    if (typeof employment === "object" && Object.keys(employment).length > 0) {
        const { title, organization, start_date, end_date } = employment;
        const formattedStartDate = new Date(start_date).toLocaleString(
            "en-US",
            dateOptions
        );
        const formattedEndDate = end_date
            ? new Date(end_date).toLocaleString("en-US", dateOptions)
            : "Present";

        const employmentDetails = [title, organization, formattedStartDate]
            .filter(Boolean)
            .join(" | ");

        secondLine = employmentDetails
            ? `${employmentDetails} - ${formattedEndDate}`
            : "No employment details found.";
    } else {
        secondLine = employment
            ? (profileData.main_title ? profileData.main_title : "")
            : "";
    }

    // let thirdLine = "";
    // if (Object.keys(education).length > 0) {
    //   thirdLine = [education.title, education.organization, education.end_date]
    //     .filter(Boolean)
    //     .join(", ");
    // } else {
    //   thirdLine = "No education found";
    // }
    const moreOptions = [
        "Move to Bucket",
        "Disqualified",
        "Sourced",
        "Applied",
        "Expressed Interest",
        "Short List",
        "Confirm Interest",
        "On Client Portal",
    ];
    const buckets = [
        "",
        "disqualified",
        "sourced",
        "applied",
        "expressed_interest",
        "short_list",
        "confirm_interest",
        "on_client_portal",
    ];

    function highlightKeyword(text) {
        if (keywords.length === 0) {
            return <>{text}</>;
        }
        const textString = String(text);
        // Create a regular expression pattern with all keywords joined by '|'
        const pattern = new RegExp(`(${keywords.join("|")})`, "gi");
        // Split the text into parts based on the pattern
        const parts = textString
            .split(pattern)
            .filter((part) => part.trim() !== "");
        const highlightedText = parts.map((part, index) => {
            const displayPart = part;
            // .startsWith(" ")
            // 	?  part.slice(1)
            // 	: part;

            return keywords.some(
                (keyword) => displayPart.toLowerCase() === keyword.toLowerCase()
            ) ? (
                <span key={index} style={{ backgroundColor: theme === 'dark' ? "darkblue" : '#ffff66' }}>
                    {displayPart}
                </span>
            ) : (
                <span key={index}>{displayPart}</span>
            );
        });
        return <>{highlightedText}</>;
    };

    const chipBckg =
        profileData.experience_level === "Junior"
            ? "#37b526"
            : profileData.experience_level === "Intermediate"
                ? "orange"
                : profileData.experience_level === "Senior"
                    ? "#2284e7"
                    : "initial";

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    function handleSeeMore() {
        setSelectedUser(profileData);
    }
    function handleSeeLess() {
        setSelectedUser("");
    }

    return (
        <>
            {showAddJobModal &&
                <AddToJobModal
                    showAddJobModal={showAddJobModal}
                    setShowAddJobModal={setShowAddJobModal}
                    uuid={profileData.user}
                    activeJobs={activeJobs}
                    showSnackbar={showSnackbar} // not sure if there is a snackbar already somewhere in CS, don't want to change more files.
                />
            }
            {SnackBarComponent}
            <Paper sx={{ width: "100%", position: "relative", padding: "15px" }}>
                <Grid container>
                    <Grid container>
                        <Grid item xs={1.25}>
                            <Avatar
                                src={
                                    profileData.image_url
                                        ? BrowserUtils.awsURL(profileData.image_url)
                                        : ""
                                }
                                sx={{
                                    width: 60,
                                    height: 60,
                                    marginRight: "10px",
                                }}
                            />
                        </Grid>

                        <Grid item xs={10.75}>
                            <Grid item xs={12} height={'3ch'}>
                                <Typography variant="body1">
                                    {curUserBucket}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {highlightKeyword(topLine)}

                                    {profileData.experience_level && (
                                        <Chip
                                            label={profileData.experience_level}
                                            style={{
                                                backgroundColor: chipBckg,
                                                color: "white",
                                                marginLeft: "10px",
                                            }}
                                        />
                                    )}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <ButtonGroup
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 5,
                            marginTop: "5px",
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.25}
                        >
                            {profileData.workable_url && (
                                <Tooltip title="View Workable Profile" arrow placement="top">
                                    <IconButton
                                        component="a"
                                        href={profileData.workable_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        size="small"
                                    >
                                        <img
                                            src={"/images/workable_logo_round.png"}
                                            alt="Workable Logo"
                                            height={15}
                                        />
                                    </IconButton>
                                </Tooltip>

                            )}

                            <Tooltip title="View Profile" arrow placement="top">
                                <span>
                                    <IconButton onClick={() => handleViewProfile(profileData.user)}>
                                        <PermIdentityIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Disqualify" arrow placement="top">
                                <span>
                                    <IconButton
                                        onClick={handleDQClick}
                                        disabled={curUserBucket === "Disqualified"}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {curBucket != 'Search' &&
                                <Tooltip
                                    title={
                                        profileData.international
                                            ? "Remove from International"
                                            : "Make International"
                                    }
                                    arrow
                                    placement="top"
                                >

                                    <span>
                                        <IconButton
                                            onClick={toggleInternational}
                                            sx={{
                                                color: profileData.international ? "primary.main" : "",
                                            }}
                                        >
                                            <PublicIcon />
                                        </IconButton>
                                    </span>


                                </Tooltip>
                            }
                            {curBucket != 'Search' &&
                                <Tooltip title="Send Email" arrow placement="top">
                                    <IconButton onClick={() => setIsSendingMessage((prev) => !prev)}
                                    >
                                        <EmailIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Move to Bucket" arrow placement="top">
                                <IconButton onClick={handleMoreClick}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={() => setShowAddJobModal(true)}>
                            Add to Different Job
                        </MenuItem>

                        {moreOptions.map((option, index) => (
                            <MenuItem
                                key={option}
                                disabled={index === 0 || option === curUserBucket}
                                selected={option === curUserBucket}
                                onClick={(event) =>
                                    handleMenuItemClick(event, index)
                                }
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>

                    {profileData.applied_from && (
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: "11px",
                                    paddingTop: "2px",
                                    // marginLeft: "-10px",
                                }}
                            >
                                Applied on {profileData.applied_from}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography
                            variant="h8"
                            component="div"
                            sx={{ paddingTop: "5px" }}
                        >
                            {highlightKeyword(secondLine)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h8" component="div">
                            Bench Status:{" "}
                            {profileData.bench_status
                                ? profileData.bench_status
                                : "None found"}
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
					<Typography variant="h8" component="div">
								{highlightKeyword(thirdLine)}
							</Typography>
				</Grid> */}
                    <Grid container item xs={12} justifyContent={'center'} pt={0.5}>
                        {/* <Typography variant="h8" component="div">
                            Sectors:{" "}
                            {profileData.sectors?.length > 0
                                ? profileData.sectors.map((sector, index) => (
                                    <React.Fragment key={index}>
                                        <Chip
                                            variant="outlined"
                                            label={highlightKeyword(sector)}
                                            size="small"
                                            style={{
                                                marginRight: "3px",
                                                marginTop: "3px",
                                            }}
                                        />
                                    </React.Fragment>
                                ))
                                : "No sectors found"}
                        </Typography> */}
                        {profileData.city && profileData.country && (
                            <Tooltip title="View location" arrow placement="top">
                                <Chip
                                    icon={<LocationOnIcon color="primary" />}
                                    label={`${profileData.city}, ${profileData.country}`}
                                    size="small"
                                    component="a"
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        `${profileData.city}, ${profileData.country}`
                                    )}`}
                                    clickable
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            </Tooltip>
                        )}
                        {!profileData.city && !profileData.country && profileData.address && (
                            <Chip
                                icon={<LocationOnIcon color="primary" />}
                                label={profileData.address}
                                size="small"
                                component="a"
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    profileData.address
                                )}`}
                                clickable
                                target="_blank"
                                rel="noopener noreferrer"
                            />
                        )}
                        {profileData.phone && (
                            <Chip
                                icon={<PhoneIcon color="primary" />}
                                label={profileData.phone}
                                size="small"
                            />
                        )}
                        {profileData.email && (
                            <Tooltip title={tooltipText} placement="top">
                                <Chip
                                    icon={<EmailIcon color="primary" />}
                                    onClick={handleEmailChipClick}
                                    label={profileData.email}
                                    size="small"
                                />
                            </Tooltip>
                        )}
                    </Grid>

                    {/* <Grid item xs={12}>
						<Typography variant="h8" component="div">
							Skills:{" "}
							{profileData.skills?.length > 0
								? profileData.skills.map((sector, index) => (
										<React.Fragment key={index}>
											<Chip
												label={highlightKeyword(sector)}
												size="small"
												style={{
													marginRight: "3px",
													marginTop: "3px",
												}}
											/>
										</React.Fragment>
								  ))
								: "No skills found"}
						</Typography>
					</Grid> */}
                </Grid>

                {seeLess && (
                    <Typography
                        sx={{
                            cursor: "pointer",
                            color: "DodgerBlue",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "-15px",
                        }}
                        onClick={() =>
                            selectedUser ? handleSeeLess() : handleSeeMore()
                        }
                    >
                        {selectedUser ? "See less" : "See more"}
                    </Typography>
                )}
            </Paper>
        </>
    );
}
