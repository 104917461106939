import { useState, useEffect } from "react";
import { CircularProgress, Box, Typography, Grid, Button, Container, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { BrowserUtils } from "../../../utilities/BrowserUtils";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { URLS } from "../../../urls";
import { sectionStyle } from "./styles";
import ThreeDPersona from "./threeDPersona/ThreeDPersona";
import EightStage from "./eightStage/EightStage";
import { useThemeContext } from "../../../themes/ThemeProviderWrapper";

export default function Personality() {
	const [personalityData, setPersonalityData] = useState();
	const [loading, setLoading] = useState(true);
	const { uuid } = useParams();
	const { theme } = useThemeContext();

	async function fetchPersonalityData() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
				`api/users/get_quiz_data/?user_uuid=${uuid}`
			);
			setPersonalityData(response.data);
		} catch (error) {
			console.error("Error fetching user data:", error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchPersonalityData();
	}, []);

	function handleQuizClick() {
		BrowserUtils.redirect('/personality_quiz');
	}
	function handleProjectStrengthsClick() {
		BrowserUtils.redirect('/project_strengths');
	}
	function openPDF() {
		// The URL of the PDF file
		const pdfUrl = 'https://www.peterlucasbench.com/_files/ugd/12480e_72eb8658dce440e8baea92dbfeb0b648.pdf';
		window.open(pdfUrl, '_blank'); // Open in a new tab
	};

	return (
		<>
			<div style={sectionStyle}>
				<Grid container py={3}>
					<Grid item xs={6}>
						<Typography
							variant="h4"
							sx={{ fontWeight: "bold", textAlign: "left" }}
						>
							Personality Insights
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign={"right"}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() =>
								BrowserUtils.redirect(URLS.DASHBOARD.CANDIDATE)
							}
						>
							Go to Dashboard
						</Button>
					</Grid>
				</Grid>
			</div>

			<Box
				width="100%"
				py={2}
				style={{
					backgroundColor: theme === 'dark' ? "#4D4D4D" : '#000',
					position: "relative",
				}}
			>
				<div style={{ ...sectionStyle, textAlign: "left" }}>
					<Typography
						style={{
							fontWeight: "bold",
							color: "#E2E3E4",
							marginTop: "10px",
							marginBottom: "10px",
						}}
					>
						The Project Strengths Model is intended to provide a
						complete picture of your personality traits, strengths,
						and how you fit into a team environment. <br />
						It is a combination of your 3-D Persona, and the 8-Stage
						Project Strengths Model.
					</Typography>
				</div>
			</Box>

			{loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100vh"
				>
					<CircularProgress color="primary" />
				</Box>
			) : (
				<Container component="main" sx={{ p: { xs: 2, md: 2 }, }}>
					<Grid container style={{ textAlign: "center" }} pt={5}>
						<Grid item xs={12} pb={3}>
							<ThreeDPersona threeDData={personalityData?.personality} theme={theme} />
						</Grid>

						<Grid item xs={12} pt={4}>
							<EightStage eightStageData={personalityData?.eight_stage} theme={theme} />
						</Grid>

						<Grid item xs={12} py={4} container justifyContent="center" alignItems="center">
							<Stack direction="row" spacing={2}>
								<Button variant="outlined" color="secondary" onClick={handleQuizClick} size="small">Personality Quiz</Button>
								<Button variant="outlined" color="secondary" onClick={handleProjectStrengthsClick} size="small">Project Strengths Questionnaire</Button>
								<Button variant="outlined" color="secondary" onClick={openPDF} size="small">Learn More</Button>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}
