import { Dialog, DialogTitle, IconButton, Divider, TextField, Button, DialogContent, DialogActions, Grid, CircularProgress, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function BenchStatusModal({
    showBenchStatusModal,
    setShowBenchStatusModal,
    benchStatusComment,
    setBenchStatusComment,
    updateProfileAPI,
    tempBenchStatus,
    saving
}) {
    return (
        <Dialog
            open={showBenchStatusModal}
            onClose={() => setShowBenchStatusModal(false)}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {"Change Bench Status"}
                <IconButton
                    onClick={() => setShowBenchStatusModal(false)}
                    color="inherit"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider
                variant="middle"
                sx={{
                    backgroundColor: "grey",
                    marginTop: "-10px",
                }}
            />

            <DialogContent>
                <Typography variant="h8">Note: This will send a notification to the user, along with the comment, if provided.</Typography>
                <TextField
                    label="Comments"
                    name="comments"
                    multiline
                    rows={4}
                    value={benchStatusComment}
                    onChange={(e) => setBenchStatusComment(e.target.value)}
                    fullWidth
                    sx={{ mt: 1 }}
                />
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center" px={2}>
                    <Grid item>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => setShowBenchStatusModal(false)}
                        >
                            {"Cancel"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => updateProfileAPI("bench_status", tempBenchStatus)}
                            disabled={saving}
                            startIcon={saving && <CircularProgress color="inherit" size={20} />}
                        >
                            {saving ? "Saving" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}