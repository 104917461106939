import { Stack, Tooltip, IconButton, ButtonGroup } from '@mui/material';
import { stepperData } from './data';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function StatusStepper({ profileProgress, steps, curStep, setCurStep }) {
    return (
        <Stack direction="row">
            <ButtonGroup size="small" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <IconButton
                    onClick={() => { setCurStep(curStep - 1); }}
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        maxWidth: 'calc(100% / 10)',
                    }}
                    disabled={curStep === 0}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>

                {stepperData.map((step, index) => (
                    <Tooltip key={index} title={step.title} arrow placement="top">
                        <IconButton
                            onClick={() => { setCurStep(index); }}
                            sx={{
                                flexGrow: 1,
                                flexShrink: 1,
                                maxWidth: 'calc(100% / 10)',
                            }}
                        >
                            {profileProgress[index] ? (
                                <CheckCircleIcon sx={{ color: curStep === index ? 'primary.main' : 'inherit' }} fontSize={'small'} />
                            ) : (
                                <CircleOutlinedIcon sx={{ color: curStep === index ? 'primary.main' : 'inherit' }} fontSize={'medium'} />
                            )}
                        </IconButton>
                    </Tooltip>
                ))}

                <IconButton
                    onClick={() => { setCurStep(curStep + 1); }}
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        maxWidth: 'calc(100% / 10)',
                    }}
                    disabled={curStep === 7}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </ButtonGroup>
        </Stack>
    );
}
