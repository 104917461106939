import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ReferenceSkillsRow from './ReferenceSkillsRow';
import { tableRowBorderStyle } from "../../experiencesModal/skills/styles";

export default function ReferencesSkillsTable( { SkillsData }) {
    return (
        <TableContainer>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<TableCell
								align="left"
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
							>
								Skill
							</TableCell>
							<TableCell
								align="left"
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
							>
								Frequency
							</TableCell>
							<TableCell
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
							>
								Description (Optional)
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{SkillsData.map((row, index) => (
							<ReferenceSkillsRow
								row_data={row}
								key={index}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
    )
}