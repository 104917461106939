import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Badge from "./Badge";

export default function EightStageBadges({ eightStageData, useMobile = false, fromCP = false, numCandidates = 0 }) {
  // Find the indices of the highest, second highest, and third highest values. 
  // Then, compare with the badgeNames[index] to render the appropriate badge icon
  const sortedIndexes = eightStageData.totals
    .map((_, index) => index)
    .sort((a, b) => eightStageData.totals[b] - eightStageData.totals[a]);
  const MIN_ICON_VALUE = 0.5;

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const isMobile = useMobile || isSmallScreen;

  const num_badges = Math.min(sortedIndexes.filter((index) => eightStageData.totals[index] > MIN_ICON_VALUE).length, 3);
  let width;
  if (fromCP) {
    switch (num_badges) {
      case 0:
      case 1:
        width = numCandidates === 1 ? '25%' : "40%";
        break;
      case 2:
      case 3:
        width = '40%';
        break;
      default:
        width = '40%';
        break;
    }
  } else {
    width = "55%";
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={fromCP ? 12 : 4} alignItems={'center'}>
        <Badge sortedIndex={sortedIndexes[0]} width={width} isMobile={isMobile} />
      </Grid>
      {eightStageData.totals[sortedIndexes[1]] > MIN_ICON_VALUE && !fromCP && (
        <Grid item xs={fromCP ? 12 : 4} mt={fromCP && 1} alignItems={'center'}>
          <Badge sortedIndex={sortedIndexes[1]} width={width} isMobile={isMobile} />
        </Grid>
      )}
      {eightStageData.totals[sortedIndexes[2]] > MIN_ICON_VALUE && !fromCP && (
        <Grid item xs={fromCP ? 12 : 4} alignItems={'center'}>
          <Badge sortedIndex={sortedIndexes[2]} width={width} isMobile={isMobile} />
        </Grid>
      )}
    </Grid>
  );
}
