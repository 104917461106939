import { Typography } from "@mui/material";

export default function ProfessionalRegistration({ professionalRegistration }) {
    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: 1.2,
                    fontWeight: 'bold',
                }}
            >
                {professionalRegistration.title}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: 1.2,
                    mb: 2
                }}
            >
                {professionalRegistration.institute}
            </Typography>
        </>
    )
}