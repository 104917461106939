import { useState } from "react";
import {
  Paper,
  Typography,
  Divider,
  Tabs,
  Tab,
  IconButton
} from "@mui/material";
import MessageTab from "./messages/MessageTab";
import NoteComponent from "../notes/NoteComponent";
import ResumeHandler from "../../pages/profile/components/details/ResumeTab/ResumeHandler";
import Interview from "./Interview";
import FitScreenIcon from '@mui/icons-material/FitScreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

export default function CommunicationBox({
  emailData,
  setEmailData,
  activeJobs,
  communicationVisible,
  events,
  user,
  emailLoading,
  allData,
  pageWidth,
  setPageWidth,
  showSnackbar,
}) {
  const [index, setIndex] = useState(0);
  const [isSendingNewEmail, setIsSendingNewEmail] = useState(false);
  function handleTabChange(event, newIndex) {
    setIndex(newIndex);
  }

  function handleChangePageWidth() {
    setPageWidth(pageWidth === 'lg' ? 'true' : 'lg');
  }

  const contentMap = {
    0: (
      <MessageTab
        emailData={emailData}
        setEmailData={setEmailData}
        activeJobs={activeJobs}
        user={user}
        emailLoading={emailLoading}
        showSnackbar={showSnackbar}
        showSendEmail={true}
        isSendingNewEmail={isSendingNewEmail}
        setIsSendingNewEmail={setIsSendingNewEmail}
      />
    ),
    1: (
      <NoteComponent
        notes={allData?.noteData}
        setNotes={allData?.setNoteData}
        user_uuid={allData?.userInfo.uuid}
      />
    ),
    2: (
      <div style={{ paddingTop: '11px', paddingLeft: '8px', paddingRight: '8px' }}>
        <ResumeHandler allData={allData} />
      </div>
    ),
    3: <Interview user_uuid={user.uuid} interview_notes={allData.userInfo.interview_notes} />,
  };

  return (
    <Paper
      sx={{
        height: "90vh",
        overflowY: "auto",
        transition: "background-color 0.2s ease",
        cursor: !communicationVisible ? "pointer" : "default",
        "&:hover": {
          backgroundColor: !communicationVisible && "#333",
        },
        position: 'relative',
      }}
    >
      <Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
        Communication
      </Typography>
      <IconButton
        onClick={handleChangePageWidth}
        sx={{
          position: 'absolute',
          top: '0px',
          right: '8px',
        }}
      >
        {pageWidth === 'lg' ? <FitScreenIcon /> : <CloseFullscreenIcon />}
      </IconButton>

      <Divider />
      <Tabs
        value={index}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
        centered // Center tabs
      >
        <Tab label="Messages" />
        <Tab label="Notes" />
        <Tab label="Resume" />
        <Tab label="Interview" />
      </Tabs>
      {contentMap[index]}
    </Paper>
  );
}
