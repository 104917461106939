import { Divider, Grid, Typography, Pagination } from "@mui/material";

export default function SearchResultsBar({ resultCount, curPage, setCurPage }) {
	let result_text;
	if (resultCount === 0) {
		result_text = "No results found";
	} else if (resultCount < 4 || curPage * 4 > resultCount) {
		result_text = `Showing ${1 + (curPage - 1) * 4} - ${resultCount} of ${resultCount}`;
	}
	else {
		result_text = `Showing ${1 + (curPage - 1) * 4} - ${4 + (curPage - 1) * 4} of ${resultCount}`;
	}

	return (
		<Grid item xs={12} container justifyContent="space-between">
			<Typography
				variant="h8"
				sx={{
					fontWeight: "bold",
					textAlign: "left",
					paddingLeft: "1rem",
					marginBottom: "0.5rem",
					marginTop: "0.5rem",
				}}
			>
				{result_text}
			</Typography>

			<Pagination
				size={'small'}
				count={Math.ceil(resultCount / 4)}
				page={curPage}
				onChange={(e, value) => { setCurPage(value) }}
				variant="outlined"
				color="secondary"
			/>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Grid>
	);
}
