
import { Grid, Container, Stack, Button } from "@mui/material";

import EightStage from "../../personality/eightStage/EightStage";

import { SessionUtils } from "../../../../utilities/SessionUtils";
export default function SingleUsePersonality({ projectStrengthValues, setProjectStrengthValues, setActiveStep }) {
    function openPDF() {
        // The URL of the PDF file
        const pdfUrl = 'https://www.peterlucasbench.com/_files/ugd/12480e_72eb8658dce440e8baea92dbfeb0b648.pdf';
        window.open(pdfUrl, '_blank'); // Open in a new tab
    };
    function handleProjectStrengthsClick() {
        SessionUtils.clearProjectStrengthsSession(null);
        setActiveStep(0);
        setProjectStrengthValues(null);
    }
    return (

        <Container component="main" sx={{ p: { xs: 2, md: 2 }, }} maxWidth={'lg'}>
            <Grid container style={{ textAlign: "center" }} >

                <Grid item xs={12} pt={0}>
                    <EightStage eightStageData={{ totals: projectStrengthValues }} />
                </Grid>

                <Grid item xs={12} py={4} container justifyContent="center" alignItems="center">
                    <Stack direction="row" spacing={2}>
                        {/* <>h2i</> */}
                        <Button variant="outlined" color="secondary" onClick={handleProjectStrengthsClick} size="small">Retake Quiz</Button>
                        <Button variant="outlined" color="secondary" onClick={openPDF} size="small">Learn More</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Container >

    );
}
