import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
} from "@mui/material";

export default function NewMessagesTable({
    tableLoading,
    unreadMessages,
    handleTableRowClick,
}) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${date.getFullYear()}`;
        const formattedTime = `${date.getHours()}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${date
                .getSeconds()
                .toString()
                .padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
        return `${formattedDate}, ${formattedTime}`;
    };

    return (
        <TableContainer
            style={{
                height: 300,
                overflowY: "auto",
                position: "relative",
            }}
        >
            {tableLoading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <Table size="small" aria-label="messages-table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    // backgroundColor: "background.paper",
                                    borderBottom: "1px solid #6c6e70",
                                    py: 0.25,
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                sx={{
                                    // backgroundColor: "background.paper",
                                    borderBottom: "1px solid #6c6e70",
                                    py: 0.25,
                                }}
                            >
                                Subject
                            </TableCell>
                            <TableCell
                                sx={{
                                    // backgroundColor: "background.paper",
                                    borderBottom: "1px solid #6c6e70",
                                    py: 0.25,
                                }}
                            >
                                Date
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {unreadMessages?.length === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{
                                        borderBottom: "1px solid #6c6e70",
                                        py: 0.25,
                                    }}
                                >
                                    <Typography color="secondary.main">
                                        No new messages
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {unreadMessages?.map((rowData, index) => (
                            <TableRow
                                key={index}
                                onClick={() => handleTableRowClick(rowData)}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "secondary.rowHover",
                                    },
                                }}
                            >
                                <TableCell
                                    sx={{ borderBottom: "1px solid #6c6e70" }}
                                >
                                    <Typography color="secondary.main">
                                        {rowData.sender.emailAddress.name}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{ borderBottom: "1px solid #6c6e70" }}
                                >
                                    <Typography color="secondary.main">
                                        {rowData.subject}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{ borderBottom: "1px solid #6c6e70" }}
                                >
                                    <Typography color="secondary.main">
                                        {formatDate(rowData.sentDateTime)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}
