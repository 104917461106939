import { useState, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import InterviewModalContent from './InterviewModalContent';
import { interviewData } from '../..';
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";
import emptyInterviewRequest from './emptyInterviewRequest';
import { RESTUtils } from '../../../../../utilities/RESTUtils';

export default function InterviewModal({ users, showModal, setShowModal, fromSpotlight = false }) {
    const [error, setError] = useState(false);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    const [buttonLoading, setButtonLoading] = useState(false);

    const { interviewVars, setInterviewVars, setUserList } = useContext(interviewData);
    function handleInterviewRequest() {
        if (isInterviewRequestValid(fromSpotlight ? users.user : interviewVars.users)) {
            if (fromSpotlight) { // if from spotlight, users will be one user which is affirmed to be interviewed
                const tempInterviewVars = { ...interviewVars };
                tempInterviewVars.users = [users.user];
                createInterviewRequest(tempInterviewVars);
            }
            else { // from shortlist, users will be multiple users and only those in interviewVars.users should be requested
                createInterviewRequest(interviewVars);
            }
        } else {
            setError(true);
        }
    }
    async function createInterviewRequest(interviewData) {
        setButtonLoading(true);
        try {
            const response = await RESTUtils.POST(
                RESTUtils.getAPIUrl() + "api/user/request_interview/",
                interviewData
            );
            if (response.status === 200) {
                closeModal();
                setShowModal(false);
                setUserList(prevData => {
                    const updatedUserList = prevData.map(user => {
                        if (interviewData.users.includes(user.user)) {
                            return { ...user, interview_requested: true };
                        }
                        return user;
                    });
                    return updatedUserList; // Return the updated array
                });
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setButtonLoading(false);
        }
    }

    function closeModal() {
        setShowModal(false);
        setError(false);
    }

    function isInterviewRequestValid(users) {
        return (
            interviewVars.name &&
            interviewVars.availability &&
            interviewVars.interview_preference &&
            users.length !== 0
        );
    }
    function clearForm() {
        setInterviewVars(emptyInterviewRequest);
        setError(false);
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setInterviewVars((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    return (
        <TwoButtonModal
            show={showModal}
            modalTitle={"Request Interview"}
            modalMessage={
                <InterviewModalContent
                    interviewVars={interviewVars}
                    setInterviewVars={setInterviewVars}
                    handleChange={handleChange}
                    error={error}
                    users={users}
                    fromShortlist={!fromSpotlight} // reverse logic, if we add a third source, we'll have to change
                />
            }
            closeButtonText="Cancel"
            closeButtonCallback={closeModal}
            okButtonText={isMobile ? "Send" : "Request Interview"}
            okButtonCallback={handleInterviewRequest}
            okButtonLoading={buttonLoading}
            deleteButton={true}
            deleteButtonText="Clear"
            deleteButtonCallBack={clearForm}
        />
    );
};

