import { useState } from "react";
import { Card, Typography, Grid, Chip, Button, Stack } from "@mui/material";
import References from "./references";
import dayjs from "dayjs";
import { snakeCaseToTitleCase } from "../../../../utilities/Helpers";
import SkillsModal from "./experiencesModal/skills/index";
import UnsavedChangesDialog from "./experiencesModal/UnsavedChangesDialog";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function PastExperiences({
	experienceData,
	isEdit,
	onClickFn,
	addMargin,
	curSkills,
	curReferenceData,
	setReferenceData,
	showSnackbar,
	handleEditSkills,
	setSkillData,
	setClickedExperience,
	userInfo,
	isPrintMode,
	index
}) {
	const [isHovered, setIsHovered] = useState(false);
	const [showReferenceModal, setShowReferenceModal] = useState(false);
	const [showSkillsModal, setShowSkillsModal] = useState(false);
	const [showUnsavedChangesModal, setUnsavedChangesShowModal] = useState(false);
	const [spacingCount, setSpacingCount] = useState(0);
	const [buttonLoading, setButtonLoading] = useState({
		ok: false,
		delete: false,
	});
	const [changedSkills, setChangedSkills] = useState({
		added: [],
		edited: [],
		deleted: [],
	});
	const curExperienceData = experienceData;
	curExperienceData.skills = curSkills;

	async function handleSkillsChanges(experienceId = false, deletedSkills = false) {
		setButtonLoading((prevState) => ({ ...prevState, ok: true }));
		let addedIds = new Set(changedSkills.added.map(item => item.id));
		let editedIds = (changedSkills.edited.map(item => item.id));
		let deletedIds;
		if (deletedSkills) {
			deletedIds = deletedSkills.map(item => item.id);
		} else {
			deletedIds = (changedSkills.deleted.map(item => item.id));
		}

		changedSkills.added = changedSkills.added.filter(item => !deletedIds.includes(item.id));
		changedSkills.edited = changedSkills.edited.filter(item => !deletedIds.includes(item.id));
		changedSkills.added = changedSkills.added.filter(item => !editedIds.includes(item.id));

		deletedIds = ([...deletedIds].filter(id => id >= 0)); // remove temporary ids
		let addedSkills = changedSkills.added;
		let editedSkills = changedSkills.edited;
		if (experienceId) {
			addedSkills = changedSkills.added.map((skill) => ({
				...skill,
				experience: experienceId,
			}));
			editedSkills = changedSkills.edited.map((skill) => ({
				...skill,
				experience: experienceId,
			}));
		}
		const changedSkillData = {
			deletedSkillIds: deletedIds,
			addedSkills: addedSkills,
			editedSkills: editedSkills,
			user_data: userInfo.uuid,
			experience_id: curExperienceData.id,
		};

		try {
			const response = await RESTUtils.POST(
				RESTUtils.getAPIUrl() + "api/users/updateSkills/",
				changedSkillData
			);
			if (response.status === 200) {
				const tempIdMap = response.data.temp_id_map;
				deletedIds.forEach((deletedId) => {
					setSkillData((prevSkillData) =>
						prevSkillData.filter((skill) => skill.id !== deletedId)
					);
				});
				addedSkills.forEach((addedSkill) => {
					setSkillData((prevSkillData) => {
						return [...prevSkillData, { ...addedSkill }];
					});
				});
				editedSkills.forEach((editedSkill) => {
					setSkillData((prevSkillData) => {
						const skillExists = prevSkillData.some((skill) => skill.id === editedSkill.id);
						if (skillExists) {
							const updatedSkillData = prevSkillData.map((skill) =>
								skill.id === editedSkill.id ? { ...editedSkill } : skill
							);
							return updatedSkillData;
						} else {
							return [...prevSkillData, { ...editedSkill }];
						}
					});
				});

				Object.keys(tempIdMap).forEach((oldId) => {
					const newId = tempIdMap[oldId];
					setSkillData((prevSkillData) => {
						const updatedSkillData = prevSkillData.map((skill) =>
							skill.id == oldId ? { ...skill, id: newId } : skill
						);
						return updatedSkillData;
					});

				});
				setChangedSkills({
					added: [],
					edited: [],
					deleted: [],
				});
			}
		} catch (error) {
			showSnackbar("Error", "error")
		} finally {
			setButtonLoading((prevState) => ({ ...prevState, ok: false }));
			closeModals();
		}
	}

	function closeModals() {
		setUnsavedChangesShowModal(false);
		setShowReferenceModal(false);
		setShowSkillsModal(false);
		setChangedSkills({
			added: [],
			edited: [],
			deleted: [],
		});
	}
	function closeSkillsModal(fromSaved = false) {
		if (fromSaved) {
			setShowSkillsModal(false);
		}
		else if (
			changedSkills.added.length > 0 ||
			changedSkills.edited.length > 0 ||
			changedSkills.deleted.length > 0
		) {
			setUnsavedChangesShowModal(true);
		} else {
			setShowSkillsModal(false);
		}
	}
	function cardClickFn(curExperienceData) {
		onClickFn(curExperienceData);
	}

	function handleOpenReferenceModal(event, curExperienceData) {
		event.stopPropagation();
		setClickedExperience(curExperienceData);
		setShowReferenceModal(true);
	};

	function handleOpenSkillsModal(event, curExperienceData) {
		event.stopPropagation();
		setClickedExperience(curExperienceData);
		setShowSkillsModal(true);
	};

	const start_year = dayjs(curExperienceData.start_date).year();
	const end_year = curExperienceData.end_date
		? ` - ${dayjs(curExperienceData.end_date).year()}`
		: " - Current";

	let bottomLine = `${start_year}${end_year}`;
	if (curExperienceData.organization) {
		bottomLine += ` | ${curExperienceData.organization}`;
	}

	return (
		<>
			{showUnsavedChangesModal && (
				<UnsavedChangesDialog
					open={showUnsavedChangesModal}
					setOpen={setUnsavedChangesShowModal}
					closeModalCallback={closeModals}
				/>
			)}
			{showReferenceModal &&
				<References
					showReferenceModal={showReferenceModal}
					setShowReferenceModal={setShowReferenceModal}
					curReferenceData={curReferenceData}
					setReferenceData={setReferenceData}
					curExperienceData={curExperienceData}
					closeButtonCallback={() => { setShowReferenceModal(false) }}
					showSnackbar={showSnackbar}
				/>
			}
			{showSkillsModal && (
				<SkillsModal
					open={showSkillsModal}
					experienceID={curExperienceData.id}
					closeButtonCallback={closeSkillsModal}
					okButtonCallback={handleSkillsChanges}
					setChangedSkills={setChangedSkills}
					setSkillData={setSkillData}
					skillData={curSkills}
					okButtonLoading={buttonLoading.ok}
				/>
			)}


			<Card
				onClick={isEdit ? () => cardClickFn(curExperienceData) : undefined}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				sx={{
					padding: isPrintMode ? '10px' : "20px",
					backgroundColor: isEdit && isHovered && "secondary.cardHover",
					transition: "background-color 0.2s ease",
					cursor: isEdit ? "pointer" : "default",
					marginTop: `${spacingCount * 10}px`,
					marginBottom: addMargin ? "10px" : "0",
					position: "relative"
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={isEdit ? 5 : 7}>
						<Typography variant="body1" onClick={() => isPrintMode && setSpacingCount((prevValue) => prevValue - 1)}>{curExperienceData.title}</Typography>
						<Typography variant="body2" onClick={() => isPrintMode && setSpacingCount((prevValue) => prevValue + 1)}>{bottomLine}</Typography>
						{curExperienceData.location && (
							<Typography variant="body2">{curExperienceData.location}</Typography>
						)}
						{curExperienceData.overview && (
							<Typography variant="body1" sx={{ paddingTop: "10px", whiteSpace: "pre-line" }}>
								{curExperienceData.overview}
							</Typography>
						)}
					</Grid>

					{!isPrintMode &&
						<Grid item xs={12} md={isEdit ? 4 : 5}>
							<Grid container>
								{(isEdit || curSkills.length > 0) && (
									<Grid item xs={12} pb={0.6}>
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											<Typography variant="body1">Skills</Typography>
											{isEdit && curSkills.length > 0 && (
												<Stack
													direction="row"
													alignItems="center"
													sx={{
														pr: 8,
														position: 'relative',
														cursor: isEdit ? "pointer" : "default",
														'&:hover .edit-text': {
															textDecorationLine: 'underline'
														},
													}}
													onClick={(e) => {
														if (isEdit) {
															e.stopPropagation();
															handleOpenSkillsModal(e, curExperienceData);
														}
													}}
												>
													<Typography
														variant="body1"
														className="edit-text"
														sx={{
															cursor: isEdit ? "pointer" : "default",
															fontSize: '16px'
														}}
													>
														Edit Skills
													</Typography>
													<ChevronRightIcon
														className="chevron-icon"
														sx={{
															cursor: isEdit ? "pointer" : "default",
														}}
													/>
												</Stack>
											)}
										</Stack>
									</Grid>
								)}

								{curSkills.length > 0 && curSkills.map((skill, index) => (
									<Grid item key={index} pr={1} mb={1}>
										<Chip
											label={skill.skill.charAt(0).toUpperCase() + skill.skill.slice(1)}
											variant="outlined"
											style={{ cursor: isEdit ? "pointer" : "default" }}
										/>
									</Grid>
								))}

								{isEdit && curSkills.length === 0 && (
									<Grid item xs={12} pt={0.5}>
										<Button
											size="small"
											variant="outlined"
											color="secondary"
											onClick={(e) => handleOpenSkillsModal(e, curExperienceData)}
											sx={{ height: '35px' }}
										>
											Add Skills
										</Button>
									</Grid>
								)}
							</Grid>


							{curExperienceData.sectors && curExperienceData.sectors.length > 0 && (
								<Grid container spacing={1} pt={1}>
									<Grid item xs={12} sx={{ textAlign: "left" }}>
										<Typography variant="body1">Sectors</Typography>
									</Grid>
									{curExperienceData.sectors.map((sector, index) => (
										<Grid item key={index}>
											<Chip
												label={sector}
												variant="outlined"
												style={{ cursor: isEdit && "pointer" }}
											/>
										</Grid>
									))}
								</Grid>
							)}
						</Grid>
					}
					{!isEdit ? null : (
						<Grid item xs={12} md={3}>
							<Grid item xs={12}>
								<Typography variant="body1">Employment Verification</Typography>
							</Grid>
							<Grid item xs={12} pt={1}>
								{isEdit && (
									<Grid item xs={12}>
										<Button
											variant="outlined"
											color="secondary"
											onClick={(event) => { handleOpenReferenceModal(event, curExperienceData) }}
											size="small"
											sx={{ py: 0.2 }}
										>
											{curReferenceData ? "View" : "Add"}
										</Button>
									</Grid>
								)}

								{curReferenceData && (
									<Grid item xs={12} pt={isEdit ? 1 : 0}>
										<Typography >
											Status: {snakeCaseToTitleCase(curReferenceData.status)}
										</Typography>
										<Typography>
											Reference sent to {curReferenceData.name} ({curReferenceData.role}) at {curReferenceData.email}
										</Typography>
									</Grid>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			</Card >
		</>
	);
}
