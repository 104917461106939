import { Grid, Typography } from "@mui/material";
import HistoryItem from "./HistoryItem";
import { useContext } from "react";
import { savedDataContext } from "../../..";

export default function HistoryTab({ enrollId }) {
	const { savedEnrollData } = useContext(savedDataContext);

	return (
		<Grid container justifyContent={"center"}>
			{savedEnrollData[enrollId]?.history?.map((item, index) => (
				<Grid item xs={12} key={index}>
					<HistoryItem
						data={item}
						user={savedEnrollData.users.find(
							(user) => user.uuid === item.admin
						)}
					/>
				</Grid>
			))}
			<Grid item my={2}>
				<Typography>No more activities to view</Typography>
			</Grid>
		</Grid>
	);
}
