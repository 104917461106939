import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	Typography,
	TextField,
	Button,
	IconButton,
	Tooltip,
	Divider,
	CircularProgress
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import emptyNote from "./emptyNote";
import ClientPortalNote from "./ClientPortalNote";
import { RESTUtils } from "../../../../../utilities/RESTUtils";
import SnackbarComponent from "../../../../../components/snackbar";

export default function NoteModal({
	noteData,
	enrollID,
	setCandidateData,
	userUUID,
}) {
	const { job_uuid } = useParams();
	const [newNote, setNewNote] = useState(emptyNote);
	const [error, setError] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [dataChanged, setDataChanged] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("info");
	const [buttonLoading, setButtonLoading] = useState(false);

	function handleChange(event) {
		const { name, value, checked, type } = event.target;
		setNewNote((prevNote) => ({
			...prevNote,
			[name]: type === "checkbox" ? checked : value,
		}));
	}


	useEffect(() => {
		if (!firstRender) {
			setDataChanged(true);
		} else {
			setFirstRender(false);
		}
	}, [newNote]);

	function handleBellChange(event) {
		setNewNote((prevNote) => ({
			...prevNote,
			alert: !prevNote.alert,
		}));
	}

	function noteInvalid() {
		const isInvalid = newNote.message === "" || newNote.author === "";
		setError(isInvalid);
		return isInvalid;
	}

	async function handleAddNote() {
		if (!noteInvalid()) {
			setButtonLoading(true);
			try {
				const response = await RESTUtils.POST(
					RESTUtils.getAPIUrl() +
					`api/user/createClientPortalNote/?job_uuid=${job_uuid}&user_uuid=${userUUID}`,
					newNote
				);

				if (response.status === 201) {
					setSnackbar("success");
					const new_note = response.data;
					setCandidateData((prevData) => ({
						...prevData,
						[userUUID]: {
							...prevData[userUUID],
							notes: [
								...(prevData[userUUID]?.notes || []),
								new_note,
							],
						},
					}));
				}
				setNewNote(emptyNote);
			} catch {
				console.log("error");
				setSnackbar("error");
			} finally {
				setButtonLoading(false);
			}
		}
	}

	function setSnackbar(type) {
		if (type === "error") {
			setSnackbarMessage("Something went wrong");
			setSnackbarSeverity("error");
			setShowSnackbar(true);
		} else {
			setSnackbarMessage("Note Created");
			setSnackbarSeverity("success");
			setShowSnackbar(true);
		}
	}
	const handleCloseSnackbar = () => {
		setShowSnackbar(false);
		setSnackbarMessage("");
	};

	return (
		<Grid container spacing={2}>
			<SnackbarComponent
				show={showSnackbar}
				message={snackbarMessage}
				severity={snackbarSeverity}
				positionVertical="bottom"
				positionHorizontal="center"
				autoHideDuration={5000}
				handleCloseCallback={handleCloseSnackbar}
			/>

			<Grid item xs={12}>
				<Typography style={{ fontSize: "1rem" }}>
					Leave notes about this candidate here. They will be visible
					to the PL admins, but not the candidates. Select the
					notification icon when adding a note to alert the PL admins,
					so questions about the candidate can be answered readily.
				</Typography>
			</Grid>

			<Grid item xs={12} mt={1}>
				<TextField
					multiline
					minRows={3}
					fullWidth
					label="Note Message"
					value={newNote.message || ""}
					name="message"
					onChange={handleChange}
					error={error && newNote.message === ""}
					required
					inputProps={{ maxLength: 501 }}
				/>
			</Grid>
			<Grid item xs={10} md={8}>
				<TextField
					label="Your Name"
					fullWidth
					value={newNote.author || ""}
					name="author"
					onChange={handleChange}
					error={error && newNote.author === ""}
					required
					inputProps={{ maxLength: 255 }}
				/>
			</Grid>
			<Grid
				item
				xs={2}
				md={1.5}
				sx={{ display: "flex", justifyContent: "center", }}
			>
				<Tooltip title="Notify PL Admins" arrow placement="top">
					<IconButton onClick={handleBellChange}>
						{newNote.alert === false ? (
							<NotificationsNoneIcon />
						) : (
							<NotificationsIcon color="primary" />
						)}
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid container item xs={3} md={2.5} justifyContent="flex-end" alignItems="center">
				<Button
					variant="outlined"
					color="secondary"
					fullWidth
					onClick={handleAddNote}
					disabled={buttonLoading}
					startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
				>
					{buttonLoading ? "Adding" : "Add Note"}
				</Button>
			</Grid>


			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12}>
				<div style={{ maxHeight: "250px", overflow: "auto" }}>
					{noteData
						.slice()
						.reverse()
						.map((noteData, index) => (
							<ClientPortalNote key={index} noteData={noteData} />
						))}
				</div>
			</Grid>
		</Grid>
	);
}
