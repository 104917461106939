import { useState } from "react";
import { Grid, Stack, IconButton, Typography, Divider, Checkbox, Tooltip } from "@mui/material";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import StatusStepper from "./StatusStepper";
import { CompletionUseEffects } from './stepUseEffects';
import { TaskUseEffects } from "./taskUseEffects";
import { stepsData, useTasks } from "./data";
import { RESTUtils } from "../../../../utilities/RESTUtils";

export default function CompletionStatusProfile({ allData }) {
  const [curStep, setCurStep] = useState(0);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const { tasks } = TaskUseEffects(allData);
  const { steps } = CompletionUseEffects(allData, tasks, isProfileComplete, setIsProfileComplete);
  const stepsCompleted = Object.values(steps).filter(step => step).length;
  const totalSteps = Object.keys(steps).length;
  const newTasks = useTasks(tasks);


  async function toggleNA(curStep) {
    console.log(allData.userInfo.not_applicable)
    const newArray = [...allData.userInfo.not_applicable];
    newArray[curStep] = !newArray[curStep];
    allData.setUserInfo((prevInfo) => ({
      ...prevInfo,
      not_applicable: newArray,
    }));
    try {
      const response = await RESTUtils.PUT(
        RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${allData.userInfo.uuid}`,
        {
          not_applicable: newArray,
        }
      );
      if (response.status === 200) {

        console.log(response.data);
      } else {
        console.log(response.data);
        const newArray = [...allData.userInfo.not_applicable];
        newArray[curStep] = !newArray[curStep];
        allData.setUserInfo((prevInfo) => ({
          ...prevInfo,
          not_applicable: newArray,
        }));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  return (
    <Grid container padding={1}>
      <Grid item xs={12} textAlign={'center'} py={0}>
        <Typography variant='h5'>{isProfileComplete ? "Profile Complete!" : `Profile Completion: ${stepsCompleted} / ${totalSteps}`}</Typography>
      </Grid>

      {!isProfileComplete && (
        <>
          <Grid item xs={12} pt={1}>
            <Divider />
          </Grid>

          <Grid container item pt={1} justifyContent={'center'}>
            <StatusStepper steps={steps} curStep={curStep} setCurStep={setCurStep} />
          </Grid>

          <Grid item xs={12} pl={1}>
            <Typography variant='h6'>{stepsData[curStep].title}</Typography>
            <Typography>{stepsData[curStep].desc}</Typography>

            {newTasks[curStep].map((task, index) => (
              <Tooltip
                key={index}
                title={task.id === 'userReferences' ? <Typography> References can be found in the 'Details' tab. </Typography> : ''}
                arrow placement="top"
              >
                <Stack direction="row" justifyContent="flex-start" alignItems={'center'} sx={{ cursor: task.id === 'userReferences' && 'help' }}>
                  {task.type === 'checkbox' ?
                    <Checkbox
                      checked={allData.userInfo.not_applicable[curStep]}
                      onChange={() => toggleNA(curStep)}
                      sx={{
                        color: "primary.contrastText",
                        "&.Mui-checked": {
                          color: "primary.contrastText",
                        },
                      }}
                    >
                    </Checkbox>

                    : (
                      <>
                        <IconButton disabled={true}>
                          {task.completed ?
                            <CheckIcon sx={{ color: 'green' }} />
                            :
                            <CircleOutlinedIcon sx={{ color: 'secondary.main' }} />
                          }
                        </IconButton>
                      </>
                    )
                  }
                  <Typography>{task.label}</Typography>
                </Stack>
              </Tooltip>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
}
