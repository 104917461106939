export function emptyReference(uuid, isAdmin) {
    let reference = {
        title: "",
        description: "",
        organization: "",
        user_data: uuid,
        id: null,
    };

    if (isAdmin) {
        reference.notes = "";
        reference.notes_date = null;
    }

    return reference;
}

export function initialState(uuid, is_admin) {
    return {
        curReferenceData: emptyReference(uuid, is_admin),
        showModal: false,
        error: false,
        isNewReference: false,
        buttonLoading: {
            okButton: false,
            deleteButton: false,
        }
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case 'SET_CURRENT_REFERENCE_DATA':
            return { ...state, curReferenceData: action.payload };
        case 'TOGGLE_MODAL':
            return { ...state, showModal: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        case 'SET_IS_NEW_REFERENCE':
            return { ...state, isNewReference: action.payload };
        case 'SET_BUTTON_LOADING':
            return { ...state, buttonLoading: { ...state.buttonLoading, ...action.payload } };
        default:
            return state;
    }
}
