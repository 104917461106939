export const letterArray = [
	["E", "I"],
	["T", "F"],
	["A", "D"],
];
export const groupedPairs = [
	[
		["extrovert", 5],
		["assertive", 7],
		["thinking", 0],
		["thinking", 10],
		["extrovert", 9],
		["thinking", 4],
		["assertive", 4],
		["extrovert", 2],
		["assertive", 6],
	],
	[
		["thinking", 9],
		["assertive", 5],
		["extrovert", 10],
		["extrovert", 4],
		["thinking", 2],
		["thinking", 1],
		["extrovert", 0],
		["assertive", 8],
		["assertive", 11],
	],
	[
		["thinking", 3],
		["extrovert", 1],
		["assertive", 10],
		["thinking", 11],
		["extrovert", 6],
		["thinking", 5],
		["assertive", 2],
		["extrovert", 8],
		["assertive", 0],
	],
	[
		["assertive", 9],
		["thinking", 6],
		["extrovert", 3],
		["extrovert", 11],
		["assertive", 3],
		["extrovert", 7],
		["thinking", 7],
		["assertive", 1],
		["thinking", 8],
	],
];

export const personaDictionary = {
	ETA: "Assertive Commander",
	EFA: "Assertive Altruist",
	ETD: "Dynamic Commander",
	EFD: "Dynamic Altruist",
	ITA: "Assertive Thinker",
	IFA: "Assertive Mediator",
	ITD: "Dynamic Thinker",
	IFD: "Dynamic Mediator",
};
export const personalityQuestions = {
	extrovert: [
		{
			question:
				"You have no problem going up to a stranger and starting a conversation.",
			inverse: false,
		},
		{
			question: "You often depend on others to initiate conversations.",
			inverse: true,
		},
		{
			question:
				"You enjoy spending quiet time with friends over going out to energetic and lively gatherings.",
			inverse: true,
		},
		{
			question:
				"When in public places, you can most often be found away from the busy areas.",
			inverse: true,
		},
		{
			question: "You enjoy large social gatherings.",
			inverse: false,
		},
		{
			question: "At social events, you are often found near the action.",
			inverse: false,
		},
		{
			question:
				"You prefer to spend time alone over being in bustling environments.",
			inverse: true,
		},
		{
			question:
				"You can easily jump into a conversation with groups of people you do not know.",
			inverse: false,
		},
		{
			question:
				"After a long hard week, you prefer to have some time to yourself to rest and relax.",
			inverse: true,
		},
		{
			question:
				"Spending extended periods of time alone makes you feel bored.",
			inverse: false,
		},
		{
			question:
				"Your idea of a good time involves lots of people and lots of action.",
			inverse: false,
		},
		{
			question:
				"In groups settings, you find it difficult to introduce yourself to new people and primarily end up talking to people you already know.",
			inverse: true,
		},
	],
	thinking: [
		{
			question:
				"You do everything you can to not hurt other people's feelings.",
			inverse: true,
		},
		{
			question: "You have no problem controlling your emotions.",
			inverse: false,
		},
		{
			question: "You find it easy to show affection for people you love.",
			inverse: true,
		},
		{
			question: "You are fine sharing how you feel with others.",
			inverse: true,
		},
		{
			question:
				"You have no problem empathizing with your friend if they are sad about something.",
			inverse: true,
		},
		{
			question:
				"You struggle to understand those who use their heart to guide their decisions.",
			inverse: false,
		},
		{
			question: "You rarely get upset as a result of other people.",
			inverse: false,
		},
		{
			question:
				"Before you make a decision, you consider how it might impact other people.",
			inverse: true,
		},
		{
			question:
				"When making decisions, you consider how it affects yourself first rather than others.",
			inverse: false,
		},
		{
			question: "You have no problem crying in front of others.",
			inverse: true,
		},
		{
			question:
				"You would not describe yourself as artistic or creative.",
			inverse: false,
		},
		{
			question:
				"You rely on logic to form your decisions versus feelings and emotions.",
			inverse: false,
		},
	],
	assertive: [
		{
			question:
				"You are confident in the choices that you have made in your life.",
			inverse: false,
		},
		{
			question:
				"You regularly feel insecure about how your work will be assessed.",
			inverse: true,
		},
		{
			question:
				"You often think that the worst-case scenario will occur.",
			inverse: true,
		},
		{
			question:
				"You often worry whether you made a good impression on someone or not.",
			inverse: true,
		},
		{
			question:
				"You often find yourself calm in high-pressure situations.",
			inverse: false,
		},
		{
			question:
				"You often find yourself thinking about mistakes that you made in the past.",
			inverse: true,
		},
		{
			question: "You do not doubt yourself or your abilities.",
			inverse: false,
		},
		{
			question:
				"Your friends or family would describe you as a perfectionist.",
			inverse: true,
		},
		{
			question: "You feel that your life is always on track.",
			inverse: false,
		},
		{
			question: "Your mood does not change very often.",
			inverse: false,
		},
		{
			question:
				"You do not feel ashamed of past decisions you have made.",
			inverse: false,
		},
		{
			question:
				"When you make a mistake, it can cause you to question your abilities.",
			inverse: true,
		},
	],
};
