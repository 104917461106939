import { useState } from "react";
import {
  Paper,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import { RESTUtils } from "../../../utilities/RESTUtils";
import ClientDetails from "./OverviewQualifications";
import ExperienceTab from "./experiences/ExperienceTab";
import NotableProjectsTab from "./notableProjects/NotableProjectsTab";
import EducationParent from "./educations/EducationParent";
import AwardTable from "./awards/AwardTab";
import DetailsParent from "./details/DetailsParent";
import WorkIcon from '@mui/icons-material/Work';
import ConstructionIcon from "@mui/icons-material/Construction";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import ListIcon from '@mui/icons-material/List';

export default function RightBox({
  allData,
  buttons,
  isEdit,
  loading,
  is_admin,
  uuid,
  fromCandidateSearch = false,
}) {
  const [index, setIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const handleChange = (fieldName, value) => {
    const updatedUserProfile = {
      ...allData.userInfo,
      [fieldName]: value,
    };
    allData.setUserInfo(updatedUserProfile);
  };

  const handleTabChange = (event, newIndex) => {
    setIndex(newIndex);
  };

  function getTabFromIndex(index) {
    return buttons[index];
  }
  async function handleSaveUserProfile() {
    setSaving(true);
    try {
      const response = await RESTUtils.PUT(
        RESTUtils.getAPIUrl() + `api/user/edit_user_data/?uuid=${uuid}`,
        {
          overview: allData.userInfo.overview,
          qualifications: allData.userInfo.qualifications,
        }
      );
      if (response.status === 200) {
        allData.showSnackbar("Profile Saved", "success");
      } else {
        console.log(response.data);
        allData.showSnackbar("Something went wrong", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setSaving(false);
    }
  }
  const contentMap = {
    "experience": (
      <ExperienceTab
        isEdit={isEdit}
        allData={allData}
      />
    ),
    "notable projects": (
      <NotableProjectsTab
        isEdit={isEdit}
        allData={allData}
      />
    ),
    "education": (
      <EducationParent
        isEdit={isEdit}
        allData={allData}
      />
    ),
    "awards": (
      <AwardTable
        isEdit={isEdit}
        allData={allData}
      />
    ),
    [is_admin ? "admin" : "details"]: <DetailsParent isEdit={isEdit} is_admin={is_admin} allData={allData} />,
  };
  const iconMap = {
    'experience': <WorkIcon />,
    'notable projects': <ConstructionIcon />,
    'education': <SchoolIcon />,
    'awards': <EmojiEventsIcon />,
    [is_admin ? "admin" : 'details']: is_admin ? <ShieldOutlinedIcon /> : <ListIcon />,
  };

  return (
    <>

      {loading ? (
        <Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "20px",
            }}
          >
            <CircularProgress />
          </div>
        </Paper>
      ) : (
        <>
          <Paper>
            {/* <div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<Tooltip
								title={
									"Use this to switch between types of profiles?"
								}
								arrow
							>
								<MoreHorizIcon />
							</Tooltip>
						</div> */}
            <ClientDetails
              profileData={allData.userInfo}
              setProfileData={allData.setUserInfo}
              skillData={allData.skillData}
              experienceData={allData.experienceData}
              isEdit={isEdit}
              handleChange={handleChange}
              handleSaveUserProfile={handleSaveUserProfile}
              fromCandidateSearch={fromCandidateSearch}
              saving={saving}
            />
          </Paper>

          <Paper sx={{ marginTop: "15px" }}>
            <Grid container justifyContent="center">
              <Tabs
                value={index}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                textColor="inherit"
                indicatorColor="primary"
              >
                {buttons?.map((label, index) => (
                  <Tab
                    key={index}
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    icon={iconMap[getTabFromIndex(index)]}
                    iconPosition="start"
                    label={label}
                  />
                ))}
              </Tabs>
            </Grid>

            <Grid container justifyContent="center">
              {contentMap[getTabFromIndex(index)]}
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
