// options.js

const skillOptions = [
    'Contractor Oversight',
    'Project Administration',
    'Contract Administration',
    'Cost Controls',
    'Scheduling',
    'Scope Management',
    'Quality',
    'Estimating',
    'Scope Development',
    'Safety Management',
    'Purchasing',
    'Training',
    'Planning',
    'Prioritization',
    'Stakeholder Management',
    'Procurement',
  ];
  
  const sectorOptions = [
    'Agriculture',
    'Construction',
    'Consulting',
    'Education',
    'Hard Rock Mining',
    'Industrial',
    'Information Technology',
    'Infrastructure',
    'Logistics',
    'Manufacturing',
    'Mining',
    'Oil & Gas',
    'Potash Mining',
    'Power Generation',
    'Public',
    'Telecommunications',
    'Uranium Mining',
  ];
  
  export { skillOptions, sectorOptions };
  