import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  Stack,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Tooltip
} from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";

export default function OverviewQualifications({
  profileData,
  setProfileData,
  handleSaveUserProfile,
  skillData,
  experienceData,
  isEdit,
  fromCandidateSearch = false,
  saving,
  stackDirection = "row",
  isPrintMode = false
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  useEffect(() => {
    async function makeAPICall() {
      if (!isFirstRender) {
        handleSaveUserProfile();
      }
    };

    let debounceTimer;

    function handleDebouncedAPICall() {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        makeAPICall();
      }, 1000);
    };

    if (!fromCandidateSearch) {
      handleDebouncedAPICall();
    } else {
      clearTimeout(debounceTimer);
    }
    return () => clearTimeout(debounceTimer);

  }, [profileData.overview, profileData.qualifications, fromCandidateSearch]);


  const handleInputChange = (fieldName, value, index = -1) => {
    setIsFirstRender(false);
    if (index !== -1) {
      const updatedQualifications = [...profileData[fieldName]]; // Copy the qualifications array
      updatedQualifications[index] = value;
      value = updatedQualifications;
    }
    const updatedUserProfile = {
      ...profileData,
      [fieldName]: value,
    };
    setProfileData(updatedUserProfile);
  };

  return (
    <Grid container spacing={2} padding={isPrintMode ? 1 : 2}>
      <Grid item xs={stackDirection === 'row' ? 6 : 12}>
        <Stack direction={"row"} spacing={1} alignItems="center" pb={1}>
          {!isMobile && <ArticleIcon />}
          <Tooltip
            title={isEdit ? <Typography>Your overview is a place to provide some background on yourself
              and your professional career.</Typography> : ""}
            arrow placement="top"
          >
            <Typography variant="h5" sx={{ cursor: isEdit && 'help' }}>Overview</Typography>
          </Tooltip>

        </Stack>

        {isEdit ? (
          <TextField
            type="text"
            value={profileData.overview || ""}
            fullWidth
            multiline
            minRows={5}
            inputProps={{ maxLength: 1000 }}
            onChange={(e) => handleInputChange("overview", e.target.value)}
          />
        ) : (
          <Typography>{profileData.overview || "No overview found"}</Typography>
        )}
      </Grid>

      <Grid item xs={stackDirection === 'row' ? 6 : 12}>
        <Stack direction={"row"} spacing={1} alignItems="center">
          {!isMobile && <WorkspacePremiumOutlinedIcon />}
          <Tooltip
            title={isEdit ? <Typography>Highlight your years of experience, degrees, certifications,
              and any other noteworthy milestones you'd like to share.</Typography> : ''}
            arrow placement="top"
          >
            <Typography variant="h5" sx={{ cursor: isEdit && 'help' }}>Qualifications</Typography>
          </Tooltip>

        </Stack>

        <List>
          {isEdit ? (
            [...Array(4)].map((_, index) => (
              <TextField
                key={index}
                type="text"
                value={profileData.qualifications[index] || ""}
                multiline
                fullWidth
                maxRows={1.75}
                size="small"
                style={{ marginBottom: "4px" }}
                InputProps={{
                  style: {
                    fontSize: "0.75rem",
                  },
                }}
                inputProps={{
                  maxLength: 254,
                }}
                onChange={(e) =>
                  handleInputChange("qualifications", e.target.value, index)
                }
              />
            ))
          ) :
            <Typography sx={{ marginTop: 1 }}>
              {profileData?.qualifications.length === 0 || profileData.qualifications.every(qualification => !qualification.trim()) ? (
                "No qualifications found."
              ) : (
                profileData.qualifications.map((qualification, index) => (
                  qualification &&
                  <ListItem style={{ marginTop: "-10px" }} key={index}>&#8226; {qualification}</ListItem>
                ))
              )}
            </Typography>
          }
        </List>
      </Grid>

      {fromCandidateSearch && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleSaveUserProfile}
            disabled={saving}
            startIcon={saving && <CircularProgress color="inherit" size={20} />}
          >
            {saving ? "Saving" : "Save"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
