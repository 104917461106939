import { useState, useEffect, createContext } from "react";
import {
	Grid,
	Typography,
	Divider,
	Paper,
	Box,
	CircularProgress,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import ShortList from "./components/shortList/ShortlistWeb";
import ShortListMobile from "./components/shortList/ShortlistMobile";
import CandidateListWeb from "./components/candidateList/CandidateListWeb";
import CandidateListMobile from "./components/candidateList/CandidateListMobile";
import Spotlight from "./components/spotlight/Spotlight";
import { RESTUtils } from "../../../utilities/RESTUtils";
import { loadingStyle } from "./styles";
import { useParams } from "react-router-dom";
import { SessionUtils } from "../../../utilities/SessionUtils";

export const interviewData = createContext();

export default function ClientPortal() {
	const { job_uuid } = useParams();
	const user = SessionUtils.getCurrentUserSession();
	const [indexA, setIndexA] = useState();
	const [indexB, setIndexB] = useState();
	const [jobData, setJobData] = useState({});
	const [userList, setUserList] = useState([]);
	const [candidateData, setCandidateData] = useState({});
	const [savedList, setSavedList] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [loading, setLoading] = useState(true);
	const [sl_order_changed, setSL_order_changed] = useState(false);
	const [interviewVars, setInterviewVars] = useState({
		name: "",
		availability: "",
		interview_preference: "",
		comments: "",
		users: [],
		job: job_uuid,
	});

	const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

	function handleTabChange(event, index) {
		setSelectedTab(index);
	}

	async function get_client_portal_data() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
				`api/user/get_client_portal_data/?job_uuid=${job_uuid}`
			);
			if (response.status === 200) {
				setCandidateData(response.data.user_data);
				setUserList(response.data.user_list);
				setSavedList(Object.keys(response.data.user_data));
				setIndexA(response.data.first_uuids[0]);
				setIndexB(response.data.first_uuids[1]);
			} else {
				console.log("error");
			}
		} catch (error) {
			console.log(error);
		} finally {
		}
	}

	async function getJobDetails() {
		try {
			const response = await RESTUtils.GET(
				RESTUtils.getAPIUrl() +
				`api/admin/get_client_portal_job_details/?job_uuid=${job_uuid}`
			);
			if (response.status === 200) {
				setJobData(response.data);
			}
		} catch (error) {
			console.error("Error fetching job details:", error);
		}
	}
	useEffect(() => {
		const fetchData = async () => {
			try {
				await Promise.all([getJobDetails(), get_client_portal_data()]);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	async function getCandidateData(uuid) {
		if (!savedList.includes(uuid)) {
			try {
				const response = await RESTUtils.GET(
					RESTUtils.getAPIUrl() +
					`api/user/get_cp_user_data/?user_uuid=${uuid}&job_uuid=${job_uuid}`
				);

				if (response.status === 200) {
					const updatedList = [...savedList, uuid];
					setSavedList(updatedList);

					setCandidateData((prevCandidateData) => {
						const newData = { ...prevCandidateData };
						newData[uuid] = response.data;
						return newData;
					});
				} else {
					console.error(
						"Failed to fetch candidate data:",
						response.status
					);
				}
			} catch (error) {
				console.error("Error fetching candidate data:", error);
			}
		}
	}
	async function updateShortList(swap, clientPortalID = false, isRemoved = false) {
		setSL_order_changed(true);
		if (!swap) { // if we are removing or adding a candidate
			const updatedCP_SL_order = jobData.CP_SL_order || [];
			if (isRemoved) {
				const updatedOrder = updatedCP_SL_order.filter(id => id != clientPortalID);
				setJobData({ ...jobData, CP_SL_order: updatedOrder });
			} else {
				if (!updatedCP_SL_order.includes(clientPortalID.toString())) {
					const updatedOrder = [...updatedCP_SL_order, clientPortalID];
					setJobData({ ...jobData, CP_SL_order: updatedOrder });
				}
			}
		} else { // if we are swapping position of 2 candidates
			const updatedOrder = [...jobData.CP_SL_order];
			const removedItem = updatedOrder.splice(swap[0], 1);
			updatedOrder.splice(swap[1], 0, removedItem[0]);

			// const temp = updatedOrder[swap[0]];
			// updatedOrder[swap[0]] = updatedOrder[swap[1]];
			// updatedOrder[swap[1]] = temp;
			setJobData({ ...jobData, CP_SL_order: updatedOrder });
		}
	}

	async function changeUserStatus(newStatus, uuid) {
		let oldStatus;
		let clientPortalID;

		const updatedUserList = userList.map((item) => {
			if (item.user === uuid) {
				oldStatus = item.status;
				clientPortalID = item.id;
				if (oldStatus === "shortlisted" || newStatus === "shortlisted") {
					updateShortList(false, clientPortalID, oldStatus === "shortlisted");
				}
				return {
					...item,
					status: newStatus,
				};
			} else {
				return item;
			}
		});

		setUserList(updatedUserList);
		const nonDQUsers = updatedUserList.filter(
			(candidate) => candidate.status !== "rejected"
		);
		const nonDQUsersLength = nonDQUsers.length;

		// If we just reactivated someone when we previously had 1 active candidate, we want to set the
		// previously hidden spotlight to be this newly activated candidate
		if (
			newStatus === "proposed" &&
			nonDQUsersLength === 2 &&
			oldStatus === "rejected"
		) {
			getCandidateData(uuid);
			setIndexB(uuid);
		}

		let setterFn;
		let otherIndex;
		if (indexA === uuid) {
			setterFn = setIndexA;
			otherIndex = indexB;
		} else {
			setterFn = setIndexB;
			otherIndex = indexA;
		}
		if (newStatus === "rejected" && nonDQUsersLength > 1) {
			const newUser = nonDQUsers.find(
				(nonDQUser) => nonDQUser.user !== otherIndex
			);
			getCandidateData(newUser.user);
			setterFn(newUser.user);
		}
		// optimistic render before the API, seems OK cause it's very simple. Zyzz maybe want to add
		// success snackbar, but maybe OK without
		try {
			const response = await RESTUtils.PUT(
				RESTUtils.getAPIUrl() +
				`api/user/change_client_portal_status/?id=${clientPortalID}&status=${newStatus}`
			);

			if (response.status === 200) {
				console.log("candidate's status successfully changed");
			}
		} catch {
			console.log("error");
		}
	}


	function changeIndex(newIndex) {
		if (indexA !== newIndex) {
			setIndexB(indexA);
			setIndexA(newIndex);
		}
	}
	async function updateShortListAPI() {
		if (sl_order_changed) {
			try {
				const response = await RESTUtils.PUT(
					RESTUtils.getAPIUrl() +
					`api/user/update_sl_order/`,
					{
						job_uuid: job_uuid,
						sl_order: jobData.CP_SL_order,
					}
				);
				if (response.status === 200) {
					console.log("shortlist order updated");
				}
			} catch (error) {
				console.error("Error updating shortlist order:", error);
			}
		}
	}
	function handleCardClick(uuid) {
		getCandidateData(uuid);
		changeIndex(uuid);
	}
	useEffect(() => {
		const nonDQdUsers = userList.filter(
			(candidate) => candidate.status !== "rejected"
		);
		if (nonDQdUsers.length === 1) {
			getCandidateData(nonDQdUsers[0].user);
			setIndexA(nonDQdUsers[0].user);
		}
	}, [userList]);

	useEffect(() => {
		updateShortListAPI();
	}, [jobData?.CP_SL_order]);

	useEffect(() => {
		async function cp_opened() {
			try {
				await RESTUtils.POST(
					RESTUtils.getAPIUrl() +
					`api/user/cp_opened/`, { 'job_uuid': job_uuid, user_uuid: user?.uuid }
				);
			} catch (error) {
				console.error("Error", error);
			}
		}
		if (!user || !user.is_admin) {
			cp_opened();
		}


	}, []);

	if (isMobile && loading) {
		return (
			<Paper>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						width: "100%",
						padding: "20px",
					}}
				>
					<CircularProgress size={50} />
				</div>
			</Paper>
		)
	}

	return (
		<interviewData.Provider value={{ interviewVars, setInterviewVars, setUserList }}>
			<Grid
				container
				style={{
					margin: "auto",
					// justifyContent: "center",
				}}
				p={isMobile ? 1 : 2}
			>
				<Grid item xs={12} style={{ textAlign: "right" }}>
					{!isMobile && (
						<>
							<Typography variant="h5" sx={{ paddingBottom: "10px" }}>
								<span style={{ fontWeight: "bold" }}>THE BENCH</span>{" "}
								CANDIDATES
							</Typography>
							<Divider
								sx={{
									borderBottom: "2px solid",
									borderColor: "primary.main",
								}}
							/>
						</>
					)}

					<Typography
						variant="h5"
						style={{
							fontWeight: "bold",
							textAlign: "left",
							paddingTop: "10px",
						}}
					>
						{jobData?.title}
					</Typography>
				</Grid>

				{/* ---------- SHORTLIST ---------- */}
				<Grid item xs={12} py={2}>
					<Paper sx={{ minHeight: "165px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						{!loading ? (
							isMobile ? (
								<ShortListMobile
									// unorderedShortList={userList.filter(
									// 	(candidate) => candidate.status === "shortlisted"
									// )}
									unorderedShortList={userList}
									changeUserStatus={changeUserStatus}
									handleCardClick={handleCardClick}
									SL_order={jobData?.CP_SL_order || []}
									updateShortList={updateShortList}
									isMobile={isMobile}
								/>
							) : (
								<ShortList
									// unorderedShortList={userList.filter(
									// 	(candidate) => candidate.status === "shortlisted"
									// )}
									unorderedShortList={userList}
									changeUserStatus={changeUserStatus}
									handleCardClick={handleCardClick}
									SL_order={jobData?.CP_SL_order || []}
									updateShortList={updateShortList}
									isMobile={isMobile}
								/>
							)
						) : (
							<CircularProgress />
						)}

					</Paper>
				</Grid>


				{/* ---------- CANDIDATE LIST ---------- */}
				<Grid item xs={12} md={2.5} pr={2}>
					<Paper>
						{isMobile ? (
							<CandidateListMobile
								userList={userList}
								handleCardClick={handleCardClick}
								changeUserStatus={changeUserStatus}
							/>
						) : (
							<CandidateListWeb
								userList={userList}
								handleCardClick={handleCardClick}
								changeUserStatus={changeUserStatus}
							/>
						)}

					</Paper>
				</Grid>

				{/* ---------- SPOTLIGHT A ---------- */}
				<Grid item xs={12} md={4.75} pt={isMobile && 2}>
					{loading && (
						<Paper>
							<Box sx={loadingStyle}>
								<CircularProgress />
							</Box>
						</Paper>
					)}

					{!loading &&
						userList.filter(
							(candidate) => candidate.status !== "rejected"
						).length > 0 &&
						(candidateData[indexA] ? (
							<Paper>
								<Spotlight
									candidateData={candidateData[indexA]}
									selectedTab={selectedTab}
									handleTabChange={handleTabChange}
									changeUserStatus={changeUserStatus}
									userList={userList}
									user={userList.find((user) => user.user === indexA)}
									setCandidateData={setCandidateData}
									isMobile={isMobile}
									numCandidates={Object.keys(candidateData).length}
								/>
							</Paper>
						) : (
							<Paper>
								<Box sx={loadingStyle}>
									<CircularProgress />
								</Box>
							</Paper>
						))}
				</Grid>

				{/* ---------- SPOTLIGHT B ---------- */}
				{!isMobile && (
					<Grid item xs={4.75} pl={2}>
						{loading && (
							<Paper>
								<Box sx={loadingStyle}>
									<CircularProgress />
								</Box>
							</Paper>
						)}
						{!loading &&
							userList.filter(
								(candidate) => candidate.status !== "rejected"
							).length > 1 &&
							(candidateData[indexB] ? (
								<Paper>
									<Spotlight
										candidateData={candidateData[indexB]}
										selectedTab={selectedTab}
										handleTabChange={handleTabChange}
										changeUserStatus={changeUserStatus}
										userList={userList}
										setCandidateData={setCandidateData}
										user={userList.find((user) => user.user === indexB)}
										numCandidates={Object.keys(candidateData).length}
									/>
								</Paper>
							) : (
								<Paper>
									<Box sx={loadingStyle}>
										<CircularProgress />
									</Box>
								</Paper>
							))}
					</Grid>
				)}
			</Grid>
		</interviewData.Provider>
	);
}
