import { Typography } from "@mui/material";

export default function ProfessionalHistory({ professionalHistory }) {
    return (
        <>
            <Typography variant="body2" fontWeight="bold">
                {professionalHistory.title}
            </Typography>

            <Typography variant="body2" sx={{ marginBottom: 1 }}>
                {professionalHistory.info}
            </Typography>
        </>
    )
}