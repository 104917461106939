import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

let defaultTheme: Theme = responsiveFontSizes(
    createTheme({
        palette: {
            header_footer_background: {
                main: '#1976d2'
            },
            header_footer_font_color: {
                main: '#ffffff'
            },
            header_footer_hover_button_color: {
                main: 'gray'
            },
            company_logo_url: '',
        },
    })
);
export default defaultTheme;