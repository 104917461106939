import { Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import columns from "../columns";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";

export default function ClientAllOpportunitiesDG({
  jobs,
  interestedList,
  handleExpressInterest,
  setInterestedList,
  setShowOppoModal,
  setCurJobData,
  loading,
  isMobile
}) {
  function handleOpportunityRowClick(params) {
    setCurJobData(params.row);
    setShowOppoModal(true);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 0.45,
    },
    {
      field: "interested",
      headerName: "Interested",
      flex: 0.35,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const isInterested = interestedList.includes(
          `${params.row.title}-${params.row.date_created.substring(0, 10)}`
        );
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleExpressInterest(params.row.title, params.row.date_created, isInterested);
            }}
            sx={{ color: isInterested ? "#ff9900" : "primary", "&:hover": { color: "#ff9900" } }}
            aria-label={isInterested ? "Delete Interest" : "Express Interest"}
          >
            <StarIcon />
          </IconButton>
        );
      },
    },
  ];

  // Conditionally add the date_created column if not mobile
  if (!isMobile) {
    columns.splice(2, 0, {
      field: "date_created",
      headerName: "Date Posted",
      flex: 0.25,
      renderCell: (params) => formatDate(params.value),
    });
  }

  return (
    <>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Typography>All Job Postings</Typography>
        </Grid>
      </Grid>

      <DataGrid
        loading={loading}
        columns={columns}
        rows={jobs}
        onRowClick={handleOpportunityRowClick}
        disableColumnMenu={true}
        rowHeight={50}
        sx={{
          "& .MuiDataGrid-withBorderColor": {
            border: "0px solid #c60c30",
          },
          "& .MuiDataGrid-row": {
            borderTop: "1px solid #6c6e70",
          },
          "& .MuiDataGrid-row:first-of-type": {
            borderTop: "1px solid inherit",
          },
          "& .MuiDataGrid-row:last-child": {
            borderBottom: "1px solid #6c6e70",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "secondary.rowHover",
            cursor: "pointer",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden"
          }
        }}
        initialState={{
          ...jobs.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        getRowId={(row) => row.title + row.date_created}
      />
    </>
  );
}
