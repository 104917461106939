import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import FooterMobile from './mobile';
import FooterWeb from './web';
import { FooterComponentProps } from './types';
import { useLocation } from 'react-router-dom';

function FooterComponent({ footerParams }: FooterComponentProps) {
  const location = useLocation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  if (location.pathname.includes('/profile/print/')) return null
  if (location.pathname.includes('/pdf')) return null

  return (
    <div>
      {
        isMobile ?
          <FooterMobile footerParams={footerParams} /> :
          <FooterWeb footerParams={footerParams} />
      }
    </div>
  )
}

export default FooterComponent;