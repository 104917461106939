import { Grid, Typography, Divider } from "@mui/material";

export default function EnrollmentsTab({ data }) {
	const renderRow = (label, value) => (
		<Grid item xs={12} paddingBottom={2}>
		  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<Typography variant="h5">{label}</Typography>
			<Typography variant="h5" style={{ textAlign: "right" }}>
			  {value}
			</Typography>
		  </div>
		  <Divider style={{ margin: "5px 0" }} />
		</Grid>
	  );
	
	  return (
		<Grid container>
		  {renderRow("New opportunities", data.new_opportunities)}
		  {renderRow("New enrollments", data.new_enrollments)}
		  {/* {renderRow("Training programs started", data.training_programs_started)} */}
		  {renderRow("Training programs completed", data.training_programs_completed)}
		  {renderRow("Expressed interest count", data.expressed_interest_count)}
		  {renderRow("Jobs posted", data.jobs_posted)}
		  {renderRow("Jobs Started", data.jobs_started)}
		</Grid>
	  );
}
