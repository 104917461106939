export const profileData = {
    awards: {
        visible: true,
        entries: []
    },
    certifications: {
        visible: true,
        entries: [
            {
                title: {
                    text: 'Project Management Professional',
                    visible: true,
                },
                details: {
                    text: 'Project Management Institute',
                    visible: true,
                },
                visible: true
            },
            {
                title: {
                    text: 'Test title 2',
                    visible: true,
                },
                details: {
                    text: 'Project Management Institute',
                    visible: true,
                },
                visible: true
            }
        ]
    },


    educations: {
        visible: true,
        entries: [
            {
                title: {
                    text: 'Education title',
                    visible: true,
                },
                details: {
                    text: 'Nutrien Allan Mine Site, SK, Canada 2021',
                    visible: true
                },
                description: {
                    text: 'Test description',
                    visible: true
                },
                visible: true,
            },
            {
                title: {
                    text: 'Education Management Professional',
                    visible: true,
                },
                details: {
                    text: 'Nutrien Allan Mine Site, SK, Canada 2021',
                    visible: true
                },
                description: {
                    text: 'Test description',
                    visible: true
                },
                visible: true,
            },
            {
                title: {
                    text: 'Test title 3',
                    visible: true,
                },
                details: {
                    text: 'Nutrien Allan Mine Site, SK, Canada 2021',
                    visible: true
                },
                description: {
                    text: 'Test description',
                    visible: true
                },
                visible: true,
            },
        ],
    },
    overview: `Ryan Dingwall is a passionate and determined mentor,
skilled in creating logical structures, improving systems,
and fostering growth, all while meticulously planning every facet of his life.

Throughout his 18-year Project Management career, Ryan has successfully managed multi-disciplines,
local and international projects such as mine hoists/winders, shafts, headframes, underground and surface infrastructure
construction, industrial gas tanks, and pipelines through various project lifecycles. He is a certified PMP and Mechanical Professional Engineer
and has been fortunate enough to wear various hats as a supplier, EPCM Consultant, and Client Representative with a variety of Canadian and
South African companies. He is now the Lead Operations & Project Manager with Peter Lucas.

Ryan is a shutdown/turnaround specialist who encourages camaraderie amongst co-workers and leads by example. Originally from Johannesburg, South Africa, Ryan has been living in Saskatoon, Canada, for the past eight years with his wife, two teenage daughters, and three cats.`,
    professionalHistory: [
        {
            title: 'Lead Operations & Project Manager',
            info: 'Peter Lucas | 2023 - Present'
        },
        {
            title: 'Project Manager, Mining, Minerals, & Metals',
            info: 'Stantec | 2023'
        },
        {
            title: 'Senior Project Manager, Mine Maintenance Engineering Department',
            info: 'Nutrien Allan Mine | 2021 - 2023'
        },
        {
            title: 'Project Manager, Mine Maintenance Engineering Department',
            info: 'Nutrien Allan Mine | 2019 - 2021'
        },
    ]
};
