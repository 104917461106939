import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { BrowserUtils } from "../../../../../../utilities/BrowserUtils";
import dateOptions from "../dateOptions";
// import profile_image_placeholder from 'ui/public/images/profile_image_placeholder.png'

export default function HistoryItem({ data, user }) {
	function toReadableFormat(str) {
		return str
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}

	let message = "";
	if (data.previous_bucket !== "") {
		message = ` moved to ${toReadableFormat(
			data.new_bucket
		)} from ${toReadableFormat(data.previous_bucket)}`;
	} else {
		message = ` applied`;
	}

	const timestamp = new Date(data.timestamp);
	const formattedTimestamp = timestamp.toLocaleString("en-US", dateOptions);

	return (
		<Grid container px={2} pt={2} alignItems="center">
			<Grid item>
				<Avatar
					sx={{
						width: 40,
						height: 40,
						marginRight: "15px",
					}}
					src={
						user?.image_url
							? BrowserUtils.awsURL(user.image_url)
							: "ui/public/images/profile_image_placeholder.png"
					}
				/>
			</Grid>
			<Grid item>
			<Typography>
				
					{user && `${user.first_name} ${user.last_name}` }
				
				{message}


				</Typography>
				<Typography variant="body2" color="secondary.main">
					{formattedTimestamp}
				</Typography>
			</Grid>
			{data.comment && (
				<Grid item xs={12} pt={1}>
					<Typography>{data.comment}</Typography>
				</Grid>
			)}
			<Grid item xs={12} mt={2}>
				<Divider />
			</Grid>
		</Grid>
	);
}
