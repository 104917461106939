import { Stack, Typography } from "@mui/material";

export default function TermsOfServiceModalContent() {
	const titleVariant = "h5";
	const bodyVariant = "body1";

	return (
		<Stack spacing={2}>
			<Typography variant={titleVariant}>
				Client Platform Access Agreement
			</Typography>
			<Typography variant={bodyVariant}>
				These terms and conditions (the “Agreement”) create a contract
				between you and Peter Lucas Project Management Inc. and its
				affiliated corporations, as defined in The Business Corporations
				Act (Saskatchewan) (Peter Lucas Project Management Inc. and its
				affiliated corporations are collectively referred to as “PLB” or
				“Peter Lucas Bench”). Please read this Agreement carefully. To
				confirm your understanding and acceptance of this Agreement,
				click “Agree“.
			</Typography>
			<Typography variant={bodyVariant}>
				1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Introduction
				to Peter Lucas Bench's Service
			</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Bench provides access to its web-based application
				as a service (the “Service”) to clients (“you” or the “Clients”)
				to find Peter Lucas staff, candidates, and independent
				contractors who provide project management or other professional
				services (each a “Talent” and the services they provide, the
				“Talent Services”). The “Platform” includes (a) Peter Lucas
				Bench's web site located at https://www.peterlucasbench.com (the
				“Website”), (b) Peter Lucas Bench's technology platform designed
				to find and connect Talent to those in need of project
				management and other professional services offered by or through
				Peter Lucas Bench (the “Platform”), and (c) all software, data,
				reports, text, images, sounds, video, and content made available
				through any of the foregoing (collectively, the “Content”). Any
				new features added to or amending the Platform are also subject
				to this Agreement. The “Matching Services” involve using any and
				all features, functions, and Content of the Platform that enable
				Clients to identify, review, contact, and otherwise take steps
				to engage Talent, as well as Peter Lucas Bench's work in
				assisting the Client in identifying, reviewing, contacting, and
				taking steps to engage Talent. The Matching Services do not
				include any work performed by Talent. Nothing in this Agreement
				obligates: (1) the Client to engage any particular Talent for
				any work; (2) Peter Lucas Bench to provide to you or any Client
				with Matching Services; or (3) Peter Lucas Bench to identify
				Talent for any Client's particular needs. For ease of reference,
				the Site, Platform, identities or descriptions of Talent and
				Talent services, the Matching Services, other content, Peter
				Lucas Bench's proprietary information, and any other Peter Lucas
				Bench products or services are collectively referred to in this
				Agreement as “PLB Properties.”
			</Typography>
			<Typography variant={bodyVariant}>
				2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acceptance of
				Terms
			</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Bench provides the PLB Properties to you through the
				Site and the Platform, subject to this Agreement. By accepting
				this Agreement or by accessing or using any portion of the PLB
				Properties, you acknowledge that you have read, understood, and
				agree to be bound by this Agreement. You further acknowledge
				that this Agreement is a contract between you and Peter Lucas
				Bench, even though it is electronic and is not physically signed
				by you and Peter Lucas Bench, and it governs your use of the PLB
				Properties. If you are entering into this Agreement on behalf of
				a company, business, or other legal entity (“Client Entity”),
				you represent that you have the authority to contractually bind
				such Client Entity to this Agreement, in which case the terms
				“you” or “your” or “Client” will refer to such Client Entity.
			</Typography>
			<Typography variant={bodyVariant}>
				3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User Account
			</Typography>
			<Typography variant={bodyVariant}>
				In order to use the Service you must register for a personal
				account (the “User Account”) by providing a username and
				password (the “Registration Information”), which may be done
				through the Website. Your Registration Information is valuable,
				and you are responsible for maintaining its confidentiality and
				security. Peter Lucas Bench is not responsible for any losses
				arising from the unauthorized use of your Registration
				Information. Please contact Peter Lucas Bench if you suspect
				that your Registration Information has been compromised.
			</Typography>
			<Typography variant={bodyVariant}>
				You also agree that you will not license, transfer, sell or
				assign your Registration Information and/or this Agreement to
				any third party without Peter Lucas Bench's written approval.
			</Typography>
			<Typography variant={bodyVariant}>
				4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Authorization
				and Access
			</Typography>
			<Typography variant={bodyVariant}>
				(a) Subject to your compliance with this Agreement and the
				provisions hereof, you may access or use the PLB Properties
				solely for the purpose of your evaluation of PLB, Talent and
				PLB's products and services as an existing or prospective Client
				of PLB. (b) You will not: (i) allow any competitor of Peter
				Lucas Bench to use or access the PLB Properties, (ii) use or
				access the PLB Properties to develop or enhance any product or
				service, or (iii) copy any ideas, features, functions, or
				graphics of the PLB Properties. You are not permitted to access
				or use the PLB Properties for public comment unless authorized
				in writing by Peter Lucas Bench. You are also not permitted to
				copy, modify, frame, repost, publicly perform or display, sell,
				reproduce, distribute, or create derivative works of the PLB
				Properties. You may only link to the Website or Platform, or any
				portion thereof, as expressly permitted by Peter Lucas Bench.
				You agree not to access the Website, Platform, or Matching
				Service by any means other than through the interface that is
				provided by Peter Lucas Bench to access the same, except that
				you may download, display and print portions of the Content
				other than any software, but only to the minimum extent
				necessary and consistent with the purpose of your access and use
				of the PLB Properties under this Agreement, and provided further
				that you do not modify such Content in any way and you keep
				intact all copyright, trademark, and other proprietary notices.
			</Typography>
			<Typography variant={bodyVariant}>
				5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ownerships and
				Restrictions
			</Typography>
			<Typography variant={bodyVariant}>
				All rights, title, and interest in and to the PLB Properties
				will remain with and belong exclusively to Peter Lucas Bench.
				You will not (a) sublicense, resell, rent, lease, transfer,
				assign, time share or otherwise commercially exploit or make the
				PLB Properties available to any third party, (b) use the PLB
				Properties in any unlawful manner (including without limitation
				in violation of any data, privacy or export control laws) or in
				any manner that interferes with or disrupts the integrity or
				performance of the PLB Properties or their related components,
				or (c) modify, adapt or hack the PLB Properties to, or try to,
				gain unauthorized access to the PLB Properties or related
				systems or networks (i.e., circumvent any encryption or other
				security measures, gain access to any source code or any other
				underlying form of technology or information, and gain access to
				any part of the PLB Properties, or any other products or
				services of Peter Lucas Bench that are not readily made
				available to the general public or to you using your own account
				name and password as instructed by Peter Lucas Bench).
			</Typography>
			<Typography variant={bodyVariant}>
				6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Limiting
				Access to Your Account
			</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Bench may access your User Account in order to
				respond to your requests for technical support or to verify
				compliance with your obligations to Peter Lucas Bench, comply
				with law, or to maintain and improve its own systems. Peter
				Lucas Bench may, at its option, provide email or other online
				account support.
			</Typography>
			<Typography variant={bodyVariant}>
				7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reservation of
				Rights
			</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Bench and its licensors each own and retain their
				respective rights in and to all logos, company names, marks,
				trademarks, copyrights, trade secrets, know-how, patents and
				patent applications that are used or embodied in or otherwise
				related to the PLB Properties. PLB grants no other rights or
				licenses (implied, by estoppel, or otherwise) whatsoever to you
				under this Agreement.
			</Typography>
			<Typography variant={bodyVariant}>
				8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy
			</Typography>
			<Typography variant={bodyVariant}>
				Personal details provided by you when you set up your User
				Account are subject to Peter Lucas Bench's privacy policy, as
				amended from time to time (the “Privacy Policy”). The Privacy
				Policy is incorporated into and forms part of this Agreement. By
				clicking “Agree” you are consenting to Peter Lucas Bench's use
				of such personal information in accordance with the Privacy
				Policy.
			</Typography>
			<Typography variant={bodyVariant}>
				9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Confidentiality
			</Typography>
			<Typography variant={bodyVariant}>
				All business, technical or financial information disclosed by
				Peter Lucas Bench via the PLB Properties, including without
				limitation, the Website, Platform, or Matching Services, is the
				“Proprietary Information” of Peter Lucas Bench. Proprietary
				Information also includes, but is not limited to, the rates of
				each Talent on the Platform. You will hold in confidence and not
				disclose to others any Proprietary Information. You will also
				not use Proprietary Information for any purposes other than
				evaluation of Peter Lucas Bench, Talent and Talent Services as
				an existing or prospective client of Peter Lucas Bench. However,
				you will not be obligated under this Section with respect to
				information that you can document is or becomes readily publicly
				available without restriction and through no fault of you (i.e.,
				information that Peter Lucas Bench makes generally available to
				the public on the Website without requiring acceptance of this
				Agreement or a similar obligation of confidentiality). When you
				have completed your use of the Platform or Matching Services, or
				if you have not used the Platform or matching service in 12
				months, you will destroy all items and copies containing or
				embodying Proprietary Information.
			</Typography>
			<Typography variant={bodyVariant}>
				10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Non-Solicit
			</Typography>
			<Typography variant={bodyVariant}>
				During all periods of your access to or use of the Platform or
				Matching Services and for twelve (12) months after each such
				access or use (collectively, all such periods are referred to as
				the “Non-Solicit Period”), you will not, directly or indirectly,
				encourage or solicit to hire, or otherwise hire or engage for
				performance of services any Talent whom you become aware of in
				connection with your interaction with Peter Lucas Bench. You
				also agree that you will not refer such Talent directly to
				parent, sibling, or other affiliated companies.
			</Typography>
			<Typography variant={bodyVariant}>
				11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rights in
				User Content
			</Typography>
			<Typography variant={bodyVariant}>
				By posting your information and other content (“User Content”)
				on or through the PLB Properties, you grant PLB a worldwide,
				non-exclusive, perpetual, irrevocable, royalty-free, fully paid,
				sublicensable and transferable license to use, modify,
				reproduce, distribute, display, publish and perform User Content
				in connection with the PLB Properties. PLB has the right, but
				not the obligation, to monitor the PLB Properties and User
				Content. PLB may remove or disable any User Content at any time
				for any reason, or for no reason at all.
			</Typography>
			<Typography variant={bodyVariant}>
				12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unsecured
				Transmission of User Content
			</Typography>
			<Typography variant={bodyVariant}>
				You understand that the operation of the Website and Platform,
				including User Content, may be unencrypted and involve (a)
				transmissions over various networks; (b) changes to conform and
				adapt to technical requirements of connecting networks or
				devices and (c) transmission to PLB's third party vendors and
				hosting partners to provide the necessary hardware, software,
				networking, storage, and related technology required to operate
				and maintain the PLB Properties. Accordingly, you acknowledge
				that you bear sole responsibility for adequate security,
				protection, and backup of User Content. PLB will have no
				liability to you for any unauthorized access or use of any User
				Content, or any corruption, deletion, destruction, or loss of
				any of User Content.
			</Typography>
			<Typography variant={bodyVariant}>
				13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Updates,
				Modification, Suspension and Discontinuation of Service
			</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Bench reserves the right in its sole discretion to
				update, modify suspend or discontinue the Service and this
				Agreement or any part thereof at any time with or without notice
				to you, and Peter Lucas Bench will not be liable to you or to
				any third party should it exercise such rights. Your continued
				use of any of the PLB Properties after the date any such changes
				become effective constitutes your acceptance of the new or
				revised Agreement.
			</Typography>
			<Typography variant={bodyVariant}>
				14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination
			</Typography>
			<Typography variant={bodyVariant}>
				If you fail, or Peter Lucas Bench has reasonable grounds to
				suspect that you have failed, to comply with any of the
				provisions of this Agreement, Peter Lucas Bench may, without
				notice to you, terminate this Agreement and terminate your
				access to the Service.
			</Typography>
			<Typography variant={bodyVariant}>
				15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclaimers
				and Limitations of Liability
			</Typography>
			<Typography variant={bodyVariant}>
				(a) Warranty Disclaimer <br />
				THE SERVICE IS PROVIDED ‘AS-IS' WITHOUT REPRESENTATIONS,
				WARRANTIES, CONDITIONS AND INDEMNITIES OF ANY KIND, EITHER
				EXPRESS OR IMPLIED. PETER LUCAS BENCH DISCLAIMS ALL
				REPRESENTATIONS, WARRANTIES AND CONDITIONS, EXPRESSED OR
				IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION,
				IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS
				FOR A PARTICULAR PURPOSE, UNINTERRUPTED OR ERROR-FREE OPERATION,
				TITLE, NON-INFRINGEMENT OR OTHER VIOLATION OF RIGHTS, OR THOSE
				ARISING OUT OF A COURSE OF DEALING OR USAGE OF TRADE. PETER
				LUCAS BENCH DOES NOT PROVIDE ANY REPRESENTATIONS, WARRANTIES,
				CONDITIONS OR INDEMNITIES REGARDING THE USE, VALIDITY, ACCURACY,
				COMPLETENESS, TIMELINESS, AVAILABILITY OR RELIABILITY OF, OR ANY
				RESULTS OF THE USE OF, OR OTHERWISE RESPECTING, THE SERVICE OR
				ANY INFORMATION OR OTHER MATERIALS MADE AVAILABLE THROUGH THE
				SERVICE. PETER LUCAS BENCH DOES NOT ASSUME ANY OBLIGATION TO
				UPDATE THE SOFTWARE AND/OR SERVICE OR ADVISE OF ANY FURTHER
				DEVELOPMENTS WITH RESPECT TO THE SOFTWARE AND/OR SERVICE.
			</Typography>
			<Typography variant={bodyVariant}>
				(b) Limitation of Liability <br /> UNDER NO CIRCUMSTANCES SHALL
				PETER LUCAS BENCH BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
				INCIDENTAL, PUNITIVE, EXEMPLARY, AGGRAVATED, ECONOMIC OR
				CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF
				USE, DATA, PROFIT, OPPORTUNITY OR SAVINGS, ARISING OUT OF OR IN
				ANY WAY RELATED TO THE USE, OR THE INABILITY TO USE OR ACCESS
				THE SERVICE OR ANY INFORMATION OR OTHER MATERIALS AVAILABLE
				THROUGH THE SERVICE WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE
				OR OTHERWISE, EVEN IF PETER LUCAS BENCH OR AN AUTHORIZED
				REPRESENTATIVE OF PETER LUCAS BENCH HAS BEEN ADVISED OF THE
				POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THE SERVICE OR ANY
				INFORMATION OR OTHER MATERIALS AVAILABLE THROUGH THE SERVICE
				RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF
				EQUIPMENT OR DATA, YOU ASSUME ANY COSTS THEREOF.
			</Typography>
			<Typography variant={bodyVariant}>
				(c) Third Party Use Disclaimer <br />
				YOU EXPRESSLY ACKNOWLEDGE THAT THE SOFTWARE RUNS ON AND THE
				UPLOADED DATA IS PROCESSED ON NETWORKS OR PLATFORMS OWNED AND
				OPERATED BY THIRD PARTIES. IN NO CIRCUMSTANCES SHALL PETER LUCAS
				BENCH BE RESPONSIBLE OR LIABLE FOR ANY DATA BREACH NOT CAUSED
				DIRECTLY BY PETER LUCAS BENCH.
			</Typography>
			<Typography variant={bodyVariant}>
				16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anti-Spam
				Legislation Consent
			</Typography>
			<Typography variant={bodyVariant}>
				In order to receive electronic communications from Peter Lucas
				Bench relating to the Service and other projects, you will be
				required to provide us with consent to receive electronic
				communications. By clicking “Agree” and providing us with your
				personal information, you are expressly consenting to receive
				such communications.
			</Typography>
			<Typography variant={bodyVariant}>
				17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assignment
			</Typography>
			<Typography variant={bodyVariant}>
				This Agreement, and your rights and obligations under this
				Agreement, may not be transferred or assigned by you without the
				prior written consent of Peter Lucas Bench, which consent may be
				arbitrarily withheld by Peter Lucas Bench in its sole and
				absolute discretion. Peter Lucas Bench may at any time assign
				this Agreement, including any of its rights and obligations
				hereunder, without your prior consent.
			</Typography>
			<Typography variant={bodyVariant}>
				18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Governing Law
				and International Use
			</Typography>
			<Typography variant={bodyVariant}>
				The laws of the Province of Saskatchewan and the laws of Canada
				applicable therein shall govern as to the interpretation,
				validity, and effect of this Agreement notwithstanding any
				conflict of laws provisions or your domicile, residence, or
				physical location. You hereby consent and submit to the
				non-exclusive jurisdiction of the courts of the Province of
				Saskatchewan in any action or proceeding instituted under or
				related to this Agreement.
			</Typography>
			<Typography variant={bodyVariant}>
				As the Platform may be accessed and used globally, if you choose
				to access the Platform from locations other than the United
				States or Canada, you do so on your own initiative and you are
				responsible for compliance with all applicable local use
				controls, laws and regulations, including those relating to the
				transmission of any technical data exported from or imported to
				Canada or the country in which you reside.
			</Typography>
			<Typography variant={bodyVariant}>
				19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entire
				Agreement
			</Typography>
			<Typography variant={bodyVariant}>
				These provisions constitute the entire agreement between you and
				Peter Lucas Bench relating to the Service, and supersede all
				previous communications, representations, and agreements,
				whether oral, written or electronic, between you and Peter Lucas
				Bench, with respect to the subject matter hereof; there being no
				representation, warranties, terms, conditions, undertakings, or
				collateral agreements (express, implied, or statutory), between
				the parties other than as expressly set forth in this agreement.
			</Typography>
			<Typography variant={bodyVariant}>
				Notwithstanding the above, if you and Peter Lucas Bench have
				entered into a contract either before or after entering into
				this Agreement and this contract is for similar or related
				products and/or services as the Service, that contract shall be
				incorporated by reference to this Agreement.
			</Typography>
			<Typography variant={bodyVariant}>
				20.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Severability
			</Typography>
			<Typography variant={bodyVariant}>
				If any provision this Agreement is invalid, illegal, or
				incapable of being enforced by reason of any rule of law or
				public policy, then such provision shall be severed from this
				Agreement and shall not affect any other provision of this
				Agreement, and this Agreement shall be construed as if such
				invalid, illegal, or unenforceable provision had never been
				contained in this Agreement. All other provisions of this
				Agreement shall, nevertheless, remain in full force and effect
				and no provision shall be deemed dependent upon any other
				provision unless so expressed herein.
			</Typography>
			<Typography variant={bodyVariant}>
				21.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enurement
			</Typography>
			<Typography variant={bodyVariant}>
				The terms and provisions of this Agreement enure to the benefit
				of, and are binding upon, you and your heirs, executors,
				administrators, personal representatives, successors and
				permitted assigns, and upon Peter Lucas Bench and its successors
				and assigns.
			</Typography>
			<Typography variant={titleVariant}>Privacy Policy</Typography>
			<Typography variant={bodyVariant}>
				Peter Lucas Project Management Inc. and its affiliated
				corporations, as defined in The Business Corporations Act
				(Saskatchewan) (Peter Lucas Project Management Inc., Peter Lucas
				Bench and its affiliated corporations are collectively referred
				to as “PLB”) are committed to respecting the privacy of our
				clients and talent. We strive to provide a safe, secure user
				experience. This privacy policy (the “Privacy Policy”) is
				incorporated into and forms part of the terms and conditions
				governing the access and use of the services provided by PLB
				through its web-based application for independent contracts who
				provide project management or other professional services for
				clients (the “Service”). This Privacy Policy sets forth the
				policies and practices that apply to the collection and usage of
				data through the provision of the Service. Your access and use
				of the Service constitutes your express and unconditional
				acceptance of all the terms and conditions of this Privacy
				Policy.
				<br /> <br />
				In particular, by submitting any personal information to PLB you
				will be deemed to have given your permission for the use and
				disclosure of such personal information as described herein.
				Personal information includes your name, address, email address
				or phone number, photograph, location, educational background,
				work history, examples of past work and billing information,
				among other items. This Privacy Policy covers only the
				collection and use of personal information of individuals by PLB
				through the provision of the Service. This Privacy Policy does
				not apply to the practices of companies or individuals that PLB
				does not own or control, including, without limitation, network
				or cloud service providers.
				<br /> <br />
				This Privacy Policy provides a description of the privacy and
				data use practices of PLB (“us”, “we” or “our”) in connection
				with our receipt, collection and use of data and information
				from you as a user of the Service.
				<br /> <br />
				The Privacy Policy may be changed or updated from time to time.
				If the Privacy Policy is changed or updated such changes or
				updates will be posted to our website so that you will always
				know what information we gather, how we might use that
				information, and to whom we will disclose it.
				<br /> <br />
				Collected Personal Data
				<br />
				<br />
				We generally collect the following types of information
				(collectively, the “Personal Data”) through the provision of the
				Service:
				<br />
				<br />
				• Personal information – We may collect certain personal
				information including, but not limited to, your name, address,
				email address, telephone numbers, contact information,
				photograph, location, educational background, work history,
				examples of past work and billing information and other similar
				information from which your identity is discernible; and
				<br />
				• Access and login information – We may record certain
				information regarding your access to the Service. These logs may
				include information such as your Personal Data, your interaction
				with the Service, internet protocol address and the date and
				time of your access to the Service.
				<br />
				<br />
				This Privacy Policy applies solely to Personal Data we collect
				through the provision of the Service, and does not apply to
				information we collect in any other fashion.
				<br />
				<br />
				Protection of Personal Data
				<br />
				<br />
				We strive to protect your Personal Data from unauthorized
				access, use, or disclosure using reasonable technical and
				organizational measures designed to secure your personal
				information from accidental loss and from unauthorized access,
				use, alternation or disclosure. However, the use of such
				measures is not, and should not be considered to be, any type of
				guarantee or warranty by us that your Personal Data will not be
				accessed by third parties or that third parties will never be
				able to defeat those measures or use your personal information
				for improper purposes. Further, we contract with a third party
				for data processing and data storage purposes and are not
				responsible for their actions or inaction.
				<br />
				<br />
				Collection, Use and Sharing of Personal Data
				<br />
				<br />
				We collect and use your Personal Data to:
				<br />
				<br />• Set up your user account to access the Service;
				<br /> • Match talent with interested clients;
				<br /> • Analyze and formulate industry and market data;
				<br /> • and Provide the Service to you and to clients. <br />
				<br />
				Your Personal Data may be shared, stored, or accessed in Canada
				or other jurisdictions or countries where the data protection
				laws may differ than those from your jurisdiction. Your personal
				information may be disclosed in response to valid demands or
				requests from governments, regulators, courts and law
				enforcement authorities in those jurisdictions or countries.
				<br />
				<br />
				If you are located outside Canada and choose to provide
				information to us, please note that we may transfer the
				information, including Personal Data, to Canada for processing
				and use.
				<br />
				<br />
				Withdrawing Consent
				<br />
				<br />
				You may withdraw your consent at any time upon reasonable
				notice, subject to legal, regulatory or contractual
				requirements. Please note that withdrawing your consent may
				affect our ability to continue to provide you with the Service
				or certain functions within the Service that you have or would
				like to receive, and which may be of value to you. To withdraw
				or change your consent, you may contact us or following the
				unsubscribe instructions in any message/communication at the
				address or telephone number listed at the end of this Policy.
				<br />
				<br />
				Updating and Removal of Personal Data
				<br />
				<br />
				You have the right to access, update, and correct inaccuracies
				in your Personal Data in our custody and control, subject to
				certain exceptions prescribed by law. You may review, correct,
				update or change your account profile information at any time by
				notifying PLB.
				<br />
				<br />
				You may also: (i) remove your account and information from our
				system; or (ii) elect to no longer receive the Service from us.
				If you wish to remove your account and/or personal information
				from our system, or elect to no longer receive the Service from
				us, please contact us using the contact information provided
				below.
				<br />
				<br />
				Retention of Personal Data
				<br />
				<br />
				We will retain your Personal Data for the time it is required to
				meet the purposes set out above and for a reasonable length of
				time thereafter or to otherwise meet legal or regulatory
				requirements. Once your Personal Data is no longer required for
				such purposes, it will be destroyed, deleted, or made anonymous.
				<br />
				<br />
				Cookies and Online Tracking Technology
				<br />
				<br />
				We may use cookies, pixel tags, web beacons and other tracking
				technologies or log data on our websites, applications, email
				messages and advertisements to gather information about your
				visit (such as demographic data, browser type, device ID, IP
				address, pages visited, activities conducted on the page, and
				the day and time of your visit). When using a mobile device, we
				may collect information such as the type of mobile device you
				use, your mobile device unique ID, the IP address of your mobile
				device, your mobile operating system, the type of mobile
				Internet browser you use and other statistics.
				<br />
				<br />
				Using cookies and other tracking technologies provides benefits
				to you, such as allowing you to maintain your account login
				information or contact information and other key elements
				relating to the Service between visits. In addition, we use
				information gathered from cookies to autofill fields on forms
				relating to the Service. This information can be corrected by
				you before submitting any form or inquiry or you can disable the
				cookie as discussed below and the information will not be
				collected.
				<br />
				<br />
				To measure site activity, provide a better user experience, and
				tailor our marketing communications, we or our service providers
				or business partners may compile information from the cookies,
				pixel tags, web beacons or other tracking technologies on our
				websites and use third party services that collect, monitor and
				analyze this type of information in order to increase our
				Service's functionality. This information may be combined with
				other information collected online or offline about you,
				including data provided by third party sources, and may be used
				or shared for the purposes described in this Privacy Policy.
				<br />
				<br />
				To the extent that Internet Protocol (IP) addresses or similar
				identifiers are considered Personal Data by local law, we treat
				these identifiers and associated information as Personal Data.
				Similarly, to the extent that non-personal information is
				combined with Personal Data, we treat the combined information
				as Personal Data for the purposes of this Privacy Policy.
				<br />
				<br />
				You can instruct your browser to refuse all cookies or to
				indicate when a cookie is being sent. However, if you do not
				accept cookies, you may not be able to use some portions of our
				Service. Please refer to your browser Help instructions to learn
				more about cookies and other technologies and how to manage
				their use. If you elect to refuse or delete cookies, you will
				need to repeat this process if you use another computer, or
				change browsers. If you choose to decline cookies, some of the
				functionality of our Service may be impaired.
				<br />
				<br />
				Links To Third Party Websites
				<br />
				<br />
				Our Service may contain links to other sites that are not
				operated by us. If you click on a third party link, you will be
				directed to that third party's site. We have no control over,
				and assume no responsibility for the content, privacy policies
				or practices of any third party sites or services and we
				strongly advise you to review the Privacy Policy of every site
				you visit.
				<br />
				<br />
				Changes To This Privacy Policy
				<br />
				<br />
				We may update this Privacy Policy from time to time. We will
				notify you of any changes by posting the new Privacy Policy and
				changes to this Privacy Policy are effective when they are
				posted.
				<br />
				<br />
				Contact Us
				<br />
				<br />
				If you have any questions about this Privacy Policy, PLB's
				privacy practices in connection with the Service, or PLB's
				collection, use, disclosure, or retention of your personal
				information in connection with the Service please contact us:
				<br />
				<br />
				Peter Lucas Project Management Inc.
				<br />
				904 – 201 21st St E,
				<br />
				Saskatoon, SK S7K 0B8
				<br />
				info@peterlucas.ca
				<br />
				+1.306.954.8550
			</Typography>
		</Stack>
	);
}
