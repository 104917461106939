import {
  Typography,
  Card,
  CardContent,
  Avatar,
  Chip,
  CardActionArea,
  FormControlLabel,
} from "@mui/material";
import { BrowserUtils } from "../../../../../utilities/BrowserUtils";

export default function CandidatePreviewCard({
  profileData,
  index,
  curSelected = false,
  handleUserCardClick,
  control,
  disqualified = false,
}) {

  const avatarSrc = profileData.image_url
    ? BrowserUtils.awsURL(profileData.image_url)
    : "";

  const experienceColors = {
    Junior: "#37b526",
    Intermediate: "orange",
    Senior: "#2284e7",
  };

  const typographyStyle = {
    flexGrow: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Card
      onClick={(event) => handleUserCardClick(index, event, disqualified)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: curSelected ? "secondary.cardHover" : null,
      }}
    >
      <CardActionArea>
        <CardContent style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel control={control} sx={{ marginRight: "5px" }} />
          <Avatar
            src={avatarSrc}
            sx={{ width: 35, height: 35, marginRight: "10px" }}
          />

          <div style={{ flex: 1 }}>
            <Typography variant="h6" sx={typographyStyle}>
              {`${profileData.first_name} ${profileData.last_name}`}
            </Typography>
            {profileData.latest_experience && (
              <Typography sx={{ ...typographyStyle, fontSize: "12px" }}>
                {profileData.latest_experience?.title}{" "}
                {profileData.latest_experience?.organization &&
                  `| ${profileData.latest_experience?.organization}`}
              </Typography>
            )}

            {profileData.city && profileData.country && (
              <Typography sx={{ ...typographyStyle, fontSize: "12px" }}>
                {`${profileData.city}, ${profileData.country}`}
              </Typography>
            )}
            <Typography sx={{ ...typographyStyle, fontSize: "12px" }}>
              Bench Status:{" "}
              {profileData.bench_status
                ? profileData.bench_status
                : "None found"}
            </Typography>
          </div>

          {profileData.experience_level && (
            <Chip
              label={profileData.experience_level}
              style={{
                backgroundColor:
                  experienceColors[profileData.experience_level] || "#37b526",
                color: "white",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
