import { Typography } from "@mui/material";


export default function Education({ education }) {
    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: 1.2,
                    fontWeight: 'bold',
                }}
            >
                {education.title}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: 1.2,
                    mb: 2
                }}
            >
                {education.institute}
            </Typography>
        </>
    )
}