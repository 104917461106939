const EmptyExperienceComponent = (uuid) => {
  let emptyExperience;

  emptyExperience = {
    id: 0,
    title: "",
    organization: "",
    start_date: "",
    end_date: "",
    startYear: "",
    endYear: "",
    startMonth: "",
    endMonth: "",
    startDay: "",
    endDay: "",
    user_data: uuid,
    overview: "",
    location: "",
    sectors: [],
    hidden: false,
  };

  return emptyExperience;
};
export default EmptyExperienceComponent;
