import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RESTUtils } from '../../../utilities/RESTUtils';
import { CircularProgress, Card, Divider, Grid, Typography, Button, Stack, Container } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import Assessment from './Assessment';

export default function ReferenceAssessment2() {
    const { uuid } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [assessmentValues, setAssessmentValues] = useState(new Array(7).fill(0));
    const [comments, setComments] = useState(new Array(7).fill(""));
    const [buttonLoading, setButtonLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    async function handleSubmit() {
        if (!validateAssessmentValues()) return;
        setButtonLoading(true);
        try {
            const response = await RESTUtils.POST(RESTUtils.getAPIUrl() + `api/public/submit_reference/`, {
                uuid: uuid,
                assessments: assessmentValues,
                comments: comments
            });
            if (response.status === 200) {
                setSubmitted(true);
            } else {
                throw new Error('Failed to submit data');
            }
        } catch (err) {
            setError(err);
        } finally {
            setButtonLoading(false);
        }
    }
    function validateAssessmentValues() {
        for (let i = 0; i < data.skills.length + 2; i++) {
            if (assessmentValues[i] === 0) {
                setValidationError(true);
                return false;
            }
        }
        return true;
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await RESTUtils.GET(RESTUtils.getAPIUrl() + `api/public/get_reference/?uuid=${uuid}`);
                if (response.status === 200) {
                    console.log(response.data)
                    setData(response.data);
                } else if (response.status === 204) {
                    setSubmitted(true);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <Card sx={{ padding: "20px", margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Card>
        );
    } else if (submitted) {
        return (
            <Card sx={{ padding: "20px", margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">Thank you for your feedback!</Typography>
            </Card>
        );
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Container component="main" sx={{ p: { xs: 0, md: 2 }, pt: { xs: 0.05 } }}>
            <Card sx={{ margin: '10px' }}>
                <Grid container padding={2}>

                    <Grid container item xs={12} sx={{ paddingBottom: '10px' }}>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <Typography variant="h5" align="center">Assessment</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item xs={7} md={8} pb={3}>
                        <Typography variant="h5">
                            Job Details
                        </Typography>
                        <Divider sx={{ mb: 1 }} />

                        <Typography variant="body1">
                            {data.experience.title}
                        </Typography>
                        <Typography variant="body2">{data.experience.start_date} - {data.experience.end_date || "Current"}</Typography>

                        <Typography variant="body2">
                            {data.experience.organization && data.experience.location
                                ? `${data.experience.organization} | ${data.experience.location}`
                                : data.experience.organization || data.experience.location}
                        </Typography>
                    </Grid>
                    <Grid container item xs={5} md={4}
                        paddingLeft={isMobile ? '10px' : '20px'}
                        paddingTop={'10px'}
                        display="flex"
                        alignItems="center"
                    >
                        <Grid item xs={12} mt={-4}>
                            <Stack direction={'row'} justifyContent={isMobile && 'space-between'}>
                                <Typography sx={{ marginRight: isMobile ? null : '80px' }} variant="body2">
                                    Agree
                                </Typography>
                                <Typography variant="body2">
                                    Disagree
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} >
                            <Assessment
                                value={assessmentValues[0]}
                                index={0}
                                setValues={setAssessmentValues}
                                comments={comments}
                                setComments={setComments}
                                setValidationError={setValidationError}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={7} md={8} pb={2}>
                        <Typography variant="h5">
                            Overview
                        </Typography>
                        <Divider sx={{ mb: 1 }} />

                        <Typography variant="body1">
                            {data.experience.overview}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={4}
                        paddingLeft={isMobile ? '10px' : '20px'}
                        paddingTop={'10px'}
                        display="flex"
                        alignItems="center"
                    >
                        <Assessment
                            value={assessmentValues[1]}
                            index={1}
                            setValues={setAssessmentValues}
                            comments={comments}
                            setComments={setComments}
                            setValidationError={setValidationError}
                        />
                    </Grid>

                    {data.skills.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                Skills
                            </Typography>
                            <Divider sx={{ mb: 1 }} />

                            <Grid container>
                                {['Skill', 'Frequency', 'Description'].map((header, index) => {
                                    if (isMobile && index === 2) return null;
                                    return (
                                        <Grid
                                            item
                                            xs={3.5}
                                            md={index < 2 ? 2 : 8}
                                            key={header}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ fontWeight: 'bold', borderBottom: '1px solid #6c6e70' }}
                                            >
                                                {header}
                                            </Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>


                            {data.skills.map((row, index) => (
                                <Grid container alignItems="center">
                                    <Grid container item xs={7} md={8} key={index}>
                                        <Grid item xs={6} md={3} sx={{ wordWrap: 'break-word' }}>
                                            <Typography>{row.skill}</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ wordWrap: 'break-word' }}>
                                            <Typography>{row.frequency}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} pt={isMobile ? '10px' : null}>
                                            <Typography>{row.description}</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={5} md={4} key={`assessment-${index}`} paddingLeft={isMobile ? '10px' : '20px'} py={2}>
                                        <Assessment
                                            value={assessmentValues[index + 2]}
                                            index={index + 2}
                                            setValues={setAssessmentValues}
                                            comments={comments}
                                            setComments={setComments}
                                            setValidationError={setValidationError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
                <Grid container justifyContent="center">
                    {validationError && (
                        <Grid item xs={12}>
                            <Typography color="error" align="center">
                                Please complete all assessments before submitting
                            </Typography>
                        </Grid>
                    )}
                    <Grid item py={1}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            disabled={buttonLoading}
                            startIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
                            onClick={() => handleSubmit()}>
                            {buttonLoading ? "Sending" : "Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}