import { Slider } from "@mui/material";
import { styled } from "@mui/system";

export const sliderStyleMobile = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#E2E3E4",
  zIndex: 1,
};
export const sliderStyleWeb = {
  position: "absolute",
  top: "45%",
  transform: "translateY(-50%)",
  color: "#E2E3E4",
  zIndex: 1,
};

export const StyledSlider = styled(Slider)(({ theme }) => ({
  ".MuiSlider-track": {
    height: 35,
    borderRadius: '14px 0px 0px 14px',
    border: "none",
    boxShadow: 'none'
  },
  ".MuiSlider-rail": {
    height: 35,
    opacity: 1,
    borderRadius: '14px',
    marginLeft: '1px'
  },
  ".MuiSlider-thumb": {
    display: "none",
  },
}));
