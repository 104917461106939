import { useState } from "react";
import {
	Stack,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Tooltip,
	Button,
} from "@mui/material";
import SkillsTableRow from "./SkillsTableRow";
import { tableRowBorderStyle } from "./styles";
import NewSkillModal from "./NewSkillModal";

export default function ExperienceModalSkills({
	experienceID,
	setChangedSkills,
	isUpdatingSkill,
	setIsUpdatingSkill,
	skillData
}) {
	const [skillDataCopy, setSkillDataCopy] = useState([...skillData]);
	const [open, setOpen] = useState(false);
	const MAX_SKILLS = 5;
	return (
		<>
			<Stack
				direction="column"
				alignItems="left"
				pb={1}
				sx={{ color: "secondary" }}
			>
				<Typography>
					Adding skills help Peter Lucas determine your strengths and
					competencies, and will be used in our assessments of best
					fit.
				</Typography>
			</Stack>

			<TableContainer>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							<TableCell
								align="left"
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
								width={450}
							>
								Skill
							</TableCell>
							<TableCell
								align="left"
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
								width={250}
							>
								Frequency
							</TableCell>
							<TableCell
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
							>
								{/* This cell header is left empty for the Delete/Clear button */}
							</TableCell>
							<TableCell
								sx={{
									...tableRowBorderStyle,
									fontWeight: "bold",
								}}
							>
								{/* This cell header is left empty for the Edit button */}
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{skillDataCopy.map((row, index) => (
							<SkillsTableRow
								row_data={row}
								key={index}
								setSkillData={setSkillDataCopy}
								skillData={skillDataCopy}
								setChangedSkills={setChangedSkills}
								isUpdatingSkill={isUpdatingSkill}
								setIsUpdatingSkill={setIsUpdatingSkill}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Tooltip
				title={skillDataCopy.length >= MAX_SKILLS ? `Maximum of ${MAX_SKILLS} skills` : ""}
				arrow
				placement='top'
			>
				<span>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							setOpen(true);
						}}
						disabled={skillDataCopy.length >= MAX_SKILLS}
						sx={{
							marginTop: "13px",
							marginRight: "10px",
						}}
					>
						Add Skill
					</Button>
				</span>

			</Tooltip>

			{open && (
				<NewSkillModal
					open={open}
					setOpen={setOpen}
					experienceID={experienceID}
					setChangedSkills={setChangedSkills}
					curSkills={skillDataCopy}
					setSkillData={setSkillDataCopy}
				/>
			)}
		</>
	);
}
