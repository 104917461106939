import { useState } from "react";
import {
  Box,
  TextField,
  Chip,
  Tooltip,
  InputAdornment,
  IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TwoButtonModal from "../../../../../components/modals/TwoButtonModal";

export default function DesignationsMultiSelect({
  values,
  handleDesignationsClick,
  maxValues,
}) {
  const [showNewDesignationModal, setShowNewDesignationModal] = useState(false);
  const [newDesignation, setNewDesignation] = useState("");
  const [error, setError] = useState(false);

  function openModal() {
    if (values.length < maxValues) {
      setShowNewDesignationModal(true);
    }
  }

  function closeModal() {
    setNewDesignation("");
    setShowNewDesignationModal(false);
  }

  function addDesignation() {
    if (newDesignation && !values.includes(newDesignation)) {
      setError(false);
      handleDesignationsClick(null, newDesignation);
      closeModal();
    } else {
      setError(true);
    }
  }

  function removeDesignation(designation) {
    handleDesignationsClick(null, designation);
  }

  function renderChips() {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
          padding: '8px',
          pointerEvents: 'none',
        }}
      >
        {values.map((value) => (
          <Chip
            key={value}
            label={value}
            size="small"
            onClick={() => removeDesignation(value)}
            sx={{
              marginRight: 0.5,
              pointerEvents: 'auto',
              cursor: 'pointer',
              border: "1px solid #808080",
            }}
          />
        ))}
      </Box>
    );
  }

  return (
    <>
      {showNewDesignationModal && (
        <TwoButtonModal
          show={showNewDesignationModal}
          modalTitle="Add Designation"
          modalMessage={
            <TextField
              label="Designation"
              autoFocus
              value={newDesignation}
              onChange={(event) => setNewDesignation(event.target.value)}
              fullWidth
              required
              inputProps={{ maxLength: 20 }}
              error={error}
            />
          }
          closeButtonText="Cancel"
          closeButtonCallback={closeModal}
          okButtonText="Add"
          okButtonCallback={addDesignation}
        />
      )}

      <Tooltip
        title={values.length === maxValues ? `Remove a designation before adding another` : `Add up to ${maxValues} designations`}
        arrow
        placement="top"
      >
        <Box sx={{ position: 'relative', width: '100%' }}>
          <TextField
            label="Designations"
            variant="outlined"
            size="small"
            fullWidth
            onClick={openModal}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={openModal}
                    edge="end"
                    disabled={values.length >= maxValues}
                    sx={{
                      color: values.length >= maxValues ? 'grey' : 'white',
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            multiline
            minRows={1}
            value={values.join(', ')}
            sx={{
              '& .MuiInputBase-input': {
                color: 'transparent',
              },
            }}
          />

          {values.length > 0 && renderChips()}
        </Box>
      </Tooltip>
    </>
  );
}
