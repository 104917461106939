import { Box, Tabs, Tab } from "@mui/material";

export default function MyTabs({ index, setIndex, isLoading }) {
	return (
		<div style={{ display: "inline-block" }}>
			<Box
				sx={{
					border: "1px solid #000",
					borderBottom: 1,
					borderColor: "divider",
				}}
			>
				<Tabs
					value={index}
					onChange={(event, newValue) => setIndex(newValue)}
					indicatorColor="primary"
					textColor="primary"
				>
					<Tab label="Overview" disabled={isLoading} />
					<Tab label="Users" disabled={isLoading} />
					<Tab label="Enrollments" disabled={isLoading} />
					<Tab label="Tasks" disabled={isLoading} />
				</Tabs>
			</Box>
		</div>
	);
}
