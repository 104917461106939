import {
	Grid,
	Box,
	Button,
	Container,
	CssBaseline,
	MobileStepper,
	Paper,
	Typography,
	CircularProgress
} from "@mui/material";
import { useState } from "react";
import { SessionUtils } from "../../../../utilities/SessionUtils";
import { RESTUtils } from "../../../../utilities/RESTUtils";
import { BrowserUtils } from "../../../../utilities/BrowserUtils";
import CircleRadio from "../components/CircleRadio";
import { projectStrengthQuestions, groupedPairs } from "./data";
// import EightStageGraph from "../../personality/eightStage/EightStageGraph";

export default function ProjectStrengthsComponent() {
	const user = SessionUtils.getCurrentUserSession();
	const [onIntro, setOnIntro] = useState(true);
	const [activeStep, setActiveStep] = useState(0);
	const [values, setValues] = useState({
		identify: Array(5).fill(3),
		invent: Array(5).fill(3),
		evaluate: Array(5).fill(3),
		plan: Array(5).fill(3),
		lead: Array(5).fill(3),
		coordinate: Array(5).fill(3),
		control: Array(5).fill(3),
		finish: Array(5).fill(3),
	});
	const [buttonLoading, setButtonLoading] = useState(false);

	const NUM_OPTIONS = 5;
	const totalSteps = 5;

	function handleNext() {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	function handleBack() {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	async function handleSubmit() {
		setButtonLoading(true);
		//do it this way for the specific order that we can then convert to array
		const stages = [
			"identify",
			"invent",
			"evaluate",
			"plan",
			"lead",
			"coordinate",
			"control",
			"finish",
		];
		const totals = Array(7);
		let count = 0;
		values['evaluate'][0] = NUM_OPTIONS + 1 - values['evaluate'][0];
		values['evaluate'][2] = NUM_OPTIONS + 1 - values['evaluate'][2];
		values['lead'][0] = NUM_OPTIONS + 1 - values['lead'][0];
		values['control'][3] = NUM_OPTIONS + 1 - values['control'][3];
		values['finish'][3] = NUM_OPTIONS + 1 - values['finish'][3];

		for (const category of stages) {
			// console.log(category)
			console.log(values[category])

			totals[count] = values[category].reduce(
				(acc, value) => acc + value,
				0
			);
			count++;
		}
		const mean =
			totals.reduce((sum, value) => sum + value, 0) / totals.length;
		const std = Math.sqrt(
			totals.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) /
			totals.length
		);
		let scores;
		if (std === 0) {
			scores = Array(8).fill(0.1);
		} else {
			scores = totals.map((element) =>
				((element - mean) / std).toFixed(2)
			);
		}
		values.totals = scores;
		values.user_data = user.uuid;

		const response = await RESTUtils.POST(
			RESTUtils.getAPIUrl() + `api/users/saveProjectStrengths/`,
			values
		);
		if (response.status === 200 || response.status === 201) {
			BrowserUtils.redirect(`/profile/edit/${user.uuid}`);
		} else {
			console.log(response.data);
		}
		setButtonLoading(false);
	};

	function pageContent() {
		const curPage = groupedPairs[activeStep].map((question) => {
			const category = question[0];
			const index = question[1];

			return (
				<Grid
					item
					key={`${category}-${index}`}
					xs={12}
					textAlign="center"
				>
					<CircleRadio
						value={values[category][index]}
						category={category}
						index={index}
						question={projectStrengthQuestions[category][index]}
						setValues={setValues}
						NUM_OPTIONS={NUM_OPTIONS}
					/>
				</Grid>
			);
		});

		return (
			<Grid container spacing={2}>
				{curPage}
			</Grid>
		);
	};

	return (
		<Container component="main" maxWidth="sm" sx={{ pt: 1 }}>
			<Paper
				elevation={6}
				sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
			>
				<CssBaseline />


				{onIntro === true ? (
					<Grid container padding={2} justifyContent={'center'}>
						<Grid item xs={12} textAlign={'center'}>
							<Typography variant="h5">
								Project Strengths Quiz
							</Typography>
						</Grid>
						<Grid item xs={12} textAlign={'center'} py={2}>
							<Typography variant="body1" textAlign={'left'}>
								The Project Strengths quiz helps identify the stages/activities of project management you prefer, and those that you may shy away from.
								The results of this quiz display a normalized graph showing your preferences for several stages of the project lifecycle.
								If the results don't match your expectations, don't worry - it's just a quick quiz!
							</Typography>
							{/* <EightStageGraph
								eightStageData={{
									"totals": [
										-1.48,
										0.89,
										-0.69,
										0.89,
										1.68,
										-0.69,
										-0.69,
										0.1
									]
								}}
								showAxesLabel={true}
							/> */}
							<Typography variant="body1" textAlign={'left'} sx={{ py: 2.5 }}>
								Your highest rated stage(s) are considered your project strengths, and are reflected in the badges shown on your profile.
							</Typography>
							<img
								src="/images/badgeExamples.png"
								alt="Project Strengths"
								style={{ width: '100%', border: '2px solid black' }}
							/>
						</Grid>
						<Button variant="outlined" color="secondary" onClick={() => { setOnIntro(false) }}>
							Begin
						</Button>
					</Grid>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
						component="form"
					>
						<Typography
							component="p"
							variant="h6"
							color={"secondary.main"}
						>
							Project Strengths Questionnaire
						</Typography>

						<MobileStepper
							variant="dots"
							steps={totalSteps}
							position="static"
							activeStep={activeStep}
							sx={{
								flexGrow: 1,
								width: "100%",
								backgroundColor: "transparent",
								"& .MuiMobileStepper-dot": {
									backgroundColor: "grey",
								},
								"& .MuiMobileStepper-dotActive": {
									backgroundColor: "primary.main",
								},
								border: "0px",
							}}
							nextButton={
								<>
									<Button
										onClick={handleNext}
										disabled={activeStep === totalSteps - 1}
										variant="outlined"
										size="small"
										color="secondary"
									>
										Next
									</Button>
								</>
							}
							backButton={
								<Button
									onClick={activeStep === 0 ? () => setOnIntro(true) : handleBack}
									variant="outlined"
									size="small"
									color="secondary"
								>
									Back
								</Button>
							}
						/>
						{pageContent()}

						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								paddingTop: "15px",
							}}
						>
							<Button
								onClick={activeStep === 0 ? () => setOnIntro(true) : handleBack}
								variant="outlined"
								size="small"
								color="secondary"
							>
								Back
							</Button>
							{activeStep !== totalSteps - 1 ? (
								<Button
									onClick={handleNext}
									disabled={activeStep === totalSteps - 1}
									variant="outlined"
									size="small"
									color="secondary"
								>
									Next
								</Button>
							) : (
								<Button
									onClick={handleSubmit}
									variant="outlined"
									size="small"
									disabled={!(activeStep === totalSteps - 1) || buttonLoading}
									leadIcon={buttonLoading && <CircularProgress color="inherit" size={20} />}
								>
									Submit
								</Button>
							)}
						</Box>
					</Box>
				)}
			</Paper>
		</Container>
	);
}
